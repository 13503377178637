import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function DashboardResumeCard(props) {
  const { title, amount, quantity, backgroundColor, icon } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Paper style={{ padding: 20, margin: 5, backgroundColor: backgroundColor }}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={2} sm={2}>
          {icon}
        </Grid>
        <Grid item xs={10} sm={10}>
          <Typography variant={"subtitle1"} align="left" style={{ padding: 0 }}>
            {title}
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant={"subtitle1"} align="right">
            Total:
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            color="primary"
            align="center"
          >
            {Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(amount)}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant={"subtitle1"} align="right">
            Cantidad:
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            color="primary"
            align="center"
          >
            {Math.floor(quantity * 100) / 100}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
