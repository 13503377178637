import * as types from "./action-types";
import {
  auth,
  firestore,
  teams,
  storage,
  events,
  tournaments,
  tournamentResults,
  eventSpecificResults,
  participantsList,
} from "../../firebase/firebasejs";
import { store } from "../configureStore";
import { showSnackbar } from "./atlete-actions";

//###########################################################################
export function getTournamentsList(businessId) {
  return (dispatch) => {
    console.log("getTeam data");
    return firestore
      .collection(tournaments)
      .where("businessId", "==", businessId)
      .onSnapshot(function(querySnapshot) {
        var tournamentsListARRAY = [];
        querySnapshot.forEach(function(doc) {
          const tournaments = { ...doc.data(), tournamentId: doc.id };
          tournamentsListARRAY.push(tournaments);
        });
        dispatch({
          type: types.TOURNAMENTS_LIST,
          tournamentsList: tournamentsListARRAY,
        });
      });
  };
}

//###########################################################################
export function getTournamentData(tournamentID) {
  return (dispatch) => {
    console.log("getTournament data");
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .onSnapshot(function(doc) {
        if (doc.exists) {
          if (doc.data()) {
            const tournament = { ...doc.data(), tournamentId: doc.id };
            dispatch({
              type: types.TOURNAMENT_DATA,
              tournament: tournament,
              tournamentID: tournamentID,
            });
          }
          console.log("Document existe");
        } else {
          dispatch({
            type: types.TOURNAMENT_DATA,
            tournament: null,
            tournamentID: null,
          });
          console.log("No such document!");
        }
      });
  };
}

//###########################################################################
export function updateTournamentAtribute(tournamentID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .set(objectChage, { merge: true })
      .then(() => {
        console.log("Dato del usuario actualizado!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function createTournament(userID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .add(objectChage)
      .then(function(docRef) {
        console.log("evento creado!");
        dispatch({
          type: types.TOURNAMENT_CREATED,
          tournamentCreatedID: docRef.id,
        });
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
        dispatch({
          type: types.TOURNAMENT_CREATED,
          tournamentCreatedID: null,
        });
      });
  };
}
//###########################################################################
export function resetCreated() {
  return (dispatch) => {
    dispatch({
      type: types.TOURNAMENT_CREATED,
      tournamentCreatedID: null,
    });
  };
}
//###########################################################################
export function createEventResult(tournamentID, resultConfig, atheteAccount) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(tournamentResults)
      .add(resultConfig)
      .then(function(docRef) {
        console.log("Resultado creado!");
      })
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  };
}
//###########################################################################
export function updateResultAtribute(tournamentID, resultID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(tournamentResults)
      .doc(resultID)
      .set(objectChage, { merge: true })
      .then(() => {
        console.log("Dato del resultado editado!");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
//###########################################################################
export function createTournamentParticipant(tournamentID, objectChage) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(participantsList)
      .add(objectChage)
      .then(function(docRef) {
        console.log("evento creado!");
        dispatch(showSnackbar(true, "Particiante creado exitosamente", 4000));
      })
      .catch(function(error) {
        dispatch(showSnackbar(true, "Error, intente de nuevo", 4000));
        console.error("Error adding document: ", error);
      });
  };
}
//###########################################################################
export function updateParticipantAtribute(
  tournamentID,
  participantID,
  objectChage
) {
  return (dispatch) => {
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(participantsList)
      .doc(participantID)
      .set(objectChage, { merge: true })
      .then(() => {
        console.log("Dato del resultado editado!");
        dispatch(showSnackbar(true, "Particiante editado exitosamente", 4000));
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
        dispatch(showSnackbar(true, "Error, intente de nuevo", 4000));
      });
  };
}
//###########################################################################
export function getParticipantsList(tournamentID) {
  return (dispatch) => {
    console.log("getResults data");
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(participantsList)
      .onSnapshot(function(querySnapshot) {
        var participantsARRAY = [];
        querySnapshot.forEach(function(doc) {
          const results = { ...doc.data(), participantID: doc.id };
          participantsARRAY.push(results);
        });
        dispatch({
          type: types.PARTICIPANTS_LIST,
          participantsList: participantsARRAY,
        });
      });
  };
}

//###########################################################################
export function getResultsList(tournamentID) {
  return (dispatch) => {
    console.log("getResults data");
    return firestore
      .collection(tournaments)
      .doc(tournamentID)
      .collection(tournamentResults)
      .onSnapshot(function(querySnapshot) {
        var tournamentResultsARRAY = [];
        querySnapshot.forEach(function(doc) {
          const results = { ...doc.data(), resultID: doc.id };
          tournamentResultsARRAY.push(results);
        });
        dispatch({
          type: types.TOURNAMENT_RESULTS,
          tournamentResults: tournamentResultsARRAY,
          tournamentID: tournamentID,
        });
      });
  };
}
// ###########################################################################
// export function getSpecificResultsList(eventID, resultID) {
//   return (dispatch) => {
//     console.log("getResults data");
//     return firestore
//       .collection(events)
//       .doc(eventID)
//       .collection(eventResults)
//       .doc(resultID)
//       .collection(eventSpecificResults)
//       .get()
//       .then(function(querySnapshot) {
//         var eventResultsARRAY = [];
//         querySnapshot.forEach(function(doc) {
//           const results = { ...doc.data(), resultID: doc.id };
//           eventResultsARRAY.push(results);
//         });
//         dispatch({
//           type: types.EVENT_SPECIFIC_RESULTS,
//           eventSpecificResults: eventResultsARRAY,
//           resultID: resultID,
//         });
//       });
//   };
// }
// //###########################################################################
// export function getAllResultsList(eventID, eventResultsList) {
//   return (dispatch) => {
//     console.log("getResults data");
//     for (var i in eventResultsList) {
//       console.log("getResults data", eventID);
//       console.log("getResults data", eventResultsList);

//       dispatch(getSpecificResultsList(eventID, eventResultsList[i].resultID));
//     }
//   };
// }
// export function upuploadResultItem(eventID, resultID, objectChage) {
//   return (dispatch) => {
//     return firestore
//       .collection(events)
//       .doc(eventID)
//       .collection(eventResults)
//       .doc(resultID)
//       .collection(eventSpecificResults)
//       .doc("number_" + objectChage.number)
//       .set(objectChage)
//       .then(() => {
//         console.log("Dato del resultado editado!");
//       })
//       .catch(function(error) {
//         console.error("Error writing document: ", error);
//       });
//   };
// }
