import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";

import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import { updateEventObject } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function DefaultDistancesAndCategories(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventDetail = useSelector((state) => state.events.eventDetail);

  function registryCopy() {
    let eventSubEventsRegistryNew = JSON.parse(
      JSON.stringify(eventSubEventsRegistry)
    );
    for (var i in eventSubEventsRegistryNew) {
      eventSubEventsRegistryNew[i].hSalidas = {
        serie_1: {
          descripcionSerie:
            "Salida " + eventSubEventsRegistryNew[i].descripcionModalidad,
          hS: "0000-00-00 00:00:00",
          hsTimeStamp: 0,
          serie: 1,
        },
      };
      delete eventSubEventsRegistryNew[i].configModalidad;
      delete eventSubEventsRegistryNew[i].userCreate;
      delete eventSubEventsRegistryNew[i].hide;
      delete eventSubEventsRegistryNew[i].order;
      eventSubEventsRegistryNew[i].modalidad =
        eventSubEventsRegistryNew[i].modalidad / 1;
      const genders = eventSubEventsRegistryNew[i].ramaCat;
      for (var e in genders) {
        const categories = genders[e].categorias;
        for (var f in categories) {
          delete eventSubEventsRegistryNew[i].ramaCat[e].categorias[f]
            .camposAdicionalesKey;
          delete eventSubEventsRegistryNew[i].ramaCat[e].categorias[f]
            .capacidad;
          delete eventSubEventsRegistryNew[i].ramaCat[e].categorias[f]
            .inscritos;
          delete eventSubEventsRegistryNew[i].ramaCat[e].categorias[f]
            .precioKey;
          eventSubEventsRegistryNew[i].ramaCat[e].categorias[f].serie = 1;
        }
      }
    }
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "modalidades/",
        eventSubEventsRegistryNew
      )
    );
  }
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h5"} component="h2">
                Utiliza esta opción para generar distancias y categorías por
                defecto
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              {eventSubEventsRegistry && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "30px" }}
                  onClick={() => registryCopy()}
                >
                  Copiar distancias y categorías de las inscripciones
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
