import React from "react";

import { Switch, Route, Router } from "react-router-dom";

import Home from "./components/pages/Home";
import CreateAccount from "./components/Login/CreateAccountNew";
import Login from "./components/Login/Login";
import PassworRestore from "./components/Login/PassworRestore";
//import PastEventsList from "./components/pages/pastevents/PastEventsList";
//import PastEventShow from "./components/pages/pastevents/PastEventShow";
//import NextEventsList from "./components/pages/nextEvents/NextEventsList";
//import NextEventShow from "./components/pages/nextEvents/NextEventShow";
//import NextEventShowANT from "./components/pages/nextEvents/NextEventShowANT";
//import RegistryConfirmationPublic from "./components/pages/nextEvents/Registry/RegistryConfirmation/RegistryConfirmationPublic";

import AthletesCotainer from "./components/pages/Athletes/AthletesCotainer";

import MyEventsCotainer from "./components/pages/myEvents/MyEventsCotainer";
import DashportServicesList from "./components/pages/dashportServices/DashportServicesList";
import AboutDashport from "./components/pages/dashportServices/AboutDashport";

import AuthorizedUsersList from "./components/pages/AuthorizedUsers/AuthorizedUsersList";
import AuthorizedUsersShow from "./components/pages/AuthorizedUsers/AuthorizedUsersShow";

import DevRouter from "./components/dev/DevRouter";
import OrganizerWebContainer from "./components/pages/AuthorizedUsers/OrganizerWeb/OrganizerWebContainer";
import SpecificOrganizerWebContainer from "./components/pages/AuthorizedUsers/OrganizerWeb/SpecificOrganizerWebContainer";
import BusinessConfigurationContainer from "./components/pages/AuthorizedUsers/BusinessConfiguration/BusinessConfigurationContainer";
import BusinessListContainer from "./components/pages/AuthorizedUsers/BusinessConfiguration/BusinessListContainer";
import SpecificTournamentsContainer from "./components/pages/AuthorizedUsers/TournamentsConfig/SpecificTournament/SpecificTournamentsContainer";
import CustomView from "./components/pages/AuthorizedUsers/CustomViews/CustomView";
import CustomViewUrl from "./components/pages/AuthorizedUsers/CustomViews/CustomViewUrl";

export default function Root() {
  return (
    <Switch>
      <Route exact path="/registro" component={CreateAccount} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/password-restore" component={PassworRestore} />
      <Route exact path="/dev/:tipo" component={DevRouter} />
      <Route exact path="/servicios" component={DashportServicesList} />
      <Route exact path="/nosotros" component={AboutDashport} />
      <Route exact path="/eventos-organizado" component={AuthorizedUsersList} />

      <Route exact path="/listado-empresas" component={BusinessListContainer} />
      <Route
        exact
        path="/:businessId/inicio"
        component={BusinessConfigurationContainer}
      />
      <Route
        exact
        path="/:businessId/torneo/:tournamentId"
        component={SpecificTournamentsContainer}
      />

      <Route
        exact
        path="/usuario-autorizado/:EventoIndex"
        component={AuthorizedUsersShow}
      />
      <Route
        exact
        path="/eventos-organizados"
        component={AuthorizedUsersList}
      />

      <Route
        exact
        path="/eventos-organizados/:EventoIndex"
        component={AuthorizedUsersShow}
      />
      <Route
        exact
        path="/eventos-organizados/:EventoIndex/:idregistro"
        component={AuthorizedUsersShow}
      />
      <Route
        exact
        path="/views/:EventoIndex/:viewIndex/:number"
        component={CustomViewUrl}
      />
      <Route
        exact
        path="/views/:EventoIndex/:viewIndex/"
        component={CustomViewUrl}
      />

      <Route
        exact
        path="/eventos-organizados/:EventoIndex/:idregistro/:descarga"
        component={AuthorizedUsersShow}
      />

      <Route
        exact
        path="/:businessId/web/:domain"
        component={SpecificOrganizerWebContainer}
      />

      <Route exact path="/atletas/" component={AthletesCotainer} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
}
