import React from "react";
import { connect } from "react-redux";
import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import {
  completeNull,
  modalidadCateogoriaNameByCode,
} from "../../../../../services/utils";
import IncomeTableCont from "./IncomeTableCont";
import DynamicInputs from "../../../../DynamicInput/DynamicInputs";
import DownloadButtomAllData from "../DownloadData/DownloadButtomAllDataNew";
import InputRenderOnChange from "../../../../Fields/InputRenderOnChange";
import FormRender from "../../../../Fields/FormRender";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class IncomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 1,
      quantity: 50,
    };
  }

  camposAdicionalesModalidad = () => {
    var camposAdicionalesModalidad = [];
    var camposAdicionalesModalidadHTML = [];

    var formulario = this.props.registryConfiguration.formulario;
    for (var i in formulario) {
      if (formulario[i].tipoCampo != "titulo") {
        camposAdicionalesModalidad.push(formulario[i]);
        camposAdicionalesModalidadHTML.push(
          <TableCell
            key={formulario[i].campo}
            data-field={formulario[i].etiqueta}
          >
            {formulario[i].etiqueta}
          </TableCell>
        );
      }
    }
    var modalidadesBuscar = this.props.eventSubEventsRegistry;

    var arrayCampoModalidad = [];

    for (var i in modalidadesBuscar) {
      var modalidadCampos =
        modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
      if (modalidadCampos != undefined) {
        for (var e in modalidadCampos) {
          var campos = modalidadCampos[e];
          for (var f in campos) {
            if (campos[f].tipoCampo != "titulo") {
              if (!arrayCampoModalidad.includes(campos[f].iDCampo)) {
                arrayCampoModalidad.push(campos[f].iDCampo);
                camposAdicionalesModalidad.push(campos[f]);
                camposAdicionalesModalidadHTML.push(
                  <TableCell
                    key={i + e + f + "CA"}
                    data-field={campos[f].etiqueta}
                  >
                    {campos[f].etiqueta}
                  </TableCell>
                );
                console.log("Campos " + campos[f].campo);
              }
            }
          }
        }
      }
    }

    return {
      camposAdicionalesModalidad: camposAdicionalesModalidad,
      camposAdicionalesModalidadHTML: camposAdicionalesModalidadHTML,
    };
  };

  productosAdicionalesModalidadTitulo = () => {
    var productosAdicionalesModalidadTitulo = [];
    var productosAdicionalesModalidadTituloHTML = [];

    var modalidadesBuscar = this.props.eventSubEventsRegistry;
    var arrayCodigoProducto = [];

    for (var i in modalidadesBuscar) {
      var modalidadProductos =
        modalidadesBuscar[i].configModalidad.productosAdicionales !== undefined
          ? modalidadesBuscar[i].configModalidad.productosAdicionales
          : {};

      if (modalidadProductos) {
        modalidadProductos["codeDiscount"] = {
          codigoProducto: "códigoDescuento",
          descripcionProducto: "Código de descuento",
          formularioProducto: [
            {
              anchoCampo: "s12 m6 l6",
              campo: 0,
              etiqueta: "code",
              iDCampo: "code",
              longitud: 100,
              observacion: "",
              regex: "",
              regexMessage: "",
              tipoCampo: "text",
              validacion: "Requerido",
              valor: "",
              hideMasterOrganizador: true,
              hideMasterCliente: true,
              hideInPublic: true,
              hideInscriptor: true,
            },
          ],
          maxCantidad: 1,
          nombreProducto: "Código de descuento",
          precioProducto: 100,
        };

        console.log("Productos", modalidadProductos);
      }

      if (modalidadProductos !== undefined) {
        for (var e in modalidadProductos) {
          if (
            !arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)
          ) {
            arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
            productosAdicionalesModalidadTitulo.push(modalidadProductos[e]);

            productosAdicionalesModalidadTituloHTML.push(
              <TableCell
                key={i + e + "cantidad"}
                data-field={`${modalidadProductos[e].nombreProducto}-Cantidad`}
              >
                {modalidadProductos[e].nombreProducto} - Cantidad
              </TableCell>
            );
            productosAdicionalesModalidadTituloHTML.push(
              <TableCell
                key={i + e + "precio"}
                data-field={`${modalidadProductos[e].nombreProducto}-Precio`}
              >
                {modalidadProductos[e].nombreProducto} - Precio
              </TableCell>
            );
            productosAdicionalesModalidadTituloHTML.push(
              <TableCell
                key={i + e + "total"}
                data-field={`${modalidadProductos[e].nombreProducto}-Total`}
              >
                {modalidadProductos[e].nombreProducto} - Total
              </TableCell>
            );
            var formularioProducto = modalidadProductos[e].formularioProducto;
            if (formularioProducto != undefined) {
              for (var f in formularioProducto) {
                productosAdicionalesModalidadTituloHTML.push(
                  <TableCell
                    key={i + e + f + "input"}
                    data-field={formularioProducto[f].etiqueta}
                  >
                    {formularioProducto[f].etiqueta}
                  </TableCell>
                );
              }
            }

            console.log(JSON.stringify(modalidadProductos[e]));
          }
        }
      }
    }

    return {
      productosAdicionalesModalidadTitulo: productosAdicionalesModalidadTitulo,
      productosAdicionalesModalidadTituloHTML: productosAdicionalesModalidadTituloHTML,
    };
  };

  getValue = (valor) => {
    this.setState(valor);
  };
  getMoreData = () => {
    this.setState({ quantity: this.state.quantity + 200 });
    console.log(this.state.quantity);
  };

  render() {
    const sticky = {
      position: "sticky",
      left: 0,
      backgroundColor: "#ffffff",
      borderRight: "2px solid red",
      zIndex: 1000,
    };
    const sticky1 = {
      // position: "sticky",
      // top: 0,
      // backgroundColor: "#ffffff",
      // borderRight: "2px solid red",
      // zIndex: 1000,
    };

    const filterSelect = {
      filter: {
        label: "Filtrar Registros",
        errorMessage: "Campo Requerido",
        fieldName: "filter",
        type: "select",
        defaultValue: 1,
        required: true,
        regex: "",
        textSize: "h6",
        optionsArray: [
          {
            label: "Inscripciones completas",
            value: 1,
          },
          {
            label: "Registros incompletos o borrados",
            value: 2,
          },
          {
            label: "Ver todos los registros",
            value: 3,
          },
        ],
      },
    };

    var tituloTablaIngresos;
    var perfilUsuarioEvento = this.props.registryAutorizedUsers[
      this.props.userID
    ].perfil;
    if (
      perfilUsuarioEvento.localeCompare("MasterCliente") == 0 ||
      perfilUsuarioEvento.localeCompare("MasterOrganizador") == 0 ||
      perfilUsuarioEvento.localeCompare("master") == 0
    ) {
      tituloTablaIngresos = (
        <div>
          <Typography variant={"h3"} align="left">
            Listado de inscritos
          </Typography>
          <Typography variant={"subtitle1"} align="left">
            Inlcuye ingresos cobrados en linea y en puntos de venta. Reporte
            disponible solo para usuarios Master
          </Typography>
        </div>
      );
    } else {
      tituloTablaIngresos = (
        <div>
          <Typography variant={"h3"} align="left">
            Listado de inscritos
          </Typography>

          <Typography variant={"subtitle1"} align="left">
            Nota: Reporte de ingresos registrados con tu nombre de usuario
          </Typography>
          <DownloadButtomAllData
            eventParticipants={this.props.eventParticipants}
            reportType={3}
            eventDetail={this.props.eventDetail}
            registryAutorizedUsers={this.props.registryAutorizedUsers}
            userID={this.props.userID}
            registryConfiguration={this.props.registryConfiguration}
            eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          />
        </div>
      );
    }

    var camposAdicionales = this.camposAdicionalesModalidad();

    var productosAdicionales = this.productosAdicionalesModalidadTitulo();

    var apellidosSeparadosSet = this.props.registryConfiguration.configuracion
      .separarApellidos;
    var apellidos = [];
    if (apellidosSeparadosSet) {
      apellidos.push(
        <TableCell key={`itemPatTitle`}>Apellido Paterno</TableCell>
      );
      apellidos.push(
        <TableCell key={`itemMatTitle`}>Apellido Materno</TableCell>
      );
    } else {
      apellidos.push(<TableCell key={`itemPatTitle`}>Apellido</TableCell>);
    }

    // TODO: filtro por modalidad, rama, categoria.
    // TODO agregar vista de cambio a peso.

    const width = "auto";
    // 3000 +
    // camposAdicionales.camposAdicionalesModalidadHTML.length * 80 +
    // productosAdicionales.productosAdicionalesModalidadTituloHTML.length * 80;
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12}>
          {tituloTablaIngresos}
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormRender
            callback={this.getValue}
            formObject={filterSelect}
            submitButtonText={"Ajustar filtro"}
          />
          <Button onClick={() => this.getMoreData()} style={{ width: "100%" }}>
            Mostrar registros anteriores
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer style={{ overflowX: "initial" }}>
            <Table
              style={{ width: width + "px" }}
              aria-label="a dense table"
              stickyHeade
            >
              <TableHead stickyHeader={true}>
                <TableRow>
                  <TableCell align="left" style={sticky}>
                    Número
                  </TableCell>
                  <TableCell align="left">Nombre (solo nombre)</TableCell>
                  {apellidos}
                  <TableCell align="center">Correo</TableCell>
                  <TableCell align="center">Teléfono</TableCell>
                  <TableCell align="center">Modalidad</TableCell>
                  <TableCell align="center">Rama</TableCell>
                  <TableCell align="center">Categoria</TableCell>
                  <TableCell align="center">Usuario</TableCell>
                  <TableCell align="center">Precio cobrado</TableCell>
                  <TableCell align="center">Fecha Registro</TableCell>
                  {camposAdicionales.camposAdicionalesModalidadHTML}
                  {productosAdicionales.productosAdicionalesModalidadTituloHTML}
                  <TableCell align="center">Estatus</TableCell>
                </TableRow>
              </TableHead>
              <IncomeTableCont
                camposAdicionales={camposAdicionales}
                productosAdicionales={productosAdicionales}
                filter={this.state.filter}
                quantity={this.state.quantity}
              />
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventParticipants: state.registry.eventParticipants,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    userID: state.atlete.userID,
    eventDetail: state.events.eventDetail,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const Income = connect(mapStateToProps, mapDispatchToProps)(IncomeView);
export default Income;
