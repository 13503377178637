import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import https from "https";
import ReaderConfigMQTT from "./ReaderConfigMQTT";

import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import CheckChip from "./CheckChip";
import { updateAthleteResult } from "../../../../../data-store/actions/events-actions";
import jsonTags from "./jsonTags.json";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReaderConfigLocal(props) {
  const { business } = props;
  const [ipReader, setIpReader] = useState("192.168.2.2");
  const [reading, setReading] = useState(false);
  const [postMessage, setPostMessage] = useState("");
  const [modeMessage, setModeMessage] = useState("");

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
  }, []);

  const classes = useStyles();
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJaRUJSQSIsImV4cCI6MTY2NzAwMTA2MX0.1k4cOLuNacbI7nDyHe0V2FuHkvy75a45UQkVyv6t4sQdhZHowcjbJPEd-pFBNuxqtxCYCC429P6JS6F2o8HYkA";

  const filter = Object.values(jsonTags).filter(
    (a) => a.tag === "44415348504F525430333437"
  );
  console.log(
    "arrayTranslateChipRaceResultToDashport",
    filter[0].chipNumber,
    filter.length
  );

  function hex_to_ascii(str1) {
    var hex = str1.toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    console.log();
    return str;
  }
  // DASHPORT HEX 44415348504f5254
  console.log(hex_to_ascii("44415348504f525430343034"));

  const date = "2022-10-29T00:55:04.178+0000";
  const fechaHoraSplit = date.split("T");
  const fechaSplit = fechaHoraSplit[0].split("-");
  const year = fechaSplit[0];
  const month = fechaSplit[1];
  const day = fechaSplit[2];
  const hora = fechaHoraSplit[1].split("+")[0];
  const horaSplit = hora.split(":");
  const hourNew =
    horaSplit[0] +
    ":" +
    horaSplit[1] +
    ":" +
    horaSplit[2].split(".")[0] +
    "." +
    horaSplit[2].split(".")[1];

  var fechaHora = year + "-" + month + "-" + day + " " + hourNew;
  console.log("fechaHora", fechaHora);

  // function startReading() {
  //   var config = {
  //     method: "put",
  //     url: "https://192.168.2.2/cloud/start",
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Headers": "*",
  //     },
  //   };

  //   Axios(config)
  //     .then(function(response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }

  // function logIn() {
  //   var config = {
  //     method: "get",
  //     url: "https://fx96005fa548.local/cloud/localRestLogin",
  //     headers: {
  //       Authorization: "Basic YWRtaW46U3BvcnQxQFplYnJh",
  //       "Access-Control-Allow-Origin": true,
  //       "Access-Control-Allow-Headers": true,
  //     },
  //     httpsAgent: new https.Agent({ rejectUnauthorized: false }),
  //   };

  //   Axios(config)
  //     .then(function(response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // }
  // function stopReading() {
  //   console.log("response");
  //   Axios({
  //     url: "https://" + ipReader + "/cloud/start", //your url
  //     method: "PUT",
  //   }).then((response) => {
  //     console.log("response", response);
  //   });
  // }

  //  return <CheckChip />;

  const reader7500 = {
    reader: "fx75007441b0.local",
    auth: "YWRtaW46eERCdkREZHNrZmxtOT8=",
  };
  const reader9600 = {
    reader: "fx96005fa548.local",
    auth: "YWRtaW46U3BvcnQxQFplYnJh",
  };
  const reader96008p = {
    reader: "fx9600760866.local",
    auth: "YWRtaW46eERCdkREZHNrZmxtOT8=",
  };
  const reader9600Dashport = {
    reader: "fx9600f54bd7.local",
    auth: "YWRtaW46U3BvcnQxQFplYnJh",
  };

  const reader = reader9600Dashport;

  function startNew() {
    // metodo de prueba para hacer peticiones desde la web directo al lector
    console.log("start");

    var config = {
      method: "get",
      url: "https://" + reader.reader + "/cloud/localRestLogin",

      headers: {
        Authorization: "Basic " + reader.auth,
      },
      httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    };

    Axios(config)
      .then(function(responseLog) {
        console.log(JSON.stringify(responseLog.data));

        var config = {
          method: "put",
          url: "https://" + reader.reader + "/cloud/start",
          headers: {
            Authorization: "Bearer " + responseLog.data.message,
          },
          httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        };

        Axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data));
            console.log("reading");
          })
          .catch(function(error) {
            console.log(error);
            console.log(error);
          });
      })
      .catch(function(error) {
        console.log(JSON.stringify(error));
        console.log(error);
      });
  }
  function startReadingLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/start",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("reading");
        setReading(true);
        setPostMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        console.log(error);
        setPostMessage(JSON.stringify(error));
      });
  }

  function stopReadingLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/stop",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("lecturas detenidas");

        setReading(false);
        setPostMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setPostMessage(JSON.stringify(error));
        console.log(error);
      });
  }
  function configReaderLocal() {
    var config = {
      method: "get",
      url: "http://localhost:3000/config",
    };

    Axios(config)
      .then(function(response) {
        console.log("config1", JSON.stringify(response.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  // function cambiarNombres() {
  //   console.log("Cambiar Nombres");
  //   for (var i in eventResults) {
  //     if (data[eventResults[i].num]) {
  //       console.log(eventResults[i].num, " - ", data[eventResults[i].num]);

  //       dispatch(
  //         updateAthleteResult(
  //           "evento_" + eventDetail.iDEvento,
  //           eventResults[i].num,
  //           { nombre: data[eventResults[i].num] }
  //         )
  //       );
  //     }
  //   }
  // }

  function getMode() {
    var config = {
      method: "get",
      url: "http://localhost:3000/getMode",
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("Modo obtenido");

        setModeMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setModeMessage(JSON.stringify(error));
        console.log(error);
      });
  }

  function setMode(reader, controlPoint) {
    let antennasArray = [1, 2, 3, 4, 5, 6, 7, 8];
    let antennasPower = [30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0];
    if (reader.antennas === 4) {
      antennasArray = [1, 2, 3, 4];
      antennasPower = [30.0, 30.0, 30.0, 30.0];
    }
    const json = {
      command: "set_mode",
      command_id: "1",
      payload: {
        antennas: antennasArray,
        filter: {
          match: "prefix",
          operation: "include",
          value: "44415348504F5254",
        },
        reportFilter: { duration: 20, type: "RADIO_WIDE" },
        tagMetaData: [
          { userDefined: eventDetail.iDEvento + "/" + controlPoint + "/true" },
        ],
        transmitPower: antennasPower,
        type: "SIMPLE",
        environment: "HIGH_INTERFERENCE",
      },
    };
    var config = {
      method: "get",
      url: "http://localhost:3000/putMode",
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": true,
      },
      data: json,
    };

    Axios(config)
      .then(function(response) {
        console.log(JSON.stringify(response.data));
        console.log("Modo obtenido");

        setModeMessage(JSON.stringify(response.data));
      })
      .catch(function(error) {
        setModeMessage(JSON.stringify(error));
        console.log(error);
      });
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Reader Config
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => startReadingLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Iniciar Lecturas{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => stopReadingLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Detener Lecturas{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              style={{ marginTop: 30 }}
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              Selecciona el punto de control
            </Typography>{" "}
          </Grid>

          {puntosControl &&
            Object.values(puntosControl).map((control) => (
              <Grid item xs={12} sm={3}>
                <Button
                  style={{
                    margin: 10,
                    padding: 10,
                    fontSize: 20,
                  }}
                  onClick={() => setMode(reader, control.kNroPunto)}
                  color="secondary"
                  variant="contained"
                >
                  {control.descripcionFinalPunto}
                </Button>
              </Grid>
            ))}

          <Grid item xs={12} sm={12}>
            <Button
              style={{ margin: 30, padding: 20, fontSize: 20, width: 200 }}
              onClick={() => getMode()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener modo de operación{" "}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: 30 }}>
            <Typography gutterBottom variant={"h1"} component="h2">
              {/* {reading && "Lecturas iniciadas"}
              {!reading && "Lecturas detenidas"} */}
              {postMessage}
            </Typography>{" "}
            <Typography gutterBottom variant={"h1"} component="h5">
              {/* {reading && "Lecturas iniciadas"}
              {!reading && "Lecturas detenidas"} */}
              {modeMessage}
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={6} style={{ margin: 30 }}>
            {/* <Button
              style={{ padding: 50, fontSize: 20, width: 500 }}
              onClick={() => configReaderLocal()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener Config{" "}
            </Button> */}
          </Grid>
        </Grid>

        {/* <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Número
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Salida chip {getListRegistries().totales.salidaChip}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Meta chip {getListRegistries().totales.metaChip}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Meta Manual {getListRegistries().totales.metaJuez}
            </Typography>{" "}
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" variant={"h6"} component="h2">
              Chip salida y meta
            </Typography>{" "}
          </Grid>
        </Grid>

        {getListRegistries().number.map((a, i) => (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ backgroundColor: i % 2 === 0 ? "white" : "#e0e0e0" }}
          >
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.num}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.salidaChip}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.metaChip}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.metaJuez}
              </Typography>{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography align="center" variant={"h6"} component="h2">
                {a.chipOk}
              </Typography>{" "}
            </Grid>
          </Grid>
        ))} */}

        {/* <Button
          style={{ padding: 50, fontSize: 20, width: 500 }}
          onClick={() => cambiarNombres()}
          color="primary"
          variant="contained"
        >
          {" "}
          Cambiar nombres
        </Button> */}

        {/* <CheckChip /> */}
      </Grid>
    </Grid>
  );
}

const arrayTranslateChipRaceResultToDashport = {
  num_e28011606000020a6683f371: 1,
  num_e28011606000020a668229fb: 2,
  num_e28011606000020a668255a1: 3,
  num_e28011606000020a6683f3a3: 4,
  num_e28011606000020a66830bc1: 5,
  num_e28011606000020a66830b20: 6,
  num_e28011606000020a66899bcb: 7,
};
const arrayTranslateChipRaceResultToDashport2 = {
  num_e28011606000020a66887c1d: 700,
  num_e28011606000020a66826ca9: 701,
  num_e28011606000020a6681f383: 702,
  num_e28011606000020a66830ba1: 703,
  num_e28011606000020a6688ed02: 704,
  num_e28011606000020a66893bd1: 705,
  num_e28011606000020a66893bb1: 706,
};
const data = {
  801: `MANUEL JESUS PINTO ARAUJO - ELOY GUZMAN - SARA RENSHAW - FERNANDO MARTIN - MARINA RIBEIRO`,
  802: `RAUL EDUARDO CORREA - MARISOL  CABRERA LAGUNES - RAÚL ALEJANDRO CORREA CABRERA - CRISTIAN  CARDONA RIVERA - CARINA CANTÚ HERNANDEZ`,
  803: `KARINA VIVIANA PALOMO MÉNDEZ - PATRICIA REYES PEÓN - JAIME JOSÉ GUILLERMO PEÓN - ANA LUISA SOBRINO ACOSTA - JOSÉ ERNESTO CERVERA SEGURA`,
  806: `JULIANA DIEZ - SANDRA  CARDENAS - JERSON CUA RAFFUL - JOSE HERNANDEZ - FERNANDO  ECHAVARRIA`,
  807: `ANA LAURA BORGES MIS - LUIS FERNANDO CAUICH CRIOLLO - ARTURO SEBASTIAN JIMENEZ CHALE - FRANCISCO VICENTE HUEZO PEON - RICARDO ALEJANDRO REJON HERRERA`,
  808: `MELISSA LAGUNA - JAIME ENRIQUE REJON AKE - GIMMY SANCHEZ GARCIA - CARLOS PACHECO POOT - LENNY SUSANA BARRERA BLANCO`,
  809: `ANA DANIELA  MARRUFO HERRERA - VICTOR MUNGUIA SAAVEDRA - JENSY  ARCEO ROJINA - JERONIMO MUNGUIA ZARCO - ANDRES  MUNGUIA ZARCO`,
};
