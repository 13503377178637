import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import CreateConvocatoryItem from "./CreateConvocatoryItem";
import CardProduct from "../../../nextEvents/Registry/ShopingCar/CardProduct";
import UpdateConvocatoryItem from "./UpdateConvocatoryItem";
import Convocatory from "./Convocatory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventConvocatoryContainer(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const eventConvocatory = useSelector(
    (state) => state.events.eventConvocatory
  );
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h2"} component="h2">
              Convocatoria
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <CreateConvocatoryItem />
          </Grid>
          <Grid item xs={12}>
            {eventConvocatory && <Convocatory />}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
