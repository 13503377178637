import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import { useTracking } from "react-tracking";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormRender from "../../../Fields/FormRender";
import SaveAthleteDataView from "./PaymentViews/SaveAthleteDataView";
import { updateUserAtribute } from "../../../../data-store/actions/atlete-actions";
import { StandarInputsData } from "./StandarInputsData";
import { translateForm } from "../../../Fields/FieldUtils";
import CurrencyPayment from "./PaymentViews/CurrencyPayment";
import AditionalInputs from "./PaymentViews/AditionalInputs";
import AditionalProducts from "./PaymentViews/AditionalProducts";
import FieldEvent from "./FieldEvent/FieldEvent";
import PaymentDetailRegistryForm from "./PaymentViews/PaymentDetailRegistryForm";
import PaymentsView from "./PaymentViews/PaymentsView";
import OxxoConfirmation from "./PaymentViews/OxxoConfirmation";
import RegistryConfirmation from "./RegistryConfirmation/RegistryConfirmation";
import { resetRegistryParticipantData } from "../../../../data-store/actions/registry-actions";
import { useTracking } from "react-tracking";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
    //    margin: "10px",
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
  },
  color: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function EventRegistryPublicForm(props) {
  const { eventIdDashport, title, name, eventLogo, params } = props;
  const dispatch = useDispatch();

  const { trackEvent } = useTracking();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfigurationData = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );
  const registryParticipantData = useSelector(
    (state) => state.registry.registryParticipantData
  );

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [personalData, setPersonalData] = useState(null);
  const [personalExtraData, setPersonalExtraData] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(true);
  const [subEvent, setSubEvent] = useState(null);
  const [successPayment, setSuccessPayment] = useState(false);
  const [oxxoPayment, setOxxoPayment] = useState(false);
  const [paymentView, setPaymentView] = useState(false);

  const [currentOrder, setCurrentOrder] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [currency, setCurrency] = useState({
    codigo: "MXN",
    tipo: "base",
    tipoCambio: 1,
    texto: "Pesos Méxicanos",
    cambioAPeso: "1",
  });
  const [currencyBase, setCurrencyBase] = useState({
    codigo: "MXN",
    tipo: "base",
    tipoCambio: 1,
    texto: "Pesos Méxicanos",
    cambioAPeso: "1",
  });

  useEffect(() => {
    if (registryConfigurationData.configuracion.moneda) {
      setCurrency(
        Object.values(registryConfigurationData.configuracion.moneda).filter(
          (a) => a.tipo === "base"
        )[0]
      );
      setCurrencyBase(
        Object.values(registryConfigurationData.configuracion.moneda).filter(
          (a) => a.tipo === "base"
        )[0]
      );
    }
    dispatch(resetRegistryParticipantData());
  }, []);

  let form = setAthleteDataToForm(
    translateForm(StandarInputsData),
    athleteAccount
  );
  let formExtra = registryConfigurationData
    ? translateForm(registryConfigurationData.gruposFormularios.general)
    : {};

  let formDistanceAndExtra = {
    distancia: {
      order: 0,
      label: "Distancia",
      errorMessage: "",
      fieldName: "modalidad",
      type: "event",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      currency: currency,
      currentAge: registryConfigurationData.configuracion.edadActual,
      birth: personalData ? personalData.fechaNacimiento : 0,
      gender: personalData ? (personalData.genero === "M" ? "V" : "F") : "",
      eventDate: eventDetail.fechaEvento,
      optionsArray: eventSubEventsRegistry ? eventSubEventsRegistry : {},
    },
    ...formExtra,
  };
  console.log("formDistanceAndExtra", formDistanceAndExtra);
  console.log("currentOrder", currentOrder);

  function setAthleteDataToForm(form, athleteAccount) {
    if (registryConfigurationData.configuracion.separarApellidos) {
      delete form.apellidos;
    } else {
      delete form.apellidoMaterno;
      delete form.apellidoPaterno;
    }
    let newForm = {};
    for (var i in form) {
      console.log(form[i]);
      newForm[i] = {
        ...form[i],
        defaultValue:
          athleteAccount && athleteAccount[form[i].fieldName] !== undefined
            ? athleteAccount[form[i].fieldName]
            : "",
      };
    }

    return newForm;
  }

  function confirmPersonalData(data) {
    // Guardo los datos del atelta en su perfil en caso que no existan datos previos en ese campo
    for (var i in data) {
      if (
        athleteAccount !== null &&
        athleteAccount !== undefined &&
        athleteAccount[i] === undefined
      ) {
        dispatch(updateUserAtribute(userID, { [i]: data[i] }));
      }
    }

    const tkl = query.get("tkl");

    trackEvent({
      eventName: "evento_" + eventDetail.iDEvento,
      typeEvent: "Lead",
      params: {
        label: "Lead",
        tkl: tkl || "direct",
        eventName: eventDetail.nombreEvento,
        facebookPixel: eventDetail.facebookPixel || false,
        gaId: eventDetail.gaId || false,
      },
    });
    console.log("data", data);
    setPersonalData(data);
  }
  function confirmPersonalExtraData(data) {
    setPersonalExtraData(data);
  }

  console.log("currentOrder", personalData);
  function confirmSubEvent(data) {
    // funcion para generar el objeto total incluyendo Distancia, Rama y Categoría seleccionada
    console.log("confirmSubEvent", data);
    setSubEvent(data);

    if (data.category) {
      const fechaNacimiento = personalData.fechaNacimiento.split("-");

      const participantDetail = {
        nombres: personalData.nombres,
        apellidos: personalData.apellidos,
        rama: personalData.genero === "M" ? "V" : personalData.genero,
        correo: personalData.correo,
        telefono: personalData.telefono,
        fechaNacimiento:
          fechaNacimiento[0] +
          "/" +
          fechaNacimiento[1] +
          "/" +
          fechaNacimiento[2],
        modalidad: data.distance,
        categoria: data.category,
        fechaRegistro: "PENDIENTE",
        mails: {
          sendMail: false,
        },
        monedaAUsar: currency,
        monedaBase: currencyBase,
        order: [
          {
            code: "insc",
            description:
              "Inscripción al evento " +
              eventDetail.nombreEvento +
              " - " +
              eventSubEventsRegistry["modalidad_" + data.distance]
                .descripcionModalidad,
            name: eventDetail.nombreEvento,
            precioKey: "precio_1",
            quantity: 1,
            unit_price:
              eventSubEventsRegistry["modalidad_" + data.distance]
                .configModalidad.precio.preventa_1.precio_1,
          },
        ],
        tipoRegistro: 1,
        usuarioRegistra: "No registrado",
        versionForm: "2.0",
      };

      setCurrentOrder({
        ...participantDetail,
        order: addComition(participantDetail.order),
      });
      console.log("confirmSubEvent", participantDetail);
      console.log("confirmSubEvent", currentOrder);
      console.log("confirmSubEvent", eventSubEventsRegistry);

      setShowPaymentForm(true);
      setShowSecondForm(false);
    } else {
      //dispatch(showSnackbar(true, "Selecciona una Categoría", 5000));
    }
  }
  function callbackInputs(data) {
    // para guardar los datos de los campos adicionales
    console.log("callbackInputs", data);
    console.log("confirmSubEvent", currentOrder);
    setShowProducts(true);
    setCurrentOrder({ ...currentOrder, ...data });
    setTimeout(() => {
      console.log("callbackInputs", currentOrder);
    }, 1000);
  }
  function callbackProducts(producto, item, add) {
    // para ajustar la orden con los datos de los productos comprados
    console.log("confirmSubEvent", item);
    console.log("confirmSubEvent", currentOrder.order);

    let order = currentOrder.order;
    console.log("find", item, order);
    const find = order.filter((a) => a.code === item.code);
    if (add) {
      // si oprimo el botón de agregar producto valido si ya está, en caso de estar aumento la cantidad
      console.log("find", find);
      if (find.length === 0) {
        // si no está lo agrego
        order.push(item);
      } else {
        // en caso de estar aumento la cantidad
        order = order.filter((a) => a.code !== item.code);
        console.log("find", find[0]);
        order.push({ ...find[0], quantity: find[0].quantity + 1 });
      }
    } else {
      // si deseo eliminar el producto
      if (find.length > 0) {
        if (find[0].quantity === 1) {
          order = order.filter((a) => a.code !== item.code);
        } else {
          // en caso de estar disminuyo la cantidad la cantidad
          order = order.filter((a) => a.code !== item.code);
          order.push({ ...find[0], quantity: find[0].quantity - 1 });
        }
      }
    }
    console.log("find", order);

    console.log("confirmSubEvent", order);

    const currentOrderNew = { ...currentOrder, order: addComition(order) };

    setCurrentOrder(currentOrderNew);
    console.log("confirmSubEvent", currentOrder);
  }
  function addComition(order) {
    console.log("confirmSubEvent", order);
    // Funcion para calcular la comision y agregarla al final de la orden
    let orderWithoutComition = order.filter((a) => a.code !== "comision");
    console.log("confirmSubEvent", orderWithoutComition);
    let comision = 0;
    for (var i in orderWithoutComition) {
      comision =
        comision +
        orderWithoutComition[i].quantity * orderWithoutComition[i].unit_price;
    }
    comision =
      Math.round(
        comision *
          registryConfigurationData.configuracion.formasDePago.comisionGlobal *
          100
      ) / 100;
    // Math.round(
    //   orderWithoutComition.reduce(
    //     (a, c) => c.unit_price * c.quantity + a.unit_price * a.quantity
    //   ) *
    //     registryConfigurationData.configuracion.formasDePago.comisionGlobal *
    //     100
    // ) / 100;
    console.log("confirmSubEvent", comision);
    orderWithoutComition.push({
      code: "comision",
      description: "Comisión por registro y pago en linea",
      name: "Comisión OnLine",
      precioKey: "",
      quantity: 1,
      unit_price: comision,
    });

    console.log("confirmSubEvent", orderWithoutComition);
    return orderWithoutComition;
  }

  const sendPayment = (success, oxxoObject) => {
    // TODO: mostrar confirmación y cerrar
    if (success) {
      setSuccessPayment(true);
      setOxxoPayment(oxxoObject);
      setPaymentView(false);
    }
    console.log(success);
  };

  if (successPayment) {
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        alignItems="center"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12} style={{ padding: "15px", textAlign: "center" }}>
          <Typography
            color="primary"
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            Felicidades {personalData.nombres}
          </Typography>
        </Grid>

        {!oxxoPayment && (
          <Grid item xs={12} style={{ padding: "15px", textAlign: "center" }}>
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle"}
              component="h5"
            >
              A continuación el detalle de tu compra, de igual manera recibirás
              un correo con la información
            </Typography>
            <RegistryConfirmation
              tipo={1}
              eventDetail={eventDetail}
              registryParticipantKeyConfirm={registryParticipantKey}
              registryParticipantDataConfirm={registryParticipantData}
              eventSubEventsRegistry={eventSubEventsRegistry}
              registryConfiguration={registryConfigurationData}
            />
          </Grid>
        )}
        {oxxoPayment && (
          <Grid item xs={12} style={{ padding: "15px", textAlign: "center" }}>
            <OxxoConfirmation responseData={oxxoPayment} />
          </Grid>
        )}
      </Grid>
    );
  }

  if (paymentView) {
    return (
      <Grid
        container
        direction="row"
        wrap="wrap"
        alignItems="center"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12} style={{ padding: "15px", textAlign: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setPaymentView(false)}
          >
            Regresar a los Datos
          </Button>

          <Typography
            color="primary"
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            Continuar
          </Typography>
          <PaymentsView
            currency={currency}
            currencyBase={currencyBase}
            callback={sendPayment}
            callbackCode={callbackProducts}
            order={currentOrder.order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      wrap="wrap"
      alignItems="center"
      alignContent="center"
      className={classes.root}
    >
      {personalData != null && (
        <div className={classes.root}>
          <Grid item xs={12} style={{ margin: "10px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setPersonalData(null)}
            >
              Regresar a los Datos
            </Button>
          </Grid>
          <Grid item xs={12} style={{ padding: "15px", textAlign: "center" }}>
            <Typography
              color="primary"
              gutterBottom
              variant={"h3"}
              component="h2"
            >
              Excelente {personalData.nombres}
            </Typography>
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle"}
              component="h5"
            >
              Puedes continuar con el registro
            </Typography>
          </Grid>
          {showSecondForm && eventDetail && eventSubEventsRegistry && (
            <Grid item xs={12} style={{ padding: "15px" }}>
              <CurrencyPayment
                callback={setCurrency}
                currentCurrency={currency}
                currencyBase={currencyBase}
                registryConfiguration={registryConfigurationData}
              />
            </Grid>
          )}
          {!showProducts && (
            <Grid item xs={12} style={{ padding: "15px" }}>
              <FieldEvent
                fieldObject={formDistanceAndExtra.distancia}
                callback={confirmSubEvent}
                currentOrder={currentOrder}
              />
            </Grid>
          )}

          {/* {showSecondForm && eventDetail && eventSubEventsRegistry && (
          <Grid item xs={12} style={{ padding: "15px" }}>
              <FormRender
                callback={confirmSubEvent}
                formObject={formDistanceAndExtra}
                submitButtonText={"Confirmar y continuar con el pago"}
              />
            )
            //renderDistanceConfig(eventSubEventsRegistry)
          </Grid>
            } */}
          <Grid item xs={12} style={{ padding: "15px" }}>
            {eventDetail &&
              eventSubEventsRegistry &&
              registryConfigurationData &&
              currentOrder &&
              !showProducts && (
                <AditionalInputs
                  eventDetail={eventDetail}
                  eventSubEventsRegistry={eventSubEventsRegistry}
                  registryConfigurationData={registryConfigurationData}
                  currentOrder={currentOrder}
                  callbackInputs={callbackInputs}
                  profile={"Public"}
                />
              )}
            {eventDetail &&
              eventSubEventsRegistry &&
              registryConfigurationData &&
              currentOrder &&
              showProducts && (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%" }}
                    onClick={() => setShowProducts(false)}
                  >
                    Regresar
                  </Button>
                  <AditionalProducts
                    currentOrder={currentOrder}
                    callbackProducts={callbackProducts}
                  />
                </div>
              )}
            {eventDetail &&
              eventSubEventsRegistry &&
              registryConfigurationData &&
              currentOrder &&
              showProducts && (
                <Grid item xs={12}>
                  <PaymentDetailRegistryForm
                    order={currentOrder.order}
                    currency={currency}
                    currencyBase={currencyBase}
                    removeItemOrder={() => false}
                  />
                </Grid>
              )}
            {/* <Grid item xs={12} style={{ padding: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "15px", width: "100%" }}
              >
                Continuar con el pago
              </Button>
            </Grid> */}

            {showPaymentForm && showProducts && (
              <SaveAthleteDataView
                currency={currency}
                callback={() => setPaymentView(true)}
                callbackCode={callbackProducts}
                order={currentOrder.order}
                currentOrder={currentOrder}
                eventDetail={eventDetail}
              />
            )}
          </Grid>
        </div>
      )}
      {personalData == null && (
        <div>
          <Grid item xs={12} style={{ padding: "10px", textAlign: "center" }}>
            <Typography
              color="primary"
              gutterBottom
              variant={"h3"}
              component="h2"
            >
              Confirma los datos de registro
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ margin: "15px", color: "white" }}>
            <FormRender
              callback={confirmPersonalData}
              formObject={form}
              submitButtonText={"Confirmar mis datos"}
            />
          </Grid>
        </div>
      )}
    </Grid>
  );
}
