import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AddAutorizedUserTiming from "./AddAutorizedUserTiming";
import AuthorizedUsersTiming from "./AuthorizedUsersTiming";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function AuthUsersTimingContainer(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventUsers = useSelector((state) => state.events.eventUsers);

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Usuarios Autorizados
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}></Grid>
          <Grid item xs={12} sm={6}>
            <AddAutorizedUserTiming />{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <AuthorizedUsersTiming eventUsers={eventUsers} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
