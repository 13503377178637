import React from "react";
import BackForwardButton from "../Forms/BackForwardButton";
//import M from 'materialize-css';
import axios from "axios";

class OxxoPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendOxxoPay = (e) => {
    this.setState({
      sending: true,
    });

    var amount = 0;
    var order = [];

    for (var i in this.props.athleteData.order) {
      var price = this.props.athleteData.order[i].unit_price;
      if (this.props.athleteData.monedaAUsar.codigo != "MXN") {
        price = parseFloat(
          price * this.props.athleteData.monedaAUsar.cambioAPeso
        );
      }

      var item = {
        name: this.props.athleteData.order[i].name,
        quantity: this.props.athleteData.order[i].quantity,
        unit_price: Math.round(price * 100),
      };

      amount =
        Math.round(
          (parseFloat(amount) +
            parseFloat(price) *
              parseFloat(this.props.athleteData.order[i].quantity)) *
            100
        ) / 100;
      console.log("amount " + amount);
      order.push(item);
    }
    console.log(order);

    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    console.log(this.props.athleteData.nombres);
    console.log(this.props.athleteData.apellidos);
    console.log(this.props.athleteData.apellidoMaterno);
    console.log(this.props.athleteData.apellidoPaterno);

    var apellidos =
      this.props.athleteData.apellidos != undefined
        ? this.props.athleteData.apellidos
        : this.props.athleteData.apellidoPaterno +
          " " +
          this.props.athleteData.apellidoMaterno;

    var nombres = this.props.athleteData.nombres + " " + apellidos;
    console.log(nombres);

    var jsonDatos = {
      nombres: nombres,
      correo: this.props.athleteData.correo,
      telefono: this.props.athleteData.telefono,
      token: "",
      tipoPago: "oxxo_pay",
      jsonItems: order,
      inscritoKey: this.props.registryParticipantKey,
      eventoIndex: eventoIndex,
      last4: "",
      brand: "",
      amount: amount,
    };

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("respuesta oxxo");
        console.log(res);
        console.log(res.data.charges.data[0].payment_method.reference);
        const paymentMethod = 2;
        this.props.setPaymentMethod(
          paymentMethod,
          res.data.charges.data[0].payment_method.reference
        );

        this.setState({
          sending: false,
        });
        this.props.backForward(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          sending: false,
        });
        var errorTexto = "";
        if (error.responseJSON != undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
        //M.toast('Error procesando el pago: ' + errorTexto, 8000);
      });
  };
  render() {
    const loadingSend = (
      <div className="preloader-wrapper active">
        <div className="spinner-layer spinner-red-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );

    const buttomSend = (
      <a
        href="#"
        onClick={(e) => this.sendOxxoPay(e)}
        id="sendOxxoPayButon"
        className="waves-effect waves-light btn-large"
      >
        <i className="material-icons right">call_to_action</i>
        <span id="sendOxxoPayText">ficha de pago</span>
      </a>
    );

    let buttomSendFinal = buttomSend;
    if (this.state.sending) {
      buttomSendFinal = loadingSend;
    } else {
      buttomSendFinal = buttomSend;
    }

    return (
      <div className="col s12 m6 l6 hoverable">
        <br />
        <br />
        <div className="center">
          <i className="large material-icons md-primary">code</i>
          <h5>Efectua el pago en cualquier oxxo cercano</h5>
          {buttomSendFinal}
          {this.props.registryParticipantKey}
        </div>
      </div>
    );
  }
}

export default OxxoPay;
