import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";

import EditableField from "../../../EditableField/EditableField";
import { setCustomView } from "../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function DragableBoxEdit(props) {
  const { view, callback, index, itemIndex } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const classes = useStyles();

  function handleChange(value) {
    console.log("[itemIndex]", value);
    console.log("[itemIndex]", view);
    console.log("[itemIndex]", itemIndex);
    const page = JSON.parse(JSON.stringify(view));
    console.log("[itemIndex]", page.views);

    page.views[itemIndex][Object.keys(value)[0]] = Object.values(value)[0];
    console.log("[itemIndex]", page);

    callback(page, index);
  }

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h4"} component="h2">
          Editar Casilla - {view.views[itemIndex].name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} style={{ margin: "20px" }}>
        {form &&
          Object.values(form).map((field, index) => {
            const defaultValue =
              view &&
              view.views &&
              view.views[itemIndex] !== undefined &&
              view.views[itemIndex][field.fieldName] !== undefined
                ? view.views[itemIndex][field.fieldName]
                : field.defaultValue;

            return (
              <EditableField
                key={index}
                fieldObject={{
                  ...field,
                  defaultValue: defaultValue,
                }}
                onSubmit={handleChange}
              />
            );
          })}
      </Grid>
    </Grid>
  );
}
const form = {
  "1name": {
    label: "Nombre de la casilla",
    errorMessage: "Campo Requerido",
    fieldName: "name",
    type: "text",
    defaultValue: "Por definir",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "1text": {
    label: "Texto",
    errorMessage: "Campo Requerido",
    fieldName: "text",
    type: "text",
    defaultValue: "Por definir",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "1width": {
    label: "Ancho en proporción (1 de 12, 6 de 12, etc)",
    errorMessage: "Campo Requerido",
    fieldName: "width",
    type: "select",
    defaultValue: 12,
    required: true,
    regex: "",
    textSize: "h6",
    optionsArray: [
      {
        value: 12,
        label: "12 de 12 Ancho completo",
      },
      {
        value: 11,
        label: "11 de 12",
      },
      {
        value: 10,
        label: "10 de 12",
      },
      {
        value: 9,
        label: "9 de 12",
      },
      {
        value: 8,
        label: "8 de 12",
      },
      {
        value: 7,
        label: "7 de 12",
      },
      {
        value: 6,
        label: "6 de 12 Mitad de espacio",
      },
      {
        value: 5,
        label: "5 de 12",
      },
      {
        value: 4,
        label: "4 de 12 Un tercio",
      },
      {
        value: 3,
        label: "3 de 12 Un cuarto",
      },
      {
        value: 2,
        label: "2 de 12",
      },
      {
        value: 1,
        label: "1 de 12",
      },
    ],
  },
  "1fontSize": {
    label: "Fuente en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "fontSize",
    type: "text",
    defaultValue: 20,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  position: {
    label: "Posición Horizontal",
    errorMessage: "Campo Requerido",
    fieldName: "position",
    type: "select",
    defaultValue: "",
    required: true,
    regex: "",
    textSize: "h6",
    optionsArray: [
      {
        value: "left",
        label: "Izquierda",
      },
      {
        value: "center",
        label: "Centro",
      },
      {
        value: "right",
        label: "Derecha",
      },
    ],
  },
  "3textColor": {
    label: "Color del Texto",
    errorMessage: "Campo Requerido",
    fieldName: "color",
    type: "color",
    defaultValue: "#ffffff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "1padding": {
    label: "Separación del margen en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "padding",
    type: "text",
    defaultValue: 0,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "3background": {
    label: "Color de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "background",
    type: "color",
    defaultValue: "#ffffff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "2backgroundImage": {
    label: "Imagen de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "backgroundImage",
    type: "photo",
    defaultValue: "",
    required: false,
    regex: "",
    textSize: "h6",
  },
};
