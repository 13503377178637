import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { Bar } from "react-chartjs-2";

const DashboardRegistryByUser = ({
  jsonbyUser,
  jsonbyUserMoney,
  registryAutorizedUsers,
}) => {
  var arrayColors = [
    "rgba(255, 99, 132, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(255, 205, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(201, 203, 207, 1)",
  ];
  var dataDispersion = {
    datasets: [
      {
        label: "Monto $",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        yAxisID: "y-axis-1",
      },
      {
        label: "Inscritos",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        yAxisID: "y-axis-2",
      },
    ],
    labels: [],
  };
  const options = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
              return value;
            },
          },
        },
      ],
    },
  };

  dataDispersion.labels.push("On Line");
  dataDispersion.datasets[0].data.push(0);
  dataDispersion.datasets[0].backgroundColor.push(arrayColors[0]);
  dataDispersion.datasets[0].borderColor.push(arrayColors[0]);
  dataDispersion.datasets[1].data.push(0);
  dataDispersion.datasets[1].backgroundColor.push(arrayColors[0]);
  dataDispersion.datasets[1].borderColor.push(arrayColors[0]);

  var x = 1;
  for (var i in jsonbyUserMoney) {
    if (registryAutorizedUsers[i] != undefined) {
      var userFinal = registryAutorizedUsers[i].nombre;
      dataDispersion.labels.push(userFinal);
      dataDispersion.datasets[0].data.push(jsonbyUserMoney[i]);
      dataDispersion.datasets[0].backgroundColor.push(arrayColors[x]);
      dataDispersion.datasets[0].borderColor.push(arrayColors[x]);
      dataDispersion.datasets[1].data.push(jsonbyUser[i]);
      dataDispersion.datasets[1].backgroundColor.push(arrayColors[x]);
      dataDispersion.datasets[1].borderColor.push(arrayColors[x]);
    } else {
      dataDispersion.datasets[0].data[0] =
        dataDispersion.datasets[0].data[0] + jsonbyUserMoney[i];
      dataDispersion.datasets[1].data[0] =
        dataDispersion.datasets[1].data[0] + jsonbyUser[i];
    }
    x++;
  }

  return (
    <Paper style={{ padding: 20, margin: 5 }}>
      <Typography variant={"h2"} color="primary" align="center">
        Total de inscripciones cobradas por usuario
      </Typography>

      <div className="col s12">
        <Bar data={dataDispersion} options={options} />
      </div>
    </Paper>
  );
};

export default DashboardRegistryByUser;
