import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fechahoraGuionAndMiliseconds } from "../../../../../services/utils";
import {
  createJuezRegistryNew,
  getLocalRegistries,
  deleteRegistry,
} from "../../../../../data-store/actions/events-actions";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import usePreventZoom from "./usePreventZoom";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "20px",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputLabelTitle: {
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));

export default function TimingRegistryContainer(props) {
  const { kPuntoControl } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);
  const localRegistries = useSelector((state) => state.events.localRegistries);
  const [currentNumber, setCurrentNumber] = useState("");
  usePreventZoom();

  useEffect(() => {
    dispatch(getLocalRegistries(eventDetail.iDEvento, kPuntoControl.kNroPunto));
  }, [eventDetail, kPuntoControl]);

  function onSubmit() {
    console.log("numero", currentNumber);
    if (currentNumber / 1 > 0) {
      const date = fechahoraGuionAndMiliseconds();

      console.log(date);
      const object = {
        competidorId: userID,
        fechaHora: date.text,
        iDEvento: eventDetail.iDEvento,
        kPuntoControl: kPuntoControl.kNroPunto,
        lat: 1,
        lng: 1,
        numero: currentNumber / 1,
        timeStamp: new Date(date.text).getTime(),
        visibleOBorrado: 0,
      };

      console.log("numero", date, object);

      dispatch(
        createJuezRegistryNew(
          object,
          localRegistries || {},
          eventDetail.iDEvento,
          kPuntoControl.kNroPunto
        )
      );

      setCurrentNumber("");
    }
  }

  function deleteRegistryFunc(registry) {
    console.log("numero", currentNumber);

    dispatch(
      deleteRegistry(
        { ...registry, visibleOBorrado: 1 },
        localRegistries || {},
        eventDetail.iDEvento,
        kPuntoControl.kNroPunto
      )
    );
  }
  function resendRegistriesFunc() {
    for (var i in localRegistries) {
      if (!localRegistries[i].sync) {
        console.log("registry123", localRegistries[i]);
        dispatch(
          createJuezRegistryNew(
            localRegistries[i],
            localRegistries || {},
            eventDetail.iDEvento,
            kPuntoControl.kNroPunto,
            true
          )
        );
      }
    }
  }

  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={10} sm={10} style={{ height: 70 }}>
          <Typography variant={"h2"} align="center">
            {currentNumber}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2}>
          <SimpleDialogDemo buttonText={<PlaylistAddCheckIcon />}>
            <div style={{ padding: 20 }}>
              <Typography gutterBottom variant={"h6"} align="center">
                Enviar todos los registros no guardados a servidor
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => resendRegistriesFunc(localRegistries)}
                style={{ width: "100%", padding: 20 }}
              >
                Enviar registros
              </Button>
              <Typography gutterBottom variant={"h6"} align="center">
                Copiar todos los registros al portapales
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(localRegistries))
                }
                style={{ width: "100%", padding: 20 }}
              >
                Copiar
              </Button>
            </div>
          </SimpleDialogDemo>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ height: 2, backgroundColor: "#ff4400" }}
        ></Grid>

        <Grid item xs={12} sm={12}>
          <Table
            size="small"
            aria-label="a dense table"
            style={{ marginBottom: 500 }}
          >
            <TableBody>
              {localRegistries &&
                Object.values(localRegistries)
                  .sort((a, b) => b.timeStamp - a.timeStamp)
                  .map((a, i) => (
                    <TableRow key={i}>
                      <TableCell className={classes.inputLabel} align="center">
                        <Typography
                          variant={"h6"}
                          component="h6"
                          align="center"
                        >
                          {a.numero}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.inputLabel} align="center">
                        {a.visibleOBorrado === 0 && a.fechaHora}

                        {a.visibleOBorrado === 1 && (
                          <Typography
                            variant={"h6"}
                            component="h6"
                            align="center"
                          >
                            Borrado
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell className={classes.inputLabel} align="center">
                        {a.sync && <DoneAllIcon />}
                        {!a.sync && <AccessAlarmsIcon />}
                      </TableCell>

                      <TableCell className={classes.inputLabel} align="center">
                        {a.visibleOBorrado === 0 && (
                          <SimpleDialogDemo buttonText={<DeleteIcon />}>
                            <div style={{ padding: 20 }}>
                              <Typography
                                gutterBottom
                                variant={"subtitle1"}
                                align="center"
                              >
                                Número:
                              </Typography>
                              <Typography
                                gutterBottom
                                variant={"h4"}
                                align="center"
                              >
                                {a.numero}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant={"subtitle1"}
                                align="center"
                              >
                                Fecha:
                              </Typography>
                              <Typography
                                gutterBottom
                                variant={"h4"}
                                align="center"
                              >
                                {a.fechaHora}
                              </Typography>

                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => deleteRegistryFunc(a)}
                                style={{ width: "100%", padding: 20 }}
                              >
                                Confirmar borrado
                              </Button>
                            </div>
                          </SimpleDialogDemo>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 1)}
          >
            1
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 2)}
          >
            2
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 3)}
          >
            3
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 4)}
          >
            4
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 5)}
          >
            5
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 6)}
          >
            6
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 7)}
          >
            7
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 8)}
          >
            8
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 9)}
          >
            9
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Paper>
            <Typography
              align="center"
              variant={"subtitle1"}
              component="h2"
            ></Typography>
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber + 0)}
          >
            0
          </Button>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Paper>
            <Typography
              align="center"
              variant={"subtitle1"}
              component="h2"
            ></Typography>
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            onClick={() => setCurrentNumber(currentNumber.slice(0, -1))}
          >
            Borrar
          </Button>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Button
            color="primary"
            style={{ margin: 5, fontSize: 25, width: "99%" }}
            variant="contained"
            onClick={() => onSubmit()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
