import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  updateEventObject,
} from "../../../../data-store/actions/events-actions";
import EditableField from "../../../EditableField/EditableField";
import { fechahora } from "../../../../services/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EnableTimingSoftware() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const createEventObject = createEventConfig.detalleEvento;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    const date = fechahora();
    const push = new Date().getTime();
    const usersToAdd = {
      ["user1" + push]: {
        accesoPermitido: true,
        correoJuez: atleteAccount.correo,
        fechaAutoriza: date,
        juezTipo: 1,
        userIdAuth: userID,
      },
      ["user2" + push]: {
        accesoPermitido: true,
        correoJuez: "eduardomadrid84@yahoo.com",
        fechaAutoriza: date,
        juezTipo: 1,
        userIdAuth: userID,
      },
    };

    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "autorizacionJuez/",
        usersToAdd
      )
    );
  }
  function showTimingInApp(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "detalleEvento/",
        value
      )
    );
  }

  if (
    atleteAccount &&
    atleteAccount.eventosAutorizadosJuez &&
    atleteAccount.eventosAutorizadosJuez["evento_" + eventDetail.iDEvento]
  ) {
    return (
      <Paper className={classes.paper}>
        <div className={classes.root}>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"} component="h2">
                Software de Crono Habilitado para tu usuario
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant={"subtitle1"} component="h2">
                Para acceder a la configuración de resultados cierra esta
                ventana emergente e ingresa a la sección de Cronometraje y
                Resultados que verás en la pántalla principal del evento
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EditableField
                fieldObject={{
                  label: "Publicar crono en la app",
                  errorMessage: "Requerido",
                  fieldName: "cronometrajeDisponible",
                  type: "switch",
                  defaultValue: eventDetail.cronometrajeDisponible,
                  required: false,
                  textSize: "h6",
                }}
                onSubmit={showTimingInApp}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Software de Crono
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant={"subtitle1"} component="h2">
              Al presionar el siguiente botón tu usuario y el usuario master de
              Dashport quedarán autorizados para configurar el software de crono
              de dashport para este evento.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <Button
              onClick={() => handleChange()}
              variant="contained"
              color="primary"
              style={{ padding: "40px" }}
            >
              Habilitar Software Crono para el evento
            </Button>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
