import React from 'react';





const DashportServicesList = () => (
<div className="row">
      <div className="col s12 m6 l6">
              <br/>
              <br/>
        <div className="center">
          <i style={{color:"red"}} className="large material-icons md-primary">camera_enhance</i>
          <h5>Retos Virtuales</h5>
          <p>Retos vistuales para organizadores de eventos profesionales y para pequeños grupos de entrenamiento.</p>
          <p>Contáctanos por mas información</p>
        </div>
      </div>
      <div className="col s12 m6 l6">
        <br/>
        <br/>
        <div className="center">
        <i style={{color:"red"}} className="large material-icons md-primary">view_list</i>
        <h5>Inscripciones en linea y puntos de venta</h5>
        <p>Simplificamos el proceso de inscripción para la comodidad de los atletas y organizadores gestionamos el recurso tecnológico y humano para facilitar esta etapa del evento.</p>
        <p>Contáctanos por mas información</p>
        </div>

      </div>
      <div className="col s12 m6 l6">
        <br/>
        <br/>
        <div className="center">
        <i style={{color:"red"}} className="large material-icons md-primary">access_alarm</i>
        <h5>Cronometraje alternativo de eventos</h5>
        <p>Cronometraje de eventos de reto personal eficiente, fácil de utilizar y accesible para que ningún evento sea realizado sin este servicio. Todos los atletas tendrán sus tiempos generales, por rama y categoría, asi como la capacidad de comparar su desempeño que los demás competidores.</p>
        <p>Contáctanos por mas información</p>

        </div>
      </div>
      <div className="col s12 m6 l6">
        <br/>
        <br/>
        <div className="center">
        <i style={{color:"red"}} className="large material-icons md-primary">camera_enhance</i>
        <h5>Fotografías deportivas</h5>
        <p>Fotos de eventos deportivos de reto personal que los atletas podrán encontrar, editar, compartir en tus redes y descargar muy fácilmente.</p>
        <p>Contáctanos por mas información</p>
        </div>
      </div>
    </div>)

export default DashportServicesList;
