import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.primary.main,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  text: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: "blue",
  },
}));

export default function FieldText(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    regex,
    min,
    max,
    step,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);

  return (
    <TextField
      margin="none"
      className={classes.text}
      fullWidth
      label={label}
      disabled={!editMode}
      helperText={errors[fieldName] ? errorMessage : null}
      error={Boolean(errors[fieldName])}
      defaultValue={defaultValue}
      inputRef={register({ required: required, pattern: new RegExp(regex) })}
      name={fieldName}
      type={max !== undefined || min !== undefined ? "number" : undefined}
      InputLabelProps={{
        className: classes.inputLabel,
      }}
      InputProps={{
        className: classes.input,
        inputProps: {
          max: max,
          min: min,
          step: step || 0,
        },
      }}
    />
  );
}
