import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import timingConfig from "../timingConfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import EventSpecificGender from "./TimingSpecificGender";
import EventSpecificClock from "../../../../Landing/sections/RaceResultsPublic/PastReports/reports/EventClock/EventSpecificClock";
import { fechahoraGuionAndMiliseconds } from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function SpecificStart(props) {
  const { start, startsKey, subEventIndex } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  const [confirm, setConfirm] = useState(false);
  const timingConfigObject = timingConfig.series;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidades/" + subEventIndex + "/hSalidas/serie_" + startsKey + "/",
        value
      )
    );
  }
  function handleTimmer(value) {
    console.log(value);

    if (value.confirm) {
      setConfirm(!confirm);
    }
    const time = { hS: value.fechaS + " " + value.horaS };
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidades/" + subEventIndex + "/hSalidas/serie_" + startsKey + "/",
        time
      )
    );
  }

  function startTimmer(value) {
    console.log(value);
    const date = fechahoraGuionAndMiliseconds();
    const time = { ...start, hS: date.text, hsTimeStamp: date.timestamp };

    console.log("timeStamp1", time);
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "modalidades/" + subEventIndex + "/hSalidas/serie_" + startsKey + "/",
        time
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  const form = {
    "7fechaS": {
      label: "Fecha Salida",
      errorMessage: "",
      fieldName: "fechaS",
      type: "date",
      defaultValue:
        start.hS.split(" ")[0] || eventDetail.fechaEvento || "0000-00-00",
      required: true,
      regex: "",
      textSize: "h6",
    },
    "7horaS": {
      label: "Hora Salida",
      errorMessage: "Formato correcto 00:00:00.000",
      fieldName: "horaS",
      type: "text",
      defaultValue: start.hS.split(" ")[1] || "00:00:00.000",
      required: true,
      regex:
        "^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]:[0-5][0-9].[0-9][0-9][0-9]$",
      textSize: "h6",
    },
  };
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "30px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h4"} component="h4">
          {startsKey.descripcionSerie}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {startsKey &&
          Object.values(timingConfigObject).map((field, index) => {
            const defaultValue = findValueByRoute(
              eventSubEvents[subEventIndex].hSalidas["serie_" + startsKey],
              field.fieldName
            );
            return (
              <EditableField
                key={index}
                fieldObject={{
                  ...field,
                  defaultValue: defaultValue,
                }}
                onSubmit={handleChange}
              />
            );
          })}
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} st>
          <EventSpecificClock
            modalidad={subEventIndex}
            serie={startsKey}
            fontSize={50}
          />

          <Typography gutterBottom variant={"subtitle1"}>
            Hora de Salida:
          </Typography>
          <Typography gutterBottom variant={"h5"}>
            {start.hS === "0000-00-00 00:00:00.000" ||
            start.hS === "0000-00-00 00:00:00"
              ? "No ha salido"
              : start.hS}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {start.hS === "0000-00-00 00:00:00.000" ||
            (start.hS === "0000-00-00 00:00:00" && (
              <Button
                style={{ padding: 30, width: "100%" }}
                variant="contained"
                color="primary"
                onClick={() => startTimmer()}
              >
                Iniciar Salida
              </Button>
            ))}
          {start.hS !== "0000-00-00 00:00:00.000" &&
            start.hS !== "0000-00-00 00:00:00" &&
            confirm && (
              <Button
                style={{ padding: 30, width: "100%" }}
                variant="contained"
                color="primary"
                onClick={() =>
                  handleTimmer({
                    fechaS: "0000-00-00",
                    horaS: "00:00:00",
                    confirm: false,
                  })
                }
              >
                Seguro que deseas Reiniciar Salída ?
              </Button>
            )}
          {start.hS !== "0000-00-00 00:00:00.000" &&
            start.hS !== "0000-00-00 00:00:00" &&
            !confirm && (
              <Button
                style={{ padding: 30, width: "100%" }}
                variant="contained"
                color="primary"
                onClick={() => setConfirm(true)}
              >
                Reiniciar Salída
              </Button>
            )}
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Typography gutterBottom variant={"h5"}>
            Editar Hora de salida
          </Typography>

          <FormRender
            callback={handleTimmer}
            formObject={form}
            submitButtonText={"Guardar Cambio"}
          />
        </Grid>
      </Paper>
    </Grid>
  );
}
