import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import BusinessCreate from "./BusinessCreate";
import BusinessConfiguration from "./BusinessConfiguration";
import OrganizerWebContainer from "../OrganizerWeb/OrganizerWebContainer";
import BusinessWebCreate from "../OrganizerWeb/BusinessWebCreate";

//const useStyles = makeStyles((theme) => ({}));

export default function BusinessListContainer(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const [business, setBusiness] = useState(false);

  useEffect(() => {
    if (atleteAccount && atleteAccount.businessAuth) {
      setBusiness(
        Object.values(atleteAccount.businessAuth).filter(
          (a) => a.profile !== "noauth"
        )[0]
      );
    }
  }, [atleteAccount]);

  //const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h4"} component="h4">
          Configuración de Empresas
        </Typography>
      </Grid>
      {atleteAccount && atleteAccount.perfil === 1 && (
        <Grid item xs={12} style={{ margin: "20px" }}>
          <SimpleDialogDemo buttonText={"Crear una Empresa"}>
            <BusinessCreate />
          </SimpleDialogDemo>
        </Grid>
      )}
      {atleteAccount && Object.values(atleteAccount.businessAuth).length > 1 && (
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-around">
            {atleteAccount &&
              atleteAccount.businessAuth &&
              Object.values(atleteAccount.businessAuth)
                .filter((a) => a.profile !== "noauth")
                .map((i) => (
                  <Grid item xs={2}>
                    <Link to={`/${i.businessId}/inicio/`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ padding: "30px" }}
                      >
                        {i.name}
                        <br />
                        {" id : " + i.businessId}
                      </Button>
                    </Link>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
