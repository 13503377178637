import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "./createEventconfig.json";
import { createPublicEvent } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventCreateForm() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const createEventObject = createEventConfig.createEvent;

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);

    dispatch(createPublicEvent(data, userID, atleteAccount));
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea un evento Nuevo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={"Crear Evento"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Paper
      style={{
        padding: 20,
        margin: 20,
        backgroundColor: "#e0e0e0",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Typography variant={"h4"} color="primary" align="center">
            Crear un evento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleDialogDemo buttonText={"Nuevo evento"}>
            {eventView}
          </SimpleDialogDemo>
        </Grid>
      </Grid>
    </Paper>
  );
}
