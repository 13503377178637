import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import FormRender from "../../../../../Fields/FormRender";
import EditableField from "../../../../../EditableField/EditableField";
import Field from "../../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import { updateParticipantAtribute } from "../../../../../../data-store/actions/tournaments-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function UpdateParticipant(props) {
  const { tournamentId, participant } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();

  function onSubmit(data) {
    const participantNew = {
      ...participant,
      ...data,
      updates: {
        ...participant.updates,
        [Math.floor(Date.now() / 1000)]: { userID: userID, change: data },
      },
    };
    dispatch(
      updateParticipantAtribute(
        tournamentId,
        participant.participantID,
        participantNew
      )
    );
  }
  let createTournamentObject = {
    name: {
      label: "Nombre del participante o Equipo",
      errorMessage: "Campo Requerido",
      fieldName: "name",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "100px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2">
          Editar datos del participante
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        {createTournamentObject &&
          Object.values(createTournamentObject).map((field, index) => {
            return (
              <EditableField
                key={index}
                fieldObject={field}
                onSubmit={onSubmit}
              />
            );
          })}
      </Grid>
    </Grid>
  );
}
