import React from "react";
import { connect } from "react-redux";
import {
  detalleEvento,
  eventConfiguration,
  eventConvocatory,
  eventSubEventsRegistry,
} from "../../../../data-store/actions/events-actions";
//import { Tab, Tabs } from 'react-materialize';
import EspecificAtleteDataForm from "./Forms/EspecificAtleteDataForm";
import AditionalInputsAtleteDataForm from "./Forms/AditionalInputsAtleteDataForm";

import SubEventsInputs from "./Forms/SubEventsInputs";
import AditionalInputsAtleteDataFormByModCat from "./Forms/AditionalInputsAtleteDataFormByModCat";
import ListAditionalProduct from "./ShopingCar/ListAditionalProduct";
import PaymentDetailRegistryForm from "./Forms/PaymentDetail/PaymentDetailRegistryForm";
import CurrencySelect from "./Forms/PaymentDetail/CurrencySelect";
import BackForwardButton from "./Forms/BackForwardButton";

import PaymentContainer from "./RegistryPayment/PaymentContainer";
import RegistryConfirmation from "./RegistryConfirmation/RegistryConfirmation";

//import M from "materialize-css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import SendRegistryData from "./Forms/SendRegistryData";
import Container from "@material-ui/core/Container";
import SubEventsCategoryInputs from "./Forms/SubEventsCategoryInputs";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class RegistryContainerPublicView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SubEventsInputsKey: 1,
      SubEventsCategoryInputsKey: 1,
      aditionalInputsModCatKey: 1000,
      aditionalProductsKey: 2000,
      registryType: 1,
      order: [],
      monedaAUsar: {
        codigo: "MXN",
        tipo: "base",
        tipoCambio: 1,
        texto: "Pesos Méxicanos",
        cambioAPeso: "1",
      },
      monedaBase: {
        codigo: "MXN",
        tipo: "base",
        tipoCambio: 1,
        texto: "Pesos Méxicanos",
        cambioAPeso: "1",
      },
      validate: {},
      value: 0,
      datosAtletaTab: false,
      opcionesEventoTab: true,
      pagoTab: true,
      confirmacionTab: true,
      mails: { sendMail: false },
      confirmationType: 0,
      paymentMethod: 0,
    };
  }
  componentDidUpdate() {
    console.log(this.state);
    console.log(this.props);
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  setPaymentMethod = (paymentMethod, oxxoReference) => {
    this.setState({
      oxxoReference: oxxoReference,
      paymentMethod: paymentMethod,
    });
  };

  setCurrencyUsed = (monedaAUsar, monedaBase, index) => {
    this.setState({
      monedaAUsar: monedaAUsar,
      monedaBase: monedaBase,
      monedaAUsarIndex: index,
    });
  };

  setInscriptionPrice = (item) => {
    console.log(item);
    //alert(JSON.stringify(item));
    var order = [];
    order.push(item);
    order = this.addOnlineCharge(order);
    this.setState({
      order: order,
    });
  };
  removeItemOrder = (indexItem) => {
    console.log("remover " + indexItem);
    var order = this.state.order;
    console.log(order);
    delete order[indexItem];
    order = this.addOnlineCharge(order);
    console.log(order);

    this.setState({
      order: order,
    });
  };
  updateOrder = (e, producto, item, add) => {
    var order = this.state.order;
    console.log("update Order");
    console.log(item);
    console.log(producto);
    var exist = false;
    for (var i in order) {
      // recorro la orden para ver si el producto ya esta agregado
      if (order[i].code == item.code) {
        exist = true;
        if (add) {
          // add true = a agregar producto
          if (producto.maxCantidad > order[i].quantity) {
            order[i].quantity = order[i].quantity + 1;
          } else {
            console.log("producto con limite");
            if (producto.maxCantidad == 1) {
              // M.toast(
              //   "Este producto ya fue agregado y permite una sola unidad ",
              //   7000
              // );
            } else {
              // M.toast(
              //   `Este producto máximo puede ser agredo ${producto.maxCantidad} veces`,
              //   7000
              // );
            }
          }
        } else {
          // add false == borrar producto
          delete order[i];
        }
      }
    }
    if (!exist) {
      order.push(item);
    }
    order = this.addOnlineCharge(order);

    this.setState({
      order: order,
    });
  };
  addOnlineCharge = (order) => {
    let ordernew = [];

    let rate = 0.07;
    if (this.props.registryConfiguration != undefined) {
      rate = this.props.registryConfiguration.configuracion.formasDePago
        .comisionGlobal;
    }
    const code = "comision";
    let totalOrder = 0;

    for (var i in order) {
      // recorro la orden para ver si el producto ya esta agregado
      if (order[i].code == code) {
        delete order[i];
      } else {
        totalOrder = order[i].unit_price * order[i].quantity;
        ordernew.push(order[i]);
      }
    }
    var comision = {
      code: code,
      description: "Comisión por registro y pago en linea",
      name: "Comisión On line",
      precioKey: "",
      quantity: 1,
      unit_price: totalOrder * rate,
    };
    ordernew.push(comision);

    return ordernew;
  };

  backForward = (forward) => {
    console.log("backForward");
    console.log(this.state);

    let arrayTabsState = [
      "datosAtletaTab",
      "opcionesEventoTab",
      "pagoTab",
      "confirmacionTab",
    ];

    this.setState({
      datosAtletaTab: true,
      opcionesEventoTab: true,
      pagoTab: true,
      confirmacionTab: true,
    });

    if (forward) {
      let tab = arrayTabsState[this.state.value + 1];
      this.setState({
        value: this.state.value + 1,
        [tab]: false,
      });
    } else {
      let tab = arrayTabsState[this.state.value - 1];
      this.setState({
        value: this.state.value - 1,
        [tab]: false,
      });
    }
  };
  update = (valor, inputObject, valid) => {
    console.log("update");
    console.log(inputObject);

    inputObject["valid"] = valid;
    console.log(inputObject);
    var validate = this.state.validate;

    validate[inputObject.iDCampo] = {};
    validate[inputObject.iDCampo] = inputObject;

    if (valid == null) {
      console.log("valid Null");
      delete validate[inputObject.iDCampo];
    } else if (valid == 3) {
      this.setState({
        [inputObject.iDCampo]: valor,
        validate: validate,
      });

      //M.toast(inputObject.etiqueta + " requerido", 4000);
    } else {
      if (
        [inputObject.iDCampo] == "rama" &&
        valor != this.state[inputObject.iDCampo]
      ) {
        this.setState({
          [inputObject.iDCampo]: valor,
          modalidad: null,
          categoria: null,
          SubEventsInputsKey: this.state.SubEventsInputsKey + 1,
          SubEventsCategoryInputsKey: this.state.SubEventsCategoryInputsKey + 1,
          aditionalInputsModCatKey: this.state.aditionalInputsModCatKey + 1,
          newaditionalProductsKey: this.state.aditionalProductsKey + 1,
          validate: validate,
        });
      } else if (
        [inputObject.iDCampo] == "fechaNacimiento" &&
        valor != this.state[inputObject.iDCampo]
      ) {
        this.setState({
          [inputObject.iDCampo]: valor,
          modalidad: null,
          categoria: null,
          SubEventsInputsKey: this.state.SubEventsInputsKey + 1,
          SubEventsCategoryInputsKey: this.state.SubEventsCategoryInputsKey + 1,
          aditionalInputsModCatKey: this.state.aditionalInputsModCatKey + 1,
          newaditionalProductsKey: this.state.aditionalProductsKey + 1,
          validate: validate,
        });
      } else if (
        [inputObject.iDCampo] == "modalidad" &&
        valor != this.state[inputObject.iDCampo]
      ) {
        this.setState({
          modalidad: valor,
          SubEventsCategoryInputsKey: this.state.SubEventsCategoryInputsKey + 1,
          categoria: null,
          validate: validate,
        });
      } else {
        this.setState({
          [inputObject.iDCampo]: valor,
          validate: validate,
        });
      }
      console.log(this.state);
    }
  };

  validateDataAndGo = (e) => {
    e.preventDefault();

    var valid = true;
    for (var i in this.state.validate) {
      console.log(this.state.validate[i]);
      console.log(this.state.validate[i].valid);
      if (
        this.state.validate[i].valid == null ||
        this.state.validate[i].valid == 3
      ) {
        console.log("entro a false");
        //M.toast(this.state.validate[i].etiqueta + " requerido", 4000);
        valid = false;
      }
    }

    if (valid) {
      this.backForward(true);
    } else {
      //M.toast("Revisa los campos requeridos", 7000);
    }
  };

  render() {
    const { value } = this.state;
    let arrayTabs = [];
    let contentTabs = [];

    arrayTabs.push(
      <Tab
        disabled={this.state.datosAtletaTab}
        key="tab-datos-atleta"
        label="Datos del atleta"
      />
    );
    contentTabs.push(
      <TabContainer key="contTab-datos-atleta">
        <EspecificAtleteDataForm
          eventDetail={this.props.eventDetail}
          registryConfiguration={this.props.registryConfiguration}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          userID={this.props.userID}
          atleteAccount={this.props.atleteAccount}
          update={this.update}
          fillInputs={true}
          actualValues={this.state}
        />
        <AditionalInputsAtleteDataForm
          eventDetail={this.props.eventDetail}
          registryConfiguration={this.props.registryConfiguration}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          userID={this.props.userID}
          atleteAccount={this.props.atleteAccount}
          update={this.update}
          fillInputs={true}
          actualValues={this.state}
        />
        <BackForwardButton
          action={(e) => this.validateDataAndGo(e)}
          forward={true}
        />
      </TabContainer>
    );
    arrayTabs.push(
      <Tab
        disabled={this.state.opcionesEventoTab}
        key="tab-opciones-evento"
        label="Opciones del evento"
      />
    );
    contentTabs.push(
      <TabContainer key="contTab-opciones-evento">
        <BackForwardButton
          backForward={(e) => this.backForward(false)}
          forward={false}
        />

        <CurrencySelect
          registryConfiguration={this.props.registryConfiguration}
          monedaAUsar={this.state.monedaAUsar}
          monedaBase={this.state.monedaBase}
          setCurrencyUsed={this.setCurrencyUsed}
          update={this.update}
          actualValues={this.state}
        />
        <SubEventsInputs
          key={this.state.SubEventsInputsKey}
          eventDetail={this.props.eventDetail}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryConfiguration={this.props.registryConfiguration}
          rama={this.state.rama}
          fechaNacimiento={this.state.fechaNacimiento}
          customStrings={this.props.customStrings}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          update={this.update}
          actualValues={this.state}
        />
        <SubEventsCategoryInputs
          key={this.state.SubEventsCategoryInputsKey}
          eventDetail={this.props.eventDetail}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryConfiguration={this.props.registryConfiguration}
          rama={this.state.rama}
          fechaNacimiento={this.state.fechaNacimiento}
          modalidad={this.state.modalidad}
          customStrings={this.props.customStrings}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          atleteAccount={this.props.atleteAccount}
          update={this.update}
          setInscriptionPrice={this.setInscriptionPrice}
          actualValues={this.state}
        />
        <AditionalInputsAtleteDataFormByModCat
          key={this.state.aditionalInputsModCatKey}
          eventDetail={this.props.eventDetail}
          registryConfiguration={this.props.registryConfiguration}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          userID={this.props.userID}
          atleteAccount={this.props.atleteAccount}
          update={this.update}
          fillInputs={true}
          modalidad={this.state.modalidad}
          rama={this.state.rama}
          categoria={this.state.categoria}
          registrytype={this.state.registryType}
          actualValues={this.state}
        />
        <ListAditionalProduct
          key={this.state.aditionalProductsKey}
          eventDetail={this.props.eventDetail}
          registryConfiguration={this.props.registryConfiguration}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryAutorizedUsers={this.props.registryAutorizedUsers}
          userID={this.props.userID}
          atleteAccount={this.props.atleteAccount}
          updateOrder={this.updateOrder}
          update={this.update}
          fillInputs={true}
          modalidad={this.state.modalidad}
          rama={this.state.rama}
          categoria={this.state.categoria}
          registryType={this.state.registryType}
          monedaAUsar={this.state.monedaAUsar}
          actualValues={this.state}
        />
        <PaymentDetailRegistryForm
          removeItemOrder={this.removeItemOrder}
          registrytype={this.state.registryType}
          order={this.state.order}
          monedaAUsar={this.state.monedaAUsar}
          monedaBase={this.state.monedaBase}
          actualValues={this.state}
        />
        <SendRegistryData
          eventDetail={this.props.eventDetail}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          registryConfiguration={this.props.registryConfiguration}
          userID={this.props.userID}
          atleteAccount={this.props.atleteAccount}
          stateFormContainer={this.state}
          registryType={this.state.registryType}
          hideModal={true}
          actualValues={this.state}
        />
      </TabContainer>
    );
    arrayTabs.push(
      <Tab disabled={this.state.pagoTab} key="tab-pago" label="Pago" />
    );
    contentTabs.push(
      <TabContainer key="contTab-pago">
        <BackForwardButton
          continueButton={true}
          backForward={this.backForward}
          forward={false}
        />
        <PaymentContainer
          continueButton={true}
          backForward={this.backForward}
          athleteData={this.state}
          test={
            this.props.eventConfiguration.prueba !== undefined
              ? this.props.eventConfiguration.prueba
              : false
          }
          registryParticipantData={this.props.registryParticipantData}
          registryParticipantKey={this.props.registryParticipantKey}
          eventDetail={this.props.eventDetail}
          removeItemOrder={this.removeItemOrder}
          registrytype={this.state.registryType}
          setPaymentMethod={this.setPaymentMethod}
        />
      </TabContainer>
    );
    arrayTabs.push(
      <Tab
        disabled={this.state.confirmacionTab}
        key="tab-confirmacion"
        label="Confirmación"
      />
    );

    contentTabs.push(
      <TabContainer key="contTab-confirmación">
        <h5>Confirmación</h5>
        <RegistryConfirmation
          registryParticipantDataConfirm={this.props.registryParticipantData}
          registryParticipantKeyConfirm={this.props.registryParticipantKey}
          registryConfiguration={this.props.registryConfiguration}
          eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          eventDetail={this.props.eventDetail}
          tipo={this.state.paymentMethod}
          oxxoReference={this.state.oxxoReference}
        />
      </TabContainer>
    );

    return (
      <Container maxWidth="md">
        <Tabs
          className="z-depth-1 red-text"
          value={value}
          onChange={this.handleChange}
        >
          {arrayTabs}
        </Tabs>
        {value === 0 && contentTabs[value]}
        {value === 1 && contentTabs[value]}
        {value === 2 && contentTabs[value]}
        {value === 3 && contentTabs[value]}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {};
};

const mapStateToProps = (state, { match }) => {
  return {
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    eventConvocatory: state.events.eventConvocatory,
    customStrings: state.events.customStrings,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    registryConfiguration: state.registry.registryConfiguration,
    atleteAccount: state.atlete.atleteAccount,
    registryParticipantData: state.registry.registryParticipantData,
    registryParticipantKey: state.registry.registryParticipantKey,
  };
};

const RegistryContainerPublic = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistryContainerPublicView);

export default RegistryContainerPublic;
