import React from "react";
import { RegistryConfirmationData } from "./RegistryConfirmationData";
import QRCode from "qrcode.react";

import { calcularEdad } from "../../../../../../services/utils";
import OxxoPaymentCode from "./OxxoPaymentCode";
import CardPaymentPromissoryNote from "./CardPaymentPromissoryNote";
import DetailOrderConfirmation from "./DetailOrderConfirmation";
import DisclaimerResponsabilitiesHTML from "../DisclaimerResponsabilities/DisclaimerResponsabilitiesHTML";
import { Typography } from "@material-ui/core";
import PhotoEditorDefault from "../PhotoEditorDefault";

class RegistryConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipo: this.props.tipo,
      update: true,
      showRaceNumber: false,
    };
  }

  componentDidMount() {
    setTimeout(
      this.setState({
        showRaceNumber: true,
      }),
      2000
    );
  }
  // { registryParticipantDataConfirm,
  //   registryParticipantKeyConfirm,
  //   eventDetail,
  //   eventSubEventsRegistry,
  //   registryConfiguration,
  //   tipo,
  //   reference,
  //   codigoSuscripcion }) => {

  render() {
    var jsonMail = {};
    jsonMail.inscritoKey = this.props.registryParticipantKeyConfirm;
    jsonMail.eventoIndex = "evento_" + this.props.eventDetail.iDEvento;

    jsonMail.detalleEvento = this.props.eventDetail;
    jsonMail.datos = this.props.registryParticipantDataConfirm;
    jsonMail.modalidades = this.props.eventSubEventsRegistry;
    console.log(this.props.eventSubEventsRegistry);
    jsonMail.inscripcionesConfiguracion = this.props.registryConfiguration;

    var color = "#000000";
    if (jsonMail.detalleEvento.organizador.colorOrganizador != undefined) {
      color = jsonMail.detalleEvento.organizador.colorOrganizador;
    }
    var numero = 0;
    if (jsonMail.datos != undefined && jsonMail.datos.numero != undefined) {
      numero = jsonMail.datos.numero;
    }
    var distancia = 0;
    var modIndex = "modalidad_" + jsonMail.datos.modalidad;
    if (jsonMail.datos.modalidad != undefined) {
      distancia = jsonMail.modalidades[modIndex].descripcionModalidad;
    }
    var rama = 0;
    var ramaIndex;
    if (jsonMail.datos.rama != undefined) {
      ramaIndex = "rama_" + jsonMail.datos.rama;
      rama = jsonMail.datos.rama;
    }
    var categoriaJson;
    var categoria;
    var categoriaIndex;
    if (jsonMail.datos.categoria != undefined) {
      categoriaIndex = "categoria_" + jsonMail.datos.categoria;
      categoriaJson =
        jsonMail.modalidades[modIndex].ramaCat[ramaIndex].categorias[
          categoriaIndex
        ];
      categoria = categoriaJson.nombreCategoria;
    }

    // tipo 0 = es que no se muestra la confirmacion todavia
    // tipo 1 = solo confirmacion aplica para pago en efectivo y tarjeta de credito aprobada
    // tipo 2 = registro y ficha de pago
    // tipo 3 = confirmacion de inscripcion y pago recibido en oxxo

    var idioma = "es";

    var jsonTextos = RegistryConfirmationData;

    var edad = calcularEdad(
      jsonMail.datos.fechaNacimiento,
      jsonMail.detalleEvento.fechaEvento,
      true
    );
    var deslindeFinal;
    if (edad < 18) {
      deslindeFinal = (
        <DisclaimerResponsabilitiesHTML
          eventDetail={this.props.eventDetail}
          registryParticipantDataConfirm={
            this.props.registryParticipantDataConfirm
          }
          more18={false}
        />
      );
    } else {
      deslindeFinal = (
        <DisclaimerResponsabilitiesHTML
          eventDetail={this.props.eventDetail}
          registryParticipantDataConfirm={
            this.props.registryParticipantDataConfirm
          }
          more18={true}
        />
      );
    }

    var detallePagoTarjeta;

    if (jsonMail.datos.statusPago.last4 != undefined) {
      detallePagoTarjeta = <CardPaymentPromissoryNote jsonMail={jsonMail} />;
    }

    var fichaDePago;
    if (this.state.tipo == 2) {
      fichaDePago = (
        <OxxoPaymentCode
          jsonMail={jsonMail}
          jsonTextos={jsonTextos}
          idioma={idioma}
          reference={this.props.oxxoReference}
          tipo={this.state.tipo}
        />
      );
    }
    var detalleOrden = (
      <DetailOrderConfirmation
        jsonMail={jsonMail}
        jsonTextos={jsonTextos}
        idioma={idioma}
        reference={this.props.oxxoReference}
        tipo={this.state.tipo}
      />
    );

    var nombresYApellidos = "";
    if (this.state.tipo == 4) {
      nombresYApellidos = this.props.codigoSuscripcion;
    } else {
      nombresYApellidos =
        jsonMail.datos.nombres + " " + jsonMail.datos.apellidos;
    }

    if (this.state.tipo == 0) {
      return (
        <Typography color="primary" gutterBottom variant={"h5"} component="h2">
          Confirmación pendiente
        </Typography>
      );
    }

    return (
      <div>
        <div className="row center-align">
          <Typography
            color="secondary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {jsonTextos[idioma].preNombre[this.state.tipo]}
          </Typography>
          <Typography
            color="primary"
            gutterBottom
            variant={"h4"}
            component="h2"
          >
            {nombresYApellidos}{" "}
          </Typography>
          <Typography
            color="secondary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {jsonTextos[idioma].postNombre[this.state.tipo]}
          </Typography>
        </div>
        <div className="row center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"h3"}
            component="h2"
          >
            {jsonMail.detalleEvento.nombreEvento}
          </Typography>
        </div>
        <div className="row center-align">
          <div className="col s6 offset-s3">
            <img
              alt="logo Evento"
              title="logo Evento"
              src={jsonMail.detalleEvento.logoEvento}
              style={{
                border: "none",
                borderRadius: "0px",
                display: "block",
                fontSize: "13px",
                outline: "none",
                textDecoration: "none",
                width: "100%",
              }}
              width={212}
            />
          </div>
        </div>
        <hr />

        {jsonMail.inscripcionesConfiguracion.configuracion.dorsal &&
          jsonMail.datos.numero > 0 && (
            <div className="row center-align">
              <div className="col s12">
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"h4"}
                  component="h2"
                >
                  Dorsal digital
                </Typography>
              </div>
              <div className="center-align col s12 m12">
                {this.state.showRaceNumber && (
                  <PhotoEditorDefault
                    size="80%"
                    participant={{
                      raceNumber: jsonMail.datos.numero,
                      athleteName: jsonMail.datos.nombres,
                      athleteLastName: jsonMail.datos.apellidos,
                    }}
                    currentEvent={{
                      distance: 10,
                      raceNumberFontColor:
                        jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                          .raceNumberFontColor,
                      raceNumberWhiteBack:
                        jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                          .raceNumberWhiteBack,
                    }}
                    urlPhoto={
                      jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                        .raceNumberImage
                    }
                    type={"raceNumber"}
                  />
                )}
              </div>
            </div>
          )}

        <hr />
        <div className="row center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"h5"}
            component="h2"
          >
            {jsonTextos[idioma].LinkYQRSeguimiento}
          </Typography>

          <div className="col s12 m12">
            <QRCode
              value={
                jsonMail.inscripcionesConfiguracion.configuracion
                  .rutaDetalleInscrito +
                "?event=" +
                jsonMail.eventoIndex +
                "&registry=" +
                jsonMail.inscritoKey +
                "&download=true"
              }
            />
          </div>
          <div className="col s12 m12">
            <Typography
              color="primary"
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              <a
                target="_blank"
                href={`${jsonMail.inscripcionesConfiguracion.configuracion.rutaDetalleInscrito}?event=${jsonMail.eventoIndex}&registry=${jsonMail.inscritoKey}&download=true`}
              >
                {jsonMail.inscripcionesConfiguracion.configuracion
                  .rutaDetalleInscrito +
                  "?event=" +
                  jsonMail.eventoIndex +
                  "&registry=" +
                  jsonMail.inscritoKey +
                  "&download=true"}
              </a>
            </Typography>
          </div>
        </div>
        <hr />
        <div className="row center-align">
          <div className="col s12">
            <Typography
              color="primary"
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              {jsonTextos[idioma].tituloDatos}{" "}
            </Typography>
          </div>
          <div className="col s3 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {jsonTextos[idioma].Numero}
              <br />
              <b className="center-align">{numero}</b>
            </Typography>
          </div>
          <div className="col s3  center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {jsonTextos[idioma].Distancia}
              <br />
              <b className="center-align">{distancia}</b>
            </Typography>
          </div>
          <div className="col s3  center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {jsonTextos[idioma].Rama}
              <br />
              <b className="center-align">{rama}</b>
            </Typography>
          </div>
          <div className="col s3 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              {jsonTextos[idioma].Categoria}
              <br />
              <b>{categoria}</b>
            </Typography>
          </div>
        </div>
        <hr />

        {detalleOrden}
        <hr />
        {fichaDePago}
        <footer />
      </div>
    );
  }
}

export default RegistryConfirmation;
