import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createEventAttribute,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import { changesRegistryFunc } from "../../../../../firebase/firebasejs";
import EditableInput from "../../../../DynamicInput/EditableInput";
import EditableField from "../../../../EditableField/EditableField";
import { translateInput } from "../../../../Fields/FieldUtils";

const AditionalInputsAndProductsDetail = ({
  ParticipantDetail,
  registryConfiguration,
  eventSubEventsRegistry,
  eventDetail,
  ParticipantDetailKey,
  userID,
  atleteAccount,
}) => {
  const dispatch = useDispatch();

  console.log(registryConfiguration);

  function handleChange(value) {
    console.log(value);

    var changesRegistry = changesRegistryFunc(
      ParticipantDetailKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      11,
      `Cambio ${Object.keys(value)[0]} de ${ParticipantDetail[
        Object.keys(value)[0]
      ] || ""} a ${Object.values(value)[0]}`
    );

    console.log(changesRegistry);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscritos/listadoInscritos/" + ParticipantDetailKey + "/",
        value
      )
    );
    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscritos/listadoInscritos/" +
          ParticipantDetailKey +
          "/changesRegistry/",
        changesRegistry
      )
    );
  }

  const camposAdicionalesModalidad = () => {
    var camposAdicionalesModalidad = [];
    var camposAdicionalesModalidadHTML = [];

    var formulario = registryConfiguration.formulario;
    for (var i in formulario) {
      if (formulario[i].tipoCampo != "titulo") {
        camposAdicionalesModalidad.push(formulario[i]);

        const field = translateInput(formulario[i]);
        camposAdicionalesModalidadHTML.push(
          <EditableField
            key={i + "CA"}
            fieldObject={{
              ...field,
              defaultValue: ParticipantDetail[field.fieldName] || "",
            }}
            onSubmit={handleChange}
          />
        );
      }
    }

    var modalidadesBuscar = eventSubEventsRegistry;

    var arrayCampoModalidad = [];

    for (var i in modalidadesBuscar) {
      var modalidadCampos =
        modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
      if (modalidadCampos != undefined) {
        for (var e in modalidadCampos) {
          if (
            modalidadesBuscar[i].ramaCat["rama_" + ParticipantDetail.rama] !=
              undefined &&
            modalidadesBuscar[i].ramaCat["rama_" + ParticipantDetail.rama]
              .categorias["categoria_" + ParticipantDetail.categoria] !=
              undefined &&
            modalidadesBuscar[i].ramaCat["rama_" + ParticipantDetail.rama]
              .categorias["categoria_" + ParticipantDetail.categoria]
              .camposAdicionalesKey != undefined &&
            modalidadesBuscar[i].ramaCat["rama_" + ParticipantDetail.rama]
              .categorias["categoria_" + ParticipantDetail.categoria]
              .camposAdicionalesKey == e
          ) {
            var campos = modalidadCampos[e];
            console.log(e);
            for (var f in campos) {
              if (campos[f].tipoCampo != "titulo") {
                if (!arrayCampoModalidad.includes(campos[f].iDCampo)) {
                  arrayCampoModalidad.push(campos[f].iDCampo);
                  camposAdicionalesModalidad.push(campos[f]);

                  const field = translateInput(campos[f]);
                  camposAdicionalesModalidadHTML.push(
                    <EditableField
                      key={i + "CA"}
                      fieldObject={{
                        ...field,
                        defaultValue: ParticipantDetail[field.fieldName] || "",
                      }}
                      onSubmit={handleChange}
                    />
                  );
                  console.log("Campos " + campos[f].iDCampo);
                }
              }
            }
          }
        }
      }
    }

    return {
      camposAdicionalesModalidad: camposAdicionalesModalidad,
      camposAdicionalesModalidadHTML: camposAdicionalesModalidadHTML,
    };
  };

  const productosAdicionalesModalidadTitulo = () => {
    var productosAdicionalesModalidadTitulo = [];
    var productosAdicionalesModalidadTituloHTML = [];

    var modalidadesBuscar = eventSubEventsRegistry;
    var arrayCodigoProducto = [];
    console.log(modalidadesBuscar);

    for (var i in modalidadesBuscar) {
      var modalidadProductos =
        modalidadesBuscar[i].configModalidad.productosAdicionales;
      if (modalidadProductos != undefined) {
        for (var e in modalidadProductos) {
          if (
            !arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)
          ) {
            arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
            productosAdicionalesModalidadTitulo.push(modalidadProductos[e]);

            productosAdicionalesModalidadTituloHTML.push(
              <h6
                key={i + e + "cantidad"}
                data-field={`${modalidadProductos[e].nombreProducto}-Cantidad`}
              >
                {modalidadProductos[e].nombreProducto}
              </h6>
            );
            var formularioProducto = modalidadProductos[e].formularioProducto;
            if (formularioProducto != undefined) {
              for (var f in formularioProducto) {
                productosAdicionalesModalidadTituloHTML.push(
                  <EditableInput
                    key={i + "CA"}
                    ParticipantDetail={ParticipantDetail}
                    userID={userID}
                    atleteAccount={atleteAccount}
                    eventDetail={eventDetail}
                    ParticipantDetailKey={ParticipantDetailKey}
                    inputObject={formularioProducto[f]}
                  />
                );
              }
            }

            console.log(JSON.stringify(modalidadProductos[e]));
          }
        }
      }
    }

    return {
      productosAdicionalesModalidadTitulo: productosAdicionalesModalidadTitulo,
      productosAdicionalesModalidadTituloHTML: productosAdicionalesModalidadTituloHTML,
    };
  };

  var camposAdicionales = camposAdicionalesModalidad()
    .camposAdicionalesModalidadHTML;
  var productosAdicionales = productosAdicionalesModalidadTitulo()
    .productosAdicionalesModalidadTituloHTML;
  // TODO: agregar el areglo de productos adicionales cuando tenga la vista lista de cada producto
  // y cuando se puede hacer el pago de la diferencia de precio al a gregar o quitar un producto.
  console.log(productosAdicionales);
  var tabla = (
    <div className="row">
      <hr />
      <div className="card-panel col s12 m12 l12">{camposAdicionales}</div>
    </div>
  );

  return tabla;
};

export default AditionalInputsAndProductsDetail;
