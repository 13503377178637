import React from "react";
//import M from 'materialize-css'
import { borrarInscritoFB } from "../../../../../firebase/firebasejs";

class DeleteParticipantButtomDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      borrado:
        this.props.ParticipantDetail.borrado == undefined
          ? false
          : this.props.ParticipantDetail.borrado,
      liberado:
        this.props.ParticipantDetail.liberado == undefined
          ? false
          : this.props.ParticipantDetail.liberado,
    };
  }

  borrarInscrito = (borrado) => {
    console.log(this.state.borrado);
    this.setState({
      borrado: borrado,
    });

    console.log(this.state.borrado);

    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    var idRegistro = this.props.ParticipantDetailKey;
    var ParticipantDetail = this.props.ParticipantDetail;

    if (borrado) {
      //M.toast("Registro Borrado", 4000);
    } else {
      //M.toast("Borrado reversado", 4000);
    }

    return borrarInscritoFB(
      eventoIndex,
      this.props.ParticipantDetailKey,
      this.props.ParticipantDetail,
      this.props.userID,
      this.props.atleteAccount,
      borrado
    );
  };

  render() {
    var boton;
    if (this.state.borrado) {
      if (this.state.liberado) {
        boton = <p>Folio Liberado</p>;
        //$("#modalInscrito").addClass("grey");
      } else {
        boton = (
          <a
            onClick={() => this.borrarInscrito(false)}
            className="waves-effect waves-light btn"
          >
            <i className="material-icons left">delete</i>reversar
          </a>
        );
        //$("#modalInscrito").addClass("grey");
      }
    } else {
      boton = (
        <a
          onClick={() => this.borrarInscrito(true)}
          className="waves-effect waves-light btn"
        >
          <i className="material-icons left">delete</i>borrar
        </a>
      );
      //   $("#modalInscrito").removeClass("grey");
    }

    return boton;
  }
}
export default DeleteParticipantButtomDetail;
