import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getPuntosControl,
  juecesEvento,
} from "../../../../../data-store/actions/events-actions";

import { Grid, Typography } from "@material-ui/core";
import FullScreenDialog from "../../../Modals/FullScreenDialog";
import TimingRegistryContainer from "./TimingRegistryContainer";
import StartsList from "./StartsList";

export default function GetControlPoints({ match }) {
  const { EventoIndex } = match.params;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents1 = useSelector((state) => state.events.eventSubEvents);
  const puntosControl = useSelector((state) => state.events.puntosControl);
  const eventUsers = useSelector((state) => state.events.eventUsers);

  useEffect(() => {
    if (
      !validateProps() ||
      ("evento_" + eventDetail.iDEvento !== EventoIndex &&
        eventDetail.cronometrajeDisponible)
    ) {
      dispatch(getPuntosControl(EventoIndex));
      dispatch(juecesEvento(EventoIndex));
    }
  }, []);

  function validateProps() {
    // para validar que todos los datos requeridos ya se descargaron para continuar
    if (
      eventUsers !== null &&
      eventUsers !== undefined &&
      puntosControl !== null &&
      puntosControl !== undefined
    ) {
      return true;
    }
    return false;
  }

  if (!eventDetail.cronometrajeDisponible) {
    return <div></div>;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant={"h5"} align="center">
          Puntos de Control
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        {puntosControl &&
          Object.values(puntosControl.val()).map((a) => (
            <FullScreenDialog
              buttomLabel={a.descripcionFinalPunto}
              title={a.descripcionFinalPunto}
              report={<TimingRegistryContainer kPuntoControl={a} />}
            />
          ))}
      </Grid>
    </Grid>
  );
}
