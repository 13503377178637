import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import FormRender from "../../../../../Fields/FormRender";
import EditableField from "../../../../../EditableField/EditableField";
import Field from "../../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import {
  createTournamentParticipant,
  updateTournamentAtribute,
} from "../../../../../../data-store/actions/tournaments-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function CreateRound(props) {
  const { tournamentId, round, roundId } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();

  const tournament = useSelector((state) => state.tournaments.tournament);

  console.log("tournament1", tournament[tournamentId]);
  function onSubmit(data) {
    let roundNumber = 1;
    if (tournament[tournamentId].rounds) {
      roundNumber = Object.keys(tournament[tournamentId].rounds).length + 1;
    }

    let groups = {};
    for (var i = 1; i <= data.numberOfGroups; i++) {
      let participants = {};
      for (var e = 1; e <= data.participantsPerGroup; e++) {
        participants["participant_" + e] = {
          name: "Vacio",
          id: null,
          number: e,
        };
      }

      groups["group_" + i] = {
        group: i,
        participants: participants,
        groupNumber: i,
      };
    }

    const objectChange = {
      rounds: {
        ["round_" + roundNumber]: {
          ...round,
          ...data,
          groups: groups,
          userCreate: userID,
          roundNumber: roundNumber,
        },
      },
    };
    dispatch(updateTournamentAtribute(tournamentId, objectChange));
  }
  let createTournamentObject = {
    name: {
      label: "Nombre de la ronda",
      errorMessage: "Campo Requerido",
      fieldName: "name",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    participantsPerGroup: {
      label: "Participantes por grupo",
      errorMessage: "Campo Requerido",
      fieldName: "participantsPerGroup",
      type: "text",
      defaultValue: 2,
      required: true,
      regex: "",
      min: 2,
      textSize: "h6",
    },
    numberOfGroups: {
      label: "Número de grupos (2 para semifinal, 4 para cuartos)",
      errorMessage: "Campo Requerido",
      fieldName: "numberOfGroups",
      type: "text",
      defaultValue: 1,
      required: true,
      regex: "",
      min: 1,
      textSize: "h6",
    },
    classificedPerGroup: {
      label: "Clasificados por grupo",
      errorMessage: "Campo Requerido",
      fieldName: "classificedPerGroup",
      type: "text",
      defaultValue: 1,
      required: true,
      regex: "",
      min: 1,
      textSize: "h6",
    },
    winnerBy: {
      label: "Determinar ganador por",
      errorMessage: "Requerido",
      fieldName: "winnerBy",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "moreWins",
          label: "Juegos Ganados",
        },
        {
          value: "morePoints",
          label: "Mas puntos",
        },
        {
          value: "moreWinsAndmorePoints",
          label: "Juegos ganados y luego mas puntos",
        },
        {
          value: "moreWinsAndmorePointsAndPower",
          label: "Juegos ganados, luego mas puntos y luego fuerza",
        },
      ],
    },
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "100px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"subtitle1"} component="h2">
          Ronda del torneo
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <FormRender
          callback={onSubmit}
          formObject={createTournamentObject}
          submitButtonText={"Crear Ronda"}
        />
      </Grid>
    </Grid>
  );
}
