import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Link, Redirect } from "react-router-dom";

import { firebaseInit } from "../../firebase/firebasejs";
import { auth } from "../../firebase/firebasejs";
//import M from 'materialize-css'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { showSnackbar } from "../../data-store/actions/organizers-actions";

export default function Login() {
  const dispatch = useDispatch();

  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const [correo, setCorreo] = useState();
  const [password, setPassword] = useState();
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    auth
      .signInWithEmailAndPassword(data.correo, data.password)
      .then(() => {
        dispatch(showSnackbar(true, "Bienvenido", 4000));
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        dispatch(
          showSnackbar(true, "Error al inicar sesión intente de nuevo", 4000)
        );
      });
  };

  if (userID != undefined) {
    return (
      <Grid container justify="center" spacing={3}>
        <Paper style={{ padding: 10 }}>
          <Grid item xs={12}>
            <h5>Bienvenido</h5>
          </Grid>
          <Grid item xs={12}>
            <h2>{atleteAccount.nombres + " " + atleteAccount.apellidos}</h2>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid container justify="center" spacing={3}>
      <Paper style={{ padding: 40 }}>
        <Grid item xs={12}>
          <h4>Iniciar Sesión</h4>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <label>Correo</label>
            <input
              name="correo"
              type="email"
              ref={register({ required: true })}
            />
            {errors.correo && "Campo requerido"}
          </Grid>

          <Grid item xs={12}>
            <label>Contraseña</label>
            <input
              name="password"
              type="password"
              ref={register({ required: true, minLength: 6 })}
            />
            {errors.correo && "Mínimo 6 caracteres"}
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" primary>
              Iniciar Sesión
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Link to="/password-restore">
              <Button primary>Recuperar contraseña </Button>
            </Link>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
