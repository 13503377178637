import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import FormRender from "../../../../../Fields/FormRender";
import EditableField from "../../../../../EditableField/EditableField";
import Field from "../../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import {
  createTournamentParticipant,
  updateParticipantAtribute,
  updateTournamentAtribute,
} from "../../../../../../data-store/actions/tournaments-actions";
import { participants } from "../../../../../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ParticipantsView(props) {
  const { tournamentId, round, group, participant } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();

  const tournament = useSelector((state) => state.tournaments.tournament);
  const participantsList = useSelector(
    (state) => state.tournaments.participantsList
  );
  console.log("participant", round, group, participant);

  console.log("data", participantsList);

  function onSubmit(data) {
    let name = "";
    if (data.id === null) {
      name = "Vacio";
    } else {
      name = participantsList.filter((a) => a.participantID)[0].name;
    }
    const objectChange = {
      rounds: {
        ["round_" + round.roundNumber]: {
          groups: {
            ["group_" + group.group]: {
              participants: {
                ["participant_" + participant.number]: {
                  id: data.id,
                  number: participant.number,
                  name: name,
                },
              },
            },
          },
        },
      },
    };
    console.log("objectChange1", data.id);
    console.log("objectChange2", objectChange);
    console.log("objectChange3", tournamentId);
    console.log("objectChange4", tournament);
    // console.log("objectChange4", jsonConcat(tournament, objectChange));

    // function jsonConcat(o1, o2) {
    //   for (var key in o2) {
    //     o1[key] = o2[key];
    //   }
    //   return o1;
    // }
    if (data.id === null) {
      const objectChangeParticipant = {
        added: false,
        round: null,
        group: null,
      };

      dispatch(
        updateParticipantAtribute(
          tournamentId,
          participant.id,
          objectChangeParticipant
        )
      );
    } else {
      const objectChangeParticipant = {
        added: true,
        round: round.roundNumber,
        group: group.groupNumber,
      };
      dispatch(
        updateParticipantAtribute(
          tournamentId,
          data.id,
          objectChangeParticipant
        )
      );
    }
    dispatch(updateTournamentAtribute(tournamentId, objectChange));
  }

  function getParticipantsList() {
    let participantsArray = Object.values(participantsList).map((a) => ({
      value: a.participantID,
      label: a.name,
      disabled: a.added,
    }));
    participantsArray.push({
      value: null,
      label: "Por definir",
    });
    return participantsArray;
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h5"} component="h2">
          {participant !== null && participantsList && (
            <div>
              <EditableField
                fieldObject={{
                  label: "Participante",
                  errorMessage: "Campo Requerido",
                  fieldName: "id",
                  type: "select",
                  defaultValue: participant.id,
                  required: true,
                  regex: "",
                  textSize: "h6",
                  optionsArray: getParticipantsList(),
                }}
                onSubmit={onSubmit}
              />
            </div>
          )}
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}
