import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";

import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import { getTournamentData } from "../../../../../data-store/actions/tournaments-actions";
import ParticipantsList from "./ParticipantsOrTeams/ParticipantsList";
import CreateField from "../../../../Fields/CreateField";
import TournamentRound from "./SpecificTournamentConfig/TournamentRound";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function SpecificTournamentsContainer({ match }) {
  const { businessId, tournamentId } = match.params;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);

  const classes = useStyles();
  const tournament = useSelector((state) => state.tournaments.tournament);

  console.log("tournamentId", tournamentId);

  useEffect(() => {
    if (businessId && tournamentId) {
      dispatch(getTournamentData(tournamentId));
    }
  }, []);

  if (!tournament || !tournament[tournamentId]) {
    return (
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography gutterBottom variant={"h1"} component="h2">
                  Cargando torneo...
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"subtitle1"} component="h2">
                Torneo
              </Typography>
              <Typography gutterBottom variant={"h3"} component="h2">
                {tournament[tournamentId].name}
              </Typography>
              <Typography gutterBottom variant={"subtitle1"} component="h2">
                {tournament[tournamentId].description} - Fecha{" "}
                {tournament[tournamentId].startDate}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <ParticipantsList tournamentId={tournamentId} />
            </Grid>
            <Grid item xs={12}>
              <TournamentRound tournamentId={tournamentId} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
