import React from "react";

//import 'materialize-css/dist/css/materialize.min.css';

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const style = {
  fontStyle: "bold",
};

const TitleResultReport = ({ data, controles, controlesArray }) => {
  var controlesText;
  if (controles) {
    if (controlesArray["modalidad_" + data.codMod] != undefined) {
      const arrayTitles = controlesArray["modalidad_" + data.codMod].titles;
      controlesText = arrayTitles.map((title, index) => (
        <TableCell style={style} key={index + " " + data.mod}>
          {title.descripcionPunto}
          <br />
          <span className="small-text">km: {title.kilometro}</span>
        </TableCell>
      ));
    }
  }

  const defaultTitle = (
    <TableRow align="center">
      <TableCell style={style}>Número</TableCell>
      <TableCell style={style}>Lugar General</TableCell>
      <TableCell style={style}>Lugar Rama</TableCell>
      <TableCell style={style}>Lugar Categoría</TableCell>
      <TableCell component="th" scope="row">
        Nombre
      </TableCell>
      <TableCell style={style}>Género</TableCell>
      <TableCell style={style}>Categoría</TableCell>
      <TableCell style={style}>Modalidad</TableCell>
      {controlesText}
      <TableCell style={style}>Tiempo Oficial</TableCell>
      <TableCell style={style}>Tiempo Real</TableCell>
      <TableCell style={style}>Certificado</TableCell>
    </TableRow>
  );

  return defaultTitle;
};

export default TitleResultReport;
