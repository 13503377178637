import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateEventObject } from "../../../../../../data-store/actions/events-actions";
import { showSnackbar } from "../../../../../../data-store/actions/organizers-actions";
import { fechahora } from "../../../../../../services/utils";
import FormRender from "../../../../../Fields/FormRender";

export default function AddAuthUserToDeposits() {
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const userID = useSelector((state) => state.atlete.userID);

  const registryAutorizedUsers = useSelector(
    (state) => state.registry.registryAutorizedUsers
  );

  const eventDeposits = useSelector((state) => state.events.eventDeposits);
  const dispatch = useDispatch();

  function saveDepostit(data) {
    let exist = false;
    for (var i in registryAutorizedUsers) {
      if (
        registryAutorizedUsers[i].correo === data.email &&
        registryAutorizedUsers[i].perfil === "MasterOrganizador"
      ) {
        const date = fechahora();
        const push = new Date().getTime();
        const depositData = {
          dateRegistry: date,
          authorized: true,
          email: data.email,
          userIdAuth: userID,
        };
        exist = true;

        dispatch(showSnackbar(true, "Usuario Agregado Correctamente ", 5000));
        console.log(" depositData", depositData);
        dispatch(
          updateEventObject(
            "evento_" + eventDetail.iDEvento,
            "deposits/usersList/" + i + "/",
            depositData
          )
        );
      }
    }
    if (!exist) {
      dispatch(
        showSnackbar(
          true,
          "Usuario no autorizado en este evento, o con perfil diferente a Master Organizador",
          5000
        )
      );
    }
  }

  const createFieldObject = {
    email: {
      label: "Correo del usuario",
      errorMessage: "Campo Requerido",
      fieldName: "email",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  };

  if (!eventDeposits && atleteAccount.perfil !== 1) {
    return <br></br>;
  }

  let showFormToAdd;
  if (atleteAccount.perfil === 1) {
    showFormToAdd = (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: "30px" }}
      >
        <Grid item xs={12}>
          <Typography
            style={{ paddingBottom: "30px" }}
            gutterBottom
            variant={"h5"}
            component="h2"
          >
            Agregar un Usuario
          </Typography>
          <FormRender
            callback={saveDepostit}
            formObject={createFieldObject}
            submitButtonText={"Agregar usuario"}
          />
        </Grid>
      </Grid>
    );
  }

  return showFormToAdd;
}
