import {
  helperMultisort,
  tiempoASegundos,
  restarFechas,
} from "../../../../../services/utils";

function timeStrToSeconds(time) {
  const timeArray = time ? time.split(":") : [];
  if (timeArray.length === 3) {
    const secondsSplit = timeArray[2].split(".");
    if (secondsSplit.length > 1) {
      return (
        (((timeArray[0] / 1) * 60 * 60 +
          (timeArray[1] / 1) * 60 +
          secondsSplit[0] / 1) *
          1000 +
          secondsSplit[1] / 1) /
        1
      );
    }
    return (
      (((timeArray[0] / 1) * 60 * 60 +
        (timeArray[1] / 1) * 60 +
        timeArray[2] / 1) *
        1000) /
      1
    );
  }
  return 0;
}
export function calculatePositions(resultados, eventSubEvents) {
  console.log("eventResultsLocal");
  // 1) Ordenar resultados por los que tienen tiempo meta
  // 2) Ordenar por modalidad y tiempo meta ascendente
  // 3) Ciclo para asignar posiciones PG, Pcat, PR y ab

  const results = resultados.map((result) => ({
    ...result,
    tiempoOficialSegundos: timeStrToSeconds(
      result.toMS ? result.toMS : result.to
    ),
  }));

  const resultsOrder = helperMultisort(
    results,
    ["codMod", "tiempoOficialSegundos"],
    ["ASC", "ASC"]
  );

  let absolutos = calcularAbsolutos(eventSubEvents);
  console.log("absolutos", absolutos, eventSubEvents);

  var posicionesPorModalidad = {};
  var posicionesPorRama = {};
  var posicionesPorCategoria = {};

  let resultsFinal = resultsOrder.map((resultado) => {
    if (resultado.tiempoOficialSegundos === 0) {
      // si no tiene tiempo de meta pongo todo a cero
      return {
        ...resultado,
        ab: 0,
        pG: 0,
        pR: 0,
        pCat: 0,
      };
    }
    //Gen = genero = rama
    var rama = resultado.gen;
    //CG = codigo categoria
    var categoria = resultado.codCat;

    //Mod = Modalidad
    var idModalidad = resultado.codMod;
    var idRama = resultado.codMod.toString() + "-" + rama;
    var idCategoria =
      resultado.codMod.toString() + "-" + rama + "-" + categoria;

    let absoluto;

    if (
      absolutos[idModalidad] !== undefined &&
      absolutos[idModalidad][rama] > 0
    ) {
      // hay absolutos para esta rama
      absoluto = 1;
      idCategoria = resultado.codMod.toString() + "-" + rama + "-ABSOLUTO";
      absolutos[idModalidad][rama] -= 1;
    } else {
      absoluto = 0;
    }
    var posicionEnModalidad = posicionesPorModalidad[idModalidad]
      ? (posicionesPorModalidad[idModalidad] += 1)
      : 1;
    var posicionEnRama = posicionesPorRama[idRama]
      ? (posicionesPorRama[idRama] += 1)
      : 1;
    var posicionEnCategoria = posicionesPorCategoria[idCategoria]
      ? (posicionesPorCategoria[idCategoria] += 1)
      : 1;

    posicionesPorModalidad[idModalidad] = posicionEnModalidad;
    posicionesPorRama[idRama] = posicionEnRama;
    posicionesPorCategoria[idCategoria] = posicionEnCategoria;

    return {
      ...resultado,
      ab: absoluto,
      pG: posicionEnModalidad,
      pR: posicionEnRama,
      pCat: posicionEnCategoria,
    };
  });
  let igual = 0;
  let noigual = 0;
  resultsFinal.map((resultado) => {
    if (
      resultado.pG === resultado.pG &&
      resultado.pR === resultado.pR &&
      resultado.pCat === resultado.pCat &&
      resultado.ab === resultado.ab
    ) {
      igual++;
      console.log("Igual");
    } else {
      noigual++;
      console.log("NOIgual", resultado.num, resultado);
    }
    return 1;
  });
  console.log("IgualTotal", igual, noigual);

  return resultsFinal;
}

export function calcularAbsolutos(eventSubEvents) {
  var resultado = {};

  Object.values(eventSubEvents).forEach(function(configuracion) {
    var modalidad = configuracion.codigoModalidad;
    var ramas = configuracion.ramaCat;
    var resultadoModalidad = {};

    for (var rama in ramas) {
      var ramaFinal = ramas[rama].rama;
      var categorias = ramas[rama].categorias;
      for (var categoria in categorias) {
        if (categorias[categoria].absolutos === true) {
          var categoriaObjeto = categorias[categoria];
          var cantidadGanadores =
            categoriaObjeto !== undefined
              ? categoriaObjeto.cantidadGanadores
              : 0;
          resultadoModalidad[ramaFinal] = cantidadGanadores;
        }
      }
    }
    resultado[modalidad] = resultadoModalidad;
  });

  return resultado;
}

export function calculateAthlete(
  result,
  registries,
  eventSubEvents,
  controlPoints
) {
  var tiempoMeta = "00:00:00";
  var tiempoMetaChip = "00:00:00";
  var fechaHoraMeta = "0000-00-00 00:00:00";
  var parciales = [];
  var x = 0;

  var tiempoOficialAnterior;
  var tiempoChipAnterior;
  var partialesAnterior;
  var controles = [];

  if (result.borrado) {
    return { ...result, to1: "00:00:00", tc1: "00:00:00" };
  }
  const registriesSort = Object.values(registries).sort(
    (a, b) => a.timeStamp - b.timeStamp
  );

  const modalidadIndex = "modalidad_" + result.codMod;
  const modalidadAthlete = eventSubEvents[modalidadIndex];
  const contraReloj = modalidadAthlete.contraReloj || false;
  const parcialesTiempoChip = modalidadAthlete.parcialesTiempoChip || false;
  let horaSalida = modalidadAthlete.hSalidas["serie_" + result.serie].hS;
  if (contrareloj) {
    horaSalida = "0000-00-00 00:00:00";
  }
  let horaSalidaChip = horaSalida;

  let lapActual = [];
  let tiempoActual = [];
  let tiempoAnterior = [];
  let kmActual = [];
  let kmAnterior = [];
  let registroSalida = false;

  var yaEsMeta = false;

  for (let Rj in registriesSort) {
    let registroJuez = registriesSort[Rj];
    let kPC = registroJuez.kPuntoControl;
    if (lapActual[kPC] === undefined) {
      lapActual[kPC] = 1;
    }
    if (tiempoActual[kPC] === undefined) {
      tiempoActual[kPC] = "0000-00-00 00:00:00";
    }
    if (tiempoAnterior[kPC] === undefined) {
      tiempoAnterior[kPC] = "0000-00-00 00:00:00";
    }
    if (kmActual[kPC] === undefined) {
      kmActual[kPC] = 0;
    }
    if (kmAnterior[kPC] === undefined) {
      kmAnterior[kPC] = 0;
    }

    var pcIndex = "kPC_" + registroJuez.kPuntoControl;
    //console.log("puntos Controles "+pcIndex);
    var puntoControlLaps =
      controlPoints[pcIndex].modalidadesPuntoDeControl[modalidadIndex].laps;

    var puntoControlLapsNum = Object.values(
      controlPoints[pcIndex].modalidadesPuntoDeControl[modalidadIndex].laps
    ).length;

    // console.log("puntos Controles size "+puntoControlLapsNum);
    var tipoControlSalida =
      controlPoints[pcIndex].modalidadesPuntoDeControl[modalidadIndex].laps[
        "lap_1"
      ].tipoControl;

    var lapSeleccionada;

    // setear hora de salida para los eventos que son contrareloj
    if (tipoControlSalida === 1 && registroJuez.visibleOBorrado === 0) {
      registroSalida = true;
      if (contrareloj) {
        horaSalida = registroJuez.fechaHora;
        horaSalidaChip = registroJuez.fechaHora;
      } else {
        // Cambio Agrego este if para que no tome como salida chip si el registro es previo a la hora de salida
        if (registroJuez.fechaHora > horaSalida) {
          horaSalidaChip = registroJuez.fechaHora;
        }
      }
      console.log("contrarel true control salida" + tipoControlSalida);
      console.log(horaSalida);
    }
    // Cambio, agregue otro condicional a para que ingrese al ciclo tambien cuando el tiempo sea inferior a la hora de salida solo si el contro es el de salida.
    if (registroJuez.fechaHora > horaSalida && puntoControlLaps != null) {
      // validar si no esta borrado el registro
      if (registroJuez.visibleOBorrado === 0) {
        console.log("lap actual " + lapActual[kPC]);

        lapSeleccionada =
          controlPoints[pcIndex].modalidadesPuntoDeControl[modalidadIndex].laps[
            "lap_" + lapActual[kPC]
          ];

        tiempoActual[kPC] = registroJuez.fechaHora;
        kmActual[kPC] = lapSeleccionada.kilometro;
        var minTime = lapSeleccionada.minTime;

        var keyControl;

        registroJuez.lap = lapSeleccionada;

        // si la hora actual menos la hora anterior es mayor
        //que el paso mas rapido multiplicado por la diferencia de kilometros,
        //es la siguiente vuelta.
        // si no es la mima.
        console.log("lap actual " + lapActual[kPC]);
        console.log("lap actual " + lapSeleccionada.tipoControl);
        if (lapActual[kPC] < puntoControlLapsNum) {
          if (lapActual[kPC] === 1) {
            if (parcialesTiempoChip) {
              tiempoAnterior[kPC] = horaSalidaChip;
            } else {
              tiempoAnterior[kPC] = horaSalida;
            }
          }

          var aumentaVuelta = pasoPorKMs(
            minTime,
            kmAnterior[kPC],
            kmActual[kPC],
            tiempoAnterior[kPC],
            tiempoActual[kPC]
          );
          console.log("aumenta vuelta " + aumentaVuelta);

          if (aumentaVuelta) {
            var lapSeleccionada2 =
              controlPoints[pcIndex].modalidadesPuntoDeControl[modalidadIndex]
                .laps["lap_" + lapActual[kPC]];

            kmAnterior[kPC] = kmActual[kPC];
            keyControl = registroJuez.kPuntoControl * 1000 + lapActual[kPC];
            lapActual[kPC] = lapActual[kPC] + 1;
          }
        } else if (lapActual[kPC] === puntoControlLapsNum) {
          if (lapActual[kPC] === 1) {
            if (parcialesTiempoChip) {
              tiempoAnterior[kPC] = horaSalidaChip;
            } else {
              tiempoAnterior[kPC] = horaSalida;
            }
          }
          if (lapSeleccionada.tipoControl === 3) {
            var validarMeta = pasoPorKMs(
              minTime,
              kmAnterior[kPC],
              kmActual[kPC],
              tiempoAnterior[kPC],
              tiempoActual[kPC]
            );
            if (validarMeta || yaEsMeta) {
              yaEsMeta = true;
              keyControl = 0;
              console.log("ya es meta");
            }
          } else {
            //                    keyControl = (registroJuez.kPuntoControl * 1000)+ lapActual[kPC];
            // Deploy este pedazo de codigo para que funciones cuando las cueltas no tengan la meta incluida
            // Cambio antes estaba la linea de arriba comentada.
            aumentaVuelta = pasoPorKMs(
              minTime,
              kmAnterior[kPC],
              kmActual[kPC],
              tiempoAnterior[kPC],
              tiempoActual[kPC]
            );
            console.log("aumenta vuelta " + aumentaVuelta);

            if (aumentaVuelta) {
              keyControl = registroJuez.kPuntoControl * 1000 + lapActual[kPC];
            }
          }
        }
        tiempoAnterior[kPC] = tiempoActual[kPC];

        if (controles[keyControl] === undefined) {
          controles[keyControl] = [];
        }
        controles[keyControl].push(registroJuez);
      }
    }
  }

  // parte calcular los parciales y tiempo meta
  var updates = {};
  var tiempoControl = [];
  controles.forEach((value, key) => {
    //ya no ordeno por que ordeno previamente
    //controles[key].sort(function(a, b){return new Date(a) - new Date(b);});
    var datosControl = controles[key][0].lap;
    if (controles[key].length <= 2) {
      tiempoControl[key] = controles[key][0].fechaHora;
    } else if (controles[key].length === 3 || controles[key].length === 4) {
      tiempoControl[key] = controles[key][1].fechaHora;
    } else {
      tiempoControl[key] = controles[key][1].fechaHora;
    }
    if (key === 0) {
      fechaHoraMeta = tiempoControl[key];
      tiempoMeta = restarFechas(horaSalida, fechaHoraMeta);
      tiempoMetaChip = restarFechas(horaSalidaChip, fechaHoraMeta);
    } else {
      var pcIndex = "kPC_" + controles[key][0].kPuntoControl;
      var parcial = {};
      parcial.fechaHoraPunto = tiempoControl[key];
      parcial.kpc = datosControl.kilometro;
      parcial.latLng =
        controlPoints[pcIndex].lat + "," + controlPoints[pcIndex].lng;

      parcial.pCat = key;
      if (parcialesTiempoChip) {
        parcial.tkpc = restarFechas(horaSalidaChip, tiempoControl[key]); // pendiente
      } else {
        parcial.tkpc = restarFechas(horaSalida, tiempoControl[key]); // pendiente
      }
      parciales.push(parcial);
    }
  });

  return {
    ...result,
    fhm1: fechaHoraMeta,
    to1: tiempoMeta,
    tc1: tiempoMetaChip,
    parciales1: parciales,
    registroSalida1: registroSalida,
  };
}

function pasoPorKMs(
  minTime,
  kmAnterior,
  kmActual,
  tiempoAnterior,
  tiempoActual
) {
  console.log("min time" + minTime);
  console.log("kmAnterior" + kmAnterior);
  console.log("kmActual" + kmActual);
  console.log("tiempoAnterior" + tiempoAnterior);
  console.log("tiempoActual" + tiempoActual);

  // si la hora actual menos la hora anterior es mayor
  //que el paso mas rapido multiplicado por la diferencia de kilometros,
  //es la siguiente vuelta.
  // si no es la mima.

  var difKm = kmActual - kmAnterior;
  var difTiempoSegundos = tiempoASegundos(
    restarFechas(tiempoAnterior, tiempoActual)
  );
  var segundosMin = tiempoASegundos(minTime);
  var segundosMinTotal = segundosMin * difKm;

  if (segundosMinTotal < difTiempoSegundos) {
    console.log("aumenta vuelta");
    return true;
  }
  return false;
}

const registriesTest = {
  "-NFcyLUCukje0tSR0-Lx": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 12:58:59.509",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667156339509,
    visibleOBorrado: 1,
  },
  "-NFcyLZ_kEkG2xgJUxCJ": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 12:59:01.011",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667156341011,
    visibleOBorrado: 1,
  },
  "-NFd4f9w12IDzQbr25Yq": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:55.326",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115055326,
    visibleOBorrado: 0,
  },
  "-NFd4fAFlXYp0wzmLty3": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:55.944",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115055944,
    visibleOBorrado: 0,
  },
  "-NFd4fBbpkRMyNDau9UD": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:59.134",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115059134,
    visibleOBorrado: 0,
  },
  "-NFd4fHn1NaNacrunJVM": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:00.774",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115060774,
    visibleOBorrado: 0,
  },
  "-NFd4fhFfv6bhEWhJUfd": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:56.977",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115056977,
    visibleOBorrado: 0,
  },
  "-NFd4fhHwFL5WH_dBff1": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:57.082",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115057082,
    visibleOBorrado: 0,
  },
  "-NFd4fhiLygxVekC7Cxi": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:58.004",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115058004,
    visibleOBorrado: 0,
  },
  "-NFd4fi-rA18mzM12OW3": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:30:58.821",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115058821,
    visibleOBorrado: 0,
  },
  "-NFd4gEUYuP7RWBVP86U": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:01.226",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115061226,
    visibleOBorrado: 0,
  },
  "-NFd4gF0eckdhAnBD8ve": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:02.054",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115062054,
    visibleOBorrado: 0,
  },
  "-NFd4gFCdW6MvLe9T12w": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:02.240",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115062240,
    visibleOBorrado: 0,
  },
  "-NFd4gFCdW6MvLe9T12x": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:02.373",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115062373,
    visibleOBorrado: 0,
  },
  "-NFd4gFnaqbheJwbJI3U": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 07:31:03.060",
    iDEvento: 555,
    kPuntoControl: 1,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667115063060,
    visibleOBorrado: 0,
  },
  "-NFdNFoszduTtmSrtC3f": {
    competidorId: "mveUXXq8ZnV28olf6BVWKluYig12",
    fechaHora: "2022-10-30 08:52:16.336",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667141536337,
    visibleOBorrado: 0,
  },
  "-NFdNG3V_tQUg1fD32FA": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.491",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932491,
    visibleOBorrado: 0,
  },
  "-NFdNG3V_tQUg1fD32FB": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.491",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932491,
    visibleOBorrado: 0,
  },
  "-NFdNG3WP_Yzm-gCxCSx": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.503",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932503,
    visibleOBorrado: 0,
  },
  "-NFdNG3WP_Yzm-gCxCSy": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.503",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932503,
    visibleOBorrado: 0,
  },
  "-NFdNG3Xt__MFzYzOWbR": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.523",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932523,
    visibleOBorrado: 0,
  },
  "-NFdNG3Xt__MFzYzOWbS": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.523",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932523,
    visibleOBorrado: 0,
  },
  "-NFdNG3YQgd9V-T3wq7d": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.556",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932556,
    visibleOBorrado: 0,
  },
  "-NFdNG3YQgd9V-T3wq7e": {
    competidorId: "Chip",
    fechaHora: "2022-10-30 08:52:12.556",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667119932556,
    visibleOBorrado: 0,
  },
  "-NFdNGVa_ZnK-YBrbYAC": {
    competidorId: "1pYxc7P8NEYwN9V3GGtRmnUGqoJ3",
    fechaHora: "2022-10-30 08:52:25.991",
    iDEvento: 555,
    kPuntoControl: 0,
    lat: 1,
    lng: 1,
    numero: 1,
    timeStamp: 1667141545992,
    visibleOBorrado: 0,
  },
};

const result = {
  ab: 0,
  borrado: true,
  cat: "ELITE",
  cg: 5,
  codCat: 1,
  codMod: 1,
  fhm: "2022-10-30 08:52:12.491",
  gen: "V",
  mod: 100,
  mp: 0,
  nombre: "HECTOR SABINO DAGDUG MORALES",
  num: 1,
  pCat: 0,
  pG: 0,
  pR: 0,
  recPos: false,
  recalcular: false,
  serie: 1,
  tc: "00:00:00",
  to: "00:00:00",
};
