import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.primary.main,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  text: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: "blue",
  },
}));
const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  required,
  ...props
}) => {
  const classes = useStyles();

  const labelId = `${name}-label`;
  console.log("SelectReq", required, defaultValue);
  return (
    <FormControl required={required} {...props}>
      <InputLabel className={classes.inputLabel} id={labelId}>
        {label}
      </InputLabel>
      <Controller
        as={
          <Select
            className={classes.inputLabel}
            labelId={labelId}
            label={label}
          >
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
