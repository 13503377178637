import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Popover,
  Slider,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import SimpleDialogDemo from "../../../../Landing/LayoutContainers/SimpleDialog";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const DashboardExtraInputs = ({ jsonAdicionales }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [similarityPercent, setSimilarityPercent] = useState(0.6);

  console.log("jsonAdicionales", jsonAdicionales);

  console.log("jsonAdicionales", similarity("merida yucatan", "Merida"));

  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  function randomColors() {
    return (
      Math.random() * 255 +
      "," +
      Math.random() * 255 +
      "," +
      Math.random() * 255
    );
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  //const id = open ? "simple-popover" : undefined;

  const options = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
              return value;
            },
          },
        },
      ],
    },
  };
  var Adicionales = [];

  for (var e in jsonAdicionales) {
    console.log("resumen ad");
    const dataDispersion = {
      datasets: [
        {
          label: "Cantidad",
          type: "bar",
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          yAxisID: "y-axis-1",
        },
        {
          label: "Porcentaje",
          type: "bar",
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          yAxisID: "y-axis-2",
        },
      ],
      labels: [],
    };

    const campoFinal = jsonAdicionales[e];
    const campo = campoFinal.etiqueta;
    const objeto = campoFinal.valores;
    const tipoCampo = campoFinal.tipoCampo;
    const showInDashboard = campoFinal.showInDashboard;
    console.log(
      "showInDashboard",
      campoFinal,
      tipoCampo,
      campo,
      showInDashboard
    );

    const suma = campoFinal.suma;
    console.log("resumen suma" + suma);
    console.log("resumen ad" + campo);
    console.log("resumen ad" + JSON.stringify(objeto));

    var adicionalesData = [];
    var x = 1;
    if (tipoCampo === "text") {
      if (showInDashboard) {
        let newObject = [];
        let newObjectAll = {};

        for (var i in objeto) {
          console.log("newObjectAll", i);
          let exist = false;
          for (var m in newObject) {
            const similar = similarity(m, i);
            if (similar > similarityPercent) {
              exist = true;
              if (!newObjectAll[m]) {
                newObjectAll[m] = {};
              }
              newObjectAll[m][i] = objeto[i];
              //console.log("newObjectAll1", newObject);
              if (!newObject[m]) {
                newObject[m] = 0;
              }
              newObject[m] = newObject[m] + objeto[i];
              break;
            }
          }
          if (!exist) {
            if (!newObjectAll[i]) {
              newObjectAll[i] = {};
            }
            newObjectAll[i][i] = objeto[i];
            if (!newObject[i]) {
              newObject[i] = 0;
            }
            newObject[i] = newObject[i] + objeto[i];
          }
          console.log("newObjectAll", newObjectAll);
        }
        let xa = 0;
        for (var f in newObject) {
          xa = xa + newObject[f];
          var title = f === "false" ? "no" : f;
          title = f === "true" ? "si" : f;
          const objectFinal = newObjectAll[f];
          adicionalesData.push(
            <Grid
              style={{ pading: 10 }}
              key={e + f + "item"}
              item
              xs={4}
              sm={2}
            >
              <Typography
                style={{ marginTop: 5 }}
                variant={"subtitle1"}
                align="center"
              >
                {title}
              </Typography>
              <Typography
                style={{ marginTop: 5 }}
                variant={"subtitle1"}
                align="center"
              >
                <SimpleDialogDemo
                  variant="text"
                  buttonText={
                    newObject[f] +
                    "(" +
                    Math.round((newObject[f] / suma) * 10000) / 100 +
                    "%)"
                  }
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: 30 }}
                  >
                    <Grid item xs={12} sm={12}>
                      <Typography variant={"h5"} color="primary" align="center">
                        Lista de textos escritos que suman el total reflejado en
                        el reporte
                      </Typography>
                    </Grid>
                    {Object.keys(objectFinal).map((a) => (
                      <Grid item xs={6} sm={6}>
                        <Typography variant={"subtitle1"} align="center">
                          {a}
                        </Typography>
                        <Typography variant={"subtitle1"} align="center">
                          {objectFinal[a]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </SimpleDialogDemo>
              </Typography>
            </Grid>
          );
          const color = "rgba(" + randomColors() + ", 1)";
          dataDispersion.labels.push(f);
          dataDispersion.datasets[0].data.push(objeto[f]);
          dataDispersion.datasets[0].backgroundColor.push(color);
          dataDispersion.datasets[0].borderColor.push(color);

          dataDispersion.datasets[1].data.push(
            Math.round((objeto[f] / suma) * 10000) / 100
          );
          dataDispersion.datasets[1].backgroundColor.push(color);
          dataDispersion.datasets[1].borderColor.push(color);
          console.log("resumen " + campo + " " + f + " " + objeto[f]);
          x++;
        }
        console.log("xa", xa);
      }
    } else {
      for (var f in objeto) {
        var title = f === "false" ? "no" : f;
        title = f === "true" ? "si" : f;
        adicionalesData.push(
          <Grid style={{ pading: 10 }} key={e + f + "item"} item xs={4} sm={2}>
            <Typography
              style={{ margin: 5 }}
              variant={"subtitle1"}
              align="center"
            >
              {title}
            </Typography>

            <Typography variant={"h5"} align="center">
              <b>{objeto[f]}</b>({Math.round((objeto[f] / suma) * 10000) / 100}
              %)
            </Typography>
          </Grid>
        );
        const color = "rgba(" + randomColors() + ", 1)";
        dataDispersion.labels.push(f);
        dataDispersion.datasets[0].data.push(objeto[f]);
        dataDispersion.datasets[0].backgroundColor.push(color);
        dataDispersion.datasets[0].borderColor.push(color);

        dataDispersion.datasets[1].data.push(
          Math.round((objeto[f] / suma) * 10000) / 100
        );
        dataDispersion.datasets[1].backgroundColor.push(color);
        dataDispersion.datasets[1].borderColor.push(color);
        console.log("resumen " + campo + " " + f + " " + objeto[f]);
        x++;
      }
    }
    function valuetext(value) {
      return `${value}%`;
    }
    function setValue(event, value) {
      console.log("similarityPercent", event, value);
      setSimilarityPercent(value / 100);
    }
    console.log("showInDashboard", showInDashboard);
    if (objeto && (showInDashboard || tipoCampo !== "text")) {
      Adicionales.push(
        <Grid style={{ marginTop: 30 }} key={e} item xs={12} sm={12}>
          <Typography variant={"h4"} color="primary" align="center">
            {campo}
          </Typography>
          {tipoCampo === "text" && showInDashboard && (
            <Typography variant={"subtitle1"} color="primary" align="center">
              NOTA: Campo de texto libre, los datos fueron agrupados por
              similitud para ser graficados. Para ver el detalle sin agrupar
              puedes consultar la lista de inscritos. Ajusta el indice sde
              similaridad la barra inferior para separar o agrupar los datos con
              base a un porcentaje de similaridad mayor o menor según se desee
            </Typography>
          )}
          {tipoCampo === "text" && showInDashboard && (
            <Slider
              defaultValue={60}
              getAriaValueText={valuetext}
              onChange={(event, value) => setValue(event, value)}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={10}
              max={100}
            />
          )}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {adicionalesData}

            <Bar data={dataDispersion} options={options} />
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Paper style={{ padding: 10, margin: 5 }}>
      <Typography variant={"h2"} align="center">
        Campos adicionales
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {Adicionales}
      </Grid>
    </Paper>
  );
};

export default DashboardExtraInputs;
