import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import timingConfig from "../timingConfig.json";
import {
  updateEventAttribute,
  deleteCategory,
} from "../../../../../data-store/actions/events-actions";
import Field from "../../../../Fields/Field";
import EditableField from "../../../../EditableField/EditableField";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function TimingSpecificCategory(props) {
  const { subEventIndex, subEvent, gender, category } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const [modalidadIndex, setModalidadIndex] = useState("modalidad_1");

  const timingConfigObject = timingConfig.categoria;
  timingConfigObject["3serie"].optionsArray = Object.values(
    eventSubEvents[subEventIndex].hSalidas
  ).map((start) => ({ value: start.serie, label: start.descripcionSerie }));
  timingConfigObject["3serie"].optionsArray.push({
    value: 0,
    label: "Variable",
  });
  useEffect(() => {}, [eventSubEvents]);

  const classes = useStyles();

  function handleChange(value) {
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidades/" +
          subEventIndex +
          "/ramaCat/" +
          gender +
          "/categorias/categoria_" +
          category.codigo +
          "/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }

    return value;
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {category.nombreCategoria}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} style={{ width: "100%" }}>
        {Object.values(timingConfigObject).map((field, index) => {
          const defaultValue = findValueByRoute(
            eventSubEvents[subEventIndex].ramaCat[gender].categorias[
              "categoria_" + category.codigo
            ],
            field.fieldName
          );

          return (
            <EditableField
              key={index}
              fieldObject={{
                ...field,
                defaultValue: defaultValue,
              }}
              onSubmit={handleChange}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
