import React from "react";
import { connect } from "react-redux";

import EspecificAtleteDataForm from "./EspecificAtleteDataForm";
import AditionalInputsAtleteDataForm from "./AditionalInputsAtleteDataForm";
import Recaptcha from "react-grecaptcha";
import SubEventsInputs from "./SubEventsInputs";
import SubEventsCategoryInputs from "./SubEventsCategoryInputs";
import AditionalInputsAtleteDataFormByModCat from "./AditionalInputsAtleteDataFormByModCat";
import ListAditionalProduct from "../ShopingCar/ListAditionalProduct";
import PaymentDetailRegistryForm from "./PaymentDetail/PaymentDetailRegistryForm";
import CurrencySelect from "./PaymentDetail/CurrencySelect";
//import M from "materialize-css";
import SendRegistryData from "./SendRegistryData";

class FormsContainerUIView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SubEventsInputsKey: 1,
      aditionalInputsModCatKey: 1000,
      aditionalProductsKey: 2000,
      registryType: 2,
      order: [],
      monedaAUsar: {
        codigo: "MXN",
        tipo: "base",
        tipoCambio: 1,
        texto: "Pesos Méxicanos",
        cambioAPeso: "1",
      },
      monedaBase: {
        codigo: "MXN",
        tipo: "base",
        tipoCambio: 1,
        texto: "Pesos Méxicanos",
        cambioAPeso: "1",
      },
      validate: {},
    };
  }

  setCurrencyUsed = (monedaAUsar, monedaBase, index) => {
    this.setState({
      monedaAUsar: monedaAUsar,
      monedaBase: monedaBase,
      monedaAUsarIndex: index,
    });
  };

  setInscriptionPrice = (item) => {
    //alert(JSON.stringify(item));
    var order = [];
    order.push(item);
    this.setState({
      order: order,
    });
  };
  removeItemOrder = (indexItem) => {
    var order = this.state.order;
    delete order[indexItem];
    this.setState({
      order: order,
    });
  };
  updateOrder = (e, producto, item, add) => {
    var order = this.state.order;
    console.log("update Order");
    console.log(item);
    console.log(producto);
    var exist = false;
    for (var i in order) {
      // recorro la orden para ver si el producto ya esta agregado
      if (order[i].code == item.code) {
        exist = true;
        if (add) {
          // add true = a agregar producto
          if (producto.maxCantidad > order[i].quantity) {
            order[i].quantity = order[i].quantity + 1;
          } else {
            console.log("producto con limite");
            if (producto.maxCantidad == 1) {
              // M.toast(
              //   "Este producto ya fue agregado y permite una sola unidad ",
              //   7000
              // );
            } else {
              // M.toast(
              //   `Este producto máximo puede ser agredo ${producto.maxCantidad} veces`,
              //   7000
              // );
            }
          }
        } else {
          // add false == borrar producto
          delete order[i];
        }
      }
    }
    if (!exist) {
      order.push(item);
    }
    this.setState({
      order: order,
    });
  };

  update = (valor, inputObject, valid) => {
    var validate = this.state.validate;
    //alert(JSON.stringify(validate));

    if (valid == null) {
      delete validate[inputObject.iDCampo];
    } else {
      validate[inputObject.iDCampo] = {};
      validate[inputObject.iDCampo] = inputObject;
    }

    console.log("update");
    if ([inputObject.iDCampo] == "rama") {
      var newSubEventsInputsKey = this.state.SubEventsInputsKey + 1;
      var newaditionalInputsModCatKey = this.state.aditionalInputsModCatKey + 1;
      var newaditionalProductsKey = this.state.aditionalProductsKey + 1;
      this.setState({
        [inputObject.iDCampo]: valor,
        modalidad: null,
        categoria: null,
        SubEventsInputsKey: newSubEventsInputsKey,
        aditionalInputsModCatKey: newaditionalInputsModCatKey,
        newaditionalProductsKey: newaditionalProductsKey,
        validate: validate,
      });
    } else if ([inputObject.iDCampo] == "fechaNacimiento") {
      var newSubEventsInputsKey = this.state.SubEventsInputsKey + 1;
      this.setState({
        [inputObject.iDCampo]: valor,
        modalidad: null,
        categoria: null,
        SubEventsInputsKey: newSubEventsInputsKey,
        aditionalInputsModCatKey: newaditionalInputsModCatKey,
        newaditionalProductsKey: newaditionalProductsKey,
        validate: validate,
      });
    } else if ([inputObject.iDCampo] == "modalidad") {
      this.setState({
        modalidad: valor,
        categoria: null,
        validate: validate,
      });
    } else {
      this.setState({
        [inputObject.iDCampo]: valor,
        validate: validate,
      });
    }
    console.log(this.state);
  };

  render() {
    // TODO: revisar que se valida el recapcha para la vista publica
    let recapchaView;
    const verifyCallback = (response) => console.log(response);
    const expiredCallback = () => {
      console.log("expiro el recapcha");
    };
    let recapchaElement = (
      <Recaptcha
        sitekey={"6LegUA4UAAAAAF58i1HTCmcJEt1z2FOd0QV5IGnB"}
        callback={verifyCallback}
        expiredCallback={expiredCallback}
        locale="es"
        className="customClassName"
        // Other props will be passed into the component.
        data-theme="light"
      />
    );
    return (
      <div>
        <div className="row">
          <div className="col s12 center-align">
            <EspecificAtleteDataForm
              eventDetail={this.props.eventDetail}
              registryConfiguration={this.props.registryConfiguration}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
              update={this.update}
              fillInputs={true}
              actualValues={this.state}
            />
            <AditionalInputsAtleteDataForm
              eventDetail={this.props.eventDetail}
              registryConfiguration={this.props.registryConfiguration}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
              update={this.update}
              fillInputs={true}
              actualValues={this.state}
            />
            <CurrencySelect
              registryConfiguration={this.props.registryConfiguration}
              monedaAUsar={this.state.monedaAUsar}
              monedaBase={this.state.monedaBase}
              setCurrencyUsed={this.setCurrencyUsed}
              actualValues={this.state}
            />
            <SubEventsInputs
              key={this.state.SubEventsInputsKey}
              eventDetail={this.props.eventDetail}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryConfiguration={this.props.registryConfiguration}
              rama={this.state.rama}
              fechaNacimiento={this.state.fechaNacimiento}
              customStrings={null}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              atleteAccount={this.props.atleteAccount}
              update={this.update}
              setInscriptionPrice={this.setInscriptionPrice}
              actualValues={this.state}
            />
            <SubEventsCategoryInputs
              key={this.state.SubEventsCategoryInputsKey}
              eventDetail={this.props.eventDetail}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryConfiguration={this.props.registryConfiguration}
              rama={this.state.rama}
              fechaNacimiento={this.state.fechaNacimiento}
              modalidad={this.state.modalidad}
              customStrings={this.props.customStrings}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              atleteAccount={this.props.atleteAccount}
              update={this.update}
              setInscriptionPrice={this.setInscriptionPrice}
              actualValues={this.state}
            />

            <AditionalInputsAtleteDataFormByModCat
              key={this.state.aditionalInputsModCatKey}
              eventDetail={this.props.eventDetail}
              registryConfiguration={this.props.registryConfiguration}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
              update={this.update}
              fillInputs={true}
              modalidad={this.state.modalidad}
              rama={this.state.rama}
              categoria={this.state.categoria}
              registrytype={this.state.registryType}
              actualValues={this.state}
            />
            <ListAditionalProduct
              key={this.state.aditionalProductsKey}
              eventDetail={this.props.eventDetail}
              registryConfiguration={this.props.registryConfiguration}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
              updateOrder={this.updateOrder}
              fillInputs={true}
              modalidad={this.state.modalidad}
              rama={this.state.rama}
              categoria={this.state.categoria}
              registryType={this.state.registryType}
              monedaAUsar={this.state.monedaAUsar}
              actualValues={this.state}
            />
            <PaymentDetailRegistryForm
              removeItemOrder={this.removeItemOrder}
              registrytype={this.state.registryType}
              order={this.state.order}
              monedaAUsar={this.state.monedaAUsar}
              monedaBase={this.state.monedaBase}
              actualValues={this.state}
            />
          </div>
        </div>
        <div className="row">
          <div className="col s12 center-align">
            <SendRegistryData
              eventDetail={this.props.eventDetail}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryConfiguration={this.props.registryConfiguration}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
              stateFormContainer={this.state}
              registryType={this.state.registryType}
              actualValues={this.state}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    userID: state.atlete.userID,
    customStrings: state.events.customStrings,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const FormsContainerUI = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormsContainerUIView);
export default FormsContainerUI;
