import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { translateForm, translateInput } from "../../../../Fields/FieldUtils";
import FormRender from "../../../../Fields/FormRender";

const AditionalInputs = ({
  eventDetail,
  eventSubEventsRegistry,
  registryConfigurationData,
  currentOrder,
  callbackInputs,
  profile,
}) => {
  const dispatch = useDispatch();

  console.log("registryConfigurationData", registryConfigurationData);
  console.log("currentOrder", currentOrder);

  function onSubmit(value) {
    console.log("value", value);
    callbackInputs(value);
  }
  function getProfileToShow(input, profile) {
    if (
      (input.hideInPublic === undefined || input.hideInPublic === false) &&
      profile === "Public"
    ) {
      return true;
    } else if (
      (input.hideInscriptor === undefined || input.hideInscriptor === false) &&
      profile === "inscriptor"
    ) {
      return true;
    } else if (
      (input.hideMasterCliente === undefined ||
        input.hideMasterCliente === false) &&
      profile === "MasterCliente"
    ) {
      return true;
    } else if (
      (input.hideMasterOrganizador === undefined ||
        input.hideMasterOrganizador === false) &&
      profile === "MasterOrganizador"
    ) {
      return true;
    }

    return false;
  }
  const camposAdicionalesModalidadFunc = () => {
    var camposAdicionalesModalidad = [];

    var modalidadesBuscar = eventSubEventsRegistry;

    var formulario = !modalidadesBuscar["modalidad_" + currentOrder.modalidad]
      .hideAditionalInputs
      ? registryConfigurationData.formulario
      : {};
    for (var i in formulario) {
      if (getProfileToShow(formulario[i], profile)) {
        camposAdicionalesModalidad.push(formulario[i]);
        console.log("registryConfigurationData", formulario[i]);
      }
    }

    var arrayCampoModalidad = [];

    if (
      currentOrder.modalidad !== undefined &&
      modalidadesBuscar["modalidad_" + currentOrder.modalidad] !== undefined
    ) {
      const modalidad =
        modalidadesBuscar["modalidad_" + currentOrder.modalidad];
      var modalidadCampos =
        modalidadesBuscar["modalidad_" + currentOrder.modalidad].configModalidad
          .camposAdicionalesModalidad;
      if (modalidadCampos !== undefined && modalidad !== undefined) {
        for (var e in modalidadCampos) {
          if (
            modalidad.ramaCat["rama_" + currentOrder.rama] !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ] !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ].camposAdicionalesKey !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ].camposAdicionalesKey === e
          ) {
            console.log("registryConfigurationData", e);
            var campos = modalidadCampos[e];
            console.log(e);
            for (var f in campos) {
              //if (campos[f].tipoCampo != "titulo") {
              if (!arrayCampoModalidad.includes(campos[f].campo)) {
                if (getProfileToShow(campos[f], profile)) {
                  camposAdicionalesModalidad.push(campos[f]);
                }
              }
              //}
            }
          }
        }
      }
    }

    return camposAdicionalesModalidad;
  };

  var camposAdicionales = camposAdicionalesModalidadFunc();
  // TODO: agregar el areglo de productos adicionales cuando tenga la vista lista de cada producto
  // y cuando se puede hacer el pago de la diferencia de precio al a gregar o quitar un producto.
  //   var tabla = (<FormRender
  //     callback={confirmSubEvent}
  //     formObject={formDistanceAndExtra}
  //     submitButtonText={"Confirmar y continuar con el pago"}
  //   />
  //   );

  return (
    <FormRender
      callback={onSubmit}
      formObject={translateForm(camposAdicionales)}
      submitButtonText={"Guardar y continuar"}
    />
  );
};

export default AditionalInputs;
