import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "./SimpleDialog";
import FormRender from "../../Fields/FormRender";
import { createEventAttribute } from "../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateWebSectionCopy(props) {
  const { Components, pageNew, callback } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventWeb = { ...pageNew };

  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  console.log("componentes", Components);
  function onSubmit(data) {
    console.log(data);
    let indexNumber = 0;
    if (
      eventWeb !== undefined &&
      eventWeb !== null &&
      eventWeb.views !== undefined
    ) {
      indexNumber = eventWeb.views.length;
    } else {
      indexNumber = eventWeb.views = [];
    }
    const route = `web/views/${indexNumber}/`;
    let value = data.section;

    let atributes = {};
    const componentConfig =
      Components[data.section].componentDescription.componentConfig;
    for (var i in componentConfig) {
      atributes[i] = componentConfig[i].defaultValue;
    }

    const newInput = {
      component: data.section,
      componentConfig: atributes,
    };
    console.log(newInput);
    eventWeb.views.push(newInput);
    callback(eventWeb);

    // dispatch(
    //   createEventAttribute("evento_" + eventDetail.iDEvento, route, newInput)
    // );
  }

  function selectList(componentsList) {
    let array = [];
    for (var i in componentsList) {
      array.push({
        label: componentsList[i].default.displayName,
        value: i,
      });
    }

    return array;
  }
  let createEventObject = {
    section: {
      label: "Selecciona la sección",
      errorMessage: "Campo Requerido",
      fieldName: "section",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: selectList(Components),
    },
  };

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea una sección de la Página
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={"Crear sección"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear una Sección"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
