import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { fechahora } from "../../../../../../../services/utils";
import SimpleDialogDemo from "../../../../../../pages/Modals/SimpleDialog";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { ResultadosGanadores } from "../../../../../../pages/reports/reportsLogic";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function PrintResultsCSV(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const [csvLabels, setCSVLabels] = useState([
    { label: "Número", key: "num" },
    { label: "Posición General", key: "pG" },
    { label: "Posición Rama", key: "pR" },
    { label: "Posición Categoría", key: "pCat" },
    { label: "Nombre", key: "nombre" },
    { label: "Genero", key: "gen" },
    { label: "Categoría", key: "cat" },
    { label: "Modalidad", key: "mod" },
    { label: "Tiempo Oficial", key: "to" },
    { label: "Tiempo Real", key: "tc" },
    { label: "Fecha hora Meta", key: "fhm" },
    { label: "Registro Salida", key: "registroSalida" },
    { label: "Serie", key: "serie" },
  ]);
  const [csvLabelsResume, setCSVLabelsResume] = useState([
    { label: "Número", key: "num" },
    { label: "Posición Categoría", key: "pCat" },
    { label: "Nombre", key: "nombre" },
    { label: "Genero", key: "gen" },
    { label: "Categoría", key: "cat" },
    { label: "Modalidad", key: "mod" },
    { label: "Tiempo Oficial", key: "to" },
  ]);
  const [csvReport, setCSVReport] = useState([]);
  const [csvReportFinishers, setCSVReportFinishers] = useState([]);
  const [csvReportWinners, setCSVReportWinners] = useState([]);
  const [arrayCategories, setarrayCategories] = useState([]);

  useEffect(() => {
    if (eventResults && eventSubEvents) {
      setarrayCategories(getCategoryArray(eventSubEvents));
      setCSVReport(eventResults);
      setCSVReportFinishers(eventResults.filter((a) => a.pG !== 0));

      const winners = ResultadosGanadores(eventResults);
      setCSVReportWinners(winners);
      let winnersAndTiles = [];
      for (var i in winners) {
        if (winners[i - 1] && winners[i - 1].pCat > winners[i].pCat) {
        }
      }
    }
  }, [eventResults]);

  const titlesPush = {
    num: "Número",
    pG: "Posición General",
    pR: "Posición Rama",
    pCat: "Posición Categoría",
    nombre: "Nombre",
    gen: "Genero",
    cat: "Categoría",
    mod: "Modalidad",
    to: "Tiempo Oficial",
    tc: "Tiempo Real",
    fhm: "Fecha hora Meta",
    registroSalida: "Registro Salida",
    seria: "Serie",
  };

  function getCategoryArray(eventSubEventsFunc) {
    let array = {};
    for (var m in eventSubEvents) {
      for (var g in eventSubEvents[m]) {
        for (var c in eventSubEvents[m].ramaCat[g]) {
          array[m + "-" + g + "-" + c] =
            eventSubEvents[m].ramaCat[g].categorias[c].cantidadGanadores;
        }
      }
    }
  }
  function downloadPDFWinners() {
    const header = [
      "Número",
      "Posición",
      "Nombres",
      "Modalidad",
      "Género",
      "Categoría",
      "Tiempo Oficial",
    ];
    console.log("PDF Winners");
    const doc = new jsPDF();
    var lMargin = 15; //left margin in mm
    var rMargin = 15; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm

    const title = "Ganadores";
    console.log(title);
    // if (title) {
    //   var lines = doc.splitTextToSize(title, pdfInMM - lMargin - rMargin);
    //   doc.text(lMargin, 15, lines);

    //      doc.text(7, 15, title);
    // }

    let tempList = [];
    for (var i in csvReportWinners) {
      if (
        csvReportWinners[i - 1] &&
        (csvReportWinners[i - 1].codMod !== csvReportWinners[i].codMod ||
          csvReportWinners[i - 1].codCad !== csvReportWinners[i].codCad ||
          csvReportWinners[i - 1].gen !== csvReportWinners[i].gen)
      ) {
        doc.autoTable({
          head: [header],
          body: tempList.map((a) => [
            a.num,
            a.pCat,
            a.nombre,
            a.mod + " km",
            a.gen,
            a.cat,
            a.to,
          ]),
          margin: { horizontal: 1 },
          columnStyles: {
            0: { halign: "center", fontSize: 10 },
            1: { halign: "center", fontSize: 10 },
            2: { fontSize: 10 },
            3: { halign: "center", fontSize: 10 },
            4: { halign: "center", fontSize: 10 },
            5: { halign: "center", fontSize: 10 },
            6: { halign: "center", fontSize: 10 },
          }, // Cells in first column centered and green
          headStyles: {
            0: { halign: "center" },
            1: { halign: "center" },
            3: { halign: "center" },
            4: { halign: "center" },
            5: { halign: "center" },
            6: { halign: "center" },
            fillColor: [255, 50, 50],
          }, // Cells in first column centered and green
        });
        tempList = [];
      }
      tempList.push(csvReportWinners[i]);
    }

    doc.save(
      "Resultados Winners - " +
        eventDetail.nombreEvento +
        " - " +
        fechahora() +
        ".pdf"
    );
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        {eventResults && (
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12} sm={12} alignContent="center">
              <CSVLink
                style={{ padding: 5 }}
                filename={
                  "Resultados - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReport}
                headers={csvLabels}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 20, margin: "5%", width: "90%" }}
                >
                  Descargar todos los resultados en CSV
                </Button>
              </CSVLink>
            </Grid>
            <Grid item xs={12} sm={12} alignContent="center">
              <CSVLink
                style={{ padding: 5 }}
                filename={
                  "Resultados Finishers - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReportFinishers}
                headers={csvLabels}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 20, margin: "5%", width: "90%" }}
                >
                  Descargar Finishers CSV
                </Button>
              </CSVLink>
            </Grid>
            <Grid item xs={12} sm={12} alignContent="center">
              <CSVLink
                style={{ padding: 5, width: "100%" }}
                filename={
                  "Ganadores - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReportWinners}
                headers={csvLabelsResume}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 20, margin: "5%", width: "90%" }}
                >
                  Descargar Ganadores CSV
                </Button>
              </CSVLink>
            </Grid>
            <Grid item xs={12} sm={12} alignContent="center">
              <Button
                variant="contained"
                color="primary"
                style={{ padding: 20, margin: "5%", width: "90%" }}
                onClick={() => downloadPDFWinners()}
              >
                Descargar PDF Ganadores
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
