import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { atletePhotosFunc } from "../../../../data-store/actions/atlete-actions";
import {
  athleteJuezRegistries,
  createJuezRegistry,
} from "../../../../data-store/actions/events-actions";

import AtleteControls from "./AtleteControls";
import AtleteCharts from "./AtleteCharts";
import AtletePhotos from "./AtletePhotos";
import { puntoFotograficoPrevRuta } from "../../../../firebase/firebasejs";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import { Typography } from "@material-ui/core";

export default function UpdateRegistryJuez(props) {
  const dispatch = useDispatch();
  const { registry, update, eventDetail } = props;
  const userID = useSelector((state) => state.atlete.userID);
  const registryJuez = useSelector((state) => state.events.registryJuez);
  const eventUsers = useSelector((state) => state.events.eventUsers);
  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  useEffect(() => {
    //dispatch();
    //athleteJuezRegistries("evento_" + eventDetail.iDEvento, resultado.num)
  }, []);

  function onSubmit(data) {
    console.log("data", data);

    const object = {
      competidorId: update ? registry.competidorId : userID,
      fechaHora: data.fecha + " " + data.hora,
      iDEvento: eventDetail.iDEvento,
      kPuntoControl: data.kPuntoControl,
      lat: registry.lat,
      lng: registry.lng,
      numero: registry.numero,
      timeStamp: new Date(data.fecha + "T" + data.hora).getTime(),
      visibleOBorrado: data.visibleOBorrado ? 1 : 0,
    };

    dispatch(createJuezRegistry(object, update, registry.index));
  }

  function selectList(object) {
    let array = [];
    for (var i in object) {
      array.push({
        label: object[i].descripcionFinalPunto,
        value: object[i].kNroPunto,
      });
    }

    return array;
  }
  function selectListTime(top) {
    let array = [];
    for (var i = 0; i <= top; i++) {
      const seconds = i < 10 ? "0" + i : i;
      array.push({
        label: seconds,
        value: seconds,
      });
    }

    return array;
  }

  console.log("registry.fechaHora", registry.fechaHora);
  const dateFormat = new Date(registry.fechaHora);
  console.log("dateFormat", dateFormat.getSeconds());
  const fecha = registry.fechaHora.split(" ")[0];
  const horaSplit = registry.fechaHora.split(" ")[1].split(":");
  const hora = registry.fechaHora.split(" ")[1];
  let createEventObject = {
    kPuntoControl: {
      label: "Punto de Control",
      errorMessage: "Campo Requerido",
      fieldName: "kPuntoControl",
      type: "select",
      defaultValue: registry.kPuntoControl,
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: selectList(puntosControl),
    },
    visibleOBorrado: {
      label: "Visible o Borrado",
      errorMessage: "Campo Requerido",
      fieldName: "visibleOBorrado",
      type: "switch",
      defaultValue: registry.visibleOBorrado === 0 ? false : true,
      required: true,
      regex: "",
      textSize: "h6",
    },
    fecha: {
      label: "Fecha",
      errorMessage: "Campo Requerido",
      fieldName: "fecha",
      type: "date",
      defaultValue:
        fecha.split("-")[0] +
        "-" +
        fecha.split("-")[1] +
        "-" +
        fecha.split("-")[2],
      required: true,
      regex: "",
      textSize: "h6",
    },
    // horas: {
    //   label: "Horas",
    //   errorMessage: "Campo Requerido",
    //   fieldName: "horas",
    //   type: "select",
    //   defaultValue: horaSplit[0] < 10 ? "0" + horaSplit[0] : horaSplit[0],
    //   required: true,
    //   regex: "",
    //   textSize: "h6",
    //   optionsArray: selectListTime(24),
    // },
    // minutos: {
    //   label: "Minutos",
    //   errorMessage: "Campo Requerido",
    //   fieldName: "minutos",
    //   type: "select",
    //   defaultValue: horaSplit[1] < 10 ? "0" + horaSplit[1] : horaSplit[1],
    //   required: true,
    //   regex: "",
    //   textSize: "h6",
    //   optionsArray: selectListTime(59),
    // },
    // segundos: {
    //   label: "Segundos",
    //   errorMessage: "Campo Requerido",
    //   fieldName: "segundos",
    //   type: "select",
    //   defaultValue:
    //     dateFormat.getSeconds() < 10
    //       ? "0" + dateFormat.getSeconds()
    //       : dateFormat.getSeconds(),
    //   required: true,
    //   regex: "",
    //   textSize: "h6",
    //   optionsArray: selectListTime(59),
    // },
    hora: {
      label: "Hora, minutos, segundos y milisegundos (00:00:00.000)",
      errorMessage: "Revisa el formato (00:00:00.000)",
      fieldName: "hora",
      type: "text",
      defaultValue: hora,
      required: true,
      regex:
        "^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]:[0-5][0-9].[0-9][0-9][0-9]$",
      textSize: "h6",
    },
  };
  return (
    <div style={{ padding: "40px", minWidth: "500px" }}>
      <Typography gutterBottom variant={"h6"} component="h5">
        Hora Original:
      </Typography>
      <Typography gutterBottom variant={"h5"} component="h2">
        {registry.fechaHora}
        {eventDetail.fechaHora}
      </Typography>
      <FormRender
        callback={onSubmit}
        formObject={createEventObject}
        submitButtonText={"Guardar Registro"}
      />
    </div>
  );
}
