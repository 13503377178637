import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper, IconButton } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  createEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateInput(props) {
  const { inputGroup, input, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  console.log("input", input);

  const createEventObject = createEventConfig.adicionalInput;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    console.log(input);
    if (
      (input.tipoCampo === "Select" || input.tipoCampo === "option") &&
      Object.keys(value)[0] === "valor"
    ) {
      const key = Object.keys(value)[0];
      const valueprev = Object.values(value)[0];
      const valorSplit = valueprev.split(";");
      value = { [key]: valorSplit.map((a) => ({ texto: a, valor: a })) };
    }

    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/gruposFormularios/" +
          inputGroup +
          "/" +
          index +
          "/",
        value
      )
    );
    // registrar campo en modalidades
    if (inputGroup !== "general") {
      for (var i in eventSubEventsRegistry) {
        const newRoute = `modalidadesInscripcion/${i}/configModalidad/camposAdicionalesModalidad/${inputGroup}`;
        dispatch(
          updateEventAttribute(
            "evento_" + eventDetail.iDEvento,
            newRoute + "/" + index + "/",
            value
          )
        );
      }
    } else {
      const newRoute = `inscripcionesConfiguracion/formulario`;
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute + "/" + index + "/",
          value
        )
      );
    }
  }

  function deleteSubEventFunc() {
    // dispatch(
    //   deleteSubEvent(
    //     "evento_" + eventDetail.iDEvento,
    //     userID,
    //     eventSubEventsRegistry,
    //     subEventIndex
    //   )
    // );
  }

  function moveUp(itemIndex) {
    console.log("itemIndex", itemIndex);
    console.log("itemIndex", input);
    console.log("itemIndex", inputGroup);

    let inputs = { ...registryConfiguration.gruposFormularios[inputGroup] };
    inputs[itemIndex] = inputs[itemIndex - 1];
    inputs[itemIndex - 1] = input;

    console.log(inputs);
    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/gruposFormularios/" + inputGroup + "/",
        inputs
      )
    );
    // registrar campo en modalidades
    if (inputGroup !== "general") {
      for (var i in eventSubEventsRegistry) {
        const newRoute = `modalidadesInscripcion/${i}/configModalidad/camposAdicionalesModalidad/${inputGroup}/`;
        dispatch(
          createEventAttribute(
            "evento_" + eventDetail.iDEvento,
            newRoute + "/",
            inputs
          )
        );
      }
    } else {
      const newRoute = `inscripcionesConfiguracion/formulario`;
      dispatch(
        createEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute + "/",
          inputs
        )
      );
    }
  }
  function moveDown(itemIndex) {
    console.log("itemIndex", itemIndex);
    console.log("itemIndex", input);

    console.log("itemIndex", inputGroup);
    let inputs = { ...registryConfiguration.gruposFormularios[inputGroup] };
    inputs[itemIndex] = inputs[itemIndex + 1];
    inputs[itemIndex + 1] = input;

    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/gruposFormularios/" + inputGroup + "/",
        inputs
      )
    );
    // registrar campo en modalidades
    if (inputGroup !== "general") {
      for (var i in eventSubEventsRegistry) {
        const newRoute = `modalidadesInscripcion/${i}/configModalidad/camposAdicionalesModalidad/${inputGroup}`;
        dispatch(
          createEventAttribute(
            "evento_" + eventDetail.iDEvento,
            newRoute + "/",
            inputs
          )
        );
      }
    } else {
      const newRoute = `inscripcionesConfiguracion/formulario`;
      dispatch(
        createEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute + "/",
          inputs
        )
      );
    }
  }

  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {input.etiqueta}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {input.iDCampo})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {Object.values(createEventObject).map((field, index) => {
              let defaultValue = input[field.fieldName];
              if (typeof defaultValue === "object") {
                //defaultValue = JSON.stringify(input[field.fieldName]);
                let def = "";
                for (var i in defaultValue) {
                  def = (i > 0 ? def + ";" : "") + defaultValue[i].valor;
                }
                defaultValue = def;
              }
              return (
                <EditableField
                  key={index}
                  fieldObject={{
                    ...field,
                    defaultValue: defaultValue,
                  }}
                  onSubmit={handleChange}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Editar"} icon={<OpenWithIcon />}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
      <Grid item xs={6}>
        {index > 0 && (
          <IconButton onClick={() => moveUp(index)}>
            <ArrowUpwardIcon />
          </IconButton>
        )}
        {registryConfiguration.gruposFormularios &&
          index + 2 <=
            registryConfiguration.gruposFormularios[inputGroup].length && (
            <IconButton onClick={() => moveDown(index)}>
              <ArrowDownwardIcon />
            </IconButton>
          )}
      </Grid>
    </Grid>
  );
}
