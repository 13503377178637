import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { getFormData } from "../../../../../data-store/actions/registry-actions";

import CSVButton from "../../Reports/CSVButton";
import {
  generateReport,
  ReportTable,
  getTotalPurchases,
} from "../../Reports/generateReport";
import { timestampToDate } from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    overflowY: "scroll",
    width: "100%",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  list: {
    margin: 30,
    width: "400px",
    color: "black",
  },
}));

export default function FormReports(props) {
  const { inputGroup, form } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const formData = useSelector((state) => state.registry.formData);
  const inputGroupStore = useSelector((state) => state.registry.inputGroup);

  const [report, setReport] = useState([]);
  const [labels, setLabels] = useState([]);
  const [reportType, setReportType] = useState(undefined);
  const [itemIdentifier, setItemIdentifier] = useState(undefined);

  console.log(eventDetail);
  const classes = useStyles();
  useEffect(() => {
    if (!formData || inputGroup !== inputGroupStore) {
      dispatch(getFormData("evento_" + eventDetail.iDEvento, inputGroup));
    }
    if (formData) {
      const data = Object.values(formData);
      const newReport = generateReport({
        data: data,
        fields: titles(form),
      });
      setLabels(newReport.labels);
      setReport(newReport.report);
      setItemIdentifier(newReport.itemIdentifier);
    }
  }, [formData]);

  function titles(form) {
    var titlesList = [];
    titlesList.push({
      label: "Fecha de Registro",
      function: timestampToDate,
      atribute: "date",
    });
    for (var i in form) {
      titlesList.push({ label: form[i].etiqueta, path: form[i].iDCampo });

      //titlesList[form[i].iDCampo] = form[i].etiqueta;
    }
    return titlesList;
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h2"} component="h2">
            Reporte formulario Campos {inputGroup}
          </Typography>
        </Grid>
        <Grid item xs={12} alignItems="center">
          {formData && <CSVButton report={report} labels={labels} />}
          {formData ? (
            <ReportTable
              report={report}
              labels={labels}
              data={Object.values(formData)}
              reportType={reportType}
              itemIdentifier={itemIdentifier}
              showButtonDetail={false}
            />
          ) : (
            <Typography gutterBottom variant={"h5"} component="h2">
              No hay datos registrados en un formulario público para estos
              campos
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
