import { Paper, Typography } from "@material-ui/core";
import React from "react";
import DeletedParticipantNumberROW from "./DeletedParticipantNumberROW";

const DeletedParticipantList = ({
  eventParticipants,
  userID,
  atleteAccount,
  eventDetail,
}) => {
  var dataTable = [];
  var eventoIndex = "evento_" + eventDetail.iDEvento;

  for (var i in eventParticipants) {
    if (eventParticipants[i].borrado && eventParticipants[i].numero != null) {
      dataTable.push(
        <DeletedParticipantNumberROW
          key={i}
          eventoIndex={eventoIndex}
          ParticipanDetail={eventParticipants[i]}
          ParticipanDetailKey={i}
          userID={userID}
          atleteAccount={atleteAccount}
        />
      );
    }
  }

  return (
    <Paper style={{ margin: 5, padding: 20 }}>
      <Typography variant={"h3"} align="left">
        Lista de folios borrados{" "}
      </Typography>

      <table className="striped">
        <thead>
          <tr>
            <th>Folio</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Usuario Borra</th>
            <th>Fecha Borra</th>
            <th>Usuario Libera</th>
            <th>Fecha Libera</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{dataTable}</tbody>
      </table>
    </Paper>
  );
};
export default DeletedParticipantList;
