import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { setEventAccount } from "../../../../../../data-store/actions/events-actions";
import { fecha } from "../../../../../../services/utils";
import EditableField from "../../../../../EditableField/EditableField";
import CSVButton from "../../../../AuthorizedUsers/Reports/CSVButton";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import AddAuthUserToDeposits from "./AddAuthUserToDeposits";
import AddItem from "./AddItem";
import EditItem from "./EditItem";

export default function SaveAccountToMaster({
  jsonDashboard,
  eventDepositsList,
}) {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventDepositsUsers = useSelector(
    (state) => state.events.eventDepositsUsers
  );

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Report ", generateReport(eventDepositsList));
    const report = generateReport(eventDepositsList);
    dispatch(setEventAccount("evento_" + eventDetail.iDEvento, report));
  }, []);

  function generateReport(data) {
    let report =
      data &&
      Object.values(data)
        .sort((a, b) =>
          a.depositDate > b.depositDate
            ? 1
            : b.depositDate > a.depositDate
            ? -1
            : 0
        )
        .map((item, i) => {
          let amount = 0;
          let date = fecha(0);

          if (item.type === "oxxoRegistryPercent") {
            amount = (item.amount / 100) * jsonDashboard.totalCompletoOxxo;
          } else if (item.type === "cardRegistryPercent") {
            amount = (item.amount / 100) * jsonDashboard.totalTarjeta;
          } else if (item.type === "cashRegistryPercent") {
            amount = (item.amount / 100) * jsonDashboard.totalPagosEfectivo;
          } else if (item.type === "fixedAmount") {
            amount = item.amount;
            date = item.depositDate;
          } else if (item.type === "deposit") {
            amount = -item.amount;
            date = item.depositDate;
          }

          return {
            ...item,
            amount: amount / 1,
            depositDate: date,
            eventId: eventDetail.iDEvento,
            eventName: eventDetail.nombreEvento,
          };
        });

    return report;

    //return { labels: labels, report: report };
  }

  return <div></div>;
}
