import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getPuntosControl,
  configuracionEvento,
  eventSubEvents,
  juecesEvento,
  resultadosEventoNew,
} from "../../../data-store/actions/events-actions";
import {
  eventParticipants,
  registryConfiguration,
  registryAutorizedUsers,
} from "../../../data-store/actions/registry-actions";

import ResultsReportNewAUTH from "../reports/ResultsReportNewAUTH";
import RealTimeResults from "../reports/RealTimeResults";
import Anouncer from "../reports/Anouncer";
import UploadResultsData from "./UploadResultsData";
import EventClock from "../reports/EventClock/EventClock";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CustomViewContainer from "./CustomViews/CustomViewsContainer.jsx";
import CustomViewContainerNew from "./CustomViews/CustomViewsContainerNew";
import DownloadResultsButton from "../../Landing/sections/RaceResultsPublic/PastReports/reports/DownloadResultsButton";
import { Grid } from "@material-ui/core";
import DashboardTimingContainer from "./Timing/DashboardTiming/DashboardTimingContainer";
import StartsList from "./Timing/TimingRegistry/StartsList";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function AuthorizedUsersResultsContainerNew({ match }) {
  const { EventoIndex } = match.params;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents1 = useSelector((state) => state.events.eventSubEvents);
  const eventResults = useSelector((state) => state.events.eventResults);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const puntosControl = useSelector((state) => state.events.puntosControl);
  const eventUsers = useSelector((state) => state.events.eventUsers);

  const [value, setValue] = useState(0);
  console.log("value", value);
  console.log(eventDetail.iDEvento, EventoIndex);

  useEffect(() => {
    if (!validateProps() || "evento_" + eventDetail.iDEvento !== EventoIndex) {
      dispatch(resultadosEventoNew(EventoIndex));

      dispatch(configuracionEvento(EventoIndex));
      dispatch(getPuntosControl(EventoIndex));
      dispatch(eventSubEvents(EventoIndex));
      dispatch(juecesEvento(EventoIndex));
    }
  }, []);

  function handleChange(event, value) {
    setValue(value);
  }
  function validateProps() {
    // para validar que todos los datos requeridos ya se descargaron para continuar
    if (
      eventResults !== null &&
      eventResults !== undefined &&
      eventSubEvents1 !== null &&
      eventSubEvents1 !== undefined &&
      eventConfiguration !== null &&
      eventConfiguration !== undefined &&
      eventUsers !== null &&
      eventUsers !== undefined &&
      puntosControl !== null &&
      puntosControl !== undefined
    ) {
      return true;
    }
    return false;
  }

  let arrayTabs = [];
  let contentTabs = [];

  let userProfile = null;
  var convocatoriaShow;
  var tabConfig;

  arrayTabs.push(<Tab key="tab-Dashboard" label="Dashboard" />);
  contentTabs.push(
    <TabContainer key="contTab-Dashboard">
      <DashboardTimingContainer />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Ganadores" label="Ganadores" />);
  contentTabs.push(
    <TabContainer key="contTab-Ganadores">
      <ResultsReportNewAUTH
        reportResultType={4}
        controles={true}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Categorias" label="Categorías" />);
  contentTabs.push(
    <TabContainer key="contTab-Categorias">
      <ResultsReportNewAUTH
        reportResultType={3}
        controles={true}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Rama" label="Rama" />);
  contentTabs.push(
    <TabContainer key="contTab-Rama">
      <ResultsReportNewAUTH
        reportResultType={2}
        controles={true}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Generales" label="Generales" />);
  contentTabs.push(
    <TabContainer key="contTab-Generales">
      <ResultsReportNewAUTH
        reportResultType={1}
        controles={true}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-RealTime" label="Real Time" />);
  contentTabs.push(
    <TabContainer key="contTab-RealTime">
      <RealTimeResults
        eventResults={eventResults}
        eventDetail={eventDetail}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Anouncer" label="Anouncer" />);
  contentTabs.push(
    <TabContainer key="contTab-Anouncer">
      <Anouncer
        eventResults={eventResults}
        eventDetail={eventDetail}
        puntosControl={puntosControl}
      />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-Clock" label="Relojes" />);
  contentTabs.push(
    <TabContainer key="contTab-Clock">
      <EventClock fontSize={250} />
    </TabContainer>
  );
  arrayTabs.push(<Tab key="tab-CustomViews" label="Vistas personalizadas" />);
  contentTabs.push(
    <TabContainer key="contTab-CustomViews">
      <CustomViewContainer />
    </TabContainer>
  );

  let element;
  if (!validateProps()) {
    element = (
      <div className="row">
        <div className="col s12 m12 l12">
          <h3>Cargando resultados...</h3>
        </div>
      </div>
    );
  } else {
    userProfile = eventUsers[userID].juezTipo;

    if (userProfile == 1) {
      // subir esto luego cuando ya esté listo ese proceso
      // arrayTabs.push(<Tab key="tab-RemoteControls" label="Controles Remotos" />);
      // contentTabs.push(<TabContainer key="contTab-RemoteControls">
      //   <h5>Configurar Controles Remotos</h5>
      // </TabContainer>)
      arrayTabs.push(<Tab key="tab-UploadData" label="Subir Data Registros" />);
      contentTabs.push(
        <TabContainer key="contTab-UploadData">
          <UploadResultsData
            eventResults={eventResults}
            eventDetail={eventDetail}
            puntosControl={puntosControl}
            eventSubEvents={eventSubEvents1}
          />
        </TabContainer>
      );
    }
    element = (
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={11} sm={11}>
            <Tabs
              className="z-depth-1 red-text"
              value={value}
              scrollButtons="auto"
              variant="scrollable"
              onChange={handleChange}
            >
              {arrayTabs}
            </Tabs>
          </Grid>
          <Grid item xs={1} sm={1}>
            <DownloadResultsButton />
          </Grid>
        </Grid>
        {value === 0 && contentTabs[value]}
        {value === 1 && contentTabs[value]}
        {value === 2 && contentTabs[value]}
        {value === 3 && contentTabs[value]}
        {value === 4 && contentTabs[value]}
        {value === 5 && contentTabs[value]}
        {value === 6 && contentTabs[value]}
        {value === 7 && contentTabs[value]}
        {value === 8 && contentTabs[value]}
        {value === 9 && contentTabs[value]}
        {value === 10 && contentTabs[value]}
        {value === 11 && contentTabs[value]}
        {value === 12 && contentTabs[value]}
      </Container>
    );
  }

  return element;
}
