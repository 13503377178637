import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import LayoutResolverEdit from "../../../../Landing/LayoutContainers/LayoutResolverEdit";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventWebContainer(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventWeb = useSelector((state) => state.events.eventWeb);
  const eventWebCopy = { ...eventWeb };

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );

  const classes = useStyles();

  let { EventoIndex } = useParams();
  console.log("eventDetail", eventDetail);
  if ("evento_" + eventDetail.iDEvento !== EventoIndex) {
    return (
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h2"} component="h2">
            Error Recarga la web
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Web del evento
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LayoutResolverEdit page={eventWebCopy} />
      </Grid>
    </Grid>
  );
}
