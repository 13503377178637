import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateProduct(props) {
  const { product, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  console.log("product", product);

  const createEventObject = createEventConfig.product;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function handleChange(value) {
    console.log("index", index);
    console.log("index", value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscripcionesConfiguracion/productosAdicionales/" +
          "/producto_" +
          product.codigoProducto +
          "/",
        value
      )
    );
    // registrar campo en modalidades
    for (var i in eventSubEventsRegistry) {
      const newRoute = `modalidadesInscripcion/${i}/configModalidad/productosAdicionales/`;
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute + "/producto_" + product.codigoProducto + "/",
          value
        )
      );
    }
  }

  function deleteSubEventFunc() {
    // dispatch(
    //   deleteSubEvent(
    //     "evento_" + eventDetail.iDEvento,
    //     userID,
    //     eventSubEventsRegistry,
    //     subEventIndex
    //   )
    // );
  }

  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {product.nombreProducto}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {product.codigoProducto})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {Object.values(createEventObject).map((field, i) => {
              const defaultValue = product[field.fieldName];
              if (field.fieldName === "formularioProducto") {
                field.optionsArray = Object.keys(
                  registryConfiguration.gruposFormularios
                ).map((a) => ({
                  value: registryConfiguration.gruposFormularios[a],
                  label: a,
                }));
                field.optionsArray.push({
                  value: null,
                  label: "Ninguno",
                });
              }

              return (
                <EditableField
                  key={i}
                  fieldObject={{
                    ...field,
                    defaultValue: defaultValue,
                  }}
                  onSubmit={handleChange}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Editar Producto"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
