import { Button, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomViewsConfig from "./CustomViewsConfig";

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function DragDropContextBox(props) {
  const { view, showEdition, callback, index, data } = props;
  const [viewItems, setViewItems] = useState(view.views);

  useEffect(() => {
    setViewItems(view.views);
  }, [view]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log("view.miniTheme", view.miniTheme);
  const style = {
    backgroundColor: view.miniTheme.background || "",
    height: view.miniTheme.height + "mm",
    width: view.miniTheme.width + "mm",
    padding: view.miniTheme.padding,
    fontFamily: view.miniTheme.fontFamily,
    fontSize: view.miniTheme.fontSize,
  };

  return (
    <div>
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;
          if (!destination) {
            viewItems.splice(source.index, 1);
            return;
          }
          if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
          ) {
            return;
          }

          const reorderConst = reorder(
            viewItems,
            source.index,
            destination.index
          );

          setViewItems(reorderConst);
          const page = JSON.parse(JSON.stringify(view));

          page.views = reorderConst;

          callback(page, index);
        }}
      >
        <Paper>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              ...style,
              border: "1px solid #ef4123",
              backgroundImage: "url(" + view.miniTheme.backgroundImage + ")",
            }}
            ref={componentRef}
          >
            <Grid item xs={12}>
              <Droppable droppableId="tasks">
                {(droppableProvided) => (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="task-container"
                  >
                    {viewItems.map((viewItem, index) => (
                      <Draggable
                        key={viewItem.text}
                        draggableId={viewItem.text}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <Grid
                            item
                            xs={viewItem.width || 12}
                            sm={viewItem.width || 12}
                            onClick={() => showEdition(index)}
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                          >
                            <div
                              style={{
                                padding: viewItem.padding
                                  ? viewItem.padding + "mm"
                                  : "0mm",
                                backgroundColor: viewItem.background,
                                backgroundImage:
                                  "url(" + viewItem.backgroundImage + ")",
                                fontSize: viewItem.fontSize
                                  ? viewItem.fontSize + "mm"
                                  : view.miniTheme.fontSize + "mm",
                                fontFamily: view.miniTheme.fontFamily,
                                color: viewItem.color || view.miniTheme.color,
                                textAlign: viewItem.position || "center",
                              }}
                            >
                              {viewItem.text}
                            </div>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Paper>
      </DragDropContext>
    </div>
  );
}

export default DragDropContextBox;
