import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import timingConfig from "../timingConfig.json";
import {
  createControlPointTiming,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import SpecificControlPoint from "./SpecificControlPoint";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ControlPointRegistry() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const controlPoint = timingConfig.controlPoint;

  function createControlPoint() {
    dispatch(
      createControlPointTiming(
        "evento_" + eventDetail.iDEvento,
        userID,
        puntosControl
      )
    );
  }

  return (
    <Grid direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={8}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Puntos de Control del evento
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          onClick={() => createControlPoint()}
          variant="contained"
          color="primary"
          style={{ padding: 10, margin: 10, width: "80%", fontSize: 15 }}
        >
          CREAR UN PUNTO DE CONTROL NUEVO
        </Button>
      </Grid>
      {puntosControl &&
        Object.values(puntosControl).map((kpc) => {
          return (
            <SpecificControlPoint kpc={kpc} controlPointIndex={kpc.kNroPunto} />
          );
        })}
    </Grid>
  );
}
