import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import TimingSpecificSubEvent from "./TimingSpecificSubEvent";
import CreateTimingSubEvent from "./CreateTimingSubEvent";
import timingConfig from "../timingConfig.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function TimingConfigSubEvents() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"} component="h2">
            Configuración Modalidades y Categorías
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CreateTimingSubEvent />
        </Grid>
        <Grid item xs={12}>
          {Object.values(eventSubEvents).map((subEvent) => {
            const modalidadIndex = "modalidad_" + subEvent.codigoModalidad;
            return (
              <TimingSpecificSubEvent
                subEventIndex={modalidadIndex}
                subEvent={subEvent}
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
