import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Text(props) {
  const {
    title,
    textArray,
    bgColor,
    textColor,
    position,
    fontFamily,
  } = props.componentConfig;
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          <p>{textArray}</p>
        </Typography>
      </Grid>
    </Grid>
  );
}

Text.displayName = "Texto con Título";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textArray: {
      label: "Texto",
      errorMessage: "Campo Requerido",
      fieldName: "textArray",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    position: {
      label: "Posición Horizontal",
      errorMessage: "Campo Requerido",
      fieldName: "position",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "left",
          label: "Izquierda",
        },
        {
          value: "center",
          label: "Centro",
        },
        {
          value: "right",
          label: "Derecha",
        },
      ],
    },
    // fontFamily: {
    //   label: "Fuente",
    //   errorMessage: "Campo Requerido",
    //   fieldName: "fontFamily",
    //   type: "select",
    //   defaultValue: "p",
    //   required: false,
    //   regex: "",
    //   textSize: "h6",
    //   optionsArray: [
    //     {
    //       value: "MavenProRegular, MavenProBold,Helvetica, Arial",
    //       label: "Maven pro",
    //     },
    //     {
    //       value: "Forza, MavenProBold,Helvetica, Arial",
    //       label: "Forza",
    //     },
    //     {
    //       value: "Arial,Helvetica",
    //       label: "Arial",
    //     },
    //     {
    //       value: "Helvetica,Arial",
    //       label: "Helvetica",
    //     },
    //     {
    //       value: "Redig,Arial",
    //       label: "Redig",
    //     },
    //     {
    //       value: "BebasNeue-Regular,Arial",
    //       label: "BebasNeue-Regular",
    //     },
    //   ],
    // },
  },
};
