import React from "react";
import DynamicInputs from "../../../../DynamicInput/DynamicInputs";
import { calcularEdad } from "../../../../../services/utils";

class SubEventsCategoryInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidad:
        this.props.actualValues.modalidad != null
          ? this.props.actualValues.modalidad
          : null,
      categoria:
        this.props.actualValues.categoria != null
          ? this.props.actualValues.categoria
          : null,
      edad: null,
      categoriasInferiores: this.props.registryConfiguration.configuracion
        .categoriasInferiores,
    };
  }

  getValue = (valor, inputObject, valid) => {
    this.props.update(valor, inputObject, valid);
    this.props.setInscriptionPrice(this.categoryPrice(valor));
  };

  categoryPrice = (codigoCategoria) => {
    var ModalidadesEvento = this.props.eventSubEventsRegistry;
    var ModalidadSeleccionada = this.props.modalidad;
    var monedaAUsar = {
      codigo: "MXN",
      tipo: "base",
      tipoCambio: 1,
      texto: "Pesos Méxicanos",
      cambioAPeso: "1",
    };
    var descripcionInscripcion = "";
    var Genero = this.props.rama;
    var GeneroTexto = "";
    var hoy = new Date();
    var precioInscripcion = 0;
    var item = {};

    for (var i in ModalidadesEvento) {
      if (ModalidadesEvento[i].codigoModalidad == ModalidadSeleccionada) {
        var ramaCategoria = ModalidadesEvento[i].ramaCat;
        var preciosArray = ModalidadesEvento[i].configModalidad.precio;
        var camposAdicionalesArray =
          ModalidadesEvento[i].configModalidad.camposAdicionalesModalidad;
        descripcionInscripcion =
          descripcionInscripcion +
          ModalidadesEvento[i].descripcionModalidad +
          " ";

        for (var e in ramaCategoria) {
          if (e.localeCompare("rama_" + Genero) == 0) {
            descripcionInscripcion = descripcionInscripcion + GeneroTexto + " ";
            var categorias = ramaCategoria[e].categorias;
            for (var f in categorias) {
              if (codigoCategoria == categorias[f].codigo) {
                var precioKey = categorias[f].precioKey;
                descripcionInscripcion =
                  descripcionInscripcion + categorias[f].nombreCategoria + " ";

                var tablaMostrada = false;
                var precioObjeto;
                for (var m in preciosArray) {
                  precioObjeto = preciosArray[m];
                  var desde = new Date(precioObjeto.desde);
                  var hasta = new Date(precioObjeto.hasta);
                  console.log(
                    "hoy " + hoy + "desde " + desde + " hasta" + hasta
                  );
                  console.log(
                    "hoy " +
                      hoy.getTime() +
                      "desde " +
                      desde.getTime() +
                      " hasta" +
                      hasta.getTime()
                  );

                  if (
                    desde.getTime() <= hoy.getTime() &&
                    hasta.getTime() >= hoy.getTime()
                  ) {
                    precioInscripcion = precioObjeto[precioKey];
                    tablaMostrada = true;
                    item = {
                      name:
                        "Inscripción al evento " +
                        this.props.eventDetail.nombreEvento,
                      description: descripcionInscripcion,
                      quantity: 1,
                      unit_price:
                        Math.round(precioObjeto[precioKey] * 100) / 100,
                      code: "insc",
                      precioKey: precioKey,
                    };
                  }
                }
                if (tablaMostrada == false) {
                  item = {
                    name:
                      "Inscripción al evento " +
                      this.props.eventDetail.nombreEvento,
                    description: descripcionInscripcion,
                    quantity: 1,
                    unit_price: Math.round(precioObjeto[precioKey] * 100) / 100,
                    code: "insc",
                    precioKey: precioKey,
                  };
                }
              }
            }
          }
        }
      }
    }
    return item;
  };

  customStringsFunct = (cSParam) => {
    var cStringsCommon = {
      modalidadSelectTitle: "Evento",
      modalidadSelectOptionOne: "Selecciona la modalidad a competir",
      categoriaOneCategoryTitle: "Categoría",
      categoriaSelectTitle: "Selecciona tu Categoría",
    };
    let customStrings = {};
    if (cSParam != null) {
      customStrings.modalidadSelectTitle =
        cSParam.modalidadSelectTitle != undefined
          ? cSParam.modalidadSelectTitle
          : cStringsCommon.modalidadSelectTitle;
      customStrings.modalidadSelectOptionOne =
        cSParam.modalidadSelectOptionOne != undefined
          ? cSParam.modalidadSelectOptionOne
          : cStringsCommon.modalidadSelectOptionOne;
      customStrings.categoriaOneCategoryTitle =
        cSParam.categoriaOneCategoryTitle != undefined
          ? cSParam.categoriaOneCategoryTitle
          : cStringsCommon.categoriaOneCategoryTitle;
      customStrings.categoriaSelectTitle =
        cSParam.categoriaSelectTitle != undefined
          ? cSParam.categoriaSelectTitle
          : cStringsCommon.categoriaSelectTitle;
    } else {
      customStrings.modalidadSelectTitle = cStringsCommon.modalidadSelectTitle;
      customStrings.modalidadSelectOptionOne =
        cStringsCommon.modalidadSelectOptionOne;
      customStrings.categoriaOneCategoryTitle =
        cStringsCommon.categoriaOneCategoryTitle;
      customStrings.categoriaSelectTitle = cStringsCommon.categoriaSelectTitle;
    }

    return customStrings;
  };

  render() {
    var edad = calcularEdad(
      this.props.fechaNacimiento,
      this.props.eventDetail.fechaEvento,
      this.props.registryConfiguration.configuracion.edadActual
    );
    console.log("edad" + edad);
    console.log(this.props.modalidad);

    const customStrings = this.customStringsFunct(this.props.customStrings);
    let categorySelct;
    if (this.props.eventSubEventsRegistry != undefined) {
      if (this.props.modalidad != undefined && this.props.modalidad > 0) {
        let categorySelctValue = [];
        categorySelctValue.push({
          valor: 0,
          texto: customStrings.categoriaOneCategoryTitle,
          disabled: true,
        });

        let existeCat = false;
        let cantidadCat = 0;

        if (this.props.modalidad != null && this.props.modalidad > 0) {
          for (var i in this.props.eventSubEventsRegistry) {
            if (
              this.props.eventSubEventsRegistry[i].codigoModalidad ==
              this.props.modalidad
            ) {
              let categorias = this.props.eventSubEventsRegistry[i].ramaCat[
                "rama_" + this.props.rama
              ].categorias;
              for (var e in categorias) {
                if (this.state.categoriasInferiores) {
                  if (
                    categorias[e].edadDesde <= edad &&
                    !categorias[e].absolutos
                  ) {
                    let sugerida = "";
                    if (
                      categorias[e].edadDesde <= edad &&
                      categorias[e].edadHasta >= edad &&
                      !categorias[e].absolutos
                    ) {
                      sugerida = " (Sugerida para tu edad)";
                    }
                    categorySelctValue.push({
                      valor: categorias[e].codigo,
                      texto: categorias[e].nombreCategoria + sugerida,
                      disabled: false,
                    });
                    existeCat = true;
                    cantidadCat++;
                  }
                } else {
                  if (
                    categorias[e].edadDesde <= edad &&
                    categorias[e].edadHasta >= edad &&
                    !categorias[e].absolutos
                  ) {
                    let sugerida = "";
                    if (
                      categorias[e].edadDesde <= edad &&
                      categorias[e].edadHasta >= edad &&
                      !categorias[e].absolutos
                    ) {
                      sugerida = " (Sugerida para tu edad)";
                    }
                    categorySelctValue.push({
                      valor: categorias[e].codigo,
                      texto: categorias[e].nombreCategoria + sugerida,
                      disabled: false,
                    });
                    existeCat = true;
                    cantidadCat++;
                  }
                }
              }
            }
          }

          if (existeCat) {
            let categorySelctObject = {
              campo: 1,
              etiqueta: customStrings.categoriaSelectTitle,
              iDCampo: "categoria",
              longitud: 100,
              observacion: "",
              tipoCampo: "Select",
              validacion: "Requerido",
              selectLabel: customStrings.categoriaSelectTitle,
              valor: categorySelctValue,
              anchoCampo: "s12 m12 l12",
            };

            console.log(categorySelctValue);
            if (cantidadCat == 1) {
              console.log(categorySelctValue[0].texto);
              categorySelct = (
                <h5 className="">{categorySelctValue[1].texto}</h5>
              );
              var m = {};
              m.target = {};
              m.target.value = categorySelctValue[1].valor;
              this.getValue(m, categorySelctObject);
            } else {
              categorySelct = (
                <DynamicInputs
                  inputObject={categorySelctObject}
                  actualValue={0}
                  callbackchange={this.getValue}
                />
              );
            }
          } else {
            categorySelct = (
              <h5 className="#e0f2f1 teal lighten-5 center-align">
                La modalidad seleccionada no tiene categorias disponibles para
                su edad y genero
              </h5>
            );
          }
        }
      }
    }

    return (
      <div>
        <div className="row">{categorySelct}</div>
      </div>
    );
  }
}

export default SubEventsCategoryInputs;
