import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Axios from "axios";
import https from "https";
import ReaderConfigMQTT from "./ReaderConfigMQTT";
import ReaderConfigLocal from "./ReaderConfigLocal";
import ReaderConfigSocket from "./ReaderConfigSocket";

import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import CheckChip from "./CheckChip";
import { updateAthleteResult } from "../../../../../data-store/actions/events-actions";
import jsonTags from "./jsonTags.json";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReaderConfig(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
  }, []);

  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <ReaderConfigMQTT />
      {/* <ReaderConfigLocal /> */}
      {/* <ReaderConfigSocket /> */}
    </Grid>
  );
}
