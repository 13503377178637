import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Conekta from "../../../../../../conekta/ConektaHelper";
import { showSnackbar } from "../../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";
import { registryParticipantDataFunc } from "../../../../../../data-store/actions/registry-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CashPayment(props) {
  const { callback, order, test, currency, currentOrder, eventDetail } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);

  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function saveAthleteDataAndPay() {}

  function generateCashPayment() {
    console.log(currentOrder);
    dispatch(
      registryParticipantDataFunc(
        "evento_" + eventDetail.iDEvento,
        currentOrder,
        2,
        userID
      )
    );
    setSending(true);

    dispatch(showSnackbar(true, "Pago exitoso ", 4000));
    callback(true);
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h3">
          Pago en efectivo
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Reciba la cantidad de dinero en efectivo y al presionar el botón de
          pagar el registro quedará completo y cargado en el estado de cuenta de
          este usuario.
        </Typography>
      </Grid>

      <Button
        onClick={() => generateCashPayment()}
        variant="contained"
        color="primary"
        style={{
          height: "150px",
          width: "100%",
          fontSize: "18px",
          margin: "10px",
        }}
      >
        Registrar pago en efectivo
      </Button>
      {sending && (
        <Grid item xs={12} sm={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  );
}
