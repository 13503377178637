import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderAtleteModal from "./HeaderAtleteModal";
import AtleteData from "./AtleteData";
import AtleteReportsContainer from "./AtleteReportsContainer";

import Background from "../../../../assets/backgroundPhotos/fondogris_Dashport.jpg";
import AtleteUpdateContainer from "./AtleteUpdateContainer";

//TODO: Agregar opciones de edicion de datos como en la app de crono.

export default function AtleteDetailContainer({ eventDetail, resultado }) {
  const eventResults = useSelector((state) => state.events.eventResults);

  console.log("AtleteDetailContainer");

  const [resultadoConnect, setResultadoConnect] = useState(false);
  useEffect(() => {
    setResultadoConnect(eventResults.filter((a) => a.num === resultado.num)[0]);
  }, [resultado]);

  return (
    <div
      className="padding overflowTrue"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      {resultadoConnect && (
        <div>
          <AtleteData resultado={resultadoConnect} eventDetail={eventDetail} />
          <AtleteUpdateContainer
            resultado={resultadoConnect}
            eventDetail={eventDetail}
          />
          <AtleteReportsContainer
            resultado={resultadoConnect}
            eventDetail={eventDetail}
          />
        </div>
      )}
    </div>
  );
}
