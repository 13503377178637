import * as types from "../actions/action-types";

const initialState = {};
export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOURNAMENTS_LIST:
      return Object.assign({}, state, {
        tournamentsList: action.tournamentsList,
      });
    case types.TOURNAMENT_CREATED:
      return Object.assign({}, state, {
        tournamentCreatedID: action.tournamentCreatedID,
      });
    case types.TOURNAMENT_DATA:
      return Object.assign({}, state, {
        tournament: Object.assign({}, state.tournament, {
          [action.tournamentID]: action.tournament,
        }),
      });
    case types.TOURNAMENT_RESULTS:
      return Object.assign({}, state, {
        tournamentResults: Object.assign({}, state.tournamentResults, {
          [action.tournamentID]: action.tournamentResults,
        }),
      });
    case types.PARTICIPANTS_LIST:
      return Object.assign({}, state, {
        participantsList: action.participantsList,
      });

    // case types.EVENT_SPECIFIC_RESULTS:
    //   return Object.assign({}, state, {
    //     eventSpecificResults: Object.assign({}, state.eventSpecificResults, {
    //       [action.resultID]: action.eventSpecificResults,
    //     }),
    //   });

    default:
      return state;
  }
}
