import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAthleteResult } from "../../../../data-store/actions/events-actions";
import { TiempoAPasoMinxK } from "../../../../services/utils";
import EditableField from "../../../EditableField/EditableField";
import { DescargarCertificadoReg } from "./certificadoLogic";

const descargarCertificadoReg = (
  e,
  eventDetail,
  resultadoJSON,
  tipo,
  certificateConfig
) => {
  e.preventDefault();
  console.log("descargar certificado");
  DescargarCertificadoReg(eventDetail, resultadoJSON, tipo, certificateConfig);
};

const AtleteData = ({ eventDetail, resultado }) => {
  var paso = TiempoAPasoMinxK(resultado.to, resultado.mod);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const certificateConfig =
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.certificateConfig
      ? eventConfiguration.timingConfig.certificateConfig
      : false;

  resultado = { ...resultado, ...setStatus(resultado, eventConfiguration) };

  const dispatch = useDispatch();
  function handleChange(data) {
    console.log("data", data);
    // if (Object.keys(data)[0] === "to") {
    //   data = { ...data, recPos: true };
    // } ya no recalculo posiciones por que se hace en local
    console.log("data", data);
    dispatch(
      updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, data)
    );
  }

  function setStatus(resultadoFunc, eventConfigurationFunc) {
    resultadoFunc.text = "";
    resultadoFunc.button = "";
    if (resultadoFunc.nombre.indexOf("*") > 0) {
      resultadoFunc.pG = 0;
      resultadoFunc.pR = 0;
      resultadoFunc.pCat = 0;
      resultadoFunc.dnfPlus = true;
    }
    if (resultadoFunc.borrado) {
      resultadoFunc.dq = true;
    }
    if (!resultadoFunc.borrado && resultadoFunc.pG === 0) {
      resultadoFunc.dnf = true;
    }
    if (
      !resultadoFunc.registroSalida &&
      !resultadoFunc.parciales &&
      resultadoFunc.pG === 0
    ) {
      resultadoFunc.dns = true;
    }
    if (resultadoFunc.dnfPlus && !resultadoFunc.dns) {
      resultadoFunc.text =
        "El atleta no finalizó el evento en el tiempo previsto para la distancia  ";
      resultadoFunc.button = "DNF";
    } else if (resultadoFunc.dnf && !resultadoFunc.dns) {
      resultadoFunc.text = "El atleta no finalizó el evento  ";
      resultadoFunc.button = "DNF";
    } else if (resultadoFunc.dq) {
      resultadoFunc.text =
        "El atleta fué descalificado por los jueces del evento";
      resultadoFunc.button = "DQ";
    } else if (resultadoFunc.dns && !resultadoFunc.dq) {
      resultadoFunc.text = "El atleta no inició el evento";
      resultadoFunc.button = "DNS";
    }
    console.log("resultado1", resultadoFunc);

    return resultadoFunc;
  }

  return (
    <div>
      <div className="divider"></div>
      <div className="row">
        <div className="col s8 left-align">
          <EditableField
            fieldObject={{
              label: "Nombres y apellidos",
              errorMessage: "Campo Requerido",
              fieldName: "nombre",
              type: "text",
              defaultValue: resultado.nombre,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
          <Typography variant={"subtitle"}>{resultado.text}</Typography>

          {/* <h5>{resultado.nombre}</h5> */}
        </div>
        <div className="col s4 left-align">
          <Button
            onClick={(e) =>
              descargarCertificadoReg(
                e,
                eventDetail,
                resultado,
                2,
                certificateConfig
              )
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            JPG
          </Button>
          <Button
            onClick={(e) =>
              descargarCertificadoReg(
                e,
                eventDetail,
                resultado,
                1,
                certificateConfig
              )
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            PDF
          </Button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <span className="col s6 m3 center-align">
          <b>Número </b>
          <br />
          {resultado.num}
        </span>
        <span className="col s6 m3 center-align">
          <b>Género </b>
          <br />
          {resultado.gen}
        </span>
        <span className="col s6 m3 center-align">
          <b>Categoría </b>
          <br />
          {resultado.cat}
        </span>
        <span className="col s6 m3 center-align">
          <b>Modalidad </b>
          <br />
          {resultado.mod}
        </span>
      </div>
      <div className="row z-depth-2">
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          {/* <b>T. Oficial</b>
          <br /> */}
          <EditableField
            fieldObject={{
              label: "Tiempo Oficial",
              errorMessage: "Campo Requerido",
              fieldName: "to",
              type: "text",
              defaultValue: resultado.to,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <EditableField
            fieldObject={{
              label: "Tiempo Chip",
              errorMessage: "Campo Requerido",
              fieldName: "tc",
              type: "text",
              defaultValue: resultado.tc,
              required: true,
              regex: "",
              textSize: "h6",
            }}
            onSubmit={handleChange}
          />

          {/* <b>T. Real</b>
          <br />
          {resultado.tc} */}
        </div>
        <div
          style={{ padding: "12.5px" }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>Paso min/K</b>
          <br />
          {paso}
        </div>
        <div
          style={{ padding: "12.5px" }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. General</b>
          <br />
          {resultado.pG}
        </div>
        <div
          style={{ padding: "12.5px" }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. Rama</b>
          <br />
          {resultado.pR}
        </div>
        <div
          style={{ padding: "12.5px" }}
          className="#f44336 red white-text col s6 m4 l2 center-align"
        >
          <b>P. Categoria</b>
          <br />

          {resultado.pCat}
        </div>
      </div>
    </div>
  );
};

export default AtleteData;
