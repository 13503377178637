import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import timingConfig from "../timingConfig.json";
import EventSpecificCategory from "./TimingSpecificCategory";
import TimingSpecificCategory from "./TimingSpecificCategory";
import CreateTimingCategory from "./CreateTimingCategory";
import CreateAbsolutesCategory from "./CreateAbsolutesCategory";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function TimingSpecificGender(props) {
  const { subEventIndex, subEvent, gender } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const [modalidadIndex, setModalidadIndex] = useState("modalidad_1");

  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="strech"
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2" align="center">
          Categorías de la rama
          {gender === "rama_F" ? " Femenil" : ""}
          {gender === "rama_V" ? " Varonil" : ""}
          {gender === "rama_M" ? " Mixto" : ""}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CreateAbsolutesCategory
          subEventIndex={subEventIndex}
          subEvent={subEvent}
          gender={gender}
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ margin: "20px" }}>
        {Object.values(subEvent.ramaCat[gender].categorias).map(
          (category, index) => {
            if (category.codigo !== 0) {
              return (
                <TimingSpecificCategory
                  subEventIndex={subEventIndex}
                  subEvent={subEvent}
                  gender={gender}
                  category={category}
                />
              );
            }
          }
        )}
      </Grid>
      <Grid item xs={12}>
        <CreateTimingCategory
          subEventIndex={subEventIndex}
          subEvent={subEvent}
          gender={gender}
        />
      </Grid>
    </Grid>
  );
}
