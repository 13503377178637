import React from "react";
import { useSelector, useDispatch } from "react-redux";

//import M from 'materialize-css'
import { db, changesRegistryFunc } from "../../../../../firebase/firebasejs";

export default function MailSendDetail(props) {
  const { ParticipantDetail, ParticipantDetailKey, eventDetail } = props;
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  function reenviarConfirmacion() {
    console.log("reenviar");
    var eventoIndex = "evento_" + eventDetail.iDEvento;
    var idRegistro = ParticipantDetailKey;

    var updates = {};
    console.log(
      "eventosCompletos/" +
        eventoIndex +
        "/inscritos/listadoInscritos/" +
        idRegistro +
        "/mails/sendMail/"
    );
    updates[
      "eventosCompletos/" +
        eventoIndex +
        "/inscritos/listadoInscritos/" +
        idRegistro +
        "/mails/sendMail/"
    ] = true;
    return db
      .ref()
      .update(updates)
      .then((snap) => {
        console.log("Renviada");
        //M.toast("Correo reenviado exitosamente!", 4000);
      });
  }

  var mails = undefined;
  if (
    ParticipantDetail.mails != undefined &&
    ParticipantDetail.mails.mailRegistry != undefined
  ) {
    mails = ParticipantDetail.mails.mailRegistry;
  }

  var tipoCorreoJson = {
    1: "Correo para notificar inscripción en web",
    2: "Correo por inscripción en punto de venta",
    3: "Correo por código de suscripción",
    4: "Confirmación de inscripción y pago recibido",
    5: "Ficha de pago y confirmación de registro",
  };

  var botonReenviarCorreo;

  var addMail = [];
  var precioCobrado = 0;
  if (mails != undefined) {
    for (var p in mails) {
      var d = new Date(mails[p].date);
      var fecha =
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds() +
        " " +
        d.getDate() +
        "/" +
        (d.getMonth() + 1) +
        "/" +
        d.getFullYear();
      var tipoCorreo = tipoCorreoJson[mails[p].tipoCorreo];
      addMail.push(
        <tr className="itemOrderResume">
          <td className="mailDate" data-mail-date={fecha}>
            {fecha}
          </td>
          <td className="mailSubject" data-mail-subject={mails[p].subject}>
            {mails[p].subject}
          </td>
          <td className="mailTipoCorreo" data-mail-tipo={tipoCorreo}>
            {tipoCorreo}
          </td>
        </tr>
      );
    }
  }

  if (ParticipantDetail.statusPago.status == "ok") {
    botonReenviarCorreo = (
      <div>
        <p>Reenviar correo de confirmación?</p>
        <a
          onClick={() => reenviarConfirmacion()}
          className="waves-effect waves-light btn"
        >
          <i className="material-icons right">mail</i>si, reenviar
        </a>
      </div>
    );
  }

  var tabla = (
    <div className="row">
      <hr />
      <div className="card-panel col s12 m12 l12">
        <table className="bordered centered">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Titulo</th>
              <th>Tipo Correo</th>
            </tr>
          </thead>
          <tbody id="listadoProductos">{addMail}</tbody>
        </table>
      </div>
      {botonReenviarCorreo}
    </div>
  );

  return tabla;
}
