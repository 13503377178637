import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDepositsUserList } from "../../../../../data-store/actions/events-actions";
import ItemsTable from "./Deposits/ItemsTable";

export default function DashboardDeposits({ jsonDashboard }) {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventDepositsList = useSelector(
    (state) => state.events.eventDepositsList
  );
  const eventDepositsUsers = useSelector(
    (state) => state.events.eventDepositsUsers
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepositsUserList("evento_" + eventDetail.iDEvento));
  }, []);

  if (!eventDepositsUsers && atleteAccount.perfil !== 1) {
    return <br></br>;
  }

  return <ItemsTable jsonDashboard={jsonDashboard} />;
}
