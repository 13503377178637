export default function timestampToOnlyDate(timestamp) {
  var fecha = new Date(timestamp);
  var dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
  var mes =
    fecha.getMonth() + 1 < 10
      ? "0" + (fecha.getMonth() + 1)
      : fecha.getMonth() + 1;
  var year = fecha.getFullYear();
  var fechaFinal = dia + "/" + mes + "/" + year;
  return fechaFinal;
}
