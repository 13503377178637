import React from "react";
import { connect } from "react-redux";
import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import {
  completeNull,
  modalidadCateogoriaNameByCode,
} from "../../../../../services/utils";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import OrderDetail from "./OrderDetail";
import PaymentDetail from "./PaymentDetail";
import MailSendDetail from "./MailSendDetail";
import ChangesDetail from "./ChangesDetail";
import AditionalInputsAndProductsDetail from "./AditionalInputsAndProductsDetail";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ParticipantDetailContainerReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  handleChange = (event, value) => {
    this.setState({ value: value });
  };

  render() {
    return (
      <div className="row">
        <div className="col s12 m8 offset-m2 l8 offset-l2">
          <Tabs
            className="z-depth-1 red-text"
            value={this.state.value}
            scrollButtons="auto"
            variant="scrollable"
            onChange={this.handleChange}
          >
            <Tab key="tab-CamposAdicionales" label="Campos Adicionales" />
            <Tab key="tab-DetalleCompra" label="Detalle Compra" />
            <Tab key="tab-DetallePago" label="Detalle Pago" />
            <Tab key="tab-CorreosEnviados" label="Correos enviados" />
            <Tab key="tab-Cambios" label="Cambios" />
          </Tabs>
          {this.state.value === 0 && (
            <TabContainer key="contTab-CamposAdicionales">
              <AditionalInputsAndProductsDetail
                ParticipantDetail={this.props.ParticipantDetail}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                eventSubEventsRegistry={this.props.eventSubEventsRegistry}
                registryConfiguration={this.props.registryConfiguration}
                userID={this.props.userID}
                atleteAccount={this.props.atleteAccount}
                eventDetail={this.props.eventDetail}
              />
            </TabContainer>
          )}
          {this.state.value === 1 && (
            <TabContainer key="contTab-DetalleCompra">
              <OrderDetail ParticipantDetail={this.props.ParticipantDetail} />
            </TabContainer>
          )}
          {this.state.value === 2 && (
            <TabContainer key="contTab-DetallePago">
              <PaymentDetail ParticipantDetail={this.props.ParticipantDetail} />
            </TabContainer>
          )}
          {this.state.value === 3 && (
            <TabContainer key="contTab-CorreosEnviados">
              <MailSendDetail
                ParticipantDetail={this.props.ParticipantDetail}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                eventDetail={this.props.eventDetail}
              />
            </TabContainer>
          )}
          {this.state.value === 4 && (
            <TabContainer key="contTab-Cambios">
              <ChangesDetail ParticipantDetail={this.props.ParticipantDetail} />
            </TabContainer>
          )}
        </div>
      </div>
    );
  }
}

export default ParticipantDetailContainerReports;
