import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import { createBusiness } from "../../../../data-store/actions/organizers-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function BusinessCreate(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const classes = useStyles();

  function onSubmit(data) {
    dispatch(
      createBusiness(
        data.name,
        data.businessId,
        userID,
        atleteAccount.nombres + " " + atleteAccount.apellidos,
        atleteAccount.correo
      )
    );
  }
  let createBusinessObject = {
    name: {
      label: "Nombre de la empresa",
      errorMessage: "Campo Requerido",
      fieldName: "name",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    businessId: {
      label: "Id para crear la empresa",
      errorMessage: "Solo minusculas y números máximo 10 carácteres",
      fieldName: "businessId",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "^([a-z0-9]{0,20})$",
      textSize: "h6",
    },
  };
  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea una empresa nueva
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createBusinessObject}
              submitButtonText={"Crear Empresa"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
