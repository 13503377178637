import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";

import EventRegistryConfig from "../../../AuthorizedUsers/EventsConfig/EventRegistryConfig";
import EventRegistryConfigSubEvents from "../../../AuthorizedUsers/EventsConfig/EventRegistryConfigSubEvents";
import EventAditionalInputsContainer from "../../../AuthorizedUsers/EventsConfig/EventAdicionalInputs/EventAditionalInputsContainer";
import EventProductsContainer from "../../../AuthorizedUsers/EventsConfig/EventProducts/EventProductsContainer";
import EventConvocatoryContainer from "../../../AuthorizedUsers/EventsConfig/EventConvocatory/EventConvocatoryContainer";
import EventRaceNumber from "../../../AuthorizedUsers/EventsConfig/EventRaceNumber/EventRaceNumber";
import EventRaceNumberToPrint from "../../../AuthorizedUsers/EventsConfig/EventRaceNumber/EventRaceNumberToPrint";
import EventDiscountCodesContainer from "../../../AuthorizedUsers/EventsConfig/EventDiscountCodes/EventDiscountCodesContainer";

export default function RegistryConfigurationContainer() {
  const eventDetail = useSelector((state) => state.events.eventDetail);

  let { EventoIndex } = useParams();
  console.log("eventDetail", eventDetail);
  if ("evento_" + eventDetail.iDEvento !== EventoIndex) {
    return (
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h2"} component="h2">
            Error Recarga la web
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <div>
      <EventRegistryConfig />
      <EventRegistryConfigSubEvents />
      <EventAditionalInputsContainer />
      <EventProductsContainer />
      <EventRaceNumber />
      {/* <EventRaceNumberToPrint /> */}
      <EventDiscountCodesContainer />
      <EventConvocatoryContainer />
    </div>
  );
}
