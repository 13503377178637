import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import BusinessCreate from "./BusinessCreate";
import {
  getBusinessGeneralData,
  updateBusinessObject,
} from "../../../../data-store/actions/organizers-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "#e0e0e0",
  },
}));

export default function BusinessAuthUsers(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );

  useEffect(() => {
    //dispatch(getBusinessGeneralData(business.businessId));
  }, [business]);

  const classes = useStyles();
  function onSubmit(data) {
    console.log("data", data);

    const date = new Date().getTime();
    const user = {
      [date]: {
        mail: data.email,
        profile: data.profile,
        createDate: date,
        businessName: business.name,
        auth: data.profile === "notauth" ? false : true,
      },
    };
    // const user= {
    //   [userID]: {
    //     name: userName,
    //     mail: userMail,
    //     auth: true,
    //     profile: "master",
    //   }

    dispatch(
      updateBusinessObject(
        business.businessId,
        "generalData/authPrevious/",
        user
      )
    );
  }
  let authObject = {
    email: {
      label: "Correo del usuario a autorizar",
      errorMessage: "Campo Requerido",
      fieldName: "email",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    profile: {
      label: "Perfil del usuario",
      errorMessage: "Campo Requerido",
      fieldName: "profile",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "master",
          label: "Master",
        },
        {
          value: "web",
          label: "Solo Acceso a editar la Web",
        },
        {
          value: "notauth",
          label: "Usuario no autorizado",
        },
      ],
    },
  };
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Usuarios Autorizados
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <FormRender
          callback={onSubmit}
          formObject={authObject}
          submitButtonText={"Autorizar Usuario"}
        />
      </Grid>
      <Grid item xs={12}>
        <List style={{ width: "100%" }}>
          {businessGeneralData &&
            businessGeneralData[business.businessId] &&
            Object.values(
              businessGeneralData[business.businessId].authUsers
            ).map((user) => (
              <ListItem>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  color="secondary"
                  primary={user.name}
                  secondary={"Perfil: " + user.profile + " - " + user.mail}
                />
                {/* <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="this"
                    onClick={() => false}
                  >
                    <SendIcon color="secondary" />
                  </IconButton>
                </ListItemSecondaryAction> */}
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}
