import React from "react";
import Grid from "@material-ui/core/Grid";

import { formatEventDate } from "../../../services/utils";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";

const AuthorizedUsersCard = ({ evento }) => (
  <Card>
    <CardActionArea
      component={Link}
      to={`/eventos-organizados/evento_${evento.iDEvento}`}
    >
      <CardMedia
        component="img"
        alt={"Logo evento:" + evento.nombreEvento}
        height="250px"
        image={evento.logoEvento}
        title={"Logo evento:" + evento.nombreEvento}
      />
      <CardContent style={{ height: "250px" }}>
        <Typography gutterBottom variant="h5" component="h2">
          {evento.nombreEvento}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <p>
            Fecha: {evento.fechaEvento && formatEventDate(evento.fechaEvento)},
            a las {evento.horaEvento}
          </p>
          <p>
            Lugar: {evento.lugarEvento}, {evento.ciudadEvento},{" "}
            {evento.paisEvento}
          </p>
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <Button
        size="large"
        color="primary"
        component={Link}
        to={`/eventos-organizados/evento_${evento.iDEvento}`}
      >
        Ingresar
      </Button>
    </CardActions>
  </Card>
);

export default AuthorizedUsersCard;
