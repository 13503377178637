import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import { createPublicEvent } from "../../../../data-store/actions/events-actions";
import EditableField from "../../../EditableField/EditableField";
import EventUpdateGeneralData from "./EventUpdateGeneralData";
import EventOrganizerData from "./EventOrganizerData";
import EventAditionalInputsContainer from "./EventAdicionalInputs/EventAditionalInputsContainer";
import EventRaceNumber from "./EventRaceNumber/EventRaceNumber";
import { registryConfiguration } from "../../../../data-store/actions/registry-actions";
import EnableTimingSoftware from "./EnableTimingSoftware";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventConfigContainer() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const createEventObject = createEventConfig.detalleEvento;

  const classes = useStyles();
  console.log("eventDetail", eventDetail);

  useEffect(() => {
    dispatch(registryConfiguration("evento_" + eventDetail.iDEvento));
  }, [eventDetail]);

  function onSubmit(data) {
    console.log(data);

    //dispatch(createPublicEvent(data, userID, atleteAccount));
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"} component="h2">
            Configuración general del evento
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ margin: "20px", padding: "20px" }}>
          <EventUpdateGeneralData />
        </Grid>
        <Grid item xs={12} sm={6} style={{ margin: "20px", padding: "20px" }}>
          <EventOrganizerData />
        </Grid>
        <Grid item xs={12} sm={6} style={{ margin: "20px", padding: "20px" }}>
          <EnableTimingSoftware />
        </Grid>
      </Grid>
    </div>
  );
}
