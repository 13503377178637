import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { fechahora } from "../../../../../../services/utils";
import SimpleDialogDemo from "../../../../../pages/Modals/SimpleDialog";
import PrintResultsCSV from "./PrintReports/PrintResultsCSV";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function DownloadResultsButton(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  useEffect(() => {}, [eventResults]);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        {eventResults && (
          <SimpleDialogDemo buttonText={"Descargar Resultados"}>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              style={{ width: 500 }}
            >
              <Grid item xs={12} sm={12} alignContent="center">
                <PrintResultsCSV />
              </Grid>
            </Grid>
          </SimpleDialogDemo>
        )}
      </Grid>
    </Grid>
  );
}
