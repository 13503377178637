import React from "react";
import { connect } from "react-redux";

import TitleResultReport from "./TitleResultReport";
import TitleResultReportDefault from "./TitleResultReportDefault";

import AtleteDetailsContainer from "../reports/atleteDetail/AtleteDetailContainer";

import { ControlesAtleta } from "./reportsLogic";
import { DescargarCertificadoReg } from "./atleteDetail/certificadoLogic";

import FormDialog from "./FormDialog";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

class RowResultReportView extends React.Component {
  // this.props.data = {typeRow:1, reportType:tipoReporte , mod:Mod, cat:Cat, gen:Gen, ab:false}
  // or {typeRow:2, result:Resultadosdelatelta en BD}
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  descargarCertificadoReg = (
    e,
    eventDetail,
    resultadoJSON,
    tipo,
    certificateConfig
  ) => {
    e.preventDefault();
    console.log("descargar certificadoaa", certificateConfig);
    DescargarCertificadoReg(
      eventDetail,
      resultadoJSON,
      tipo,
      certificateConfig
    );
  };

  render() {
    const certificateConfig =
      this.props.eventConfiguration.timingConfig &&
      this.props.eventConfiguration.timingConfig.certificateConfig
        ? this.props.eventConfiguration.timingConfig.certificateConfig
        : false;
    var resultado;

    var element;

    var typeRow = this.props.data.typeRow;
    if (typeRow == 1) {
      // titulo

      element = (
        <TitleResultReport
          data={this.props.data}
          controles={this.props.controles}
          controlesArray={this.props.controlesArray}
        />
      );
    } else if (typeRow == 3) {
      element = (
        <TitleResultReportDefault
          data={this.props.data}
          controles={this.props.controles}
          controlesArray={this.props.controlesArray}
        />
      );
    } else {
      // fila de resutlado de un atleta

      resultado = this.props.data.result;
      var eventDetail = this.props.eventDetail;

      var controlesAtleta = [];
      var controlesTD;
      if (this.props.controles) {
        controlesAtleta = ControlesAtleta(
          this.props.puntosControl,
          resultado,
          this.props.controlesArray
        );
        controlesTD = controlesAtleta.map((control, index) => (
          <TableCell key={index} className={control.class}>
            {control.value}
          </TableCell>
        ));
      }

      element = (
        <TableRow>
          <TableCell>{resultado.num}</TableCell>
          <TableCell>{resultado.pG}</TableCell>
          <TableCell>{resultado.pR}</TableCell>
          <TableCell>{resultado.pCat}</TableCell>
          <TableCell component="th" scope="row">
            <FormDialog
              buttomLabel={resultado.nombre + " (ABRIR DETALLE)"}
              ShowIcon={false}
              ButtonStyle={null}
              maxWidthProp={"lg"}
              style={{
                minHeight: "80vh",
                maxHeight: "80vh",
              }}
              report={
                <AtleteDetailsContainer
                  eventDetail={this.props.eventDetail}
                  resultado={resultado}
                />
              }
            />
          </TableCell>
          <TableCell>{resultado.gen}</TableCell>
          <TableCell>{resultado.cat}</TableCell>
          <TableCell>{resultado.mod}</TableCell>
          {controlesTD}
          <TableCell>{resultado.to}</TableCell>
          <TableCell>{resultado.tc}</TableCell>
          <TableCell>
            <a
              onClick={(e) =>
                this.descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  2,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat"
              target="_blank"
            >
              JPG
            </a>
            <a
              onClick={(e) =>
                this.descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  1,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat"
              target="_blank"
            >
              PDF
            </a>
          </TableCell>
        </TableRow>
      );
    }

    return element;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
  };
};

const RowResultReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(RowResultReportView);

export default RowResultReport;
