export const StandarInputsData = {
  correo: {
    campo: 1,
    etiqueta: "Correo",
    iDCampo: "correo",
    longitud: 100,
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m4 l4",
    order: 7,
    observacion: "Campo Requerido no puede incluir espacios",
    errorMessage: "Campo Requerido no puede incluir espacios",
    regex: "^[0-9a-zA-Z@!#$%&'*+-/=?^_`{|}~(),:;<>@]{3,1000}$",
  },
  nombres: {
    campo: 2,
    etiqueta: "Nombres",
    iDCampo: "nombres",
    longitud: 100,
    observacion: "Campo de requerido",
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m6 l6",
    order: 1,
  },
  apellidos: {
    campo: 3,
    etiqueta: "Apellidos",
    iDCampo: "apellidos",
    longitud: 100,
    observacion: "Campo de requerido",
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m6 l6",
    order: 2,
  },
  apellidoMaterno: {
    campo: 3,
    etiqueta: "Apellido Materno",
    iDCampo: "apellidoMaterno",
    longitud: 100,
    observacion: "Campo de requerido",
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m3 l3",
    order: 4,
  },
  apellidoPaterno: {
    campo: 3,
    etiqueta: "Apellido Paterno",
    iDCampo: "apellidoPaterno",
    longitud: 100,
    observacion: "Campo de requerido",
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m3 l3",
    order: 3,
  },
  telefono: {
    campo: 4,
    etiqueta: "Teléfono",
    iDCampo: "telefono",
    longitud: 100,
    observacion: "Mínimo 10 dígitos (solo números)",
    tipoCampo: "text",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m4 l4",
    order: 8,
    errorMessage: "Mínimo 10 dígitos (solo números)",
    regex: "^[0-9]{10,15}$",
  },
  fechaNacimiento: {
    campo: 5,
    etiqueta: "Fecha de Nacimiento",
    iDCampo: "fechaNacimiento",
    longitud: 100,
    observacion: "Campo de requerido, Revisa el formato (AAAA-MM-DD)",
    tipoCampo: "date",
    validacion: "Requerido",
    valor: "",
    anchoCampo: "s12 m4 l4",
    order: 6,
    errorMessage: "Revisa el formato (AAAA-MM-DD)",
    regex:
      "^(19[0-9][0-9]|20[0-9][0-9])-(0[0-9]|1[0-2])-(0[0-9]|1[0-9]|2[0-0]|3[0-1])$",
  },
  genero: {
    campo: 6,
    etiqueta: "Género",
    iDCampo: "genero",
    longitud: 100,
    observacion: "Campo de requerido",
    tipoCampo: "Select",
    validacion: "Requerido",
    selectLabel: "Genero",
    order: 5,
    valor: [
      {
        texto: "Femenino",
        valor: "F",
      },
      {
        texto: "Masculino",
        valor: "M",
      },
    ],
    anchoCampo: "s12 m4 l4",
  },
};
