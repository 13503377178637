import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import TournamentsList from "./TournamentsList";
import CreateTournaments from "./CreateTournaments";
import CreateField from "../../../Fields/CreateField";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function TournamentsContainer(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );
  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h1"} component="h2">
                Torneos
              </Typography>{" "}
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <SimpleDialogDemo buttonText={"Crear un torneo"}>
                <CreateTournaments business={business} />
              </SimpleDialogDemo>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px" }}>
              <SimpleDialogDemo buttonText={"Formularios del torneo"}>
                <CreateField />
              </SimpleDialogDemo>
            </Grid>

            <Grid item xs={12}>
              <TournamentsList business={business} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
