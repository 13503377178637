import React from "react";
import { ordenarPuntosDeControlDatos } from "./reportsLogic";
import DynamicInputs from "../../DynamicInput/DynamicInputs";
import EventClock from "./EventClock/EventClock";
import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditableField from "../../EditableField/EditableField";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

class RealTimeResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlSelect: 0,
      resultsArray: null,
    };
  }
  setControl = (valor) => {
    this.setState({
      controlSelect: valor,
    });
    this.updateList(this.props.eventResults, valor);
  };

  componentDidMount = () => {
    this.initComponent();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      if (prevProps != this.props) {
        if (prevProps.puntosControl != this.props.puntosControl) {
          this.getControlsList();
        }
        if (prevProps.eventResults != this.props.eventResults) {
          this.updateList(this.props.eventResults, this.state.controlSelect);
        }
      }
    }
  };
  initComponent = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      this.updateList(this.props.eventResults, this.state.controlSelect);
      this.getControlsList();
    }
  };
  updateList = (results, valor) => {
    var filas = ordenarPuntosDeControlDatos(false, results, valor, false);
    console.log(filas);
    this.setState({ resultsArray: filas });
  };
  getControlsList = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      var puntosControl = this.props.puntosControl.val();

      var valor = [];
      var arrayControls = [];
      // agrego valor null para tener todos los controles
      valor.push({
        value: null,
        label: "Todos los controles",
      });
      // agrego valor 0 (CERO) para tener solo la meta
      valor.push({
        value: 0,
        label: "Meta del evento",
      });

      for (var i in puntosControl) {
        console.log(puntosControl[i].descripcionFinalPunto);
        var descripcionFinalPunto = puntosControl[i].descripcionFinalPunto;
        var modalidadesControl = puntosControl[i].modalidadesPuntoDeControl;
        for (var e in modalidadesControl) {
          var descripcion = modalidadesControl[e].descripcionModalidad;
          var laps = modalidadesControl[e].laps;
          for (var f in laps) {
            var descripcionLap = laps[f].descripcionPunto;
            var controlPoint = puntosControl[i].kNroPunto * 1000 + laps[f].lap;
            console.log(controlPoint);

            if (
              arrayControls.indexOf(controlPoint) == -1 &&
              controlPoint != 0 &&
              laps[f].tipoControl != 3
            ) {
              arrayControls.push(controlPoint);
              console.log(arrayControls);
              valor.push({
                value: controlPoint,
                label: descripcionFinalPunto + " Control " + controlPoint,
              });
            }
          }
        }
      }
    }

    // agrego valor 100000 para pedir datos de los competidores que no han llegado
    valor.push({
      value: 100000,
      label:
        "Competidores por llegar (Solo aplica para eventos que registraron la salida)",
    });

    this.setState({ controlsList: valor });
  };
  handleChange = (value) => {
    console.log(value);
    this.setControl(value);
  };

  render() {
    var puntosControl = this.props.puntosControl.val();
    console.log(puntosControl);
    var element = "";

    if (this.state.resultsArray != null) {
      var PCNombre;
      if (this.state.controlSelect == null) {
        PCNombre = "Todos los controles";
      } else if (this.state.controlSelect === 0) {
        PCNombre = "Tiempo oficial en meta";
      } else if (this.state.controlSelect === 100000) {
        PCNombre = "Competidores por llegar a la meta";
      } else {
        PCNombre = "Punto de control: " + this.state.controlSelect;
      }

      let report = "";
      if (this.state.controlSelect === 100000) {
        report = this.state.resultsArray.JsonKPCPorTerminar.map(
          (data, index) => (
            <TableRow key={index}>
              <TableCell align="center">{data.num}</TableCell>
              <TableCell>{data.nombre}</TableCell>
              <TableCell align="center">{data.mod}</TableCell>
              <TableCell align="center">{data.gen}</TableCell>
              <TableCell align="center">{data.cat}</TableCell>
              <TableCell align="center">{data.kpc}</TableCell>
              <TableCell align="center">{data.tiempo}</TableCell>
            </TableRow>
          )
        );
      } else {
        report = this.state.resultsArray.JsonKPC.map((data, index) => (
          <TableRow key={index}>
            <TableCell align="center">{data.num}</TableCell>
            <TableCell>{data.nombre}</TableCell>
            <TableCell align="center">{data.mod}</TableCell>
            <TableCell align="center">{data.gen}</TableCell>
            <TableCell align="center">{data.cat}</TableCell>
            <TableCell align="center">{data.kpc}</TableCell>
            <TableCell align="center">{data.tiempo}</TableCell>
          </TableRow>
        ));
      }

      element = (
        <div
          className="row"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <EventClock fontSize={50} />

          <Paper style={{ padding: 10, margin: 5, backgroundColor: "#f44336" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs={9} sm={9}>
                <Typography
                  align="center"
                  style={{ color: "white" }}
                  gutterBottom
                  variant={"h3"}
                  component="h2"
                >
                  {PCNombre}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3}>
                <Typography
                  align="center"
                  style={{ color: "white" }}
                  gutterBottom
                  variant={"subtitle1"}
                  component="h2"
                >
                  Nro de atletas
                </Typography>
                <Typography
                  align="center"
                  style={{ color: "white" }}
                  gutterBottom
                  variant={"h4"}
                  component="h2"
                >
                  {this.state.resultsArray.JsonKPC.length}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} align="center">
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    overflow: "auto",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    {this.state.controlsList
                      .sort((a, b) => a.value - b.value)
                      .map((a) => (
                        <Button
                          style={{ margin: 5, fontSize: 12 }}
                          variant="contained"
                          onClick={() => this.handleChange(a.value)}
                        >
                          {a.label}
                        </Button>
                      ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" data-field="id">
                  Número
                </TableCell>
                <TableCell data-field="name">Nombre</TableCell>
                <TableCell align="center" data-field="mod">
                  Modalidad
                </TableCell>
                <TableCell align="center" data-field="gen">
                  Género
                </TableCell>
                <TableCell align="center" data-field="cat">
                  Categoría
                </TableCell>
                <TableCell align="center" data-field="km">
                  KM
                </TableCell>
                <TableCell align="center" data-field="tiempo">
                  Tiempo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{report}</TableBody>
          </Table>
        </div>
      );
    } else {
      element = "";
    }

    return element;
  }
}

export default RealTimeResults;
