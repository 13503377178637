import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
//import { useTracking } from "react-tracking";
import LayoutResolver from "../../LayoutContainers/LayoutResolver";
import {
  getEventWeb,
  initOrganizerEvents,
} from "../../../../data-store/actions/organizers-actions";
import { Grid, Typography } from "@material-ui/core";
import NextEventsCard from "./Next/NextEventsCard";
import PastEventsCard from "./Past/PastEventsCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EventsList(props) {
  const dispatch = useDispatch();

  const { eventStatus, businessId, title } = props.componentConfig;
  const organizerEvents = useSelector(
    (state) => state.organizer.organizerEvents
  );

  useEffect(() => {
    dispatch(initOrganizerEvents(businessId, eventStatus));
  }, [eventStatus, businessId]);

  console.log("eventosArray", eventStatus);
  console.log("eventosArray", organizerEvents);

  function eventsFbToArray(events, status) {
    console.log("eventosArray", "funciton");
    var eventosArray = [];

    for (var i in events) {
      if (events[i].eventoDisponible / 1 === status) {
        eventosArray.push(events[i]);
      }
    }

    if (eventStatus === 1) {
      eventosArray.sort(
        (a, b) => new Date(b.fechaEvento) - new Date(a.fechaEvento)
      );
    } else {
      eventosArray.sort(
        (a, b) => new Date(a.fechaEvento) - new Date(b.fechaEvento)
      );
    }
    console.log("eventosArray", eventosArray);

    return eventosArray;
  }

  return (
    <div>
      <Typography variant="h3" color="primary" style={{ textAlign: "center" }}>
        {title}
      </Typography>
      <Grid
        container
        direction="row"
        justify="left"
        alignItems="left"
        spacing={2}
      >
        {eventStatus === 2 &&
          eventsFbToArray(organizerEvents, eventStatus).map((evento) => (
            <Grid item xs={12} sm={4}>
              <NextEventsCard key={evento.iDEvento} evento={evento} />
            </Grid>
          ))}
        {(eventStatus === 1 || eventStatus === 4) &&
          eventsFbToArray(organizerEvents, eventStatus).map((evento) => (
            <Grid item xs={12} sm={4}>
              <PastEventsCard key={evento.iDEvento} evento={evento} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

EventsList.displayName = "Lista de Eventos";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo de la sección",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    eventStatus: {
      label: "Status de los eventos a mostrar",
      errorMessage: "Campo Requerido",
      fieldName: "eventStatus",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: 2,
          label: "En proceso de inscripciones",
        },
        {
          value: 4,
          label: "Durante el evento",
        },
        {
          value: 1,
          label: "Resultados publicados",
        },
      ],
    },
    businessId: {
      label: "Id del negocio",
      errorMessage: "Campo Requerido",
      fieldName: "businessId",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
