import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import timingConfig from "../timingConfig.json";
import {
  createCategory,
  createCategoryTiming,
} from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateTimingCategory(props) {
  const { subEventIndex, subEvent, gender } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const timingConfigObject = timingConfig.categoria;

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);

    dispatch(
      createCategoryTiming(
        "evento_" + eventDetail.iDEvento,
        data,
        userID,
        eventSubEvents,
        subEventIndex,
        gender
      )
    );
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea una nueva categoría
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={timingConfigObject}
              submitButtonText={"Crear Categoría"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear Categoría"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
