import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import SendIcon from "@material-ui/icons/Send";
import { getTournamentsList } from "../../../../data-store/actions/tournaments-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function TournamentsList(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );
  const tournamentsList = useSelector(
    (state) => state.tournaments.tournamentsList
  );

  const classes = useStyles();

  useEffect(() => {
    if (business) {
      dispatch(getTournamentsList(business.businessId));
    }
  }, [business]);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ margin: "20px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h5"} component="h2">
          Torneos realizados
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <List style={{ width: "100%" }}>
          {tournamentsList &&
            Object.values(tournamentsList).map((tournament) => {
              if (tournament) {
                return (
                  <ListItem>
                    <ListItemIcon>
                      {/* <CreditCardIcon color="secondary" /> */}
                    </ListItemIcon>
                    <ListItemText
                      color="secondary"
                      primary={tournament.name}
                      secondary={
                        tournament.description +
                        " Fecha: " +
                        tournament.startDate
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="this"
                        component={Link}
                        to={`/${business.businessId}/torneo/${tournament.tournamentId}`}
                      >
                        <SendIcon color="secondary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            })}
        </List>
      </Grid>
    </Grid>
  );
}
