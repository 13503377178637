import React from "react";
import { ordenarPuntosDeControlDatos } from "./reportsLogic";
import DynamicInputs from "../../DynamicInput/DynamicInputs";
import EventClock from "./EventClock/EventClock";
import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditableField from "../../EditableField/EditableField";

class Anouncer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlSelect: 0,
      resultsArray: null,
    };
  }
  setControl = (valor) => {
    this.setState({
      controlSelect: valor,
    });
    this.updateList(this.props.eventResults);
  };

  componentDidMount = () => {
    this.initComponent();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      if (prevProps != this.props) {
        if (prevProps.puntosControl != this.props.puntosControl) {
          this.getControlsList();
        }
        if (prevProps.eventResults != this.props.eventResults) {
          this.updateList(this.props.eventResults);
        }
      }
    }
  };
  initComponent = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      this.updateList(this.props.eventResults);
      this.getControlsList();
    }
  };

  handleChange = (value) => {
    console.log(value);
    this.setControl(value.puntosControlid);
  };

  updateList = (results) => {
    var filas = ordenarPuntosDeControlDatos(
      false,
      results,
      this.state.controlSelect,
      true
    );
    console.log(filas);
    this.setState({ resultsArray: filas });
  };
  getControlsList = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      var puntosControl = this.props.puntosControl.val();

      var valor = [];
      var arrayControls = [];
      // agrego valor null para tener todos los controles
      valor.push({
        value: null,
        label: "Todos los controles",
      });
      // agrego valor 0 (CERO) para tener solo la meta
      valor.push({
        value: 0,
        label: "Meta del evento",
      });

      for (var i in puntosControl) {
        console.log(puntosControl[i].descripcionFinalPunto);
        var descripcionFinalPunto = puntosControl[i].descripcionFinalPunto;
        var modalidadesControl = puntosControl[i].modalidadesPuntoDeControl;
        for (var e in modalidadesControl) {
          var descripcion = modalidadesControl[e].descripcionModalidad;
          var laps = modalidadesControl[e].laps;
          for (var f in laps) {
            var descripcionLap = laps[f].descripcionPunto;
            var controlPoint = puntosControl[i].kNroPunto * 1000 + laps[f].lap;
            console.log(controlPoint);

            if (
              arrayControls.indexOf(controlPoint) == -1 &&
              controlPoint != 0 &&
              laps[f].tipoControl != 3
            ) {
              arrayControls.push(controlPoint);
              console.log(arrayControls);
              valor.push({
                value: controlPoint,
                label: descripcionFinalPunto + " Control " + controlPoint,
              });
            }
          }
        }
      }
    }

    this.setState({ controlsList: valor });
  };
  render() {
    var puntosControl = this.props.puntosControl.val();
    console.log(puntosControl);
    var element = "";

    if (this.state.resultsArray != null) {
      var selectPuntosControl = {
        label: "Puntos de Control",
        errorMessage: "Campo Requerido",
        fieldName: "puntosControlid",
        type: "select",
        defaultValue: null,
        required: false,
        regex: "",
        textSize: "h6",
        optionsArray: this.state.controlsList,
      };

      var PCNombre;
      if (this.state.controlSelect == null) {
        PCNombre = "Todos los controles";
      } else if (this.state.controlSelect == 0) {
        PCNombre = "Tiempo oficial en meta";
      } else if (this.state.controlSelect == 100000) {
        PCNombre = "Competidores por llegar a la meta";
      } else {
        PCNombre = "Punto de control: " + this.state.controlSelect;
      }

      let report = this.state.resultsArray.JsonKPC.map((data, index) => (
        <TableRow key={index}>
          <TableCell style={{ fontSize: "50px" }}>{data.num}</TableCell>
          <TableCell align="center" style={{ fontSize: "80px" }}>
            {data.nombre}
          </TableCell>
          <TableCell style={{ fontSize: "50px" }}>{data.tiempo}</TableCell>
        </TableRow>
      ));

      element = (
        <div
          className="row"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div style={{ width: "300px" }}>
            <EditableField
              fieldObject={{
                ...selectPuntosControl,
                defaultValue: this.state.controlSelect,
              }}
              onSubmit={this.handleChange}
            />
          </div>
          {/* <DynamicInputs inputObject={selectPuntosControl} 
                  actualValue={this.state.controlSelect} 
                  callbackchange={e => this.setControl(e)}/> */}

          <EventClock fontSize={100} />
          <div className="col s12 z-depth-3">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell data-field="id">Número</TableCell>
                  <TableCell data-field="name">Nombre</TableCell>
                  <TableCell data-field="id">Tiempo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{report}</TableBody>
            </Table>
          </div>
        </div>
      );
    } else {
      element = "";
    }

    return element;
  }
}

export default Anouncer;
