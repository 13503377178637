import React from "react";
import {
  fechahora,
  completeNull,
  calcularEdad,
  modalidadCateogoriaNameByCode,
  timestampToDate,
} from "../../../../../services/utils";
import { CSVLink } from "react-csv";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

class DownloadButtomAllData extends React.Component {
  constructor(props) {
    super(props);
  }

  csvDataFunc = () => {
    console.log("descarga");
    var csvData = [];
    var newParticipants = { ...this.props.eventParticipants };
    for (var i in newParticipants) {
      //delete newParticipants[i].photos;
      csvData.push({
        ...newParticipants[i],
      });
    }
    return csvData;
  };
  descargarReporte = (filter) => {
    var tipoReporte = this.props.reportType;
    // 1 = reporte full data
    // 2 = reporte Dashboard
    // 3 = reporte Ingresos Usuario

    //Filter
    // 1 = Inscripciones completas
    // undefined o 0 = todos
    // 2 =  Inscripciones en Linea TC, TD y Oxxo
    // 3 = inscripciones en efectivo
    var EventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    var nombreEvento = this.props.eventDetail.nombreEvento;
    var perfilUsuarioEvento = this.props.registryAutorizedUsers[
      this.props.userID
    ].perfil;

    var detalle = "";
    if (tipoReporte == 1) {
      detalle = " - inscritos Full Data - ";
    } else if (tipoReporte == 2) {
      detalle = " - Data inscritos - ";
    } else if (tipoReporte == 3) {
      detalle = " - ingresos - ";
    }

    var nombreArchivo = nombreEvento + detalle + fechahora();

    var jsonTablas = this.listadoInscritosImprimirCSV(
      perfilUsuarioEvento,
      EventoIndex,
      this.props.eventParticipants,
      tipoReporte,
      this.props.registryConfiguration,
      filter
    );
    console.log("jsonTablas", jsonTablas);

    this.exportCSV(jsonTablas.TablaInscritos, nombreArchivo);
  };
  // #######################################################################################
  // #######################################################################################

  exportCSV = (csvContent, tituloArchivo) => {
    var bom = "\uFEFF";
    csvContent = bom + csvContent;
    //console.log(csvContent);
    var pom = document.createElement("a");
    document.body.appendChild(pom);
    pom.style = "display: none";
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute("download", tituloArchivo + ".csv");
    pom.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(pom);
  };

  // #######################################################################################

  camposAdicionalesModalidad = () => {
    var camposAdicionalesModalidad = [];
    var camposAdicionalesModalidadHTML = [];
    var camposAdicionalesModalidadCSV = [];

    var formulario = this.props.registryConfiguration.formulario;
    for (var i in formulario) {
      if (formulario[i].tipoCampo != "titulo") {
        camposAdicionalesModalidad.push(formulario[i]);
        camposAdicionalesModalidadHTML.push(
          <th key={formulario[i].campo} data-field={formulario[i].etiqueta}>
            {formulario[i].etiqueta.replace(",", " ")}
          </th>
        );
        camposAdicionalesModalidadCSV.push(
          formulario[i].etiqueta.replace(",", " ")
        );
      }
    }

    var modalidadesBuscar = this.props.eventSubEventsRegistry;

    var arrayCampoModalidad = [];

    for (var i in modalidadesBuscar) {
      var modalidadCampos =
        modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
      if (modalidadCampos != undefined) {
        for (var e in modalidadCampos) {
          var campos = modalidadCampos[e];
          for (var f in campos) {
            if (campos[f].tipoCampo != "titulo") {
              if (!arrayCampoModalidad.includes(campos[f].iDCampo)) {
                arrayCampoModalidad.push(campos[f].iDCampo);
                camposAdicionalesModalidad.push(campos[f]);
                camposAdicionalesModalidadHTML.push(
                  <th key={i + e + f + "CA"} data-field={campos[f].etiqueta}>
                    {campos[f].etiqueta.replace(",", " ")}
                  </th>
                );
                camposAdicionalesModalidadCSV.push(
                  campos[f].etiqueta.replace(",", " ")
                );
                console.log("Campos " + campos[f].campo);
              }
            }
          }
        }
      }
    }

    return {
      camposAdicionalesModalidad: camposAdicionalesModalidad,
      camposAdicionalesModalidadHTML: camposAdicionalesModalidadHTML,
      camposAdicionalesModalidadCSV: camposAdicionalesModalidadCSV,
    };
  };

  productosAdicionalesModalidadTitulo = () => {
    var productosAdicionalesModalidadTitulo = [];
    var productosAdicionalesModalidadTituloHTML = [];
    var productosAdicionalesModalidadTituloCSV = [];

    var modalidadesBuscar = this.props.eventSubEventsRegistry;
    var arrayCodigoProducto = [];

    for (var i in modalidadesBuscar) {
      var modalidadProductos =
        modalidadesBuscar[i].configModalidad.productosAdicionales !== undefined
          ? modalidadesBuscar[i].configModalidad.productosAdicionales
          : {};

      if (modalidadProductos) {
        modalidadProductos["codeDiscount"] = {
          codigoProducto: "códigoDescuento",
          descripcionProducto: "Código de descuento",
          formularioProducto: [
            {
              anchoCampo: "s12 m6 l6",
              campo: 0,
              etiqueta: "code",
              iDCampo: "code",
              longitud: 100,
              observacion: "",
              regex: "",
              regexMessage: "",
              tipoCampo: "text",
              validacion: "Requerido",
              valor: "",
            },
          ],
          maxCantidad: 1,
          nombreProducto: "Código de descuento",
          precioProducto: 100,
        };

        console.log("Productos", modalidadProductos);
      }
      if (modalidadProductos != undefined) {
        for (var e in modalidadProductos) {
          if (
            !arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)
          ) {
            arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
            productosAdicionalesModalidadTitulo.push(modalidadProductos[e]);

            productosAdicionalesModalidadTituloHTML.push(
              <th
                key={i + e + "cantidad"}
                data-field={`${modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto}-Cantidad`}
              >
                {modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto.replace(",", " ")}{" "}
                - Cantidad
              </th>
            );
            productosAdicionalesModalidadTituloHTML.push(
              <th
                key={i + e + "precio"}
                data-field={`${modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto}-Precio`}
              >
                {modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto.replace(",", " ")}{" "}
                - Precio
              </th>
            );
            productosAdicionalesModalidadTituloHTML.push(
              <th
                key={i + e + "total"}
                data-field={`${modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto}-Total`}
              >
                {modalidadProductos[e].nombreProducto &&
                  modalidadProductos[e].nombreProducto.replace(",", " ")}{" "}
                - Total
              </th>
            );

            productosAdicionalesModalidadTituloCSV.push(
              `${modalidadProductos[e].nombreProducto &&
                modalidadProductos[e].nombreProducto.replace(
                  ",",
                  " "
                )} - Cantidad`
            );
            productosAdicionalesModalidadTituloCSV.push(
              `${modalidadProductos[e].nombreProducto &&
                modalidadProductos[e].nombreProducto.replace(
                  ",",
                  " "
                )} - Precio`
            );
            productosAdicionalesModalidadTituloCSV.push(
              `${modalidadProductos[e].nombreProducto &&
                modalidadProductos[e].nombreProducto.replace(",", " ")} - Total`
            );
            var formularioProducto = modalidadProductos[e].formularioProducto;
            if (formularioProducto != undefined) {
              for (var f in formularioProducto) {
                productosAdicionalesModalidadTituloHTML.push(
                  <th
                    key={i + e + f + "input"}
                    data-field={formularioProducto[f].etiqueta}
                  >
                    {formularioProducto[f].etiqueta.replace(",", " ")}
                  </th>
                );
                productosAdicionalesModalidadTituloCSV.push(
                  `${formularioProducto[f].etiqueta.replace(",", " ")}`
                );
              }
            }

            console.log(JSON.stringify(modalidadProductos[e]));
          }
        }
      }
    }

    return {
      productosAdicionalesModalidadTitulo: productosAdicionalesModalidadTitulo,
      productosAdicionalesModalidadTituloHTML: productosAdicionalesModalidadTituloHTML,
      productosAdicionalesModalidadTituloCSV: productosAdicionalesModalidadTituloCSV,
    };
  };

  listadoInscritosImprimirCSV = (
    perfilUsuarioEvento,
    eventoIndex,
    ListadoInscritos,
    tipoReporte,
    JSONFormInscripcion,
    filter
  ) => {
    var apellidosSeparadosSet =
      JSONFormInscripcion.configuracion.separarApellidos;
    var apellidos = `"Apellidos",`;
    if (apellidosSeparadosSet) {
      apellidos = `"Apellido Paterno","Apellido Materno",`;
    }

    var camposAdicionales = ``;

    var camposAdicionales = this.camposAdicionalesModalidad()
      .camposAdicionalesModalidadCSV;

    var productosAdicionales = ``;
    var productosAdicionales = this.productosAdicionalesModalidadTitulo()
      .productosAdicionalesModalidadTituloCSV;

    if (tipoReporte == 1) {
      var TablaInscritos = `Número,Nombre,${apellidos}Correo,Teléfono,"Fecha de nacimiento",Edad,Modalidad,Rama,Categoría,Usuario,Fecha Registro,${camposAdicionales},${productosAdicionales},Estatus, Interno, ConektaOrder,Monto,Comisión`;
    } else if (tipoReporte == 2) {
      var TablaInscritos = `Número,Nombre,${apellidos}Modalidad,Rama,Categoría,Usuario,Fecha Registro,${camposAdicionales},${productosAdicionales},Estatus,Monto,Comisión`;
    } else if (tipoReporte == 3) {
      var TablaInscritos = `Número,Nombre,${apellidos}Modalidad,Rama,Categoría,Usuario,Fecha Registro,${camposAdicionales},${productosAdicionales},Estatus,Monto,Comisión`;
    }

    var totalCobrado = 0;

    if (ListadoInscritos != null) {
      for (var i in ListadoInscritos) {
        var inscritoData = ListadoInscritos[i];

        var precioCobrado = 0;
        var comision = 0;

        var detalleOrden = "";
        var modalidad = ListadoInscritos[i].modalidad;
        var categoria = ListadoInscritos[i].categoria;
        var genero = ListadoInscritos[i].rama;
        var modCatJson = modalidadCateogoriaNameByCode(
          this.props.eventSubEventsRegistry,
          modalidad,
          categoria,
          genero
        );

        var apellidosDatos = `"${completeNull(
          ListadoInscritos[i].apellidos
        )}",`;
        if (apellidosSeparadosSet) {
          apellidosDatos = `"${completeNull(
            ListadoInscritos[i].apellidoPaterno
          )}","${completeNull(ListadoInscritos[i].apellidoMaterno)}",`;
        }

        var camposAdicionalesData = [];
        var camposAdicionalesArray = this.camposAdicionalesModalidad()
          .camposAdicionalesModalidad;
        for (var m in camposAdicionalesArray) {
          if (camposAdicionalesArray[m].tipoCampo != "titulo") {
            if (inscritoData[camposAdicionalesArray[m].iDCampo] != undefined) {
              camposAdicionalesData.push(
                completeNull(
                  inscritoData[camposAdicionalesArray[m].iDCampo] + ""
                ).replace(",", " ")
              );
            } else {
              camposAdicionalesData.push("N/A");
            }
          }
        }

        var productosAdicionalesData = [];
        var productosAdicionalesArray = this.productosAdicionalesModalidadTitulo()
          .productosAdicionalesModalidadTitulo;
        for (var f in productosAdicionalesArray) {
          var order = inscritoData.order;

          var productExist = false;
          var unitPrice = productosAdicionalesArray[f].precioProducto;

          if (order !== undefined) {
            for (var g in order) {
              var codeProducto =
                productosAdicionalesArray[f].codigoProducto ===
                "códigoDescuento"
                  ? "códigoDescuento"
                  : "producto-" + productosAdicionalesArray[f].codigoProducto;

              if (order[g].code === codeProducto) {
                productExist = true;
                productosAdicionalesData.push(order[g].quantity);
                productosAdicionalesData.push(order[g].unit_price);
                productosAdicionalesData.push(
                  order[g].quantity * order[g].unit_price
                );
                var formularioProducto =
                  productosAdicionalesArray[f].formularioProducto;
                if (formularioProducto !== undefined) {
                  for (var e in formularioProducto) {
                    if (formularioProducto[e].tipoCampo !== "titulo") {
                      if (
                        order[g].extraData !== undefined &&
                        order[g].extraData[formularioProducto[e].iDCampo] !==
                          undefined
                      ) {
                        console.log(
                          "correo",
                          inscritoData.correo,
                          order[g].extraData[formularioProducto[e].iDCampo]
                        );
                        productosAdicionalesData.push(
                          completeNull(
                            order[g].extraData[formularioProducto[e].iDCampo] +
                              ""
                          ).replace(",", " ")
                        );
                      } else {
                        productosAdicionalesData.push("N/R");
                      }
                    }
                  }
                }
              }
            }
          }
          // este if entra si no existe el producto adicional dentro de la orden, es para agregar la colmna vacia
          if (!productExist) {
            productosAdicionalesData.push(0);
            productosAdicionalesData.push(unitPrice);
            productosAdicionalesData.push(0);
            var formularioProducto =
              productosAdicionalesArray[f].formularioProducto;
            if (formularioProducto !== undefined) {
              for (var m in formularioProducto) {
                if (formularioProducto[m].tipoCampo !== "titulo") {
                  productosAdicionalesData.push("N/A");
                }
              }
            }
          }
        }

        var resaltaBorrado = "";
        var borrado = "";
        var statusCode = 0;

        var noCompletado = "";
        if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == ""
        ) {
          noCompletado = "Registro onLine Incompleto";
        }

        var statusPago = "";
        if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "oxxo_pay" &&
          ListadoInscritos[i].statusPago.status == "pendiente"
        ) {
          statusPago = "Pendiente de pago en oxxo";
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "oxxo_pay" &&
          ListadoInscritos[i].statusPago.status == "ok"
        ) {
          statusPago = "Pagado en oxxo";
          statusCode = 1;
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "efectivo"
        ) {
          statusPago = "Pagado en efectivo";
          statusCode = 2;
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "card"
        ) {
          statusPago = "Pagado con tarjeta";
          statusCode = 3;
        }

        var noCompletado = "";
        if (ListadoInscritos[i].borrado) {
          resaltaBorrado = "#e57373 red lighten-2";
          borrado = "Borrado";
          statusCode = 0;
        }

        var conektaOrder = "";
        if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "oxxo_pay" &&
          ListadoInscritos[i].statusPago.status == "pendiente"
        ) {
          conektaOrder = ListadoInscritos[i].statusPago.idOperacion || "NA";
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "oxxo_pay" &&
          ListadoInscritos[i].statusPago.status == "ok"
        ) {
          conektaOrder = ListadoInscritos[i].statusPago.idOperacion || "NA";
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "efectivo"
        ) {
          conektaOrder = "NA";
        } else if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaPago == "card"
        ) {
          conektaOrder = ListadoInscritos[i].statusPago.idOperacion || "NA";
        }

        var usuario;
        // console.log(ListadoInscritos[i].statusPago.formaInscripcion);
        if (
          ListadoInscritos[i].statusPago &&
          ListadoInscritos[i].statusPago.formaInscripcion == "punto de Venta"
        ) {
          if (
            this.props.registryAutorizedUsers[
              ListadoInscritos[i].usuarioRegistra
            ] != undefined
          ) {
            usuario = this.props.registryAutorizedUsers[
              ListadoInscritos[i].usuarioRegistra
            ].nombre;
          } else {
            usuario = "vacio";
          }
        } else {
          usuario = "on line";
        }

        var edadActual = JSONFormInscripcion.configuracion.edadActual;
        var fechaEvento = this.props.eventDetail.fechaEvento;

        var edad = calcularEdad(
          ListadoInscritos[i].fechaNacimiento,
          fechaEvento,
          edadActual
        );
        var order = ListadoInscritos[i].order;
        for (var p in order) {
          if (order[p].code.localeCompare("comision") == 0) {
            comision = parseFloat(order[p].unit_price * order[p].quantity);
          } else {
            precioCobrado =
              parseFloat(precioCobrado) +
              parseFloat(order[p].unit_price * order[p].quantity);
          }
        }

        const filterArray = {
          0: [0, 1, 2, 3],
          1: [1, 2, 3],
          2: [1, 3],
          3: [2],
        };

        if (
          (filter && filterArray[filter].includes(statusCode)) ||
          filter === 0
        ) {
          if (tipoReporte == 1) {
            TablaInscritos =
              TablaInscritos +
              `\n${completeNull(ListadoInscritos[i].numero)},"${completeNull(
                ListadoInscritos[i].nombres
              )}",${apellidosDatos}"${completeNull(
                ListadoInscritos[i].correo
              )}","${completeNull(
                ListadoInscritos[i].telefono
              )}",${completeNull(
                ListadoInscritos[i].fechaNacimiento
              )},${completeNull(edad)},"${completeNull(
                modCatJson.modalidad
              )}","${completeNull(ListadoInscritos[i].rama)}","${completeNull(
                modCatJson.categoria
              )}","${usuario}","${completeNull(
                ListadoInscritos[i].serverTime != undefined
                  ? timestampToDate(ListadoInscritos[i].serverTime)
                  : ListadoInscritos[i].fechaRegistro
              )}",${camposAdicionalesData},${productosAdicionalesData},"${borrado} ${noCompletado} ${statusPago}", ${i},${conektaOrder},${precioCobrado},${comision}`;
          } else if (tipoReporte == 2) {
            TablaInscritos =
              TablaInscritos +
              `\n${completeNull(ListadoInscritos[i].numero)},"${completeNull(
                ListadoInscritos[i].nombres
              )}",${apellidosDatos}"${completeNull(
                modCatJson.modalidad
              )}","${completeNull(ListadoInscritos[i].rama)}","${completeNull(
                modCatJson.categoria
              )}","${usuario}","${completeNull(
                ListadoInscritos[i].serverTime != undefined
                  ? timestampToDate(ListadoInscritos[i].serverTime)
                  : ListadoInscritos[i].fechaRegistro
              )}",${camposAdicionalesData},${productosAdicionalesData},"${borrado} ${noCompletado} ${statusPago}",${precioCobrado},${comision}`;
          } else if (tipoReporte == 3) {
            if (ListadoInscritos[i].usuarioRegistra === this.props.userID) {
              TablaInscritos =
                TablaInscritos +
                `\n${completeNull(ListadoInscritos[i].numero)},"${completeNull(
                  ListadoInscritos[i].nombres
                )}",${apellidosDatos}"${completeNull(
                  modCatJson.modalidad
                )}","${completeNull(ListadoInscritos[i].rama)}","${completeNull(
                  modCatJson.categoria
                )}","${usuario}","${completeNull(
                  ListadoInscritos[i].serverTime != undefined
                    ? timestampToDate(ListadoInscritos[i].serverTime)
                    : ListadoInscritos[i].fechaRegistro
                )}",${camposAdicionalesData},${productosAdicionalesData},"${borrado} ${noCompletado} ${statusPago}",${precioCobrado},${comision}`;
            }
          }
        }
      }
    }

    var jsonInscritos = {
      TablaInscritos: TablaInscritos,
    };

    return jsonInscritos;
  };

  render() {
    var element;
    if (this.props.reportType === 1) {
      element = (
        <Paper style={{ padding: 10 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12} sm={12}>
              <Typography align="center" variant={"h4"} component="h2">
                Descargar Registros
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 5 }}>
              <Button
                onClick={() => this.descargarReporte(1)}
                variant="contained"
                color="primary"
                style={{ padding: 10, minHeight: 70, width: "100%" }}
              >
                <i className="material-icons right">file_download</i>{" "}
                Inscripciones Completas
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 5 }}>
              <Button
                onClick={() => this.descargarReporte(2)}
                variant="contained"
                color="primary"
                style={{ padding: 10, minHeight: 70, width: "100%" }}
              >
                <i className="material-icons right">file_download</i> En línea
                (TC, TD y Oxxo pay)
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 5 }}>
              <Button
                onClick={() => this.descargarReporte(3)}
                variant="contained"
                color="primary"
                style={{ padding: 10, minHeight: 70, width: "100%" }}
              >
                <i className="material-icons right">file_download</i> En
                Efectivo
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} style={{ padding: 5 }}>
              <Button
                onClick={() => this.descargarReporte(0)}
                variant="contained"
                style={{ padding: 10, minHeight: 70, width: "100%" }}
              >
                <i className="material-icons right">file_download</i> Descargar
                todos los registros completos o incompletos
              </Button>
            </Grid>
          </Grid>
        </Paper>
      );
    } else if (this.props.reportType === 2) {
      element = (
        <Button
          onClick={() => this.descargarReporte(0)}
          variant="contained"
          color="primary"
          style={{ padding: 10, width: 300 }}
        >
          <i className="material-icons right">file_download</i> Inscritos
        </Button>
      );
    } else if (this.props.reportType === 3) {
      element = (
        <Button
          onClick={() => this.descargarReporte(0)}
          variant="contained"
          color="primary"
          style={{ padding: 10, width: 300 }}
        >
          <i className="material-icons right">file_download</i> Ingresos
          cobrados
        </Button>
      );
    }
    return element;
  }
}
export default DownloadButtomAllData;
