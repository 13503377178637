import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import timingConfig from "../timingConfig.json";
import {
  createControlPointLap,
  updateEventAttribute,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";
import ControlPointLap from "./ControlPointLap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    overflowY: "scroll",
    margin: 5,
    backgroundColor: "#e0e0e0",
  },
}));

export default function ControlPointDistances(props) {
  const { kpc, controlPointIndex } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  const userID = useSelector((state) => state.atlete.userID);
  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const controlPoint = timingConfig.controlPoint;

  function onSubmit(data) {
    console.log(data);
  }
  function addDistance(modalidad, object) {
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "puntosDeControl/kPC_" +
          controlPointIndex +
          "/modalidadesPuntoDeControl/modalidad_" +
          modalidad +
          "/",
        object
      )
    );
  }
  function removeDistance(modalidad) {
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "puntosDeControl/kPC_" +
          controlPointIndex +
          "/modalidadesPuntoDeControl/modalidad_" +
          modalidad +
          "/",
        null
      )
    );
  }

  function createLap(distanceIndex) {
    dispatch(
      createControlPointLap(
        "evento_" + eventDetail.iDEvento,
        userID,
        controlPointIndex,
        puntosControl,
        distanceIndex
      )
    );
  }

  return (
    <Grid item xs={12}>
      {eventSubEvents &&
        Object.values(eventSubEvents).map((subEvent, index) => {
          console.log();

          let added = false;
          let laps = false;
          if (
            puntosControl["kPC_" + kpc.kNroPunto].modalidadesPuntoDeControl &&
            puntosControl["kPC_" + kpc.kNroPunto].modalidadesPuntoDeControl[
              "modalidad_" + subEvent.codigoModalidad
            ]
          ) {
            added = true;
            laps =
              puntosControl["kPC_" + kpc.kNroPunto].modalidadesPuntoDeControl[
                "modalidad_" + subEvent.codigoModalidad
              ].laps;
          }
          return (
            <Paper style={{ padding: 20, margin: 5 }}>
              <Typography
                gutterBottom
                variant={"subtitle1"}
                component="subtitle1"
              >
                Modalidad
              </Typography>
              <Typography gutterBottom variant={"h3"}>
                {subEvent.descripcionModalidad}
              </Typography>
              {added && (
                <div>
                  <Button
                    onClick={() => removeDistance(subEvent.codigoModalidad)}
                  >
                    Los atletas de la distancia "{subEvent.descripcionModalidad}
                    " no pasan por este punto de control
                  </Button>
                </div>
              )}
              {!added && (
                <Button
                  style={{ margin: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    addDistance(subEvent.codigoModalidad, {
                      descripcionModalidad: subEvent.descripcionModalidad,
                      laps: {
                        lap_1: {
                          descripcionPunto: "Meta",
                          kilometro:
                            kpc.kNroPunto === 0
                              ? subEvent.modalidad / 1
                              : subEvent.modalidad / 2,
                          lap: 1,
                          maxTime: "00:07:00",
                          minTime: "00:00:01",
                          tipoControl: kpc.kNroPunto === 0 ? 3 : 2,
                          unidadPaso: "min/k",
                        },
                      },
                      modalidad: subEvent.modalidad / 1,
                    })
                  }
                >
                  Agregar la distancia "{subEvent.descripcionModalidad}" a este
                  punto de control
                </Button>
              )}
              <Grid container direction="row" alignItems="flex-start">
                {laps &&
                  Object.values(laps)
                    .sort((a, b) => a.lap - b.lap)
                    .map((lap) => (
                      <Grid item xs={6}>
                        <ControlPointLap
                          kpc={kpc}
                          controlPointIndex={kpc.kNroPunto}
                          distanceIndex={subEvent.codigoModalidad}
                          lap={lap}
                        />
                      </Grid>
                    ))}
                <Grid item xs={12}>
                  <Button
                    style={{ margin: 10 }}
                    onClick={() => createLap(subEvent.codigoModalidad)}
                    variant="contained"
                    color="primary"
                  >
                    Crear una vuelta o segundo paso por el mismo control
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {laps && Object.values(laps).length > 1 && (
                    <Paper style={{ padding: 10, margin: 5 }}>
                      <Typography gutterBottom variant={"h6"}>
                        NOTA: Para un correcto funcionamiento de las vueltas
                        estima corretamente paso mínimo por KM que demora un
                        atleta entre una vuelta y la siguiente.
                      </Typography>
                      <Typography gutterBottom variant={"h6"}>
                        Por ejm: Para eventos de carrera pedestre un atleta muy
                        rápido puede lograr un paso de 3 m/k con colocar un paso
                        un poco inferior a ese ya estariamos separarando
                        correctamente un registro en este control del registro
                        siguiente
                      </Typography>
                    </Paper>
                  )}
                </Grid>{" "}
              </Grid>
            </Paper>
          );
        })}
    </Grid>
  );
}
