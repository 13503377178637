import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import { store } from "../configureStore";

// #########################################################################################

export function initOrganizerEvents(businessId, eventStatus) {
  var ref = fb.db.ref(fb.EventosLista);
  return (dispatch) => {
    ref
      .orderByChild("businessId")
      .equalTo(businessId)
      .on("value", (snapshot) => {
        dispatch({
          type: types.ORGANIZER_EVENTS,
          organizerEvents: snapshot.val(),
        });
      });
  };
}
// #########################################################################################
export function createBusinessWeb(
  domain,
  domainKey,
  description,
  businessId,
  userID
) {
  console.log("createBusiness");

  const defaultWeb = {
    auth: true,
    description: description,
    domain: domain,
    key: domainKey,
    web: {
      containers: {
        home: {
          available: true,
          copyFrom: false,
          description: "home",
          miniTheme: {
            background: "#ef4123",
            fontFamily: "Forza, MavenProBold,Helvetica, Arial",
          },
          url: "home",
          views: [
            {
              component: "emptySpace",
              componentConfig: {
                bgColor: "",
                height: "50px",
              },
            },
            {
              component: "title",
              componentConfig: {
                position: "",
                textColor: "#ffffff",
                title: "SITIO EN CONSTRUCCIÓN",
              },
            },
            {
              component: "emptySpace",
              componentConfig: {
                bgColor: "",
                height: "",
                width: "3",
              },
            },
            {
              component: "image",
              componentConfig: {
                alt: "vacio",
                bgColor: "",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/fotosVarias%2F1644439896355%2FDASHPORT_isotipo_W.png?alt=media&token=132fb59f-7c74-411a-97df-0a5eca846080",
                width: "3",
              },
            },
            {
              component: "emptySpace",
              componentConfig: {
                bgColor: "",
                height: "",
                width: "3",
              },
            },
            {
              component: "emptySpace",
              componentConfig: {
                bgColor: "",
                height: "300px",
              },
            },
          ],
        },
      },
      icon:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2Fdashport_ico.ico?alt=media&token=a2a993c3-d14c-4afa-a36e-56968067c968",
      title: businessId,
      businessId: businessId,
    },
  };
  var updates = {};
  console.log("create", `business/${businessId}/`);
  updates[
    `business/${businessId}/generalData/organizersWeb/${domainKey}/`
  ] = defaultWeb;

  return (dispatch) => {
    let ref = fb.db.ref(`organizersWeb/${domainKey}/`);

    return ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      if (snapshot.exists()) {
        // write relevant logic here
        dispatch(
          showSnackbar(true, "Id de la web ya existe intente con otro", 5000)
        );
        return true;
      } else {
        // handle the case when a query finds 0 results
        return fb.db
          .ref()
          .update(updates)
          .then((snap) => {
            console.log("Vista guardada exitosamente");
            dispatch(showSnackbar(true, "Web guardada exitosamente", 5000));
          });
      }
    });
  };
}
// #########################################################################################

export function getOrganizerWeb(url, businessId) {
  let ref = fb.db.ref(
    `business/${businessId}/generalData/organizersWeb/${url}/web/`
  );
  console.log("getOrganizerWeb");

  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.ORGANIZER_WEB,
        organizerWeb: snapshot.val(),
      });
    });
  };
}
// #########################################################################################
export function setOrganizerWebView(businessId, url, subUrl, value) {
  console.log("setOrganizerWeb");
  var updates = {};
  console.log("create", `organizersWeb/${url}/web/containers/${subUrl}/`);
  updates[
    `business/${businessId}/generalData/organizersWeb/${url}/web/containers/${subUrl}/`
  ] = value;

  return (dispatch) => {
    return fb.db
      .ref()
      .update(updates)
      .then((snap) => {
        console.log("Vista guardada exitosamente");
        dispatch(showSnackbar(true, "Vista guardada exitosamente", 5000));
      });
  };
}
// #########################################################################################
export function setOrganizerWebAttributes(businessId, url, object) {
  console.log("setOrganizerWeb");
  var updates = {};
  console.log("create", `organizersWeb/${url}/web/`);

  for (var i in object) {
    updates[
      `business/${businessId}/generalData/organizersWeb/${url}/web/${i}/`
    ] = object[i];
  }
  console.log("create", updates);

  return (dispatch) => {
    return fb.db
      .ref()
      .update(updates)
      .then((snap) => {
        console.log("Datos guardados exitosamente");
        dispatch(showSnackbar(true, "Datos guardados exitosamente", 5000));
      });
  };
}

// #########################################################################################
export function createBusiness(name, businessId, userID, userName, userMail) {
  console.log("createBusiness");

  const value = {
    businessData: {
      name: name,
      createDate: new Date().getTime(),
    },
    authUsers: {
      [userID]: {
        name: userName,
        mail: userMail,
        auth: true,
        profile: "master",
      },
    },
  };
  var updates = {};
  console.log("create", `business/${businessId}/`);
  updates[`business/${businessId}/generalData/`] = value;
  updates[`competidores/${userID}/businessAuth/${businessId}`] = {
    name: name,
    businessId: businessId,
    createDate: new Date().getTime(),
    profile: "master",
  };

  return (dispatch) => {
    let ref = fb.db.ref(`business/${businessId}`);

    return ref.once("value", (snapshot) => {
      console.log(snapshot.val());
      if (snapshot.exists()) {
        // write relevant logic here
        dispatch(
          showSnackbar(true, "Id de empresa ya existe intente con otro", 5000)
        );
        return true;
      } else {
        // handle the case when a query finds 0 results
        return fb.db
          .ref()
          .update(updates)
          .then((snap) => {
            console.log("Vista guardada exitosamente");
            dispatch(showSnackbar(true, "Empresa guardada exitosamente", 5000));
          });
      }
    });
  };
}
// #########################################################################################
export function updateBusinessAttribute(businessId, route, data) {
  console.log("Guardar datos de evento");

  if (businessId) {
    // referencia a la base de datos
    var updates = {};

    updates[
      `${fb.business}${businessId}/${route}${Object.keys(data)[0]}/`
    ] = Object.values(data)[0];

    console.log(data);
    console.log(route);
    console.log(businessId);
    console.log(updates);

    return (dispatch) => {
      return fb.db
        .ref()
        .update(updates)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(showSnackbar(true, "Dato guardado correctamente", 5000));

          dispatch(createBusinessLog(businessId, data, route));
        })
        .catch(function(error) {
          console.log("Error" + error);
          dispatch(showSnackbar(true, "Error guardando", 5000));
        });
    };
  }
}

//#################################################################
export function updateBusinessObject(businessId, route, data) {
  console.log("Guardar datos de evento");

  if (businessId) {
    // referencia a la base de datos
    var updates = {};

    updates[`${fb.business}${businessId}/${route}`] = data;

    return (dispatch) => {
      return fb.db
        .ref()
        .update(updates)
        .then(function() {
          console.log("Datos Guardados");
          dispatch(showSnackbar(true, "Dato guardado correctamente", 5000));

          dispatch(createBusinessLog(businessId, data, route));
        })
        .catch(function(error) {
          dispatch(showSnackbar(true, "Error guardando", 5000));

          console.log("Error" + error);
        });
    };
  }
}

// #########################################################################################// #########################################################################################
export function createBusinessLog(businessId, data, route) {
  console.log("Guardar datos");

  if (businessId) {
    // referencia a la base de datos
    const date = new Date().getTime();
    const userID = store.getState().atlete.userID;

    const finalRoute = `${fb.business}/${businessId}/logs/${date}/`;

    return (dispatch) => {
      return fb.db
        .ref(finalRoute)
        .set({
          data: data,
          route: route || "NA",
          date: date,
          userIdCreate: userID || "NA",
        })
        .then(function() {
          //console.log("Datos Guardados");
        })
        .catch(function(error) {
          //console.log("Error" + error);
        });
    };
  }
}
// #########################################################################################// #########################################################################################
// #########################################################################################// #########################################################################################
export function getBusinessGeneralData(businessId) {
  const ref = fb.db.ref(`${fb.business}/${businessId}/generalData/`);
  console.log("getBusinessId", businessId);

  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log("getBusinessIdget");
      console.log("getBusinessId", snapshot.val());
      dispatch({
        type: types.BUSINESS_GENERAL_DATA,
        businessGeneralData: { [businessId]: snapshot.val() },
      });
    });
  };
}
// #########################################################################################// #########################################################################################
export function getEventWeb(eventoIndex) {
  const ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.eventWeb}`);
  console.log("eventWeb");

  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_WEB_PUBLIC,
        eventWebPublic: snapshot.val(),
        eventWebPublicIndex: eventoIndex,
      });
    });
  };
}
//###########################################################################
export function showSnackbar(show, text, duration) {
  return (dispatch) => {
    console.log("showSnackbar");
    dispatch({
      type: types.SHOW_SNACKBAR,
      snackbar: { show: show, text: text, duration: duration },
    });
    setTimeout(() => {
      dispatch({
        type: types.SHOW_SNACKBAR,
        snackbar: { show: false, text: "", duration: 0 },
      });
    }, duration);
  };
}
//###########################################################################
export function closeSnackbar() {
  return (dispatch) => {
    console.log("showSnackbar");
    dispatch({
      type: types.SHOW_SNACKBAR,
      snackbar: { show: false, text: "", duration: 0 },
    });
  };
}
// #########################################################################################// #########################################################################################
export function saveRegistryDataToGroupedData(
  eventDetail,
  eventSubEventsRegistry,
  eventParticipants
) {
  console.log();
  // Get a new write batch

  let x = 0;
  const chunk = 400;

  const batchsLength = Object.values(eventParticipants).length / chunk;
  let currentBatch = 0;
  let batch = {};

  for (var e = 0; e < batchsLength; e++) {
    batch[e] = fb.firestore.batch();
  }

  const route = "participantsGrouped";
  let data = {};

  for (let i in eventParticipants) {
    x++;
    let organizador = ["dashport"];
    if (eventDetail.businessId) {
      organizador.push(eventDetail.businessId);
    }
    const finalData = {
      ...eventParticipants[i],
      descripcionModalidad: eventSubEventsRegistry[
        "modalidad_" + eventParticipants[i].modalidad
      ]
        ? eventSubEventsRegistry["modalidad_" + eventParticipants[i].modalidad]
            .descripcionModalidad
        : "NA",
      modalidadDistance: eventSubEventsRegistry[
        "modalidad_" + eventParticipants[i].modalidad
      ]
        ? eventSubEventsRegistry["modalidad_" + eventParticipants[i].modalidad]
            .modalidad
        : "NA",
      ciudadEvento: eventDetail.ciudadEvento,
      paisEvento: eventDetail.paisEvento,
      tipoDeEvento: eventDetail.tipoDeEvento,
      lugarEvento: eventDetail.lugarEvento,
      nombreEvento: eventDetail.nombreEvento,
      fechaEvento: eventDetail.fechaEvento,
      iDEvento: eventDetail.iDEvento,
      organizer: organizador,
    };
    data[eventDetail.iDEvento + "-" + i] = finalData;
    batch[currentBatch].set(
      fb.firestore.collection(route).doc(eventDetail.iDEvento + "-" + i),
      finalData
    );

    if (x >= chunk) {
      console.log("batch", data, currentBatch, x);

      batch[currentBatch].commit().then(() => {
        console.log("Datos Guardados");
      });
      x = 0;
      currentBatch++;
      data = {};
    }
  }
  console.log("batch", data, currentBatch, x);

  batch[currentBatch].commit().then(() => {
    console.log("Datos Guardados");
  });
  x = 0;
  console.log("batch", data);
  // // Commit the batch
  // batch.commit().then(() => {
  //   console.log("Datos Guardados");
  // });
}
// #########################################################################################// #########################################################################################
export function getBusinessParticipants(businessId, limit) {
  console.log();
  const route = "participantsGrouped";

  return (dispatch) => {
    fb.firestore
      .collection(route)
      .where("organizer", "array-contains", businessId)
      //      .limit(limit)
      .get()
      .then((querySnapshot) => {
        let businessParticipantsData = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          businessParticipantsData[doc.id] = doc.data();
          console.log(doc.id, " => ", doc.data());
        });
        dispatch({
          type: types.BUSINESS_PARTICIPANTS,
          businessParticipants: businessParticipantsData,
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };
}
