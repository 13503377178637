import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getCleanedString } from "../../../services/utils";
import FormDialog from "../Modals/FormDialog";
import AtleteDetailsContainer from "../reports/atleteDetail/AtleteDetailContainer";

export default function BuscadorResults(props) {
  const { icon, certificates } = props;
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  const [athletesList, setAthletesList] = useState();

  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  const findRegistry = (value) => {
    var ValorBuscado = value;
    let listaAtletas = [];
    if (eventResults != undefined) {
      if (!isNaN(ValorBuscado)) {
        console.log("numero");
        eventResults.forEach((value, i) => {
          //      console.log(ListaAtletas[value]);
          if (value.num == ValorBuscado) {
            if (certificates) {
              listaAtletas.push(
                <li key={i} className="collection-item">
                  <FormDialog
                    buttomLabel={
                      (value.num == undefined ? "Folio Pendiente" : value.num) +
                      " - " +
                      value.nombre +
                      " - IMPRIMIR CERTIFICADO"
                    }
                    ShowIcon={false}
                    ButtonStyle={null}
                    maxWidthProp={"lg"}
                    style={{
                      minHeight: "80vh",
                      maxHeight: "80vh",
                    }}
                    report={<div>Aqui va la impresion de certificado</div>}
                  />
                </li>
              );
            } else {
              listaAtletas.push(
                <li key={i} className="collection-item">
                  <FormDialog
                    buttomLabel={
                      (value.num == undefined ? "Folio Pendiente" : value.num) +
                      " - " +
                      value.nombre
                    }
                    ShowIcon={false}
                    ButtonStyle={null}
                    maxWidthProp={"lg"}
                    style={{
                      minHeight: "80vh",
                      maxHeight: "80vh",
                    }}
                    report={
                      <AtleteDetailsContainer
                        eventDetail={eventDetail}
                        resultado={value}
                      />
                    }
                  />
                </li>
              );
            }
          }
        });

        return listaAtletas;
      } else {
        console.log("texto");
        if (ValorBuscado.length > 2) {
          eventResults.forEach((value, i) => {
            ValorBuscado = getCleanedString(ValorBuscado);
            var nombreBuscado = ValorBuscado.toUpperCase();
            const nombreComparar = getCleanedString(value.nombre.toUpperCase());
            if (nombreComparar.match(nombreBuscado) != null) {
              if (certificates) {
                listaAtletas.push(
                  <li key={i} className="collection-item">
                    <FormDialog
                      buttomLabel={
                        (value.num == undefined
                          ? "Folio Pendiente"
                          : value.num) +
                        " - " +
                        value.nombre +
                        " - IMPRIMIR CERTIFICADO"
                      }
                      ShowIcon={false}
                      ButtonStyle={null}
                      maxWidthProp={"lg"}
                      style={{
                        minHeight: "80vh",
                        maxHeight: "80vh",
                      }}
                      report={<div>Aqui va la impresion de certificado</div>}
                    />
                  </li>
                );
              } else {
                listaAtletas.push(
                  <li key={i} className="collection-item">
                    <FormDialog
                      buttomLabel={
                        (value.num == undefined
                          ? "Folio Pendiente"
                          : value.num) +
                        " - " +
                        value.nombre
                      }
                      ShowIcon={false}
                      ButtonStyle={null}
                      maxWidthProp={"lg"}
                      style={{
                        minHeight: "80vh",
                        maxHeight: "80vh",
                      }}
                      report={
                        <AtleteDetailsContainer
                          eventDetail={eventDetail}
                          resultado={value}
                        />
                      }
                    />
                  </li>
                );
              }
            }
          });
          return listaAtletas;
        }
      }
    }
  };

  const handleChange = (e) => {
    console.log("AntdInput", e.target.value);
    setAthletesList(findRegistry(e.target.value));
    console.log(athletesList);
  };

  let buttom;
  if (icon) {
    buttom = (
      <li>
        <i className="material-icons md-40 marginIcons">search</i>
      </li>
    );
  } else {
    buttom = (
      <li>
        <a href="#" className="red-text">
          Buscar por Nombre o Número
        </a>
      </li>
    );
  }
  return (
    <div>
      <h5>Buscador por Nombre o Número</h5>

      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          onChange={handleChange}
          name="nameOrNumber"
          defaultValue=""
          ref={register}
        />
      </form>
      <ul id="ListaNumeroNombre" className="collection">
        {athletesList}
      </ul>
    </div>
  );
}
