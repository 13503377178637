import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
} from "../../../../../data-store/actions/events-actions";
import UpdateInput from "./UpdateInput";
import CreateInput from "./CreateInput";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormReports from "./FormReports";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  list: {
    margin: 30,
    width: "400px",
    color: "black",
  },
}));

export default function InputsGroup(props) {
  const { inputGroup } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  const createEventObject = createEventConfig.adicionalInput;

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Grupo de campos {inputGroup}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              {inputGroup === "general" &&
                "Los campos generales son mostrados a todos los atletas que realicen el registro."}
              {inputGroup !== "general" &&
                "Los campos adicionales por categoría se deben asignar a cada categoría donde se quieran mostrar (ejm categoría relevos para datos extra del resto del equipo)"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CreateInput inputGroup={inputGroup} />
          </Grid>
          <Grid item xs={6}>
            <SimpleDialogDemo buttonText={"Ver Reporte"} fullScreen={true}>
              <FormReports
                inputGroup={inputGroup}
                form={registryConfiguration.gruposFormularios[inputGroup]}
              />
            </SimpleDialogDemo>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <List className={classes.list}>
              {registryConfiguration.gruposFormularios[inputGroup].map(
                (input, index) => (
                  <ListItem>
                    <ListItemText
                      primary={"Nombre: " + input.etiqueta}
                      secondary={"tipo: " + input.tipoCampo}
                    />
                    <ListItemAvatar>
                      <UpdateInput
                        inputGroup={inputGroup}
                        input={input}
                        index={index}
                      />
                    </ListItemAvatar>
                  </ListItem>
                )
              )}
            </List>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
