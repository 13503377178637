import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Field from "./Field";
import FieldSelect from "./FieldSelect";
import FieldDate from "./FieldDate";
import SimpleDialogDemo from "../Landing/LayoutContainers/SimpleDialog";
import FormRender from "./FormRender";
import {
  generateReport,
  ReportTable,
} from "../pages/AuthorizedUsers/Reports/generateReport";
import { Typography } from "@material-ui/core";
import EditableField from "../EditableField/EditableField";

export default function InputsArray(props) {
  const { fieldObject, callback, item, index, submitButtonText } = props;

  const { register, handleSubmit, errors, watch, control } = useForm();
  console.log("errors", errors);

  const [report, setReport] = useState([]);
  const [labels, setLabels] = useState([]);
  const [reportType, setReportType] = useState(undefined);
  const [itemIdentifier, setItemIdentifier] = useState(undefined);

  useEffect(() => {
    let fields = [];
    fields.push({ label: "Editar", path: "edit" });
    fields.push(
      ...Object.values(fieldObject.inputsArray).map((column) => ({
        label: column.label,
        path: column.fieldName,
      }))
    );
    fields.push({ label: "Oculto", path: "hidden" });
    const data =
      item.componentConfig[fieldObject.fieldName] !== undefined
        ? Object.values(item.componentConfig[fieldObject.fieldName]).map(
            (item1, index) => {
              console.log("data", item.componentConfig);
              const inputsArrayNew = Object.values(fieldObject.inputsArray).map(
                (itemNew) => ({
                  ...itemNew,
                  defaultValue:
                    item.componentConfig[fieldObject.fieldName][index][
                      itemNew.fieldName
                    ],
                })
              );

              const formObjectNew = {
                ...inputsArrayNew,
                index: {
                  label: "index",
                  errorMessage: "Campo Requerido",
                  fieldName: "index",
                  type: "hidden",
                  defaultValue: index,
                  required: true,
                  regex: "",
                  textSize: "h6",
                },
                hidden: {
                  label: "Ocultar Item (Selecciona para ocultar)",
                  errorMessage: "Campo Requerido",
                  fieldName: "hidden",
                  type: "switch",
                  defaultValue: false,
                  required: true,
                  regex: "",
                  textSize: "h6",
                },
              };

              return {
                ...item1,
                edit: (
                  <SimpleDialogDemo buttonText={"Editar Item"}>
                    <div style={{ padding: 20 }}>
                      <EditViewArray
                        callback={onSubmitAttribute}
                        formObject={formObjectNew}
                        index={index}
                      />
                    </div>
                  </SimpleDialogDemo>
                ),
              };
            }
          )
        : [];

    const newReport = generateReport({
      data: data,
      fields: fields,
    });
    setLabels(newReport.labels);
    setReport(newReport.report);
    setItemIdentifier(newReport.itemIdentifier);
  }, []);

  const onSubmit = (data) => {
    console.log("data", data);

    callback(data, fieldObject.fieldName, parseInt(data.index));

    //callback(data, fieldObject.fieldName);
  };
  const onSubmitAttribute = (data, index) => {
    console.log("data", data);
    console.log("data", index);

    console.log("data", item.componentConfig);
    console.log("data", fieldObject.fieldName);
    console.log("data", item.componentConfig[fieldObject.fieldName].in);
    console.log("data", item.componentConfig[fieldObject.fieldName][index]);
    const newItem = item.componentConfig[fieldObject.fieldName][index];
    newItem[Object.keys(data)[0]] = Object.values(data)[0];
    console.log("data", newItem);

    callback(newItem, fieldObject.fieldName, index);
  };

  console.log("item", fieldObject);

  console.log("watchFieldEVent", watch);

  return (
    <div>
      <Typography gutterBottom variant={"subtitle2"} component="h2">
        {fieldObject.label}
      </Typography>
      <ReportTable
        report={report}
        labels={labels}
        data={report}
        reportType={reportType}
        itemIdentifier={itemIdentifier}
      />
      <SimpleDialogDemo buttonText={"Agregar Item"}>
        <div style={{ padding: 20 }}>
          <FormRender
            callback={onSubmit}
            formObject={{
              ...fieldObject.inputsArray,
              index: {
                label: "index",
                errorMessage: "Campo Requerido",
                fieldName: "index",
                type: "hidden",
                defaultValue:
                  item.componentConfig[fieldObject.fieldName] === undefined ||
                  item.componentConfig[fieldObject.fieldName] === ""
                    ? 0
                    : Object.values(item.componentConfig[fieldObject.fieldName])
                        .length,
                required: true,
                regex: "",
                textSize: "h6",
              },
              hidden: {
                label: "Ocultar Item (Selecciona para ocultar)",
                errorMessage: "Campo Requerido",
                fieldName: "hidden",
                type: "switch",
                defaultValue: false,
                required: true,
                regex: "",
                textSize: "h6",
              },
            }}
            submitButtonText={"Crear Item"}
          />
        </div>
      </SimpleDialogDemo>
    </div>
  );
}

function EditViewArray(props) {
  const { formObject, index, callback } = props;
  function handleChange(data) {
    callback(data, index);
  }
  return (
    <div>
      {Object.values(formObject).map((field, index) => {
        return (
          <EditableField
            key={index}
            fieldObject={field}
            onSubmit={handleChange}
          />
        );
      })}
    </div>
  );
}
