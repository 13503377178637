/**
 * Created by avaldez on 12/20/16.
 */
import React from 'react';

import AboutUs from './About-us';
import ContactUs from './Contact-us';


const Footer = () =>(
	<footer className="page-footer  #f44336 red">
	<div className="container">
		<div className="row">
			<div className="col l6 s12">
				<AboutUs/>
			</div>
			<div className="col l3 s12">
				<ContactUs/>
			</div>
		</div>
	</div>
	<div className="footer-copyright">
		<div className="container">
		</div>
	</div>
</footer>

)
export default Footer;


/*
function footer() {

	const {element: aboutUsElement} = aboutUs();
	const {element: contactUsElement} = contactUs();

	const element = (
	);

	return {
		element,
		events: {}
	};

}
*/