import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getTrakingLinks } from "../../../../../data-store/actions/events-actions";

function DashboardTrackingLinks() {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const trackingLinks = useSelector((state) => state.events.trackingLinks);

  useEffect(() => {
    if (
      (eventDetail && !trackingLinks) ||
      (trackingLinks && !trackingLinks["evento_" + eventDetail.iDEvento])
    ) {
      dispatch(getTrakingLinks("evento_" + eventDetail.iDEvento));
    }
  }, []);

  var dataDispersion = {
    datasets: [
      {
        label: "Views",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        id: "y-axis-1",
      },
      {
        label: "Leads",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        id: "y-axis-1",
      },
      {
        label: "Purchase",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        id: "y-axis-1",
      },
    ],
    labels: [],
  };
  const options = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          suggestedMin: 0,
        },
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
              return value;
            },
          },
        },
      ],
    },
  };

  if (trackingLinks && trackingLinks["evento_" + eventDetail.iDEvento]) {
    const linkList = trackingLinks["evento_" + eventDetail.iDEvento];

    for (var i in linkList) {
      var link = i;
      dataDispersion.labels.push(link);
      dataDispersion.datasets[0].data.push(linkList[i]["PageView"] || 0);
      dataDispersion.datasets[0].backgroundColor.push(
        "rgba(255, 99, 132, 0.5)"
      );
      dataDispersion.datasets[0].borderColor.push("rgb(255, 99, 132)");
      dataDispersion.datasets[1].data.push(linkList[i]["Lead"] || 0);
      dataDispersion.datasets[1].backgroundColor.push(
        "rgba(53, 162, 235, 0.5)"
      );
      dataDispersion.datasets[1].borderColor.push("rgb(53, 162, 235)");
      dataDispersion.datasets[2].data.push(linkList[i]["Purchase"] || 0);
      dataDispersion.datasets[2].backgroundColor.push("rgba(100, 200, 5, 0.5)");
      dataDispersion.datasets[2].borderColor.push("rgb(100, 200, 5)");
    }
  }

  return (
    <Paper style={{ padding: 20, margin: 5 }}>
      <Typography variant={"h2"} color="primary" align="center">
        Analíticas de los links de seguimiento.
      </Typography>
      <Typography variant={"h4"} align="center">
        Links de seguimiento registrados :{" "}
      </Typography>

      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          <Typography variant={"h6"} align="center">
            Link
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"h6"} align="center">
            Vistas
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"h6"} align="center">
            Leas o Próspectos
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"h6"} align="center">
            Compras
          </Typography>
        </Grid>
      </Grid>

      {trackingLinks &&
        trackingLinks["evento_" + eventDetail.iDEvento] &&
        Object.keys(trackingLinks["evento_" + eventDetail.iDEvento]).map(
          (a) => {
            return (
              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ padding: 10 }}
              >
                <Grid item xs={6}>
                  <Typography variant={"h5"} align="center">
                    {a === "direct"
                      ? "Acceso directo"
                      : "https://dashport.run/evento_" +
                        eventDetail.iDEvento +
                        "?tkl=" +
                        a}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant={"h5"} align="center">
                    {trackingLinks["evento_" + eventDetail.iDEvento][a][
                      "PageView"
                    ] || 0}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant={"h5"} align="center">
                    {trackingLinks["evento_" + eventDetail.iDEvento][a][
                      "Lead"
                    ] || 0}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant={"h5"} align="center">
                    {trackingLinks["evento_" + eventDetail.iDEvento][a][
                      "Purchase"
                    ] || 0}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        )}

      <div className="col s12">
        <Bar data={dataDispersion} options={options} />
      </div>

      <Typography variant={"h2"} color="primary" align="center">
        Otros métodos de seguimiento
      </Typography>

      <Typography variant={"h5"} align="center">
        Pixel de Facebook :{" "}
        {eventDetail.facebookPixel || "Pixel específico del evento no creado"}
      </Typography>
      <Typography variant={"h5"} align="center">
        Google Analytics :{" "}
        {eventDetail.gaId || "Código de Google Analytics no registrado"}
      </Typography>
    </Paper>
  );
}

export default DashboardTrackingLinks;
