import React, { Suspense, lazy, Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import CameraFeed from "./CameraFeed";
import CameraDashport from "./CameraDashport";

class ControlPointPhotoRegistryView extends React.Component {
  state = {
    eventos: 20,
  };

  uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Connect to a seaweedfs instance
  };
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justify="left"
          alignItems="left"
          spacing={2}
        >
          <CameraDashport eventId={this.props.eventId} />
          {/* <CameraFeed sendFile={this.uploadImage} /> */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const ControlPointPhotoRegistry = connect(mapStateToProps)(
  ControlPointPhotoRegistryView
);

export default ControlPointPhotoRegistry;
