import React from "react";
import {
  completeNull,
  modalidadCateogoriaNameByCode,
} from "../../../../../services/utils";
import { cambiarModGenCatFB } from "../../../../../firebase/firebasejs";
import FormRender from "../../../../Fields/FormRender";

class ModCatGenDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: true,
      editing: false,
      ModCatGenDetailJSON: {
        modalidad: null,
        genero: null,
        categoria: null,
        statusPago: null,
        eventoIndex: null,
        idRegistro: null,
        modalidadAnterior: null,
        generoAnterior: null,
        categoriaAnterior: null,
      },
    };
  }

  cambiarModGenCat = (data) => {
    console.log("Cambio MODCATGEN");
    var valorSplit = data.valor.split("-#%&");
    var ModCatGenDetailJSON = {};
    ModCatGenDetailJSON.modalidad = valorSplit[0];
    ModCatGenDetailJSON.genero = valorSplit[1];
    ModCatGenDetailJSON.categoria = valorSplit[2];
    ModCatGenDetailJSON.statusPago = valorSplit[3];
    ModCatGenDetailJSON.eventoIndex = valorSplit[4];
    ModCatGenDetailJSON.idRegistro = valorSplit[5];

    ModCatGenDetailJSON.modalidadAnterior = valorSplit[6];
    ModCatGenDetailJSON.generoAnterior = valorSplit[7];
    ModCatGenDetailJSON.categoriaAnterior = valorSplit[8];

    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    cambiarModGenCatFB(
      this.props.eventSubEventsRegistry,
      ModCatGenDetailJSON,
      eventoIndex,
      this.props.ParticipantDetailKey,
      this.props.ParticipantDetail,
      this.props.userID,
      this.props.atleteAccount
    );
    this.toogleEditing();
  };

  editarModalidadRamaCategoria = (
    eventSubEventsRegistry,
    modalidad,
    Genero,
    categoria,
    statusPago,
    eventoIndex,
    idRegistro
  ) => {
    var modCatJson = modalidadCateogoriaNameByCode(
      eventSubEventsRegistry,
      modalidad,
      categoria,
      Genero
    );
    console.log(JSON.stringify(modCatJson));

    var opcionesSelect = [];
    var ModalidadesEvento = eventSubEventsRegistry;
    var precioKey = "";
    var anteriorMod = false;
    var anteriorRama = false;

    var espacio = " ";

    var modalidadTexto = "";
    var generoTexto = "";
    var categoriaTexto = "";
    var codMod = 0;
    var codCat = "";
    var codGen = "";

    for (var i in ModalidadesEvento) {
      codMod = ModalidadesEvento[i].codigoModalidad;
      anteriorMod = false;
      modalidadTexto = `Evento: ${ModalidadesEvento[i].descripcionModalidad}`;
      if (ModalidadesEvento[i].codigoModalidad == modalidad) {
        // resaltar la categoria previa
        anteriorMod = true;
        //    opcionesSelect.push(<option value="0" disabled>${modalidadTexto} (Previa)</option>)
      } else {
        //    opcionesSelect.push(<option value="0" disabled>${modalidadTexto} </option>);
      }
      var ramaCategoria = ModalidadesEvento[i].ramaCat;
      for (var e in ramaCategoria) {
        anteriorRama = false;
        if (e.localeCompare("rama_F") == 0) {
          generoTexto = "Rama Femenil";
          codGen = "F";
        } else {
          generoTexto = "Rama Varonil";
          codGen = "V";
        }
        if (e.localeCompare("rama_" + Genero) == 0 && anteriorMod) {
          anteriorRama = true;
          // resaltar rama previa
          //      opcionesSelect.push(<option value="0" disabled>${espacio.repeat(30)}${generoTexto} (Previa)</option>);
        } else {
          //      opcionesSelect.push(<option value="0" disabled>${espacio.repeat(30)}${generoTexto}</option>);
        }
        var categorias = ramaCategoria[e].categorias;
        for (var f in categorias) {
          codCat = categorias[f].codigo;
          categoriaTexto = `${modalidadTexto} ${generoTexto} Categoria ${categorias[f].nombreCategoria}`;
          if (!categorias[f].absolutos) {
            console.log(categorias[f].nombreCategoria);
            if (
              categorias[f].codigo == categoria &&
              anteriorMod &&
              anteriorRama
            ) {
              opcionesSelect.push({
                label: `${espacio.repeat(0)} ${categoriaTexto} (Previa)`,
                value: `${codMod}-#%&${codGen}-#%&${codCat}-#%&${statusPago}-#%&${eventoIndex}-#%&${idRegistro}-#%&${modalidad}-#%&${Genero}-#%&${categoria}`,
              });
              precioKey = categorias[f].precioKey;
            } else {
              opcionesSelect.push({
                label: `${espacio.repeat(0)} ${categoriaTexto}`,
                value: `${codMod}-#%&${codGen}-#%&${codCat}-#%&${statusPago}-#%&${eventoIndex}-#%&${idRegistro}-#%&${modalidad}-#%&${Genero}-#%&${categoria}`,
              });
            }
          }
        }
      }
    }

    const filterSelect = {
      filter: {
        label: "Selecciona la opción corresponiente",
        errorMessage: "Campo Requerido",
        fieldName: "valor",
        type: "select",
        defaultValue: 1,
        required: true,
        regex: "",
        textSize: "h6",
        optionsArray: opcionesSelect,
      },
    };

    return (
      <div>
        <div className="input-field col s10">
          <FormRender
            callback={this.cambiarModGenCat}
            formObject={filterSelect}
            submitButtonText={"Guardar Cambio"}
          />
        </div>
      </div>
    );
  };

  toogleEditing = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  render() {
    const modalidad = this.props.ParticipantDetail.modalidad;
    const categoria = this.props.ParticipantDetail.categoria;
    const genero = this.props.ParticipantDetail.rama;
    const modCatJson = modalidadCateogoriaNameByCode(
      this.props.eventSubEventsRegistry,
      modalidad,
      categoria,
      genero
    );
    const statusPago = this.props.ParticipantDetail.statusPago.status;
    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;

    let tabla;
    if (this.state.editing && this.state.editable) {
      tabla = this.editarModalidadRamaCategoria(
        this.props.eventSubEventsRegistry,
        modalidad,
        genero,
        categoria,
        statusPago,
        eventoIndex,
        this.props.ParticipantDetailKey
      );
    } else {
      tabla = (
        <div style={{ padding: "10px" }} onClick={() => this.toogleEditing()}>
          <div
            style={{ padding: "10px" }}
            className="#f44336 red white-text col s3 m3 l3 center-align"
          >
            <b>Modalidad </b>
            <br />
            {modCatJson.modalidad}
          </div>
          <div
            style={{ padding: "10px" }}
            className="#f44336 red white-text col s3 m3 l3 center-align"
          >
            <b>Rama </b>
            <br />
            {genero}
          </div>
          <div
            style={{ padding: "10px" }}
            className="#f44336 red white-text col s3 m3 l3 center-align"
          >
            <b>Categoría </b>
            <br />
            {modCatJson.categoria}
          </div>
          <div
            style={{ padding: "10px" }}
            className="#f44336 red white-text col s3 m3 l3 center-align"
          >
            <b>Status pago </b>
            <br />
            {statusPago}
          </div>
        </div>
      );
    }
    return tabla;
  }
}

export default ModCatGenDetail;
