import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import {
  getEventDataToCopy,
  updateEventObject,
} from "../../../../data-store/actions/events-actions";
import {
  generateVirtualEventsLink,
  sendAllMails,
} from "../../../../data-store/actions/registry-actions";
import EditableField from "../../../EditableField/EditableField";
import EventRaceNumber from "./EventRaceNumber/EventRaceNumber";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
}));

export default function EventRegistryCopyPassEventsConfig() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const events = useSelector((state) => state.events.events);
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );

  const eventDataToCopy = useSelector((state) => state.events.eventDataToCopy);

  const createEventObject = createEventConfig.inscripcionesConfiguracion;

  const classes = useStyles();

  function copyData(type) {
    if (type === "registry") {
      dispatch(
        updateEventObject(
          "evento_" + eventDetail.iDEvento,
          "inscripcionesConfiguracion/",
          eventDataToCopy.inscripcionesConfiguracion
        )
      );
      dispatch(
        updateEventObject(
          "evento_" + eventDetail.iDEvento,
          "modalidadesInscripcion/",
          eventDataToCopy.modalidadesInscripcion
        )
      );
    } else if (type === "web") {
      dispatch(
        updateEventObject(
          "evento_" + eventDetail.iDEvento,
          "web/",
          eventDataToCopy.web
        )
      );
    }
  }
  function onSubmit(data) {
    console.log(data);
    dispatch(
      getEventDataToCopy(data.eventId, "evento_" + eventDetail.iDEvento)
    );
  }
  const inputsForm = [
    {
      label: "Selecciona el id de evento a copiar",
      errorMessage: "Campo Requerido",
      fieldName: "eventId",
      type: "select",
      defaultValue: "p",
      required: false,
      regex: "",
      textSize: "h6",
      optionsArray:
        atleteAccount &&
        atleteAccount.eventosAutorizados &&
        Object.keys(atleteAccount.eventosAutorizados)
          .sort((a, b) => {
            const a1 = a.split("_")[1] / 1;
            const b1 = b.split("_")[1] / 1;
            return a1 - b1;
          })
          .filter((i) => atleteAccount.eventosAutorizados[i].autorizado)
          .map((i) => {
            return {
              value: i,
              label: events[i] ? i + " - " + events[i].nombreEvento : i,
            };
          }),
    },
  ];

  return (
    <Paper className={classes.paper}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h4"} component="h2">
            Selecciona el evento a copiar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant={"subtitle1"} component="h2">
            Esta función permite copiar la configuración de inscripciones
            incluyedo distancias, categorías, precios, foliado, campos
            adicionales y produtos adicionales. Adicionalmente permite copiar la
            web utilizada
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
          <FormRender
            callback={onSubmit}
            formObject={inputsForm}
            submitButtonText={"Obtener configuracion de inscripciones y web"}
          />
        </Grid>
        {eventDataToCopy && eventDataToCopy.modalidadesInscripcion && (
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant={"h4"}
              component="h2"
              align="center"
            >
              Configuración de Inscripción
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "400px" }}
              onClick={() => copyData("registry")}
            >
              COPIAR CONFIGURACIÓN DE INSCRIPCIONES
            </Button>
          </Grid>
        )}
        {eventDataToCopy && eventDataToCopy.web && (
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant={"h4"}
              component="h2"
              align="center"
            >
              Página web
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{ width: "400px" }}
              onClick={() => copyData("web")}
            >
              COPIAR Página Web
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
