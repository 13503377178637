import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEventAttribute,
  updateEventObject,
} from "../../../../../../data-store/actions/events-actions";
import { fechahora } from "../../../../../../services/utils";
import EditableField from "../../../../../EditableField/EditableField";
import FormRender from "../../../../../Fields/FormRender";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";

export default function EditItem(props) {
  const { index, deposit } = props;
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const userID = useSelector((state) => state.atlete.userID);

  const eventDeposits = useSelector((state) => state.events.eventDeposits);
  const dispatch = useDispatch();

  function handleChange(data) {
    console.log(data);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "deposits/depositsList/" + index + "/",
        data
      )
    );
  }
  const createFieldObject = {
    type: {
      label: "Tipo de Registro",
      errorMessage: "Campo Requerido",
      fieldName: "type",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "oxxoRegistryPercent",
          label: "Porcentaje de las inscripciones en Oxxo",
        },
        {
          value: "cardRegistryPercent",
          label: "Porcentaje de las inscripciones en TC y TD",
        },
        {
          value: "cashRegistryPercent",
          label: "Porcentaje de las inscripciones en Efectivo",
        },
        {
          value: "fixedAmount",
          label: "Monto Fijo",
        },
        {
          value: "deposit",
          label: "Deposito o transferencia realizada",
        },
      ],
    },
    depositDate: {
      label: "Fecha",
      errorMessage: "Campo Requerido",
      fieldName: "depositDate",
      type: "date",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    amount: {
      label:
        "Monto para valor fijo o Porcentaje para tipos de porcentaje (ejm 100 = 100%) ",
      errorMessage: "Campo Requerido",
      fieldName: "amount",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      min: -1000000000,
      step: "0.01",
    },
    description: {
      label: "Descripción",
      errorMessage: "Campo Requerido",
      fieldName: "description",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    reference: {
      label: "Referencia si aplica",
      errorMessage: "Campo Requerido",
      fieldName: "reference",
      type: "text",
      defaultValue: "",
      required: false,
      regex: "",
      textSize: "h6",
    },
    document: {
      label: "Imagen de soporte (opcional)",
      errorMessage: "Campo Requerido",
      fieldName: "document",
      type: "file",
      defaultValue: false,
      required: false,
      regex: "",
      textSize: "h6",
    },
  };

  if (!eventDeposits && atleteAccount.perfil !== 1) {
    return <br></br>;
  }

  let showFormToAdd;
  if (atleteAccount.perfil === 1) {
    showFormToAdd = (
      <SimpleDialogDemo buttonText={"Editar"} fullScreen={false}>
        <div style={{ margin: "30px" }}>
          {Object.values(createFieldObject).map((field, index) => {
            return (
              <EditableField
                key={index}
                fieldObject={{
                  ...field,
                  defaultValue: deposit[field.fieldName],
                }}
                onSubmit={handleChange}
              />
            );
          })}
        </div>
      </SimpleDialogDemo>
    );
  }

  return showFormToAdd;
}
