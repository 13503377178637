import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { getEventsAcccount } from "../../../../data-store/actions/events-actions";
import { getBusinessParticipants } from "../../../../data-store/actions/organizers-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ParticipantsData(props) {
  const { businessId } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventsAccount = useSelector((state) => state.events.eventsAccount);
  const businessParticipants = useSelector(
    (state) => state.organizer.businessParticipants
  );

  const businesNew = "volare";
  console.log("businessId", businessId);
  useEffect(() => {
    dispatch(getBusinessParticipants(businesNew, 1000000));
  }, []);

  const classes = useStyles();

  let dollarUSLocale = Intl.NumberFormat("en-US");
  let totalTable = 0;
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Participantes
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID de Evento</TableCell>
              <TableCell align="center">Evento</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Nombres</TableCell>
              <TableCell align="center">Apellidos</TableCell>
              <TableCell align="center">Correo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businessParticipants &&
              Object.values(businessParticipants).map((participant, index) => (
                <TableRow key={index}>
                  <TableCell align="center" scope="row">
                    {participant.iDEvento}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {participant.nombreEvento}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {participant.fechaEvento}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {participant.nombres}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {participant.apellidos}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {participant.correo}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
