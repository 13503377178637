import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import timingConfig from "../timingConfig.json";
import {
  createCategory,
  createCategoryTiming,
  createCategoryTimingAsolutes,
} from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateAbsolutesCategory(props) {
  const { subEventIndex, subEvent, gender } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  let timingConfigObject = timingConfig.categoriaAbsolutos;

  // timingConfigObject["1nombreCategoria"].defaultValue="Absolutos";
  // timingConfigObject["1nombreCategoria"].defaultValue="Absolutos";

  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);

    dispatch(
      createCategoryTimingAsolutes(
        "evento_" + eventDetail.iDEvento,
        data,
        userID,
        eventSubEvents,
        subEventIndex,
        gender
      )
    );
  }
  function deleteAbsolutes() {
    dispatch(
      createCategoryTimingAsolutes(
        "evento_" + eventDetail.iDEvento,
        { cantidadGanadores: 0, nombreCategoria: "" },
        userID,
        eventSubEvents,
        subEventIndex,
        gender,
        true
      )
    );
  }

  const haveAbsolutes =
    eventSubEvents &&
    eventSubEvents[subEventIndex] &&
    eventSubEvents[subEventIndex].ramaCat &&
    eventSubEvents[subEventIndex].ramaCat[gender] &&
    eventSubEvents[subEventIndex].ramaCat[gender].categorias &&
    eventSubEvents[subEventIndex].ramaCat[gender].categorias["categoria_0"]
      ? true
      : false;

  console.log(
    "eventSubEvents1",
    eventSubEvents,
    subEventIndex,
    gender,
    haveAbsolutes
  );

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Agregar categoría de absolutos
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={timingConfigObject}
              submitButtonText={"Crear Categoría Absolutos"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  if (haveAbsolutes) {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} style={{ padding: 20 }}>
          <Typography gutterBottom variant={"h5"} component="h2">
            Ganadores Absolutos:{" "}
            {
              eventSubEvents[subEventIndex].ramaCat[gender].categorias[
                "categoria_0"
              ].cantidadGanadores
            }{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 20 }}>
          <Button variant="contained" onClick={() => deleteAbsolutes()}>
            Eliminar premiación de absolutos
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear Categoría Absolutos"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
