import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import createEventConfig from "./EventCreate/createEventconfig.json";
import { updateEventAttribute } from "../../../../data-store/actions/events-actions";
import EditableField from "../../../EditableField/EditableField";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventUpdateGeneralData() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const createEventObject = createEventConfig.detalleEvento;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "detalleEvento/",
        value
      )
    );
  }
  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Datos generales del evento
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {Object.values(createEventObject).map((field, index) => {
              return (
                <EditableField
                  key={index}
                  fieldObject={{
                    ...field,
                    defaultValue:
                      eventDetail[field.fieldName] !== undefined
                        ? eventDetail[field.fieldName]
                        : "",
                  }}
                  onSubmit={handleChange}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
