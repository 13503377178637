import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { Line } from "react-chartjs-2";

const DashboardRegistryDispersion = ({ jsonDiaInscrito }) => {
  var dataDispersion = {
    datasets: [
      {
        label: "Inscritos por día",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Inscritos Acumulados",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
    labels: [],
  };
  let acumulado = 0;
  for (var i in jsonDiaInscrito) {
    acumulado = acumulado + jsonDiaInscrito[i];
    dataDispersion.labels.push(i);
    dataDispersion.datasets[0].data.push(jsonDiaInscrito[i]);
    dataDispersion.datasets[1].data.push(acumulado);
  }

  return (
    <Paper style={{ padding: 20, margin: 5 }}>
      <Typography variant={"h2"} color="primary">
        Dispersión de inscripciones
      </Typography>

      <div className="col s12">
        <Line data={dataDispersion} />
      </div>
    </Paper>
  );
};

export default DashboardRegistryDispersion;
