import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import timestampToOnlyDate from "./timestampToOnlyDate";
import SimpleDialogDemo from "../../Modals/SimpleDialog";

//import SimpleDialogDemo from "../../../";

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: "80%",
  },
}));

export function generateReport({ data, fields }) {
  // data should be an Array of Objects with all necessary data for report
  // fields should be Array of Objects in order they should appear in report
  // ej. [{label: "name", path: data.user.name}, {label: "lastname", path: data.user.lastname}]

  function deepFind(obj, path) {
    const paths = path.split(".");
    let current = obj;
    let i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }

  let labels = [];
  fields.forEach((field) => labels.push(field.label));

  let report = [];
  console.log("data", data);
  data.forEach((item) => {
    let row = [];
    fields.forEach((field) => {
      if (field.path) {
        row.push(deepFind(item, field.path));
      } else if (field.function) {
        console.log(field);
        console.log(item);
        row.push(field.function(item[field.atribute]));
      }
    });
    report.push(row);
  });

  console.log("universal report generator:", { labels, report });
  return {
    labels,
    report,
    itemIdentifier: fields[0] ? fields[0].path : undefined,
  };
}

export function getTotalPurchases(purchase) {
  let total = 0;
  purchase.items.forEach((item) => {
    total += item.unit_price * (item.quantity || 1);
  });
  let totalString = total.toString();
  return totalString;
}

export function UserPurchaseDetail(item) {
  const labels = ["Item", "Producto", "Cantidad", "Precio"];
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          color="primary"
          variant="subtitle2"
          component="h1"
          style={{ padding: 10 }}
        >
          {item &&
            item.athleteAccount &&
            `${item.athleteAccount.name} ${item.athleteAccount.lastName}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          color="primary"
          variant="subtitle2"
          component="h1"
          style={{ padding: 10 }}
        >
          {item &&
            item.date &&
            `Registrado desde ${timestampToOnlyDate(item.date * 1000)}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer style={{ width: "100%" }} component={Paper}>
          <Table aria-label="simple table" style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                {labels.map((label) => (
                  <TableCell>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {item &&
                item.items &&
                item.items.map((item) => (
                  <TableRow>
                    <TableCell>{item.itemID}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.unit_price}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export function ReportTable(props) {
  const {
    report,
    labels,
    data,
    reportType,
    itemIdentifier,
    showButtonDetail,
    detailComponent,
  } = props;

  function getDetailComponent(reportType, item) {
    if (detailComponent !== undefined) {
      return detailComponent(item);
    }
    if (reportType === "compras") {
      return UserPurchaseDetail(item);
    }
    if (reportType === "code") {
      return detailComponent(item);
    }

    return;
  }

  const classes = useStyles();
  return report.length > 0 ? (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {labels.map((column) => (
              <TableCell key={column} align="left">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {report.map((row, i) => {
            let rowArr = [];
            const itemID = row[0];
            const item = data.find((el) => el[itemIdentifier] === itemID);

            row.forEach((column, j) => {
              rowArr.push(
                <TableCell key={`${i}-${j}`}>
                  {typeof column == "boolean" ? (column ? "SI" : "NO") : column}
                </TableCell>
              );
            });
            if (showButtonDetail) {
              rowArr.push(
                <TableCell key={"detalle"}>
                  <SimpleDialogDemo buttonText={"Ver Detalles"}>
                    {getDetailComponent(reportType, item)}
                  </SimpleDialogDemo>
                </TableCell>
              );
            }

            return <TableRow key={row}>{rowArr}</TableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
