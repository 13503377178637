import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";

import EditableField from "../../../EditableField/EditableField";
import { setCustomView } from "../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CustomViewsConfig(props) {
  const { view, callback, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const classes = useStyles();

  function handleChange(value) {
    console.log("miniTheme", value);
    console.log("miniTheme", view);
    const page = JSON.parse(JSON.stringify(view));
    if (page.miniTheme === undefined) {
      page.miniTheme = {};
    }

    page.miniTheme[Object.keys(value)[0]] = Object.values(value)[0];
    console.log("miniTheme", page);

    callback(page, index);
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Editar Vista
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {form &&
              Object.values(form).map((field, index) => {
                const defaultValue =
                  view &&
                  view.miniTheme !== undefined &&
                  view.miniTheme[field.fieldName] !== undefined
                    ? view.miniTheme[field.fieldName]
                    : field.defaultValue;

                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Editar Vista"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
const form = {
  "1name": {
    label: "Nombre de la vista",
    errorMessage: "Campo Requerido",
    fieldName: "name",
    type: "text",
    defaultValue: "Por definir",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "1type": {
    label: "Tipo de vista",
    errorMessage: "Campo Requerido",
    fieldName: "viewType",
    type: "select",
    defaultValue: "Por definir",
    required: true,
    regex: "",
    textSize: "h6",
    optionsArray: [
      {
        value: "athleteResults",
        label: "Resultado de atletas",
      },
      {
        value: "lastAthleteArrive",
        label: "Mostrar el último atleta en llegar",
      },
      {
        value: "firstAnd5LastDiference",
        label:
          "Mostrar el primer atleta y los 4 últimos con diferencia de tiempo",
      },
    ],
  },
  "1height": {
    label: "Alto en milímetros (ejm 100)",
    errorMessage: "Campo Requerido",
    fieldName: "height",
    type: "text",
    defaultValue: 500,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "1width": {
    label: "Ancho en milímetros (ejm 100)",
    errorMessage: "Campo Requerido",
    fieldName: "width",
    type: "text",
    defaultValue: 500,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "1heightImage": {
    label: "Alto en milímetros de la imagen a descargar (ejm 100)",
    errorMessage: "Campo Requerido",
    fieldName: "heightImage",
    type: "text",
    defaultValue: 500,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "1widthImage": {
    label: "Ancho en milímetros  de la imagen a descargar (ejm 100p)",
    errorMessage: "Campo Requerido",
    fieldName: "widthImage",
    type: "text",
    defaultValue: 500,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "1padding": {
    label: "Separación del margen del cotenedor en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "padding",
    type: "text",
    defaultValue: 10,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "1paddingBox": {
    label: "Separación de cada caja en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "paddingBox",
    type: "text",
    defaultValue: 10,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "2fontFamily": {
    label: "Fuente por defecto",
    errorMessage: "Campo Requerido",
    fieldName: "fontFamily",
    type: "select",
    defaultValue: "p",
    required: false,
    regex: "",
    textSize: "h6",
    optionsArray: [
      {
        value: "MavenProRegular, MavenProBold,Helvetica, Arial",
        label: "Maven pro",
      },
      {
        value: "Forza, MavenProBold,Helvetica, Arial",
        label: "Forza",
      },
      {
        value: "Arial,Helvetica",
        label: "Arial",
      },
      {
        value: "Helvetica,Arial",
        label: "Helvetica",
      },
      {
        value: "Redig,Arial",
        label: "Redig",
      },
      {
        value: "BebasNeue-Regular,Arial",
        label: "BebasNeue-Regular",
      },
    ],
  },
  "1fontSize": {
    label: "Tamaño de la fuente por defecto en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "fontSize",
    type: "text",
    defaultValue: 20,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "3background": {
    label: "Color de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "background",
    type: "color",
    defaultValue: "#ffffff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "2backgroundImage": {
    label: "Imagen de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "backgroundImage",
    type: "photo",
    defaultValue: "",
    required: false,
    regex: "",
    textSize: "h6",
  },
  "3columns": {
    label: "Número de Columnas",
    errorMessage: "Campo Requerido",
    fieldName: "columns",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 1,
  },
  "3columnsMargin": {
    label: "Margen derecho de de Columnas en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "columnsMargin",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "4rows": {
    label: "Número filas",
    errorMessage: "Campo Requerido",
    fieldName: "rows",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 1,
  },
  "3rowMargin": {
    label: "Margen inferior de las filas en milímetros",
    errorMessage: "Campo Requerido",
    fieldName: "rowMargin",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "3topMargin": {
    label: "Margen superior de la hoja en milímetros (para hojas de etiquetas)",
    errorMessage: "Campo Requerido",
    fieldName: "topMargin",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
  "3leftMargin": {
    label:
      "Margen izquierdo de la hoja en milímetros (para hojas de etiquetas)",
    errorMessage: "Campo Requerido",
    fieldName: "leftMargin",
    type: "text",
    defaultValue: 1,
    required: true,
    regex: "",
    textSize: "h6",
    min: 0,
  },
};
