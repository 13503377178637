import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
  },
}));

export default function CSVButton(props) {
  const { report, labels } = props;
  const classes = useStyles();
  let reportWithLabels = [labels, ...report];
  return report.length > 0 ? (
    <Button variant="contained" color="primary" className={classes.button}>
      <CSVLink className={classes.button} data={reportWithLabels}>
        Descarga CSV
      </CSVLink>
    </Button>
  ) : null;
}
