import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import { createEventAttribute } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateInput(props) {
  const { inputGroup } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  let createEventObject = createEventConfig.product;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function onSubmit(data) {
    console.log(
      "formularioProducto",
      data,
      registryConfiguration.gruposFormularios
    );
    let indexNumber = 0;
    if (registryConfiguration.productosAdicionales !== undefined) {
      indexNumber =
        Object.keys(registryConfiguration.productosAdicionales).length + 1;
    }
    const route = `inscripcionesConfiguracion/productosAdicionales/producto_${indexNumber}/`;

    const newInput = {
      anchoCampo: data.anchoCampo || "s12 m12 l12",
      codigoProducto: indexNumber,
      color: "",
      colorText: "",
      imagenProducto: data.imagenProducto || null,
      descripcionProducto: data.descripcionProducto || "Descripción",
      hideInPublic: data.hideInPublic || false,
      hideInscriptor: data.hideInscriptor || false,
      hideMasterCliente: data.hideMasterCliente || false,
      hideMasterOrganizador: data.hideMasterOrganizador || false,
      maxCantidad: data.maxCantidad || 1,
      nombreProducto: data.nombreProducto || "Nombre del producto",
      precioProducto: data.precioProducto || 100,
      formularioProducto: data.formularioProducto,
    };

    // if (
    //   registryConfiguration.gruposFormularios &&
    //   registryConfiguration.gruposFormularios[data.formularioProducto]
    // ) {
    //   newInput.formularioProducto =
    //     registryConfiguration.gruposFormularios[data.formularioProducto];
    // }

    console.log("formularioProducto", newInput);

    dispatch(
      createEventAttribute("evento_" + eventDetail.iDEvento, route, newInput)
    );
    for (var i in eventSubEventsRegistry) {
      const newRoute = `modalidadesInscripcion/${i}/configModalidad/productosAdicionales/producto_${indexNumber}/`;
      dispatch(
        createEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute,
          newInput
        )
      );
    }
  }

  if (registryConfiguration.gruposFormularios) {
    createEventObject.formularioProducto.optionsArray = Object.keys(
      registryConfiguration.gruposFormularios
    ).map((a) => ({ value: a, label: a }));
    createEventObject.formularioProducto.optionsArray.push({
      value: null,
      label: "Ninguno",
    });
  }
  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea un nuevo producto
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={"Crear un Producto"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear un Producto"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
