import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper, IconButton } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createCodeConfig from "./createCodeConfig.json";
import {
  updateEventAttribute,
  createEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { eventCodes } from "../../../../../data-store/actions/registry-actions";
import CodeUses from "./CodeUses";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "100%",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateCode(props) {
  const { codesGroup, code } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);

  const createCodeObject = createCodeConfig.codeTypesSelection;
  const createCodeConfigObject = createCodeConfig;

  const classes = useStyles();

  useEffect(() => {
    if (!codesList) dispatch(eventCodes("evento_" + eventDetail.iDEvento));
  }, []);

  // function handleChange(value) {
  //   console.log(value);

  //   console.log(value);
  //   // dispatch(
  //   //   updateEventAttribute(
  //   //     "evento_" + eventDetail.iDEvento,
  //   //     "inscripcionesConfiguracion/gruposFormularios/" +
  //   //       codeGroup +
  //   //       "/" +
  //   //       index +
  //   //       "/",
  //   //     value
  //   //   )
  //   // );
  //   // registrar campo en modalidades
  //   if (codeGroup !== "general") {
  //     for (var i in eventSubEventsRegistry) {
  //       const newRoute = `modalidadesInscripcion/${i}/configModalidad/camposAdicionalesModalidad/${codeGroup}/`;
  //       // dispatch(
  //       //   updateEventAttribute(
  //       //     "evento_" + eventDetail.iDEvento,
  //       //     newRoute + "/" + index + "/",
  //       //     value
  //       //   )
  //       // );
  //     }
  //   } else {
  //     const newRoute = `inscripcionesConfiguracion/formulario/`;
  //     // dispatch(
  //     //   updateEventAttribute(
  //     //     "evento_" + eventDetail.iDEvento,
  //     //     newRoute + "/" + index + "/",
  //     //     value
  //     //   )
  //     // );
  //   }
  // }

  function handleChange(data) {
    console.log("UpdateCode", data);

    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "codigosDescuento/codigos/" + code.code + "/",
        data
      )
    );
  }

  console.log("code", code);
  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {code && code.code}
            </Typography>
            <Typography gutterBottom variant={"h4"} component="h2">
              {code && code.typeCode}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {code && code.codeGroup})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {code &&
              Object.values(createCodeConfigObject.updateCode).map(
                (field, index) => {
                  return (
                    <EditableField
                      key={index}
                      fieldObject={{
                        ...field,
                        defaultValue: code[field.fieldName],
                      }}
                      onSubmit={handleChange}
                    />
                  );
                }
              )}
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <Typography gutterBottom variant={"h5"} component="h2">
              Usos
            </Typography>
            <CodeUses code={code} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
