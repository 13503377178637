import React from 'react';
import Grid from '@material-ui/core/Grid';

import {formatEventDate} from '../../../services/utils';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom'

const EventCartEmpty = () => (

	<Grid item xs={4} >
			<Card >
				<CardContent style={{height:'250px', backgroundColor:"#FFFFFF"}}>
				</CardContent>
				<CardContent style={{height:'250px'}}>
					<Typography gutterBottom variant="h5" component="h2" style={{backgroundColor:"#FFFFFF"}}>
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">

					</Typography>
				</CardContent>
				<CardActions>
					<Button size="large" color="primary">
					</Button>
				</CardActions>
			</Card>
	</Grid>

)

export default EventCartEmpty;
