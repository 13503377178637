import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";

import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { useParams } from "react-router-dom";
import {
  getOrganizerWeb,
  setOrganizerWebAttributes,
  setOrganizerWebView,
  showSnackbar,
} from "../../../../data-store/actions/organizers-actions";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import LayoutResolverEditCopy from "../../../Landing/LayoutContainers/LayoutResolverEditCopy";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import EditableField from "../../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function GenericWebSections(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const organizerWeb = useSelector((state) => state.organizer.organizerWeb);
  const organizerWebCopy = { ...organizerWeb };
  let { domain, businessId } = useParams();
  const [container, setContainer] = useState("home");

  console.log("organizerWebCopy", organizerWebCopy);

  const classes = useStyles();
  useEffect(() => {
    dispatch(getOrganizerWeb(domain, businessId));
  }, [domain, businessId]);

  function createViewObject() {
    return {
      descripcion: {
        label: "Descripción",
        errorMessage: "Campo Requerido",
        fieldName: "description",
        type: "text",
        defaultValue: "",
        required: true,
        regex: "",
        textSize: "h6",
      },
      available: {
        label: "Vista pública",
        errorMessage: "Campo Requerido",
        fieldName: "available",
        type: "switch",
        defaultValue: true,
        required: true,
        regex: "",
        textSize: "h6",
      },
    };
  }
  function createViewPush(data) {
    console.log("create", data);

    if (organizerWebCopy.containers[data.url]) {
      console.log("create", "url ya existente");
      dispatch(
        showSnackbar(true, "URL ya existente, puedes editar la actual", 8000)
      );
      return false;
    }
    const objectView = {
      description: data.description,
      available: data.available,
      url: data.url,
      copyFrom: data.copyFrom,
      miniTheme:
        organizerWebCopy !== undefined &&
        organizerWebCopy.containers !== undefined &&
        organizerWebCopy.containers[data.copyFrom] !== undefined
          ? organizerWebCopy.containers[data.copyFrom].miniTheme
          : null,
      views:
        organizerWebCopy !== undefined &&
        organizerWebCopy.containers !== undefined &&
        organizerWebCopy.containers[data.copyFrom] !== undefined
          ? organizerWebCopy.containers[data.copyFrom].views
          : [],
    };
    console.log("create", objectView);
    dispatch(setOrganizerWebView(businessId, domain, data.url, objectView));
  }
  function updateViewObject(url, view) {
    console.log("view", url, view, view.description);
    return {
      url: {
        label: "Url de la vista",
        errorMessage: "Campo Requerido",
        fieldName: "url",
        type: "hidden",
        defaultValue: url,
        required: true,
        regex: "",
        textSize: "h6",
      },
      descripcion: {
        label: "Descripción",
        errorMessage: "Campo Requerido",
        fieldName: "description",
        type: "text",
        defaultValue: view.description || "",
        required: true,
        regex: "",
        textSize: "h6",
      },
      copyFrom: {
        label: "Copiar Vista",
        errorMessage: "Campo Requerido",
        fieldName: "copyFrom",
        type: "select",
        defaultValue: url || "",
        required: false,
        regex: "",
        textSize: "h6",
        optionsArray:
          organizerWebCopy !== undefined &&
          organizerWebCopy.containers !== undefined
            ? Object.keys(organizerWebCopy.containers).map((i) => ({
                value: i,
                label: i,
              }))
            : [],
      },
      available: {
        label: "Vista pública",
        errorMessage: "Campo Requerido",
        fieldName: "available",
        type: "switch",
        defaultValue: view.available,
        required: true,
        regex: "",
        textSize: "h6",
      },
    };
  }
  function updateViewPush(data) {
    console.log("create", data);

    const objectView = {
      description: data.description,
      available: data.available,
      url: data.url,
      copyFrom: data.copyFrom,
      views:
        organizerWebCopy !== undefined &&
        organizerWebCopy.containers !== undefined &&
        organizerWebCopy.containers[data.copyFrom] !== undefined
          ? organizerWebCopy.containers[data.copyFrom].views
          : [],
    };
    console.log("create", objectView);
    dispatch(setOrganizerWebView(businessId, domain, data.url, objectView));
  }
  function titleAndIconObject() {
    return {
      title: {
        label: "Título de la web",
        errorMessage: "Campo Requerido",
        fieldName: "title",
        type: "text",
        defaultValue: organizerWebCopy.title || "",
        required: true,
        regex: "",
        textSize: "h6",
      },
      icon: {
        label: "Icono de la web",
        errorMessage: "Campo Requerido",
        fieldName: "icon",
        type: "file",
        defaultValue: organizerWebCopy.icon || "",
        required: false,
        regex: "",
        textSize: "h6",
      },
    };
  }
  function updateTitleAndIcon(data) {
    console.log("create", data);

    dispatch(setOrganizerWebAttributes(businessId, domain, data));
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Secciones genéricas
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h5"} component="h2">
          Las secciones genéricas son usadas para crear partes de la web que van
          a ser utilizadas en varias vistas por ejm el menú de navegación. Con
          esto al editar el menú genérico se ajusta de forma autómática en todas
          las vistas que lo tengas incorporado.
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <SimpleDialogDemo buttonText="Crear Sección Genérica">
          <div style={{ margin: "30px" }}>
            <Typography gutterBottom variant={"h5"} component="h5">
              Secciones Genéricas, pueden ser usadas en cualquier vista
            </Typography>
            <FormRender
              callback={createViewPush}
              formObject={createViewObject()}
              submitButtonText={"Crear sección"}
            />
          </div>
        </SimpleDialogDemo>
      </Grid>

      <Grid item xs={12}>
        <List style={{ width: "100%" }}>
          {organizerWeb &&
            organizerWebCopy &&
            organizerWebCopy.generics &&
            Object.keys(organizerWebCopy.generics).map((i) => (
              <div style={{ margin: 20 }}>
                <ListItem>
                  <ListItemIcon>
                    <WebIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    color="secondary"
                    primary={organizerWebCopy.generics[i].description || ""}
                    secondary={"Ruta: " + i}
                  />
                  <ListItemSecondaryAction>
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={6}>
                        <SimpleDialogDemo
                          buttonText="Abrir Vista"
                          fullScreen={true}
                        >
                          {organizerWeb &&
                            organizerWebCopy &&
                            organizerWebCopy.generics &&
                            organizerWebCopy.generics[i] && (
                              <LayoutResolverEditCopy
                                page={organizerWebCopy.generics[i]}
                                organizerWeb={domain}
                                container={i}
                                businessId={businessId}
                              />
                            )}
                        </SimpleDialogDemo>
                      </Grid>
                      <Grid item xs={6}>
                        <SimpleDialogDemo buttonText="Editar Configuración de la Vista">
                          <div style={{ margin: "30px" }}>
                            <Typography
                              gutterBottom
                              variant={"h5"}
                              component="h5"
                            >
                              Editar la vista {i}
                            </Typography>
                            <FormRender
                              callback={updateViewPush}
                              formObject={updateViewObject(
                                i,
                                organizerWebCopy.containers[i]
                              )}
                              submitButtonText={"Editar"}
                            />
                          </div>
                        </SimpleDialogDemo>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
                <hr />
              </div>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}
