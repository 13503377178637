import React from "react";
import { connect } from "react-redux";
import { helperMultisort } from "../../../services/utils";
import {
  ResultadosGanadores,
  ResultadosGenerales,
  ResultadosRama,
  ResultadosCategoria,
  controlesArrayFunc,
  ImprimirDatos,
} from "./reportsLogic";

import Row from "./RowResultReport";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";
import { Button } from "@material-ui/core";

class ResultsReportNewAUTHView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAll: false,
    };

    var filasTotal = this.getReport(true, false);
    this.state = {
      filas: filasTotal.slice(0, 20),
      filasTotal: filasTotal,
      keepRendering: false,
    };
  }

  componentDidMount = () => {
    console.log("did mount");
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUpdate = () => {
    //this.getReport(false);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({ keepRendering: false });
  };

  handleScroll = () => {
    //var el = document.getElementById("dialogReport");
    //window.scrollBy(100, 100);
    //console.log(window.pageYOffset);

    console.log("handelScroll");
    this.setState({
      keepRendering: true,
    });
    this.recursive();
  };
  viewAllFunction = () => {
    this.setState({
      viewAll: !this.state.viewAll,
    });
  };

  recursive = () => {
    setTimeout(() => {
      let hasMore = this.state.filas.length + 1 < this.state.filasTotal.length;
      console.log(hasMore);
      this.setState((prev, props) => ({
        filas: this.state.filasTotal.slice(0, prev.filas.length + 5),
      }));
      // bloqueo la recursividad y se activa solo si continua haciendo scroll
      //if (hasMore && this.state.keepRendering) this.recursive();
    }, 0);
  };

  getReport = (init, todos) => {
    var iDEvento = this.props.eventDetail.iDEvento;

    let checkControls = false;
    if (this.props.controles && this.props.puntosControl != undefined) {
      checkControls = true;
    }
    if (!this.props.controles) {
      checkControls = true;
    }
    if (this.props.eventResults != undefined && checkControls) {
      var resultadosOrdendos;
      if (this.props.reportResultType === 4) {
        resultadosOrdendos = ResultadosGanadores(this.props.eventResults);
      } else if (this.props.reportResultType === 1) {
        resultadosOrdendos = ResultadosGenerales(this.props.eventResults);
      } else if (this.props.reportResultType === 2) {
        resultadosOrdendos = ResultadosRama(this.props.eventResults);
      } else if (this.props.reportResultType === 3) {
        resultadosOrdendos = ResultadosCategoria(this.props.eventResults);
      } else {
        resultadosOrdendos = this.props.eventResults;
      }
    }
    var filas = ImprimirDatos(
      resultadosOrdendos,
      this.props.reportResultType,
      true,
      this.props.controles,
      true,
      iDEvento,
      this.props.eventConfiguration.timingConfig
    );

    if (init) {
      return filas;
    } else {
      this.setState((prev, props) => ({
        filas: filas.slice(0, prev.filas.length + 20),
        filasTotal: filas,
      }));
    }
  };

  render() {
    console.log("reportType", this.props.reportResultType);
    var element = "";

    if (
      this.props.eventDetail != undefined &&
      (this.props.puntosControl != undefined || !this.props.controles)
    ) {
      var controlesArray;
      if (this.props.controles) {
        controlesArray = controlesArrayFunc(this.props.puntosControl);
      }

      element = (
        <div>
          {/* {this.props.reportResultType !== 4 && this.state.viewAll && (
            <Button
              style={{ width: "500px", padding: "10px", marging: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => this.viewAllFunction()}
            >
              Ocultar no finalizados
            </Button>
          )}
          {this.props.reportResultType !== 4 && !this.state.viewAll && (
            <Button
              style={{ width: "500px", padding: "10px", marging: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => this.viewAllFunction()}
            >
              Mostar Todos los atletas
            </Button>
          )} */}

          <Table
            style={{
              backgroundImage: `url(${Background})`,
              backgroundRepeat: "no-repeat",
            }}
            size="small"
            aria-label="a dense table"
            id="Tabla-1"
            className="bordered centered highlight"
          >
            <TableBody id="Resultados">
              {this.state.filas.map((data, index) => (
                <Row
                  key={index}
                  data={data}
                  controlesArray={controlesArray}
                  controles={this.props.controles}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      );
    } else {
      element = <h5>Cargando</h5>;
    }
    return element;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventResults: state.events.eventResults,
    puntosControl: state.events.puntosControl,
    eventConfiguration: state.events.eventConfiguration,
  };
};

const ResultsReportNewAUTH = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsReportNewAUTHView);

export default ResultsReportNewAUTH;
