import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardPayment from "./CardPayment";
import OxxoPayment from "./OxxoPayment";
import CodePayment from "./CodePayment";
import CashPayment from "./CashPayment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function PaymentsView(props) {
  const {
    callback,
    callbackCode,
    order,
    test,
    currency,
    currencyBase,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  const classes = useStyles();
  const [view, setView] = useState("CASH");

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h6">
          Total a pagar $
        </Typography>
        <Typography color="primary" component="h1" variant="h3">
          {order &&
            order.reduce(function(acc, obj) {
              return (
                acc +
                Math.round(
                  parseFloat(obj.unit_price) *
                    obj.quantity *
                    currency.tipoCambio *
                    100
                ) /
                  100
              );
            }, 0)}{" "}
          {currency.codigo}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "40px", textAlign: "center" }}
      >
        {/* {registryConfiguration.configuracion.formasDePago.codigoDescuento && (
          <CodePayment
            callbackCode={callbackCode}
            currency={currency}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        )} */}
        {/* {registryConfiguration.configuracion.formasDePago.conektaCard
            .disponible && (
            <Button
              onClick={() => setView("CARD")}
              variant="contained"
              color="primary"
              style={{
                height: "150px",
                width: "150px",
                fontSize: "18px",
                margin: "10px",
              }}
            >
              Tarjeta de Crédito o Débito
            </Button>
          )}
          {registryConfiguration.configuracion.formasDePago.conektaOxxoPay
            .disponible && (
            <Button
              onClick={() => setView("OXXO")}
              variant="contained"
              color="primary"
              style={{
                height: "150px",
                width: "150px",
                fontSize: "18px",
                margin: "10px",
              }}
            >
              Pago en Oxxo
            </Button>
          )} */}
      </Grid>

      {view === "CASH" && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "40px", textAlign: "center" }}
        >
          <CashPayment
            currency={currency}
            callback={callback}
            order={order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
            test={false}
          />
        </Grid>
      )}
      {view === "CARD" && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "40px", textAlign: "center" }}
        >
          <Button
            onClick={() => setView(false)}
            variant="contained"
            color="primary"
          >
            Cambiar método de pago
          </Button>

          <CardPayment
            currency={currency}
            currencyBase={currencyBase}
            callback={callback}
            order={order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
            test={false}
          />
        </Grid>
      )}
      {view === "OXXO" && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "40px", textAlign: "center" }}
        >
          <Button
            onClick={() => setView(false)}
            variant="contained"
            color="primary"
          >
            Cambiar método de pago
          </Button>

          <OxxoPayment
            currency={currency}
            currencyBase={currencyBase}
            callback={callback}
            order={order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        </Grid>
      )}
    </Grid>
  );
}
