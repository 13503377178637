import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomViewsConfig from "./CustomViewsConfig";
import BuscadorResultsCustomsViews from "./BuscadorResultsCustomsViews";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

function DragDropContextBoxPlain(props) {
  const { view, dataProp } = props;
  const [viewItems, setViewItems] = useState(view.views);
  const [newItems, setNewItems] = useState(view.views);
  const [showNewItems, setShowNewItems] = useState(false);

  useEffect(() => {
    setViewItems(view.views);
  }, [view]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const downloadJPG = () => {
    htmlToImage
      .toJpeg(document.getElementById("contentView"), {
        canvasHeight: view.miniTheme.heightImage || view.miniTheme.height,
        canvasWidth: view.miniTheme.widthImage || view.miniTheme.width,
        height: view.miniTheme.height,
        width: view.miniTheme.width,
      })
      .then(function(dataUrl) {
        var link = document.createElement("a");
        link.download = "prueba de imagen.jpeg";
        link.href = dataUrl;
        link.click();
      });
  };

  const style = {
    backgroundColor: view.miniTheme.background || "",
    height: view.miniTheme.height + "mm",
    width: view.miniTheme.width + "mm",
    padding: view.miniTheme.padding + "mm",
    fontFamily: view.miniTheme.fontFamily,
    fontSize: view.miniTheme.fontSize,
  };
  function callback(data, action) {
    setNewItems(findData(data));
    setShowNewItems(true);

    if (action === "print") {
      console.log(data);

      setTimeout(() => {
        handlePrint();
      }, 500);
      //setShowNewItems(false);
    }
    if (action === "jpg") {
      setTimeout(() => {
        htmlToImage
          .toJpeg(document.getElementById("contentView"), {
            quality: 1,
            canvasHeight: view.miniTheme.heightImage || view.miniTheme.height,
            canvasWidth: view.miniTheme.widthImage || view.miniTheme.width,
          })
          .then(function(dataUrl) {
            var link = document.createElement("a");
            link.download = data.num + " " + data.nombre + ".jpeg";
            link.href = dataUrl;
            link.click();
          });
      }, 800);
      //setShowNewItems(false);
    }
  }

  function findData(data) {
    let newItems = [];
    for (var i in viewItems) {
      console.log(viewItems[i]);
      let text = viewItems[i].text;

      for (var e in data) {
        console.log("[" + e + "]", data[e]);
        if (typeof data[e] !== "object") {
          text = text.replace("[" + e + "]", data[e]);
        }
      }
      newItems.push({ ...viewItems[i], text: text });
    }
    return newItems;
  }
  function findDataArray(dataArray) {
    let newItems = [];
    for (var i in viewItems) {
      console.log(viewItems[i]);
      let text = viewItems[i].text;
      let indexData = text.split("#")[0];
      let data = null;

      if (indexData >= 0) {
        data = dataArray[indexData];
      }

      for (var e in data) {
        console.log("[" + e + "]", data[e]);
        if (typeof data[e] !== "object") {
          text = text.replace("[" + e + "]", data[e]);
        }
      }
      newItems.push({ ...viewItems[i], text: text });
    }
    return newItems;
  }
  return (
    <div>
      <BuscadorResultsCustomsViews callback={callback} />
      <Button
        variant="contained"
        color="primary"
        onClick={handlePrint}
        style={{ margin: "20px" }}
      >
        Imprimir vista final
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={downloadJPG}
        style={{ margin: "20px" }}
      >
        Descargar JPG
      </Button>

      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          ...style,
          backgroundImage: "url(" + view.miniTheme.backgroundImage + ")",
        }}
        id="contentView"
        ref={componentRef}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="task-container"
          >
            {showNewItems &&
              newItems.map((viewItem, index) => (
                <Grid item xs={viewItem.width || 12} sm={viewItem.width || 12}>
                  <div
                    style={{
                      padding: view.miniTheme.paddingBox,
                      backgroundColor: viewItem.background,
                      backgroundImage: "url(" + viewItem.backgroundImage + ")",
                      fontSize: viewItem.fontSize || view.miniTheme.fontSize,
                      fontFamily: view.miniTheme.fontFamily,
                      color: viewItem.color || view.miniTheme.color,
                      textAlign: viewItem.position || "center",
                    }}
                  >
                    {viewItem.text}
                  </div>
                </Grid>
              ))}

            {!showNewItems &&
              viewItems.map((viewItem, index) => (
                <Grid item xs={viewItem.width || 12} sm={viewItem.width || 12}>
                  <div
                    style={{
                      padding: viewItem.padding
                        ? viewItem.padding + "mm"
                        : "0mm",
                      backgroundColor: viewItem.background,
                      backgroundImage: "url(" + viewItem.backgroundImage + ")",
                      fontSize: viewItem.fontSize
                        ? viewItem.fontSize + "mm"
                        : view.miniTheme.fontSize + "mm",

                      fontFamily: view.miniTheme.fontFamily,
                      color: viewItem.color || view.miniTheme.color,
                      textAlign: viewItem.position || "center",
                    }}
                  >
                    {viewItem.text}
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DragDropContextBoxPlain;
