import React, { Suspense, lazy, Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import EventCartEmpty from "../Modals/EventCartEmpty";
import AuthorizedUsersCard from "./AuthorizedUsersCard";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import EventCreateForm from "./EventsConfig/EventCreate/EventCreateForm";
import DownloadEventsList from "./DownloadEventsList";
import EventsFinder from "./EventsFinder";
import { Typography } from "@material-ui/core";

class AuthorizedUsersListView extends React.Component {
  state = {
    eventos: 21,
    eventsFilter: null,
  };

  eventsFbToArray = (events, atleteAccount, cortarLista) => {
    var eventosArray = [];

    var EventosAutorizados = [];
    var EventosAutorizadosJuez = [];

    if (atleteAccount) {
      for (var i in atleteAccount.eventosAutorizados) {
        if (atleteAccount.eventosAutorizados[i].autorizado) {
          EventosAutorizados.push(i);
        }
      }
      for (var i in atleteAccount.eventosAutorizadosJuez) {
        if (atleteAccount.eventosAutorizadosJuez[i]) {
          EventosAutorizadosJuez.push(i);
        }
      }
    }

    for (var i in events) {
      if (
        EventosAutorizados.indexOf(i) >= 0 ||
        EventosAutorizadosJuez.indexOf(i) >= 0
      ) {
        eventosArray.push(events[i]);
      }
    }
    eventosArray.sort(
      (a, b) => new Date(b.fechaEvento) - new Date(a.fechaEvento)
    );
    var final = eventosArray.slice(0, 1000000000);

    if (cortarLista) {
      final = eventosArray.slice(0, this.state.eventos);
    }
    return final;
  };

  setEvetnsFilter = (eventsFilerCallback) => {
    console.log("eventsFilerCallback", eventsFilerCallback);
    this.setState({ eventsFilter: eventsFilerCallback });
  };
  render() {
    if (this.props.atleteAccount == null) {
      return <h5>Inicia sesión para continuar</h5>;
    }
    return (
      <Grid
        container
        direction="row"
        justify="left"
        alignItems="left"
        spacing={2}
      >
        <Grid item xs={12} sm={8}>
          <DownloadEventsList />
          <Typography variant={"h2"} color="primary" align="center">
            Eventos Autorizados
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <EventCreateForm />
        </Grid>
        <Grid item xs={12} sm={12}>
          <EventsFinder
            callback={this.setEvetnsFilter}
            events={this.eventsFbToArray(
              this.props.events,
              this.props.atleteAccount,
              false
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            direction="row"
            justify="left"
            alignItems="left"
            spacing={2}
          >
            {this.state.eventsFilter
              ? this.state.eventsFilter.map((evento) => (
                  <Grid item xs={12} sm={4}>
                    <AuthorizedUsersCard
                      key={evento.iDEvento}
                      evento={evento}
                    />
                  </Grid>
                ))
              : this.eventsFbToArray(
                  this.props.events,
                  this.props.atleteAccount,
                  true
                ).map((evento) => (
                  <Grid item xs={12} sm={4}>
                    <AuthorizedUsersCard
                      key={evento.iDEvento}
                      evento={evento}
                    />
                  </Grid>
                ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          {!this.state.eventsFilter && (
            <ButtonGroup
              variant="contained"
              color="primary"
              fullWidth
              aria-label="full width outlined button group"
            >
              <Button
                onClick={(e) =>
                  this.setState({ eventos: this.state.eventos + 21 })
                }
              >
                Mostar más
              </Button>
              <br />
              <Button
                onClick={(e) =>
                  this.setState({ eventos: this.state.eventos + 1000000000 })
                }
              >
                Mostar todos
              </Button>
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const AuthorizedUsersList = connect(mapStateToProps)(AuthorizedUsersListView);

export default AuthorizedUsersList;
