import { Paper, Typography } from "@material-ui/core";
import React from "react";

const AuthorizedUsersTiming = ({ eventUsers }) => {
  var dataTable = [];

  for (var i in eventUsers) {
    dataTable.push(
      <tr key={i}>
        <td>{eventUsers[i].nombreJuez}</td>
        <td>{eventUsers[i].juezTipo === 1 ? "Master" : "Anotador"}</td>
        <td>{eventUsers[i].correoJuez}</td>
      </tr>
    );
  }

  return (
    <Paper style={{ margin: 5, padding: 20, minHeight: 320 }}>
      <Typography variant={"h4"} align="left">
        Lista de usuarios para el crono
      </Typography>
      <table>
        <thead>
          <tr>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Nombre
              </Typography>
            </th>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Perfil
              </Typography>
            </th>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Correo
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>{dataTable}</tbody>
      </table>
    </Paper>
  );
};
export default AuthorizedUsersTiming;
