import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createCodeConfig from "./createCodeConfig.json";
import {
  eventCodes,
  eventCodesGroups,
} from "../../../../../data-store/actions/registry-actions";
import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import EventSpecificGender from "../EventSpecificGender";
import CreateCode from "./CreateCode";
import CodesGroup from "./CodesGroup";
import UpdateCode from "./UpdateCode";
import OpenWithIcon from "@material-ui/icons/OpenWith";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EventDiscountCodesContainer(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);

  const classes = useStyles();

  useEffect(() => {
    if (!codesGroups)
      dispatch(eventCodesGroups("evento_" + eventDetail.iDEvento));
  }, []);

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h2"} component="h2">
              Códigos de descuento o prepago
            </Typography>
            <SimpleDialogDemo buttonText={"Crear un grupo de códigos"}>
              <CreateCode inputGroup={null} />
            </SimpleDialogDemo>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {codesGroups &&
              Object.keys(codesGroups).map((index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={"Grupo: " + codesGroups[index].codeGroup}
                      secondary={"Tipo: " + codesGroups[index].typeCode}
                    />
                    <ListItemAvatar>
                      <SimpleDialogDemo
                        buttonText={"Editar"}
                        fullScreen={true}
                        icon={<OpenWithIcon />}
                      >
                        <CodesGroup
                          codeGroup={codesGroups[index]}
                          code={codesGroups[index].code}
                          index={index}
                        />
                      </SimpleDialogDemo>
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
