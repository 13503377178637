import React from "react";
import { connect } from "react-redux";
import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import {
  completeNull,
  modalidadCateogoriaNameByCode,
  calcularEdad,
} from "../../../../../services/utils";

import { timestampToDate } from "../../../../../services/utils";

import ParticipantDetailContainer from "../ParticipantDetail/ParticipantDetailContainer";
import FormDialog from "../../../Modals/FormDialog";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SimpleDialogDemo from "../../../../Landing/LayoutContainers/SimpleDialog";

class IncomeRowView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  filterType = (filter, data) => {
    if (
      filter === 1 &&
      data.statusPago &&
      data.statusPago.status === "ok" &&
      !data.borrado
    ) {
      return true;
    } else if (
      filter === 2 &&
      data.statusPago &&
      data.statusPago.status === "pendiente"
    ) {
      return true;
    } else if (filter === 3) {
      return true;
    } else if (filter === 4) {
      return true;
    }
    return false;
  };

  toggleModal = (e) => {
    e.preventDefault();
    console.log("toogle modal");
    this.setState({});
  };

  render() {
    const sticky = {
      position: "sticky",
      left: 0,
      backgroundColor: "#ffffff",
      borderRight: "2px solid red",
      zIndex: 1000,
    };

    var perfilUsuarioEvento = this.props.registryAutorizedUsers[
      this.props.userID
    ].perfil;
    var inscritoData = this.props.inscritoData;

    var filterFinal = this.filterType(this.props.filter, inscritoData);

    if (!filterFinal) {
      return null;
    } else {
      var inscritoKey = this.props.inscritoKey;
      var camposAdicionales = this.props.camposAdicionales;
      var productosAdicionalesData = this.props.productosAdicionales;

      var precioCobrado = 0;
      var detalleOrden = "";
      var modalidad = inscritoData.modalidad;
      var categoria = inscritoData.categoria;
      var genero = inscritoData.rama;

      var modCatJson = modalidadCateogoriaNameByCode(
        this.props.eventSubEventsRegistry,
        modalidad,
        categoria,
        genero
      );

      var apellidosSeparadosSet = this.props.registryConfiguration.configuracion
        .separarApellidos;
      var apellidosDatos = [];
      if (apellidosSeparadosSet) {
        apellidosDatos.push(
          <TableCell key={`pat${inscritoKey}`}>
            <div style={{ width: 100 }}>
              <FormDialog
                buttomLabel={completeNull(inscritoData.apellidoPaterno)}
                ShowIcon={false}
                ButtonStyle={null}
                maxWidthProp={"lg"}
                style={{
                  minHeight: "80vh",
                  maxHeight: "80vh",
                }}
                report={
                  <ParticipantDetailContainer
                    ParticipantDetail={inscritoData}
                    ParticipantDetailKey={inscritoKey}
                  />
                }
              />
            </div>
          </TableCell>
        );
        apellidosDatos.push(
          <TableCell key={`mat${inscritoKey}`}>
            <div style={{ width: 100 }}>
              <FormDialog
                buttomLabel={
                  completeNull(inscritoData.apellidoMaterno) + " (VER)"
                }
                ShowIcon={false}
                ButtonStyle={null}
                maxWidthProp={"lg"}
                style={{
                  minHeight: "80vh",
                  maxHeight: "80vh",
                }}
                report={
                  <ParticipantDetailContainer
                    ParticipantDetail={inscritoData}
                    ParticipantDetailKey={inscritoKey}
                  />
                }
              />
            </div>
          </TableCell>
        );
      } else {
        apellidosDatos.push(
          <TableCell key={`ap${inscritoKey}`}>
            <div style={{ width: 200 }}>
              <FormDialog
                buttomLabel={completeNull(inscritoData.apellidos) + " (VER)"}
                ShowIcon={false}
                ButtonStyle={null}
                maxWidthProp={"lg"}
                style={{
                  minHeight: "80vh",
                  maxHeight: "80vh",
                }}
                report={
                  <ParticipantDetailContainer
                    ParticipantDetail={inscritoData}
                    ParticipantDetailKey={inscritoKey}
                  />
                }
              />
            </div>
          </TableCell>
        );
      }

      var camposAdicionalesData = [];
      var camposAdicionalesArray = camposAdicionales.camposAdicionalesModalidad;
      for (var i in camposAdicionalesArray) {
        if (camposAdicionalesArray[i].tipoCampo != "titulo") {
          if (inscritoData[camposAdicionalesArray[i].iDCampo] != undefined) {
            camposAdicionalesData.push(
              <TableCell key={i}>
                {completeNull(inscritoData[camposAdicionalesArray[i].iDCampo])}
              </TableCell>
            );
          } else {
            camposAdicionalesData.push(<TableCell key={i}>N/A</TableCell>);
          }
        }
      }

      var productosAdicionales = [];
      var productosAdicionalesArray =
        productosAdicionalesData.productosAdicionalesModalidadTitulo;

      console.log("productosAdicionalesArray1", productosAdicionalesArray);
      for (var f in productosAdicionalesArray) {
        const aditionalProduct = productosAdicionalesArray[f];
        var order = inscritoData.order;
        var productExist = false;
        var unitPrice = aditionalProduct.precioProducto;

        var x = 0;
        if (order !== undefined) {
          for (var g in order) {
            const orderNew = order[g];
            var codeProducto =
              aditionalProduct.codigoProducto === "códigoDescuento"
                ? "códigoDescuento"
                : "producto-" + aditionalProduct.codigoProducto;

            console.log(
              "productosAdicionalesArray2",
              completeNull(inscritoData.numero),
              orderNew.code,
              codeProducto,
              x,
              productosAdicionalesArray,
              order[g]
            );
            if (orderNew.code === codeProducto) {
              x++;
              productExist = true;
              productosAdicionales.push(
                <TableCell key={f + g + "cantidad"}>
                  {orderNew.quantity}
                </TableCell>
              );
              productosAdicionales.push(
                <TableCell key={f + g + "precio"}>
                  {orderNew.unit_price}
                </TableCell>
              );
              productosAdicionales.push(
                <TableCell key={f + g + "total"}>
                  {orderNew.quantity * orderNew.unit_price}
                </TableCell>
              );
              var formularioProducto = aditionalProduct.formularioProducto;
              if (formularioProducto !== undefined) {
                for (var e in formularioProducto) {
                  if (formularioProducto[e].tipoCampo !== "titulo") {
                    if (
                      orderNew.extraData !== undefined &&
                      orderNew.extraData[formularioProducto[e].iDCampo] !==
                        undefined
                    ) {
                      productosAdicionales.push(
                        <TableCell key={f + g + e + "input"}>
                          {orderNew.extraData[formularioProducto[e].iDCampo]}
                        </TableCell>
                      );
                    } else {
                      productosAdicionales.push(
                        <TableCell key={f + g + e + "input"}>N/R</TableCell>
                      );
                    }
                  }
                }
              }
            }
          }
        }
        // este if entra si no existe el producto adicional dentro de la orden, es para agregar la colmna vacia
        if (!productExist) {
          productosAdicionales.push(
            <TableCell key={f + "cantidad"}>0</TableCell>
          );
          productosAdicionales.push(
            <TableCell key={f + "precio"}>{unitPrice}</TableCell>
          );
          productosAdicionales.push(<TableCell key={f + "total"}>0</TableCell>);
          var formularioProducto = aditionalProduct.formularioProducto;
          if (formularioProducto !== undefined) {
            for (var i in formularioProducto) {
              if (formularioProducto[i].tipoCampo !== "titulo") {
                productosAdicionales.push(
                  <TableCell key={f + i + "PA"}>N/A</TableCell>
                );
              }
            }
          }
        }
      }

      var resaltaBorrado = "";
      var borrado = "";
      var noCompletado = "";
      if (inscritoData.borrado) {
        resaltaBorrado = "#e57373 red lighten-2";
        borrado = "Borrado";
      }

      var noCompletado = "";
      if (inscritoData.statusPago.formaPago == "") {
        noCompletado = "Registro onLine Incompleto";
      }

      var statusPago = "";
      if (
        inscritoData.statusPago.formaPago == "oxxo_pay" &&
        inscritoData.statusPago.status == "pendiente"
      ) {
        statusPago = "Pendiente de pago en oxxo";
      } else if (
        inscritoData.statusPago.formaPago == "oxxo_pay" &&
        inscritoData.statusPago.status == "ok"
      ) {
        statusPago = "Pagado en oxxo";
      } else if (inscritoData.statusPago.formaPago == "efectivo") {
        statusPago = "Pagado en efectivo";
      } else if (inscritoData.statusPago.formaPago == "card") {
        statusPago = "Pagado con tarjeta";
      }

      var usuario;
      var usuariosAutorizados = this.props.registryAutorizedUsers;
      if (inscritoData.statusPago.formaInscripcion == "punto de Venta") {
        if (usuariosAutorizados[inscritoData.usuarioRegistra] != undefined) {
          usuario = usuariosAutorizados[inscritoData.usuarioRegistra].nombre;
        } else {
          usuario = "vacio";
        }
      } else {
        usuario = "on line";
      }

      var edadActual = this.props.registryConfiguration.configuracion
        .edadActual;
      var fechaEvento = this.props.eventDetail.fechaEvento;

      var edad = calcularEdad(
        inscritoData.fechaNacimiento,
        fechaEvento,
        edadActual
      );
      var order = inscritoData.order;
      for (var p in order) {
        precioCobrado =
          parseFloat(precioCobrado) +
          parseFloat(order[p].unit_price * order[p].quantity);
      }

      let row = (
        <TableRow className={resaltaBorrado}>
          <TableCell style={sticky}>
            {completeNull(inscritoData.numero)}
          </TableCell>
          <TableCell>
            <div style={{ width: 200 }}>
              <FormDialog
                buttomLabel={completeNull(inscritoData.nombres) + " (ver)"}
                ShowIcon={false}
                ButtonStyle={null}
                maxWidthProp={"lg"}
                style={{
                  minHeight: "80vh",
                  maxHeight: "80vh",
                }}
                report={
                  <ParticipantDetailContainer
                    ParticipantDetail={inscritoData}
                    ParticipantDetailKey={inscritoKey}
                  />
                }
              />
            </div>
          </TableCell>
          {apellidosDatos}
          <TableCell>{completeNull(inscritoData.correo)}</TableCell>
          <TableCell>{completeNull(inscritoData.telefono)}</TableCell>
          <TableCell align="center">
            <div style={{ width: 200 }}>
              {completeNull(modCatJson.modalidad)}
            </div>
          </TableCell>
          <TableCell>{completeNull(inscritoData.rama)}</TableCell>
          <TableCell>{completeNull(modCatJson.categoria)}</TableCell>
          <TableCell>{usuario}</TableCell>
          <TableCell>{precioCobrado}</TableCell>
          <TableCell>
            <div style={{ width: 150 }}>
              {completeNull(
                inscritoData.serverTime != undefined
                  ? timestampToDate(inscritoData.serverTime)
                  : inscritoData.fechaRegistro
              )}
            </div>
          </TableCell>
          {camposAdicionalesData}
          {productosAdicionales}
          <TableCell>
            {borrado} {noCompletado} {statusPago}
          </TableCell>
        </TableRow>
      );

      return row;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventParticipants: state.registry.eventParticipants,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    eventSubEventsRegistrySnap: state.events.eventSubEventsRegistrySnap,
    userID: state.atlete.userID,
  };
};

const IncomeRow = connect(mapStateToProps, mapDispatchToProps)(IncomeRowView);

export default IncomeRow;
