import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";
import {
  createTournament,
  resetCreated,
} from "../../../../data-store/actions/tournaments-actions";
import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function CreateTournaments(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );
  const classes = useStyles();

  const tournamentCreatedID = useSelector(
    (state) => state.tournaments.tournamentCreatedID
  );

  function onSubmit(data) {
    const logo =
      businessGeneralData[business.businessId].businessData.logo ||
      "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FfotoPrincipal_Dashport_bajaResolucion%20(1).jpeg?alt=media&token=e04c3966-1881-4428-9385-288e4d120b09";

    const tournament = {
      ...data,
      logo: logo,
      userCreate: userID,
      businessId: business.businessId,
    };
    dispatch(createTournament(userID, tournament));
  }
  let createTournamentObject = {
    name: {
      label: "Nombre del Torneo",
      errorMessage: "Campo Requerido",
      fieldName: "name",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    tournametSport: {
      label: "Deporte o disciplina",
      errorMessage: "Requerido",
      fieldName: "tournametSport",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "padel",
          label: "Padel",
        },
        {
          value: "tennis",
          label: "Tenis",
        },
      ],
    },
    startDate: {
      label: "Fecha de Inicio",
      errorMessage: "Campo requerido",
      fieldName: "startDate",
      type: "date",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    description: {
      label: "Descripción del torneo",
      errorMessage: "Campo requerido",
      fieldName: "description",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  };

  if (tournamentCreatedID) {
    return (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        style={{ padding: "100px" }}
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            Torneo creado éxitosamente
          </Typography>{" "}
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{ padding: "30px", width: "100%" }}
            component={Link}
            to={`/${business.businessId}/torneo/${tournamentCreatedID}`}
            onClick={() => dispatch(resetCreated())}
          >
            Ir al torneo
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            style={{ padding: "10px", width: "100%" }}
            onClick={() => dispatch(resetCreated())}
          >
            Crear otro torneo
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "100px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2">
          Crea un torneo
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <FormRender
          callback={onSubmit}
          formObject={createTournamentObject}
          submitButtonText={"Crear un torneo"}
        />
      </Grid>
    </Grid>
  );
}
