import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { getEventsAcccount } from "../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "#e0e0e0",
  },
}));

export default function AccountData(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventsAccount = useSelector((state) => state.events.eventsAccount);

  useEffect(() => {
    dispatch(getEventsAcccount());
  }, []);

  const classes = useStyles();

  let dollarUSLocale = Intl.NumberFormat("en-US");
  let totalTable = 0;
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Estados de cuenta eventos
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID de Evento</TableCell>
              <TableCell align="center">Evento</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Descripción</TableCell>
              <TableCell align="center">Monto</TableCell>
              <TableCell align="center">Saldo por evento</TableCell>
              <TableCell align="center">Documento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventsAccount &&
              Object.values(eventsAccount).map((eventID, index) => {
                const specificEventAccount = eventID;
                let totalEvent = 0;
                //let eventIDmap = 0;
                return Object.values(specificEventAccount)
                  .sort((a, b) => a.depositDate - b.depositDate)
                  .map((itemNew, i) => {
                    totalEvent = totalEvent + itemNew.amount / 1;
                    totalTable = totalTable + itemNew.amount / 1;

                    // if (eventIDmap === itemNew.eventId) {
                    //   return <div></div>;
                    // }
                    //eventIDmap = itemNew.eventId;
                    console.log("totalTable", totalTable);
                    return (
                      <TableRow key={i}>
                        <TableCell align="center" scope="row">
                          {itemNew.eventId}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {itemNew.eventName}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {itemNew.depositDate}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {itemNew.type}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {itemNew.description}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {dollarUSLocale.format(itemNew.amount)}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {dollarUSLocale.format(totalEvent)}
                        </TableCell>
                        <TableCell align="center" scope="row">
                          {itemNew.document && (
                            <Button target="_blank" href={itemNew.document}>
                              Ver
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  });
              })}
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="right">TOTAL</TableCell>
              <TableCell align="center">
                {dollarUSLocale.format(totalTable)}
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
