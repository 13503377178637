import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
//import { useTracking } from "react-tracking";
import LayoutResolver from "../../LayoutContainers/LayoutResolver";
import { getEventWeb } from "../../../../data-store/actions/organizers-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EventWeb(props) {
  const dispatch = useDispatch();

  const { eventIndex } = props.componentConfig;
  const eventWebPublic = useSelector((state) => state.organizer.eventWebPublic);

  useEffect(() => {
    dispatch(getEventWeb(eventIndex));
  }, [eventIndex]);

  const classes = useStyles();

  return <LayoutResolver page={eventWebPublic} />;
}

EventWeb.displayName = "EventWeb";

export const componentDescription = {
  componentConfig: {
    eventIndex: {
      label: "ID del evento a mostar",
      errorMessage: "Campo Requerido",
      fieldName: "eventIndex",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
