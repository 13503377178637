import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

const DashboardExtraProducts = ({ jsonProducts }) => {
  var Adicionales = [];
  var data = {
    labels: [],
    series: [],
  };
  for (var e in jsonProducts) {
    console.log("resumen ad");

    var nombreProducto = jsonProducts[e].nombreProducto;
    var objeto = jsonProducts[e].formularioProducto;
    var suma = jsonProducts[e].suma;
    console.log("resumen suma" + suma);
    console.log("resumen ad" + nombreProducto);
    console.log("resumen ad" + JSON.stringify(objeto));

    Adicionales.push(
      <Grid item xs={12} sm={6}>
        <Paper style={{ padding: 20, margin: 5, backgroundColor: "#e0e0e0" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            key={e}
          >
            <Grid item xs={12} sm={12}>
              <Typography variant={"h3"} align="center">
                {nombreProducto}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Typography variant={"subtitle1"} align="right">
                Total ventas :
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"h5"} align="left" color="primary">
                {jsonProducts[e].sumaCompras}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"subtitle1"} align="right">
                Total cantidad vendidas :
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"h5"} align="left" color="primary">
                {jsonProducts[e].sumaCantidades}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"subtitle1"} align="right">
                Total Ingresos :
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"h5"} align="left" color="primary">
                {Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(jsonProducts[e].sumaIngresos)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Paper style={{ padding: 10, margin: 5 }}>
      <Typography variant={"h2"} align="center">
        Productos Adicionales
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        key={e}
      >
        {Adicionales}
      </Grid>
    </Paper>
  );
};

export default DashboardExtraProducts;
