import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import BusinessCreate from "./BusinessCreate";
import {
  getBusinessGeneralData,
  updateBusinessAttribute,
} from "../../../../data-store/actions/organizers-actions";
import BusinessAuthUsers from "./BusinessAuthUsers";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "#e0e0e0",
  },
}));

export default function BusinessConfiguration(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );

  useEffect(() => {
    if (business) {
      dispatch(getBusinessGeneralData(business.businessId));
    }
  }, [business]);

  const classes = useStyles();
  function onSubmit(data) {
    console.log("data", data);
    dispatch(
      updateBusinessAttribute(
        business.businessId,
        "generalData/businessData/",
        data
      )
    );
  }
  let createBusinessObject = {
    name: {
      label: "Nombre de la empresa",
      errorMessage: "Campo Requerido",
      fieldName: "name",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    descripcion: {
      label: "Descripción de actividad que realiza",
      errorMessage: "Requerido",
      fieldName: "description",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    pais: {
      label: "País",
      errorMessage: "Requerido",
      fieldName: "pais",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    ciudad: {
      label: "Ciudad",
      errorMessage: "Requerido",
      fieldName: "ciudad",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    direccion: {
      label: "Dirección",
      errorMessage: "Requerido",
      fieldName: "direccion",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    logo: {
      label: "Logotipo",
      errorMessage: "Requerido",
      fieldName: "logo",
      type: "file",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  };

  if (!business) {
    return (
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography gutterBottom variant={"h2"} component="h2">
                  Datos generales
                </Typography>{" "}
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h2"} component="h2">
                Datos generales
              </Typography>{" "}
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {businessGeneralData &&
                businessGeneralData[business.businessId] &&
                Object.values(createBusinessObject).map((field, index) => {
                  return (
                    <EditableField
                      key={index}
                      fieldObject={{
                        ...field,
                        defaultValue:
                          businessGeneralData[business.businessId].businessData[
                            field.fieldName
                          ],
                      }}
                      onSubmit={onSubmit}
                    />
                  );
                })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <BusinessAuthUsers business={business} />
        </Paper>
      </Grid>
    </Grid>
  );
}
