import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import { updateUserAtribute } from "../../../../data-store/actions/atlete-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function OrganizerWebContainer(props) {
  const { business } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );

  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Páginas web del organizador
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List style={{ width: "100%" }}>
          {businessGeneralData &&
            businessGeneralData[business.businessId] &&
            businessGeneralData[business.businessId].organizersWeb &&
            Object.values(
              businessGeneralData[business.businessId].organizersWeb
            ).map((i) => (
              <Link to={"/" + business.businessId + "/web/" + i.key}>
                <ListItem>
                  <ListItemIcon>
                    <WebIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    color="secondary"
                    primary={i.domain}
                    secondary={i.description}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="this"
                      onClick={() => false}
                    >
                      <SendIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Link>
            ))}
        </List>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}
