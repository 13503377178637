import React from "react";
import { connect } from "react-redux";
import {
  detalleEvento,
  eventConvocatory,
  eventWeb,
  eventSubEventsRegistry,
  customStrings,
} from "../../../data-store/actions/events-actions";
import WhiteContainer from "../Modals/WhiteContainer";

import { Link } from "react-router-dom";
import RegistryContainerUI from "../nextEvents/Registry/RegistryContainerUI";
import RegistryContainerPublic from "../nextEvents/Registry/RegistryContainerPublic";
import ModalBuscadorRegistry from "../nextEvents/Registry/ModalBuscadorRegistry";
import ResultsReport from "../reports/ResultsReport";
import AuthorizedUsersResultsContainerNew from "./AuthorizedUsersResultsContainerNew";
import ModalBuscadorResults from "../reports/ModalBuscadorResults";

import AuthorizedUsersTimingContainer from "./Timing/AuthorizedUsersTimingContainer";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { formatEventDate } from "../../../services/utils";
import LinearProgress from "@material-ui/core/LinearProgress";

import BuscadorRegistry from "../nextEvents/Registry/BuscadorRegistry";
import BuscadorResults from "../reports/BuscadorResults";
import FullScreenDialog from "../Modals/FullScreenDialog";
import FormDialog from "../Modals/FormDialog";
import EventConfigContainer from "./EventsConfig/EventConfigContainer";
import LayoutResolver from "../../Landing/LayoutContainers/LayoutResolver";
import EventWebContainer from "./EventsConfig/EventWeb/EventWebContainer";
import TimingRegistryContainer from "./Timing/TimingRegistry/TimingRegistryContainer";
import GetControlPoints from "./Timing/TimingRegistry/GetControlPoints";

class AuthorizedUsersShowView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      reportType: 0,
    };
  }
  openWhiteContainer = (e, tipo) => {
    e.preventDefault();
    this.setState({
      reportType: tipo,
      isOpen: !this.state.isOpen,
    });
  };

  toggleWhiteContainer = () => {
    console.log("show Report");
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    if (this.props.atleteAccount == null) {
      return <h5>Inicia sesión para continuar</h5>;
    }
    var element;

    let AutorizedUserButton;
    let uploadPhotoButton;
    let configurationButton;
    let webButton;
    let realTimeResultsButtons;

    if (
      this.props.atleteAccount != undefined &&
      this.props.atleteAccount.eventosAutorizados != undefined &&
      this.props.atleteAccount.eventosAutorizados[
        this.props.match.params.EventoIndex
      ] != undefined &&
      this.props.atleteAccount.eventosAutorizados[
        this.props.match.params.EventoIndex
      ].autorizado
    ) {
      var BarButtons = (
        <FormDialog
          buttomLabel={"search"}
          ShowIcon={true}
          ButtonStyle={null}
          maxWidthProp={"sm"}
          report={<BuscadorRegistry icon={false} />}
        />
      );
      if (
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "master" ||
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "MasterOrganizador" ||
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "MasterCliente" ||
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "inscriptor"
      ) {
        AutorizedUserButton = (
          <FullScreenDialog
            buttomLabel={"Inscripciones"}
            title={"Inscripciones"}
            report={<RegistryContainerUI match={this.props.match} />}
            BarButtons={BarButtons}
          />
        );
      }
      if (
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "master" ||
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "MasterOrganizador"
      ) {
        configurationButton = (
          <FullScreenDialog
            buttomLabel={"Configuración Evento"}
            title={"Configuración Evento"}
            report={<EventConfigContainer />}
            BarButtons={null}
          />
        );
        webButton = (
          <FullScreenDialog
            buttomLabel={"Web del evento"}
            title={"Web del evento"}
            report={<EventWebContainer />}
            BarButtons={null}
          />
        );
      }

      if (
        this.props.atleteAccount.eventosAutorizados[
          this.props.match.params.EventoIndex
        ].perfil === "Web"
      ) {
        webButton = (
          <FullScreenDialog
            buttomLabel={"Web del evento"}
            title={"Web del evento"}
            report={<EventWebContainer />}
            BarButtons={null}
          />
        );
      }
    }

    if (
      this.props.atleteAccount != undefined &&
      this.props.atleteAccount.eventosAutorizadosJuez != undefined &&
      this.props.atleteAccount.eventosAutorizadosJuez[
        this.props.match.params.EventoIndex
      ]
    ) {
      let BarButtonsSearch = (
        <FormDialog
          buttomLabel={"search"}
          ShowIcon={true}
          ButtonStyle={null}
          maxWidthProp={"sm"}
          report={<BuscadorResults icon={false} />}
        />
      );

      realTimeResultsButtons = (
        <div>
          <h5>Resultados evento</h5>
          <FullScreenDialog
            buttomLabel={"Cronometraje y Resultados"}
            title={"Cronometraje y Resultados"}
            report={
              <AuthorizedUsersResultsContainerNew match={this.props.match} />
            }
            BarButtons={BarButtonsSearch}
          />
          <FullScreenDialog
            buttomLabel={"Configuración de Cronometraje"}
            title={"Configuración de Cronometraje"}
            report={<AuthorizedUsersTimingContainer match={this.props.match} />}
            BarButtons={null}
          />

          {this.props.match && <GetControlPoints match={this.props.match} />}

          {/* <FullScreenDialog
            buttomLabel={"Registros y Fotos"}
            title={"Registros y Fotos"}
            report={
              <AuthorizedUsersTimingContainer match={this.props.match} />
            }
            BarButtons={null}
          /> */}
        </div>
      );
    }

    if (this.props.evento != undefined) {
      element = (
        <Container maxWidth="sm">
          <Card>
            <CardMedia
              component="img"
              alt={"Logo evento:" + this.props.evento.nombreEvento}
              height="120px"
              image={this.props.evento.logoEvento}
              title={"Logo evento:" + this.props.evento.nombreEvento}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {this.props.evento.nombreEvento}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <p>
                  Fecha: {formatEventDate(this.props.evento.fechaEvento)}, a las{" "}
                  {this.props.evento.horaEvento}
                </p>
                <p>
                  Lugar: {this.props.evento.lugarEvento},{" "}
                  {this.props.evento.ciudadEvento},{" "}
                  {this.props.evento.paisEvento}
                </p>
              </Typography>
            </CardContent>
            <CardActions>
              {AutorizedUserButton}
              {uploadPhotoButton}
              {configurationButton}
              {webButton}
            </CardActions>
            <CardActions>{realTimeResultsButtons}</CardActions>
          </Card>
        </Container>
      );
    } else {
      element = <LinearProgress />;
    }

    return element;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    match: match,

    eventDetailSend: dispatch(detalleEvento(match.params.EventoIndex)),
    eventConvocatorySend: dispatch(eventConvocatory(match.params.EventoIndex)),
    eventWebSend: dispatch(eventWeb(match.params.EventoIndex)),
    eventSubEventsRegistrySend: dispatch(
      eventSubEventsRegistry(match.params.EventoIndex)
    ),
    customStringsSend: dispatch(customStrings(match.params.EventoIndex)),
  };
};

const mapStateToProps = (state, { match }) => {
  var EventoIndex = match.params.EventoIndex;
  var evento;
  if (state.events.events != undefined) {
    evento = state.events.events[EventoIndex];
  } else {
    evento = undefined;
  }
  return {
    evento: evento,
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    eventConvocatory: state.events.eventConvocatory,
    eventWeb: state.events.eventWeb,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    atleteAccount: state.atlete.atleteAccount,
    customStrings: state.events.customStrings,
  };
};

const AuthorizedUsersShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedUsersShowView);

export default AuthorizedUsersShow;
