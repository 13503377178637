import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  eventCodes,
  eventCodesGroups,
} from "../../../../../data-store/actions/registry-actions";
import SimpleDialogDemo from "../../../../Landing/LayoutContainers/SimpleDialog";
import DashboardCodeReports from "./DashboardCodeReports";
import { Bar } from "react-chartjs-2";

export default function DashboardDiscountCodes({ codesData, codesAmount }) {
  var Adicionales = [];
  var data = {
    labels: [],
    series: [],
  };

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!codesGroups && eventDetail)
      dispatch(eventCodesGroups("evento_" + eventDetail.iDEvento));
    dispatch(eventCodes("evento_" + eventDetail.iDEvento));
  }, []);

  let codesArray = {};
  console.log("codesArray", codesData);
  console.log("codesArray", codesList);

  for (var i in codesList) {
    if (codesArray[codesList[i].typeCode] === undefined) {
      codesArray[codesList[i].typeCode] = { amount: 0, maxUses: 0, uses: 0 };
    }

    codesArray[codesList[i].typeCode].amount =
      codesData[codesList[i].code] !== undefined
        ? -codesData[codesList[i].code].amount +
          codesArray[codesList[i].typeCode].amount
        : codesArray[codesList[i].typeCode].amount;
    codesArray[codesList[i].typeCode].maxUses =
      codesArray[codesList[i].typeCode].maxUses + codesList[i].maxUses / 1;
    codesArray[codesList[i].typeCode].uses = codesList[i].uses
      ? Object.values(codesList[i].uses).length +
        codesArray[codesList[i].typeCode].uses
      : codesArray[codesList[i].typeCode].uses;
  }
  console.log("codesArray", codesArray);
  var arrayColors = [
    "rgba(255, 99, 132, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(255, 205, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(201, 203, 207, 1)",
  ];
  var dataDispersion = {
    datasets: [
      {
        label: "Emitidos",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        yAxisID: "y-axis-1",
      },
      {
        label: "Usados",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        yAxisID: "y-axis-1",
      },
      {
        label: "Monto",
        type: "bar",
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
        yAxisID: "y-axis-2",
      },
    ],
    labels: [],
  };
  const options = {
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnArea: false,
          },
        },
        {
          ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
              return value;
            },
          },
        },
      ],
    },
  };

  for (var f in codesArray) {
    dataDispersion.labels.push(f);
    dataDispersion.datasets[0].data.push(codesArray[f].maxUses);
    dataDispersion.datasets[0].backgroundColor.push(arrayColors[0]);
    dataDispersion.datasets[0].borderColor.push(arrayColors[0]);
    dataDispersion.datasets[1].data.push(codesArray[f].uses);
    dataDispersion.datasets[1].backgroundColor.push(arrayColors[1]);
    dataDispersion.datasets[1].borderColor.push(arrayColors[1]);
    dataDispersion.datasets[2].data.push(codesArray[f].amount);
    dataDispersion.datasets[2].backgroundColor.push(arrayColors[2]);
    dataDispersion.datasets[2].borderColor.push(arrayColors[2]);
  }

  return (
    <Paper style={{ padding: 10, margin: 5 }}>
      <Typography variant={"h2"} align="center">
        Códigos de descuento
      </Typography>
      {!codesList && (
        <Typography gutterBottom variant={"h5"} component="h2" align="center">
          No hay códigos creados en este evento
        </Typography>
      )}
      {codesList && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} style={{ margin: 20 }}>
            <Typography variant={"h4"} align="center" color="primary">
              Total descuentos por códigos:
              {Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(-codesAmount)}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} style={{ margin: 20 }}>
            <Typography variant={"h4"} align="center" color="primary">
              <SimpleDialogDemo
                buttonText={"Lista detallada de códigos"}
                fullScreen={true}
              >
                <DashboardCodeReports codesData={codesData} />
              </SimpleDialogDemo>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="col s12">
              <Bar data={dataDispersion} options={options} />
            </div>
          </Grid>
        </Grid>
      )}{" "}
    </Paper>
  );
}
