import React from "react";
import DynamicInputs from "../../../../DynamicInput/DynamicInputs";
import { calcularEdad } from "../../../../../services/utils";

class SubEventsInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidad:
        this.props.actualValues.modalidad != null
          ? this.props.actualValues.modalidad
          : null,
      edad: null,
      cantidadCat: null,
      categoriasInferiores: this.props.registryConfiguration.configuracion
        .categoriasInferiores,
    };
  }

  customStringsFunct = (cSParam) => {
    var cStringsCommon = {
      modalidadSelectTitle: "Evento",
      modalidadSelectOptionOne: "Selecciona la modalidad a competir",
      categoriaOneCategoryTitle: "Categoría",
      categoriaSelectTitle: "Selecciona tu Categoría",
    };
    let customStrings = {};
    if (cSParam != null) {
      customStrings.modalidadSelectTitle =
        cSParam.modalidadSelectTitle != undefined
          ? cSParam.modalidadSelectTitle
          : cStringsCommon.modalidadSelectTitle;
      customStrings.modalidadSelectOptionOne =
        cSParam.modalidadSelectOptionOne != undefined
          ? cSParam.modalidadSelectOptionOne
          : cStringsCommon.modalidadSelectOptionOne;
      customStrings.categoriaOneCategoryTitle =
        cSParam.categoriaOneCategoryTitle != undefined
          ? cSParam.categoriaOneCategoryTitle
          : cStringsCommon.categoriaOneCategoryTitle;
      customStrings.categoriaSelectTitle =
        cSParam.categoriaSelectTitle != undefined
          ? cSParam.categoriaSelectTitle
          : cStringsCommon.categoriaSelectTitle;
    } else {
      customStrings.modalidadSelectTitle = cStringsCommon.modalidadSelectTitle;
      customStrings.modalidadSelectOptionOne =
        cStringsCommon.modalidadSelectOptionOne;
      customStrings.categoriaOneCategoryTitle =
        cStringsCommon.categoriaOneCategoryTitle;
      customStrings.categoriaSelectTitle = cStringsCommon.categoriaSelectTitle;
    }

    return customStrings;
  };

  render() {
    var edad = calcularEdad(
      this.props.fechaNacimiento,
      this.props.eventDetail.fechaEvento,
      this.props.registryConfiguration.configuracion.edadActual
    );
    //alert(edad);
    const customStrings = this.customStringsFunct(this.props.customStrings);
    let subEventsSelectValue = [];
    let subEventsSelect;
    let categorySelct;
    if (this.props.eventSubEventsRegistry != undefined) {
      for (var i in this.props.eventSubEventsRegistry) {
        subEventsSelectValue.push({
          valor: this.props.eventSubEventsRegistry[i].codigoModalidad,
          texto: this.props.eventSubEventsRegistry[i].descripcionModalidad,
          disabled: false,
        });
      }
      let subEventsSelectObject = {
        campo: 1,
        etiqueta: customStrings.modalidadSelectTitle,
        iDCampo: "modalidad",
        longitud: 100,
        observacion: "",
        tipoCampo: "Select",
        validacion: "Requerido",
        selectLabel: customStrings.modalidadSelectTitle,
        valor: subEventsSelectValue,
        anchoCampo: "s12 m12 l12",
      };
      subEventsSelect = (
        <DynamicInputs
          inputObject={subEventsSelectObject}
          actualValue={this.props.actualValues.modalidad}
          callbackchange={this.props.update}
        />
      );
    }

    return <div className="row">{subEventsSelect}</div>;
  }
}

export default SubEventsInputs;
