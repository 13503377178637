import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import FormRender from "../../../../../Fields/FormRender";

import EditableField from "../../../../../EditableField/EditableField";
import Field from "../../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import SendIcon from "@material-ui/icons/Send";
import { getParticipantsList } from "../../../../../../data-store/actions/tournaments-actions";
import CreateParticipant from "./CreateRound";
import CreateRound from "./CreateRound";
import RoundView from "./RoundView";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function TournamentRound(props) {
  const { tournamentId } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );
  const participantsList = useSelector(
    (state) => state.tournaments.participantsList
  );

  const tournament = useSelector(
    (state) => state.tournaments.tournament[tournamentId]
  );

  const classes = useStyles();

  useEffect(() => {
    if (tournamentId) {
    }
  }, [tournamentId]);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ margin: "20px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h5"} component="h2">
          Resultados
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear Ronda"} fullScreen={false}>
          <CreateRound tournamentId={tournamentId} />
        </SimpleDialogDemo>
      </Grid>
      <Grid item xs={12}>
        {tournament &&
          tournament.rounds &&
          Object.values(tournament.rounds).map((round, i) => (
            <RoundView round={round} tournamentId={tournamentId} />
          ))}
      </Grid>
    </Grid>
  );
}
