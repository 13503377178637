import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DragDropContextBoxPlain from "./DragDropContextBoxPlain";
import { useParams } from "react-router-dom";
import {
  getPuntosControl,
  configuracionEvento,
  eventSubEvents,
  juecesEvento,
  resultadosEventoNew,
  getCustomViews,
} from "../../../../data-store/actions/events-actions";

export default function CustomViewUrl(props) {
  const dispatch = useDispatch();
  let { EventoIndex, viewIndex, number } = useParams();

  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents1 = useSelector((state) => state.events.eventSubEvents);
  const eventResults = useSelector((state) => state.events.eventResults);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const puntosControl = useSelector((state) => state.events.puntosControl);
  const customViews = useSelector((state) => state.events.customViews);

  useEffect(() => {
    dispatch(resultadosEventoNew(EventoIndex));

    dispatch(configuracionEvento(EventoIndex));
    dispatch(getPuntosControl(EventoIndex));
    dispatch(eventSubEvents(EventoIndex));
    dispatch(juecesEvento(EventoIndex));
    dispatch(getCustomViews(EventoIndex));
  }, []);

  console.log("EventoIndex", EventoIndex, viewIndex);
  return (
    <div>
      {customViews && customViews[viewIndex] && (
        <DragDropContextBoxPlain view={customViews[viewIndex]} />
      )}
    </div>
  );
}
