import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FormRender from "./FormRender";

export default function CreateField(props) {
  const { fieldObject, callback, idFieldsList } = props;
  const [type, setType] = useState(false);
  const [created, setCreated] = useState(false);

  function onSubmit(data) {
    console.log("campo", { type: type, ...data });

    // const participant = {
    //   ...data,
    //   userCreate: userID,
    //   date: Math.floor(Date.now() / 1000),
    //   updates: {
    //     [Math.floor(Date.now() / 1000)]: { userID: userID, change: data },
    //   },
    // };
    // dispatch(createTournamentParticipant(tournamentId, participant));
  }
  function onSubmitType(data) {
    setType(data.type);
  }

  let fieldTypeObject = {
    type: {
      label: "Tipo de Campo",
      errorMessage: "Requerido",
      fieldName: "type",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "^[0-9a-zA-Z]{3,1000}$",
      textSize: "h6",
      optionsArray: [
        {
          value: "text",
          label: "Campo de Texto",
        },
        {
          value: "select",
          label: "Lista de Selección",
        },
        {
          value: "checkbox",
          label: "Casilla de Verificación",
        },
        {
          value: "option",
          label: "Botones de Opción",
        },
        {
          value: "date",
          label: "Campo de Fecha",
        },
        {
          value: "photo",
          label: "Campo para subir fotos",
        },
        {
          value: "titulo",
          label: "Titulo para el formulario",
        },
      ],
    },
  };

  let createFieldObject = {
    label: {
      label: "Texto a mostrar del campo",
      errorMessage: "Campo Requerido",
      fieldName: "label",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    fieldName: {
      label: "Nombre del campo para guardar en base de datos",
      errorMessage: "Requerido solo letros y números sin espacios",
      fieldName: "fieldName",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "^[0-9a-zA-Z]{3,1000}$",
      textSize: "h6",
    },
    defaultValue: {
      label: "Valor por defecto",
      errorMessage: "Requerido",
      fieldName: "defaultValue",
      type: "text",
      defaultValue: "",
      required: false,
      textSize: "h6",
    },
    required: {
      label: "Requerido",
      errorMessage: "Requerido",
      fieldName: "required",
      type: "switch",
      defaultValue: "",
      required: false,
      textSize: "h6",
    },
    regex: {
      label: "Filtro regex para validar datos",
      errorMessage: "Campo Requerido",
      fieldName: "regex",
      type: "text",
      defaultValue: "",
      required: false,
      regex: "",
    },
    errorMessage: {
      label: "Mensaje de error",
      errorMessage: "Campo Requerido",
      fieldName: "errorMessage",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  };

  if (type === "select") {
    delete createFieldObject.regex;
    createFieldObject = {
      ...createFieldObject,
      optionsArray: {
        label: "Agrega las opciones del select separadas por punto y coma ';' ",
        errorMessage: "Campo Requerido",
        fieldName: "optionsArray",
        type: "text",
        defaultValue: "",
        required: true,
        regex: "",
        textSize: "h6",
      },
    };

    // inputsArray: {
    //   label: {
    //     label: "Etiqueta",
    //     errorMessage: "Campo Requerido",
    //     fieldName: "label",
    //     type: "text",
    //     defaultValue: "",
    //     required: true,
    //     regex: "",
    //     textSize: "h6",
    //   },
    //   value: {
    //     label: "Valor a Guardar",
    //     errorMessage: "Campo Requerido",
    //     fieldName: "value",
    //     type: "text",
    //     defaultValue: "",
    //     required: true,
    //     regex: "",
    //     textSize: "h6",
    //   },
    // },
  }

  if (created) {
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "100px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2">
          Campo creado exitosamente
        </Typography>
      </Grid>
    </Grid>;
  }
  if (type) {
    return (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        style={{ padding: "100px" }}
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h3"} component="h2">
            Continua con los demás parámetros
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRender
            callback={onSubmit}
            formObject={createFieldObject}
            submitButtonText={"Guardar Campo"}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "100px" }}
    >
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2">
          Selecciona el tipo de campo
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormRender
          callback={onSubmitType}
          formObject={fieldTypeObject}
          submitButtonText={"Continar"}
        />
      </Grid>
    </Grid>
  );
}
