import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createCodeConfig from "./createCodeConfig.json";
import { createEventAttribute } from "../../../../../data-store/actions/events-actions";
import {
  createEventCodes,
  eventCodes,
} from "../../../../../data-store/actions/registry-actions";
import { makeidCodes } from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateCode(props) {
  const { inputGroup } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);

  useEffect(() => {
    console.log("codeList");

    if (!codesList) dispatch(eventCodes("evento_" + eventDetail.iDEvento));
  }, []);

  const createCodeObject = createCodeConfig.codeTypesSelection;
  const createCodeConfigObject = createCodeConfig;

  const [codeGroupType, setCodeGroupType] = useState(false);
  const [codeGroupTypeData, setCodeGroupTypeData] = useState(false);
  const [codeTitle, setCodeTitle] = useState(false);

  const classes = useStyles();

  function goBack() {
    setCodeGroupTypeData(false);
    setCodeGroupType(false);
    setCodeTitle(false);
  }
  function onSubmitGroup(data) {
    console.log("data", data);
    setCodeGroupTypeData(data);

    const configTypes = {
      customCode: {
        fixedRegistryAmount: {
          value: "createCodeCustomFixed",
          title:
            "Código Personalizado de valor fijo en la moneda del evento para la inscripción",
        },
        fixedTotalAmount: {
          value: "createCodeCustomFixed",
          title:
            "Código Personalizado de valor fijo en la moneda del evento para la compra total",
        },
        fixedRegistryAmountPerUse: {
          value: "createCodeCustomFixed",
          title:
            "Código Personalizado de valor fijo por cada vez que sea usado, en la moneda del evento para la inscripción",
        },
        fixedTotalAmountPerUse: {
          value: "createCodeCustomFixed",
          title:
            "Código Personalizado de valor fijo por cada vez que sea usado, en la moneda del evento para la compra total",
        },
        PercentRegistry: {
          value: "createCodeCustomPercent",
          title: "Código Personalizado por un porcentaje de la inscripción",
        },
        PercentAmount: {
          value: "createCodeCustomPercent",
          title: "Código Personalizado por un porcentaje de la compra total",
        },
      },
      randomCode: {
        fixedRegistryAmount: {
          value: "createCodeRandomFixed",
          title:
            "Código aleatorio de valor fijo en la moneda del evento para la inscripción",
        },
        fixedTotalAmount: {
          value: "createCodeRandomFixed",
          title:
            "Código aleatorio de valor fijo en la moneda del evento para la compra total",
        },
        fixedRegistryAmountPerUse: {
          value: "createCodeRandomFixed",
          title:
            "Código aleatorio de valor fijo por cada vez que sea usado, en la moneda del evento para la inscripción",
        },
        fixedTotalAmountPerUse: {
          value: "createCodeRandomFixed",
          title:
            "Código aleatorio de valor fijo por cada vez que sea usado, en la moneda del evento para la compra total",
        },
        PercentRegistry: {
          value: "createCodeRandomPercent",
          title: "Código aleatorio por un porcentaje de la inscripción",
        },
        PercentAmount: {
          value: "createCodeRandomPercent",
          title: "Código aleatorio por un porcentaje de la compra total",
        },
      },
    };
    setCodeGroupType(configTypes[data.customCode][data.typeCode].value);
    setCodeTitle(configTypes[data.customCode][data.typeCode].title);
  }
  console.log("codesGroups", codesGroups);
  function onSubmit(data) {
    console.log(data);
    let codesGroupNew = "codigos_1";
    if (codesGroups !== undefined && codesGroups !== null) {
      codesGroupNew =
        "codigos_" + (parseInt(Object.keys(codesGroups).length) + 1);
    }

    const codesGroup = {
      userID: userID,
      codeGroup: codesGroupNew,
      value:
        codeGroupTypeData.typeCode === "PercentAmount" ||
        codeGroupTypeData.typeCode === "PercentRegistry"
          ? data.value / 100
          : data.value,
      maxUses: data.maxUses,
      typeCode: codeGroupTypeData.typeCode,
      codesQuantity: data.code !== undefined ? 1 : data.codesQuantity || 1,
    };

    const codesList = generateCodesObject(
      data.code !== undefined ? 1 : data.codesQuantity || 1,
      codeGroupTypeData.typeCode,
      codeGroupTypeData.typeCode === "PercentAmount" ||
        codeGroupTypeData.typeCode === "PercentRegistry"
        ? data.value / 100
        : data.value,
      data.maxUses,
      codesGroupNew,
      data.code || null
    );
    console.log("route", codesList);

    dispatch(
      createEventCodes(
        "evento_" + eventDetail.iDEvento,
        codesGroupNew,
        codesGroup,
        codesList
      )
    );
  }

  function generateCodesObject(
    cantidadCodigos,
    tipoCodigo,
    valorCodigo,
    maxUses,
    codeGroup,
    code
  ) {
    var codigos = {};
    if (code) {
      codigos[code] = {
        available: true,
        code: code,
        maxUses: maxUses,
        typeCode: tipoCodigo,
        value: valorCodigo,
        codeGroup: codeGroup,
      };
    } else {
      for (var i = 0; i < cantidadCodigos; i++) {
        var Codigo = makeidCodes(7);
        codigos[Codigo] = {
          available: true,
          code: Codigo,
          maxUses: maxUses,
          typeCode: tipoCodigo,
          value: valorCodigo,
          codeGroup: codeGroup,
        };
      }
    }
    return codigos;
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {!codeGroupType && (
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"} component="h2">
                Crea un nuevo grupo de códigos
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
              <FormRender
                callback={onSubmitGroup}
                formObject={createCodeObject}
                submitButtonText={"Crear un grupo de códigos"}
              />
            </Grid>
          </Grid>
        )}
        {codeGroupType && (
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Button
                onClick={() => goBack()}
                variant="contained"
                color="primary"
              >
                Regresar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant={"h4"} component="h2">
                Configuración específica
              </Typography>
              <Typography gutterBottom variant={"subtitle1"} component="h2">
                {codeTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
              <FormRender
                callback={onSubmit}
                formObject={createCodeConfigObject[codeGroupType]}
                submitButtonText={"Crear código"}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {eventView}
      </Grid>
    </Grid>
  );
}
