import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Conekta from "../../../../../conekta/ConektaHelper";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormRender from "../../../../Fields/FormRender";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function DunosusaPayment(props) {
  const {
    callback,
    order,
    test,
    currency,
    currencyBase,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);

  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function onSubmitNew(dataFrom) {
    var data = new FormData();
    data.append("key", "apinav2022");
    data.append("ticket", dataFrom.ticket);
    data.append("importe", dataFrom.importe);
    data.append("fecha", dataFrom.fecha);
    console.log("dunosusa", data);

    var config = {
      method: "post",
      url: "https://navegantes.mx/wsvd/validator",
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": true,
      },
      data: data,
    };

    setSending(true);
    axios(config)
      .then(function(response) {
        console.log("dusonusa", JSON.stringify(response.data));
      })
      .catch(function(error) {
        setSending(false);

        console.log("dusonusa", error);
      });
  }

  function onSubmit(data) {
    setSending(true);

    var jsonDatos = {
      key: "apinav2022",
      ticket: data.ticket,
      importe: data.importe,
      fecha: data.fecha,
    };

    var form = new FormData();
    form.append("key", "apinav2022");
    form.append("ticket", data.ticket);
    form.append("importe", data.importe);
    form.append("fecha", data.fecha);

    console.log("dunosusa", jsonDatos);

    var headers = { ...form.getHeaders() };

    axios
      .post("https://navegantes.mx/wsvd/validator/", form, headers)
      .then((res) => {
        console.log("dunosusa", res);
        dispatch(showSnackbar(true, "Pago exitoso ", 4000));
        callback(true, res.data);

        setSending(false);
      })
      .catch((error) => {
        console.log("dunosusa er", error);
        setSending(false);
        var errorTexto = "";
        if (error.responseJSON !== undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
        //callback(false);
        dispatch(
          showSnackbar(true, "Error procesando el pago: " + errorTexto, 2000)
        );
      });
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
        margin: "20px",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h3">
          Dunosusa Pay
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Rellena los campos con la información de tu ticket de compra
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Continua explicación
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Más
        </Typography>
      </Grid>
      <FormRender
        callback={onSubmitNew}
        formObject={{
          ticket: {
            label: "Número de Ticket",
            errorMessage: "Campo Requerido",
            fieldName: "ticket",
            type: "text",
            defaultValue: "",
            required: true,
            regex: "",
            textSize: "h6",
          },
          fecha: {
            label: "Fecha del Ticket",
            errorMessage: "Campo Requerido",
            fieldName: "fecha",
            type: "date",
            defaultValue: 0,
            required: true,
            regex: "",
            textSize: "h6",
          },
          importe: {
            label: "Importe pagado",
            errorMessage: "Campo Requerido",
            fieldName: "importe",
            type: "text",
            defaultValue: 0,
            required: true,
            regex: "",
            textSize: "h6",
            min: 0,
          },
        }}
        submitButtonText={"Crear sección"}
      />
      {sending && (
        <Grid item xs={12} sm={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  );
}
