import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";
import FormRender from "../../../Fields/FormRender";

import EditableField from "../../../EditableField/EditableField";
import Field from "../../../Fields/Field";
import LayoutResolverEdit from "../../../Landing/LayoutContainers/LayoutResolverEdit";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import BusinessCreate from "./BusinessCreate";
import BusinessConfiguration from "./BusinessConfiguration";
import OrganizerWebContainer from "../OrganizerWeb/OrganizerWebContainer";
import BusinessWebCreate from "../OrganizerWeb/BusinessWebCreate";
import TournamentsContainer from "../TournamentsConfig/TournamentsContainer";
import AccountData from "../Accounts/AccountData";
import ParticipantsData from "../Participants/ParticipantsData";

//const useStyles = makeStyles((theme) => ({}));

export default function BusinessConfigurationContainer(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const [business, setBusiness] = useState(false);
  const businessGeneralData = useSelector(
    (state) => state.organizer.businessGeneralData
  );
  let { businessId } = useParams();

  useEffect(() => {
    if (atleteAccount && atleteAccount.businessAuth) {
      setBusiness(
        Object.values(atleteAccount.businessAuth).filter(
          (a) => a.profile !== "noauth" && a.businessId === businessId
        )[0]
      );
    }
  }, [atleteAccount]);

  //const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      {atleteAccount && atleteAccount.perfil === 1 && (
        <Grid item xs={12} style={{ margin: "20px" }}>
          <SimpleDialogDemo buttonText={"Crear una Empresa"}>
            <BusinessCreate />
          </SimpleDialogDemo>
        </Grid>
      )}
      <Grid item xs={12}>
        <hr />
        <Typography gutterBottom variant={"h2"} component="h2">
          {business && business.name}
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <BusinessConfiguration business={business} />
      </Grid>

      {businessGeneralData &&
        businessGeneralData[business.businessId] &&
        businessGeneralData[business.businessId].businessData &&
        businessGeneralData[business.businessId].businessData.businessType &&
        businessGeneralData[
          business.businessId
        ].businessData.businessType.includes("padel") && (
          <Grid item xs={12}>
            <TournamentsContainer business={business} />
          </Grid>
        )}
      {atleteAccount && atleteAccount.perfil === 1 && (
        <Grid item xs={12} style={{ margin: "20px" }}>
          <SimpleDialogDemo buttonText={"Crear una Web nueva"}>
            <BusinessWebCreate businessId={business.businessId} />
          </SimpleDialogDemo>
        </Grid>
      )}
      <Grid item xs={12}>
        <OrganizerWebContainer business={business} />
      </Grid>
      {atleteAccount &&
        atleteAccount.perfil === 1 &&
        business.businessId === "dashport" && (
          <Grid item xs={12} style={{ margin: "20px" }}>
            <SimpleDialogDemo buttonText={"Ver cuentas"} fullScreen={true}>
              <AccountData />
            </SimpleDialogDemo>
          </Grid>
        )}
      {atleteAccount &&
        atleteAccount.perfil === 1 &&
        business.businessId === "dashport" && (
          <Grid item xs={12} style={{ margin: "20px" }}>
            <SimpleDialogDemo
              buttonText={"PARTICIPANTES DE LOS EVENTOS DEL NEGOCIO"}
              fullScreen={true}
            >
              <ParticipantsData businessId={business.businessId} />
            </SimpleDialogDemo>
          </Grid>
        )}
    </Grid>
  );
}
