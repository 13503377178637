import React from "react";
import { Typography } from "@material-ui/core";
//import EventResultsListPublic from "./EventResultsListPublic";
import PastEventShow from "./PastReports/PastEventShow";

export default function RaceResults(props) {
  console.log("Race Result");
  const {
    bgColor,
    eventLogo,
    image,
    title,
    renderType,
    eventIdDashport,
    textBottom,
  } = props.componentConfig;
  const type = 1;
  console.log("eventIdDashport", eventIdDashport);
  return (
    <PastEventShow
      eventIdDashport={eventIdDashport}
      title={title}
      eventLogo={eventLogo}
      image={image}
      renderType={renderType}
      textBottom={textBottom}
    />

    /// <EventResultsListPublic
    //   eventIdDashport={eventIdDashport}
    //   title={title}
    //   eventLogo={eventLogo}
    //   resultsType={1}
    // />
  );
}

RaceResults.displayName = "Resultados de carrera";

export const componentDescription = {
  label: RaceResults.displayName,
  componentConfig: {
    eventLogo: {
      label: "Logo del registro",
      errorMessage: "",
      fieldName: "eventLogo",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    image: {
      label: "Imagen de inscripcion",
      errorMessage: "",
      fieldName: "image",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    eventIdDashport: {
      label: "ID del evento en Dashport",
      errorMessage: "",
      fieldName: "eventIdDashport",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    renderType: {
      label: "Tipo de vista a para mostra el formulario",
      errorMessage: "Campo Requerido",
      fieldName: "renderType",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        { label: "Vista resumida", value: "view" },
        { label: "Vista detallada", value: "viewFull" },
        { label: "Imagen", value: "image" },
      ],
    },
    title: {
      label: "Titulo de la sección",
      errorMessage: "",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textBottom: {
      label: "Texto al final de la sección",
      errorMessage: "",
      fieldName: "textBottom",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de Fondo",
      errorMessage: "",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
