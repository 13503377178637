import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Distance from "./Distance";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "white",
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    color: "white",
  },
  text: {
    position: "relative",
    paddingRight: 40,
    color: "white",
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: "white",
  },
});

export default function FieldEvent(props) {
  const { fieldObject, callback, currentOrder } = props;

  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    currency,
    birth,
    gender,
    eventDate,
    currentAge,
    optionsArray,
    userProfile,
    watchArray,
  } = fieldObject;
  console.log("FieldEvent", fieldObject);
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  console.log("currentOrder", currentOrder);
  const [categoryObject, setCategoryObject] = useState({
    distance: currentOrder.modalidad || null,
    gender: currentOrder.rama || null,
    category: currentOrder.categoria || null,
  });

  function setCategoryFunc(value) {
    console.log("data", value);
    setCategoryObject(value);
    callback(value);
  }

  console.log("currentOrder", optionsArray);
  console.log("currentOrder", birth);
  // const eventDate = watch("eventDate");
  // const gender = watch("gender");
  // const birth = watch("birth");

  if (gender === undefined || birth === undefined) {
    return (
      <Typography
        gutterBottom
        align="center"
        color="primary"
        variant={"h5"}
        component="h2"
        style={{ padding: 20 }}
      >
        Selecciona el género y fecha de nacimiento para continuar
      </Typography>
    );
  }

  return (
    <div>
      <Typography
        gutterBottom
        variant={"h4"}
        align="center"
        component="h2"
        color="primary"
      >
        MODALIDADES Y CATEGORÍAS
      </Typography>
      <Typography
        gutterBottom
        variant={"subtitle1"}
        align="center"
        component="h5"
        color="secondary"
      >
        Selecciona la categoría dentro de la modalidad que deseas realizar. Las
        opciones mostradas están filtradas con base al género y fecha de
        nacimiento registrados.
      </Typography>

      {Object.values(optionsArray)
        .sort((a, b) => a.order - b.order)
        .map((distance, i) => {
          if (distance.hide) {
            return null;
          }
          let available = true;
          if (
            distance.configModalidad.folios.capacidad <=
            distance.configModalidad.folios.inscritos
          ) {
            available = false;
          }

          return (
            <Distance
              key={i}
              callback={setCategoryFunc}
              distance={distance}
              eventDate={eventDate}
              categoryObject={categoryObject}
              gender={gender}
              birth={birth}
              currency={currency}
              currentAge={currentAge}
              available={available}
              userProfile={userProfile}
            />
          );
        })}
    </div>
  );
}
