import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Grid, Paper, Typography } from "@material-ui/core";

import { Modal, Button, Input, Icon, Row } from "react-materialize";
import { getCleanedString } from "../../../services/utils";
import FormDialog from "../Modals/FormDialog";

export default function EventsFinder(props) {
  const { icon, callback, events } = props;

  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);

  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  const findRegistry = (value) => {
    var ValorBuscado = value;
    let listaAtletas = [];

    if (ValorBuscado === "") {
      return null;
    }
    if (events !== undefined) {
      console.log("texto", ValorBuscado);
      if (ValorBuscado.length > 2) {
        ValorBuscado = getCleanedString(ValorBuscado);
        var nombreBuscado = ValorBuscado.toUpperCase();
        for (var i in events) {
          const value = events[i];
          const index = i;
          console.log("value.businessId", value.businessId);
          console.log(value.nombreEvento);
          if (value.nombreEvento !== undefined) {
            const valueToFind =
              value.nombreEvento +
                " " +
                value.fechaEvento +
                " " +
                value.ciudadEvento +
                " " +
                value.iDEvento +
                " " +
                value.lugarEvento +
                " " +
                value.paisEvento +
                " " +
                value.tipoDeEvento +
                " " +
                value.businessId || "";

            const nombreComparar = getCleanedString(valueToFind.toUpperCase());
            if (nombreComparar.match(nombreBuscado) != null) {
              listaAtletas.push(value);
            }
          }
        }
      }
      console.log(listaAtletas);
      return listaAtletas;
    }
  };

  const handleChange = (e) => {
    console.log("AntdInput", e.target.value);
    callback(findRegistry(e.target.value));
  };

  return (
    <Paper style={{ padding: 20, margin: 20, backgroundColor: "#e0e0e0" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={12} sm={12}>
          <Typography variant={"h4"} color="primary" align="center">
            Buscador de Eventos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant={"h6"} align="center">
            Puedes buscar por nombre, lugar, tipo de evento, fecha (AAAA-MM-DD).
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant={"h4"} align="center">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                onChange={handleChange}
                name="nameOrNumber"
                defaultValue=""
                style={{ fontSize: 30 }}
                ref={register}
              />
            </form>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
