import React from "react";
import {
  RestarTiempos,
  TiempoAPasoMinxK,
  tiempoASegundos,
  TiempoAPasoBy,
} from "../../../../services/utils";

const AtleteControls = ({ resultado, puntosControl }) => {
  var Parciales = resultado.parciales;

  var parcialesArray = [];

  var TiempoAnterior = "00:00:00";
  var KilómetroAnterior = 0;

  var DifTiempo;
  var PasoParcial;

  if (Parciales != undefined) {
    Parciales.sort((a, b) =>
      a.kpc > b.kpc ? 1 : b.kpc > a.kpc ? -1 : 0
    ).forEach((value) => {
      if (value.pCat != 500 && value.kpc != 500 && value.kpc != 0) {
        DifTiempo = RestarTiempos(TiempoAnterior, value.tkpc);

        const parcialPaseType = getPartialPaseType(
          value,
          puntosControl,
          resultado
        );

        PasoParcial = TiempoAPasoBy(
          DifTiempo,
          value.kpc - KilómetroAnterior,
          parcialPaseType
        );

        const parcialName = getPartialName(value, puntosControl, resultado);

        parcialesArray.push(
          <tr key={resultado.num - value.kpc}>
            {puntosControl && <td>{parcialName}</td>}

            <td>
              {KilómetroAnterior} - {value.kpc}
            </td>
            <td>{value.tkpc}</td>
            <td>{DifTiempo}</td>
            <td>{PasoParcial}</td>
          </tr>
        );
        TiempoAnterior = value.tkpc;
        KilómetroAnterior = value.kpc;
      }
    });
  }
  if (resultado.to !== "00:00:00") {
    console.log(resultado.to);
    DifTiempo = RestarTiempos(TiempoAnterior, resultado.to);
    const parcialPaseType = getFinishPaseType(puntosControl, resultado);
    PasoParcial = TiempoAPasoBy(
      DifTiempo,
      resultado.mod - KilómetroAnterior,
      parcialPaseType
    );
    parcialesArray.push(
      <tr key={resultado.num - 0}>
        {puntosControl && <td>Meta</td>}

        <td>
          {KilómetroAnterior} - {resultado.mod}
        </td>
        <td>{resultado.to}</td>
        <td>{resultado.to !== "00:00:00" ? DifTiempo : "NA"}</td>
        <td>{resultado.to !== "00:00:00" ? PasoParcial : "NA"}</td>
      </tr>
    );
  }
  function getPartialName(value, puntosControl, resultado) {
    if (!puntosControl) {
      return "na";
    }
    const control = Math.floor(value.pCat / 1000);
    const lap = (value.pCat - control * 1000) / 1;
    console.log();
    let description = "";
    if (
      puntosControl &&
      puntosControl["kPC_" + control] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap].descripcionPunto
    ) {
      description =
        puntosControl["kPC_" + control].modalidadesPuntoDeControl[
          "modalidad_" + resultado.codMod
        ].laps["lap_" + lap].descripcionPunto;
    }

    return description;
  }
  function getPartialPaseType(value, puntosControl, resultado) {
    if (!puntosControl) {
      return "min/k";
    }
    const control = Math.floor(value.pCat / 1000);
    const lap = (value.pCat - control * 1000) / 1;
    console.log();
    let description = "";
    if (
      puntosControl &&
      puntosControl["kPC_" + control] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap].unidadPaso
    ) {
      description =
        puntosControl["kPC_" + control].modalidadesPuntoDeControl[
          "modalidad_" + resultado.codMod
        ].laps["lap_" + lap].unidadPaso;
    }

    return description;
  }
  function getFinishPaseType(puntosControl, resultado) {
    if (!puntosControl) {
      return "min/k";
    }

    for (var i in puntosControl) {
      const controlIndex = i;

      if (
        puntosControl &&
        puntosControl[controlIndex] &&
        puntosControl[controlIndex].modalidadesPuntoDeControl &&
        puntosControl[controlIndex].modalidadesPuntoDeControl[
          "modalidad_" + resultado.codMod
        ] &&
        puntosControl[controlIndex].modalidadesPuntoDeControl[
          "modalidad_" + resultado.codMod
        ].laps
      ) {
        const laps =
          puntosControl[controlIndex].modalidadesPuntoDeControl[
            "modalidad_" + resultado.codMod
          ].laps;
        for (var e in laps) {
          const lap = laps[e];
          if (lap.tipoControl === 1) {
            return lap.unidadPaso;
          }
        }
      }
    }
    return "min/k";
  }
  let parcialesView = parcialesArray.map((parcial) => parcial);

  return (
    <div>
      <div className="row">
        <div className="col s10 m10 l10">
          <h5>Parciales</h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m8 offset-m2 z-depth-2">
          <table className="bordered centered" id="DetalleComp">
            <thead>
              <tr>
                {puntosControl && <th scope="col">Descripción</th>}

                <th scope="col">Kilómetro</th>
                <th scope="col">Tiempo</th>
                <th scope="col">Dif. Min.</th>
                <th scope="col">Ritmo</th>
              </tr>
            </thead>
            <tbody id="ListaDetalles">{parcialesView}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AtleteControls;
