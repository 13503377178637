import { Typography } from "@material-ui/core";
import React from "react";
import {
  RestarTiempos,
  TiempoAPasoMinxK,
} from "../../../../../../../services/utils";

const AtleteControls = ({ resultado, puntosControl }) => {
  var Parciales = resultado.parciales;

  var parcialesArray = [];

  var TiempoAnterior = "00:00:00";
  var KilómetroAnterior = 0;

  var DifTiempo;
  var PasoParcial;

  if (Parciales != undefined) {
    Parciales.sort((a, b) =>
      a.kpc > b.kpc ? 1 : b.kpc > a.kpc ? -1 : 0
    ).forEach((value) => {
      if (value.pCat != 500 && value.kpc != 500 && value.kpc != 0) {
        DifTiempo = RestarTiempos(TiempoAnterior, value.tkpc);
        PasoParcial = TiempoAPasoMinxK(
          DifTiempo,
          value.kpc - KilómetroAnterior
        );

        const parcialName = getPartialName(value, puntosControl, resultado);

        parcialesArray.push(
          <tr key={resultado.num - value.kpc}>
            {puntosControl && <td>{parcialName}</td>}
            <td>
              {KilómetroAnterior} - {value.kpc}
            </td>
            <td>{value.tkpc}</td>
            <td>{DifTiempo}</td>
            <td>{PasoParcial}</td>
          </tr>
        );
        TiempoAnterior = value.tkpc;
        KilómetroAnterior = value.kpc;
      }
    });
  }
  console.log(resultado.to);
  DifTiempo = RestarTiempos(TiempoAnterior, resultado.to);
  PasoParcial = TiempoAPasoMinxK(DifTiempo, resultado.mod - KilómetroAnterior);
  parcialesArray.push(
    <tr key={resultado.num - 0}>
      {puntosControl && <td>Meta</td>}

      <td>
        {KilómetroAnterior} - {resultado.mod}
      </td>
      <td>{resultado.to}</td>
      <td>{DifTiempo}</td>
      <td>{PasoParcial}</td>
    </tr>
  );

  function getPartialName(value, puntosControl, resultado) {
    if (!puntosControl) {
      return "na";
    }
    const control = Math.floor(value.pCat / 1000);
    const lap = (value.pCat - control * 1000) / 1;
    console.log();
    let description = "";
    if (
      puntosControl &&
      puntosControl["kPC_" + control] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap] &&
      puntosControl["kPC_" + control].modalidadesPuntoDeControl[
        "modalidad_" + resultado.codMod
      ].laps["lap_" + lap].descripcionPunto
    ) {
      description =
        puntosControl["kPC_" + control].modalidadesPuntoDeControl[
          "modalidad_" + resultado.codMod
        ].laps["lap_" + lap].descripcionPunto;
    }

    return description;
  }
  let parcialesView = parcialesArray.map((parcial) => parcial);

  return (
    <div>
      <div className="row">
        <div className="col s10 m10 l10">
          <Typography variant={"h4"} color="primary">
            Parciales
          </Typography>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m8 offset-m2 z-depth-2">
          <table className="bordered centered" id="DetalleComp">
            <thead>
              <tr>
                {puntosControl && <th scope="col">Descripción</th>}
                <th scope="col">Kilómetro</th>
                <th scope="col">Tiempo</th>
                <th scope="col">Dif. Min.</th>
                <th scope="col">Paso (min/K)</th>
              </tr>
            </thead>
            <tbody id="ListaDetalles">{parcialesView}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AtleteControls;
