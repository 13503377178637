import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getCleanedString, helperMultisort } from "../../../../services/utils";
import FormDialog from "../../Modals/FormDialog";
import { Button, Grid } from "@material-ui/core";

export default function BuscadorResultsCustomsViews(props) {
  const { icon, certificates, callback } = props;
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  const [athletesList, setAthletesList] = useState();

  const [showRealTime, setShowRealTime] = useState(false);

  useEffect(() => {
    if (showRealTime) {
      showLast20();
      setShowRealTime(true);
    }
  }, [eventResults]);

  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  const findRegistry = (value) => {
    setShowRealTime(false);
    var ValorBuscado = value;
    let listaAtletas = [];
    if (eventResults != undefined) {
      if (!isNaN(ValorBuscado)) {
        console.log("numero");
        eventResults.forEach((value, i) => {
          //      console.log(ListaAtletas[value]);
          if (value.num == ValorBuscado) {
            listaAtletas.push(
              <li key={i} className="collection-item">
                {(value.num == undefined ? "Folio Pendiente" : value.num) +
                  " - " +
                  value.nombre}
                <Button
                  color="primary"
                  onClick={() => callback(value, "print")}
                >
                  IMPRIMIR
                </Button>
                <Button color="primary" onClick={() => callback(value, "jpg")}>
                  Descargar JPG
                </Button>
              </li>
            );
          }
        });

        return listaAtletas;
      } else {
        console.log("texto");
        if (ValorBuscado.length > 2) {
          eventResults.forEach((value, i) => {
            ValorBuscado = getCleanedString(ValorBuscado);
            var nombreBuscado = ValorBuscado.toUpperCase();
            const nombreComparar = getCleanedString(value.nombre.toUpperCase());
            if (nombreComparar.match(nombreBuscado) != null) {
              listaAtletas.push(
                <li key={i} className="collection-item">
                  {(value.num == undefined ? "Folio Pendiente" : value.num) +
                    " - " +
                    value.nombre}
                  <Button
                    color="primary"
                    onClick={() => callback(value, "print")}
                  >
                    IMPRIMIR
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => callback(value, "jpg")}
                  >
                    Descargar JPG
                  </Button>
                </li>
              );
            }
          });
          return listaAtletas;
        }
      }
    }
  };

  const handleChange = (e) => {
    console.log("AntdInput", e.target.value);
    setAthletesList(findRegistry(e.target.value));
    console.log(athletesList);
  };

  let buttom;
  if (icon) {
    buttom = (
      <li>
        <i className="material-icons md-40 marginIcons">search</i>
      </li>
    );
  } else {
    buttom = (
      <li>
        <a href="#" className="red-text">
          Buscar por Nombre o Número
        </a>
      </li>
    );
  }
  function showLast20() {
    setShowRealTime(!showRealTime);
    const results = helperMultisort(eventResults, ["fhm"], ["DESC"]);

    setAthletesList(
      results.slice(0, 50).map((value, i) => (
        <li key={i} className="collection-item">
          {(value.num == undefined ? "Folio Pendiente" : value.num) +
            " - " +
            value.nombre}
          <Button color="primary" onClick={() => callback(value, "print")}>
            IMPRIMIR
          </Button>
          <Button color="primary" onClick={() => callback(value, "jpg")}>
            Descargar JPG
          </Button>
        </li>
      ))
    );
  }
  return (
    <div>
      <Button color="primary" variant="contained" onClick={() => showLast20()}>
        {showRealTime
          ? "Mostrando últimos en cruzar"
          : "Mostrar últimos en cruzar la meta"}
      </Button>
      <h5>Buscador por Nombre o Número</h5>
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          onChange={handleChange}
          name="nameOrNumber"
          defaultValue=""
          ref={register}
        />
      </form>
      <ul id="ListaNumeroNombre" className="collection">
        {athletesList}
      </ul>
    </div>
  );
}
