import * as types from "../actions/action-types";

const initialState = [];
export default function atleteReducer(state = initialState, action) {
  switch (action.type) {
    case types.ATLETE_PHOTOS:
      return Object.assign({}, state, {
        atletePhotos: action.atletePhotos,
      });
    case types.ATLETE_ACCOUNT:
      return Object.assign({}, state, {
        atleteAccount: action.atleteAccount,
        userID: action.userID,
      });
    case types.REQUESTTOKENGARMIN:
      return Object.assign({}, state, {
        requestTokenURLGarmin: action.requestTokenURLGarmin,
      });
    case types.ACTIVITIESGARMIN:
      var activityGarminId = action.activityGarminId;
      var activityGarmin = action.activityGarmin;
      activityGarmin.id = activityGarminId;
      return Object.assign({}, state, {
        activitiesGarmin: Object.assign({}, state.activitiesGarmin, {
          [activityGarminId]: activityGarmin,
        }),
      });
    case types.ACTIVITIESGARMINDETAIL:
      return Object.assign({}, state, {
        activityGarminDetail: action.activityGarminDetail,
        activityGarminDetailId: action.activityGarminDetailId,
      });
    case types.ACTIVITIESGARMINLOADING:
      return Object.assign({}, state, {
        activitiesGarminLoading: action.activitiesGarminLoading,
      });
    case types.POSTWORKOUTGARMIN:
      return Object.assign({}, state, {
        postWorkoutGarmin: action.postWorkoutGarmin,
      });
    case types.TRAKING_ACTIVITY_APP:
      var trakingActivityAppId = action.trakingActivityAppId;
      var trakingActivityApp = action.trakingActivityApp;
      trakingActivityApp.id = trakingActivityAppId;
      return Object.assign({}, state, {
        activitiesAppTraking: Object.assign({}, state.activitiesAppTraking, {
          [trakingActivityAppId]: trakingActivityApp,
        }),
      });
    case types.SHOW_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: action.snackbar,
      });

    default:
      return state;
  }
}
