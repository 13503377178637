import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import timingConfig from "../timingConfig.json";
import {
  updateEventAttribute,
  deleteSubEvent,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import EventSpecificGender from "./TimingSpecificGender";
import TimingSpecificSubEventStarts from "./TimingSpecificSubEventStarts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function TimingSpecificSubEvent(props) {
  const { subEventIndex, subEvent } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  const timingConfigObject = timingConfig.modalidad;

  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "modalidades/" + subEventIndex + "/",
        value
      )
    );
  }
  function findValueByRoute(object, route, field) {
    const routeSplit = route.split("/");
    const fieldName = routeSplit[routeSplit.length - 1];
    let value = object;
    console.log(fieldName);
    console.log(value);
    for (var i = 0; i < routeSplit.length; i++) {
      value = value[routeSplit[i]] !== undefined ? value[routeSplit[i]] : "";
    }
    console.log(value);

    return value;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h2"} component="h2">
              {subEvent.descripcionModalidad}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {eventSubEvents &&
              eventSubEvents[subEventIndex] &&
              Object.values(timingConfigObject).map((field, index) => {
                const defaultValue = findValueByRoute(
                  eventSubEvents[subEventIndex],
                  field.fieldName
                );
                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimingSpecificSubEventStarts
              subEventIndex={subEventIndex}
              subEvent={subEvent}
            />
          </Grid>
          {Object.keys(subEvent.ramaCat).map((genderKey) => {
            return (
              <Grid item xs={12} sm={5} style={{ margin: "20px" }}>
                <Paper style={{ padding: "20px" }}>
                  <EventSpecificGender
                    subEventIndex={subEventIndex}
                    subEvent={subEvent}
                    gender={genderKey}
                  />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Paper>
  );
}
