import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import timingConfig from "../timingConfig.json";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import ControlPointDistances from "./ControlPointDistances";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px",
    margin: 10,
    textAlign: "left",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function SpecificControlPoint(props) {
  const { kpc, controlPointIndex } = props;
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);

  const classes = useStyles();

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const controlPoint = timingConfig.controlPoint;

  function onSubmit(data) {
    console.log(data);
  }
  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "puntosDeControl/kPC_" + controlPointIndex + "/",
        value
      )
    );
  }

  if (hide) {
    // vista resumida para auditar los controles
    return (
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          style={{ padding: 20 }}
        >
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={"h4"} align="left">
              {kpc.descripcionFinalPunto}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setHide(!hide)}
            >
              Mostrar detalle
            </Button>
          </Grid>

          <Grid item xs={12} sm={8}>
            {kpc.modalidadesPuntoDeControl &&
              Object.values(kpc.modalidadesPuntoDeControl).map(
                (kpcDetail, index) => {
                  return (
                    <div>
                      <Paper style={{ margin: 5, padding: 5 }}>
                        <Grid container direction="row" alignItems="flex-start">
                          <Grid item xs={12}>
                            <Typography variant={"h5"} align={"left"}>
                              {kpcDetail.descripcionModalidad}
                            </Typography>
                            <Typography variant={"body"} align={"left"}>
                              Este control representa los kilómetros siguientes
                            </Typography>
                          </Grid>

                          {Object.values(kpcDetail.laps)
                            .sort((a, b) => a.lap - b.lap)
                            .map((lap, index) => {
                              return (
                                <Grid item xs={6} sm={2}>
                                  <Paper
                                    style={{
                                      backgroundColor: "#e0e0e0",
                                      margin: 5,
                                      padding: 5,
                                      minHeight: 60,
                                    }}
                                  >
                                    <Typography
                                      variant={"subtitle1"}
                                      align={"center"}
                                    >
                                      Lap {lap.lap}:
                                    </Typography>
                                    <Typography
                                      variant={"subtitle1"}
                                      align={"center"}
                                    >
                                      KM{lap.kilometro}
                                    </Typography>
                                  </Paper>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Paper>
                    </div>
                  );
                }
              )}

            {!kpc.modalidadesPuntoDeControl && (
              <Paper style={{ margin: 5, padding: 20 }}>
                <Typography variant={"h5"} align={"center"}>
                  Este punto de control no tiene ningúna distancia registrada
                </Typography>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        style={{ padding: 20 }}
      >
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h2"}>
            {kpc.descripcionFinalPunto}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setHide(!hide)}
          >
            Ocultar detalle
          </Button>
        </Grid>

        <Grid item xs={12}>
          {Object.values(controlPoint).map((field, index) => {
            const fieldNew = {
              ...field,
              defaultValue: kpc[field.fieldName],
            };
            return (
              <div>
                <EditableField
                  key={index}
                  fieldObject={fieldNew}
                  onSubmit={handleChange}
                />
                <ControlPointDistances
                  kpc={kpc}
                  controlPointIndex={kpc.kNroPunto}
                />
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}
