import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {
  generateReport,
  ReportTable,
  getTotalPurchases,
} from "../../../AuthorizedUsers/Reports/generateReport";
import CSVButton from "../../../AuthorizedUsers/Reports/CSVButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: 10,
    margin: 20,
    backgroundColor: "#e0e0e0",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    overflowY: "scroll",
    width: "100%",
  },
  buttons: {
    margin: 30,
    width: "350px",
    color: "black",
  },
  list: {
    margin: 30,
    width: "500px",
    color: "black",
  },
}));

export default function DashboardCodeReports(props) {
  const { codesData, codesIndex } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const [report, setReport] = useState([]);
  const [labels, setLabels] = useState([]);
  const [reportType, setReportType] = useState(undefined);
  const [itemIdentifier, setItemIdentifier] = useState(undefined);

  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );

  console.log(eventDetail);
  const classes = useStyles();
  useEffect(() => {
    if (codesList && codesList) {
      // filtro solo los códigs del grupo a revisar
      const data = Object.values(codesList).map((a) => {
        return {
          ...a,
          valueUsed:
            codesData[a.code] !== undefined ? -codesData[a.code].amount : 0,
          participants:
            codesData[a.code] !== undefined
              ? codesData[a.code].participants
              : [],

          value:
            a.typeCode === "PercentRegistry" || a.typeCode === "PercentAmount"
              ? a.value * 100 + "%"
              : a.value,
          available:
            a.uses && Object.values(a.uses).length === parseInt(a.maxUses)
              ? false
              : true,
          usesCount:
            a.uses && Object.values(a.uses).length
              ? Object.values(a.uses).length
              : 0,
        };
      });
      const newReport = generateReport({
        data: data,
        fields: [
          { label: "Código", path: "code" },
          { label: "Valor usado", path: "valueUsed" },
          { label: "Valor", path: "value" },
          { label: "Disponible", path: "available" },
          { label: "Usos permitidos", path: "maxUses" },
          { label: "Veces usado", path: "usesCount" },
          { label: "Tipo de código", path: "typeCode" },
        ],
      });
      setLabels(newReport.labels);
      setReport(newReport.report);
      setItemIdentifier(newReport.itemIdentifier);
    }
  }, [codesList]);

  function detailComponent(item) {
    console.log("item", item);

    //    return JSON.stringify(item);
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        style={{ margin: 30 }}
      >
        <Grid item xs={12} alignItems="center" style={{ margin: 30 }}>
          <Typography variant={"h5"} align="center">
            Atletas que usaron este código
          </Typography>
        </Grid>
        <Grid item xs={12} alignItems="center">
          {item.participants.map((a) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Grid item xs={2} alignItems="center">
                <Typography variant={"subtitle1"} align="center">
                  {eventParticipants[a].numero}
                </Typography>
              </Grid>
              <Grid item xs={5} alignItems="center">
                <Typography variant={"subtitle1"} align="center">
                  {eventParticipants[a].nombres}
                </Typography>
              </Grid>
              <Grid item xs={5} alignItems="center">
                <Typography variant={"subtitle1"} align="center">
                  {eventParticipants[a].apellidos}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" alignContent="center">
        <Grid item xs={12} alignItems="center">
          {codesList && <CSVButton report={report} labels={labels} />}
          {codesList ? (
            <ReportTable
              report={report}
              labels={labels}
              data={Object.values(codesList).map((a) => {
                return {
                  ...a,
                  valueUsed:
                    codesData[a.code] !== undefined
                      ? -codesData[a.code].amount
                      : 0,
                  participants:
                    codesData[a.code] !== undefined
                      ? codesData[a.code].participants
                      : [],
                  value:
                    a.typeCode === "PercentRegistry" ||
                    a.typeCode === "PercentAmount"
                      ? a.value * 100 + "%"
                      : a.value,
                  available:
                    a.uses &&
                    Object.values(a.uses).length === parseInt(a.maxUses)
                      ? false
                      : true,
                  usesCount:
                    a.uses && Object.values(a.uses).length
                      ? Object.values(a.uses).length
                      : 0,
                };
              })}
              reportType={"code"}
              itemIdentifier={itemIdentifier}
              showButtonDetail={true}
              detailComponent={detailComponent}
            />
          ) : (
            <Typography gutterBottom variant={"h5"} component="h2">
              No hay códigos creados en este evento
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
