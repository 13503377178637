import React from "react";
import { connect } from "react-redux";
import { db } from "../../../firebase/firebasejs";
import AuthorizedUsersCard from "./AuthorizedUsersCard";
import Grid from "@material-ui/core/Grid";

class UploadResultsDataView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayData: [],
    };
  }

  readFilesUploadData = (event, subir, EventoIndex) => {
    console.log("readFilesUploadData");
    //event.preventDefault();
    let displayData = [];

    var file = this.state.file;

    if (!subir) {
      var file = event.target.files;
      this.setState({ file: file });
      console.log(file);
    }

    String.prototype.splitCSV = function(sep) {
      for (
        var foo = this.split((sep = sep || ",")), x = foo.length - 1, tl;
        x >= 0;
        x--
      ) {
        if (foo[x].replace(/"\s+$/, '"').charAt(foo[x].length - 1) == '"') {
          if (
            (tl = foo[x].replace(/^\s+"/, '"')).length > 1 &&
            tl.charAt(0) == '"'
          ) {
            foo[x] = foo[x].replace(/^\s*"|"\s*$/g, "").replace(/""/g, '"');
          } else if (x) {
            foo.splice(x - 1, 2, [foo[x - 1], foo[x]].join(sep));
          } else
            foo = foo
              .shift()
              .split(sep)
              .concat(foo);
        } else foo[x].replace(/""/g, '"');
      }
      return foo;
    };

    let resultLines;
    for (var i = 0; i < file.length; i++) {
      if (file[i]) {
        var filename = file[i].name;
        var reader = new FileReader();
        reader.readAsText(file[i], "UTF-8");
        console.log(file[i].name);
        reader.onload = (evt) => {
          var result = evt.target.result;
          resultLines = result.split("\n");
          var titulos = resultLines[0].splitCSV();
          var indexNumero = null;
          var indexTiempo = null;
          var indexControl = null;
          for (var e in titulos) {
            if (titulos[e] == "Número" || titulos[e] == "Numero") {
              indexNumero = e;
            } else if (titulos[e] == "Tiempo") {
              indexTiempo = e;
            } else if (titulos[e] == "Control") {
              indexControl = e;
            }
          }
          console.log(indexTiempo);
          if (
            indexNumero != null &&
            indexTiempo != null &&
            indexControl != null
          ) {
            for (var f in resultLines) {
              if (f > 0) {
                var datos = resultLines[f].splitCSV();
                console.log(datos[indexTiempo]);
                var tiempoASegundos = this.convertTimeToSeconds(
                  datos[indexTiempo]
                );
                console.log(datos);
                var salida = this.getHoraSalida(datos[indexNumero]);
                if (tiempoASegundos != null && salida != null) {
                  console.log(tiempoASegundos);
                  displayData.push(
                    <div>
                      Número: {datos[indexNumero]} Tiempo: {datos[indexTiempo]}
                      Hora salida:{" "}
                      {this.sumarSegAFecha(salida, tiempoASegundos)} Hora salida
                      Modalidad: {salida}{" "}
                    </div>
                  );
                  if (subir) {
                    this.addRegistryTime(
                      datos[indexNumero],
                      this.sumarSegAFecha(salida, tiempoASegundos),
                      EventoIndex,
                      datos[indexControl]
                    );
                  }
                } else {
                  if (tiempoASegundos == null) {
                    displayData.push(<h5>Formato de números incorrecto</h5>);
                    break;
                  }
                  if (salida == null) {
                    displayData.push(
                      <h5>
                        Número de atleta {datos[indexNumero]} no registrado en
                        Dashport
                      </h5>
                    );
                  }
                }
              }
            }
          } else {
            if (indexNumero == null) {
              displayData.push(<h5>Columna Número no encontrada</h5>);
            }
            if (indexTiempo == null) {
              displayData.push(<h5>Columna Tiempo no encontrada</h5>);
            }
            if (indexControl == null) {
              displayData.push(<h5>Columna Control no encontrada</h5>);
            }
          }
          console.log(displayData);
          this.setState({ displayData: displayData });
        };
        reader.onerror = (evt) => {
          displayData.push(<h5>error reading file</h5>);
          this.setState({ displayData: displayData });
        };
      }
    }
  };

  convertTimeToSeconds = (tiempo) => {
    console.log(tiempo);

    var tiemposplit = tiempo.split(":");
    console.log(tiemposplit[0] / 1);
    console.log(tiemposplit[1] / 1);
    console.log(tiemposplit[2] / 1);
    var seconds = 0;
    if (tiemposplit.length == 3) {
      seconds =
        (tiemposplit[0] / 1) * 3600 +
        (tiemposplit[1] / 1) * 60 +
        tiemposplit[2] / 1;
    } else {
      document.getElementById("ConfiguracionEvento").innerHTML =
        "Error en formato de tiempo";
      return null;
    }

    return seconds;
  };

  getHoraSalida = (numero) => {
    let ResultadosEvento = this.props.eventResults;
    var datosAtleta = null;
    for (var i in ResultadosEvento) {
      if (ResultadosEvento[i].num == numero) {
        datosAtleta = ResultadosEvento[i];
      }
    }

    if (datosAtleta == null) {
      return null;
    }
    console.log(datosAtleta);
    console.log(this.props.eventSubEvents["modalidad_" + datosAtleta.codMod]);

    var horaSalida = this.props.eventSubEvents[
      "modalidad_" + datosAtleta.codMod
    ].hSalidas["serie_" + datosAtleta.serie].hS;

    console.log(horaSalida);

    return horaSalida;
  };
  // ###########################################################################
  addRegistryTime = (number, fechaHora, EventoIndex, kPuntoControl) => {
    var iDEvento = EventoIndex.split("_");
    // llamo la funcion que despliega las tablas
    var updates = {};
    var jsonPush = {};
    jsonPush.competidorId = "Chip";
    jsonPush.fechaHora = fechaHora;
    jsonPush.iDEvento = parseInt(iDEvento[1]);
    jsonPush.kPuntoControl = parseInt(kPuntoControl);
    jsonPush.lat = 1;
    jsonPush.lng = 1;
    jsonPush.numero = parseInt(number);
    jsonPush.timeStamp = new Date(fechaHora).getTime();
    jsonPush.visibleOBorrado = 0;

    var nuevoRegistroJuez = db
      .ref()
      .child(`eventosCompletos/${EventoIndex}/registrosJuez/num_${number}/`)
      .push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    updates[
      `eventosCompletos/${EventoIndex}/registrosJuez/num_${number}/${nuevoRegistroJuez}/`
    ] = jsonPush;
    updates[
      `eventosCompletos/${EventoIndex}/resultados/num_${number}/recalcular/`
    ] = true;
    console.log(
      `eventosCompletos/${EventoIndex}/registrosJuez/num_${number}/${nuevoRegistroJuez}/`
    );
    console.log(fechaHora);

    return db.ref().update(updates);
  };

  borrarRegistrosDeChip = (event, eventoIndex) => {
    event.preventDefault();

    var competidorIdABorrar = "Chip";
    // borrar de aqui hacia abajo
    return db
      .ref("/eventosCompletos/" + eventoIndex + "/registrosJuez/")
      .once("value")
      .then((snap) => {
        var updates = {};
        var x = 0;

        var registrosJuezEvento = snap.val();
        var numeroIndex;
        for (var i in registrosJuezEvento) {
          numeroIndex = i;
          var registros = registrosJuezEvento[i];
          for (var e in registros) {
            console.log(JSON.stringify(registros[e].competidorId));
            var competidorId = registros[e].competidorId;
            if (competidorId.localeCompare(competidorIdABorrar) == 0) {
              updates[
                "/eventosCompletos/" +
                  eventoIndex +
                  "/registrosJuez/" +
                  numeroIndex +
                  "/" +
                  e +
                  "/visibleOBorrado/"
              ] = 1;
              x = x + 1;
              //     console.log("borrar" + x);
            }
          }
          //        console.log(registrosJuezEvento[i]);
          //        console.log(i);
        }
        updates[
          "/eventosCompletos/" +
            eventoIndex +
            "/resultados/" +
            numeroIndex +
            "/recPos/"
        ] = true;

        return db.ref().update(updates);
      });
  };
  sumarSegAFecha = (fechaParam, segundosParam) => {
    var fechaTomada = new Date(fechaParam);
    var fecha = new Date(
      fechaTomada.setTime(fechaTomada.getTime() + segundosParam * 1000)
    );

    var seg =
      fecha.getSeconds() < 10 ? "0" + fecha.getSeconds() : fecha.getSeconds();
    var min =
      fecha.getMinutes() < 10 ? "0" + fecha.getMinutes() : fecha.getMinutes();
    var horas =
      fecha.getHours() < 10 ? "0" + fecha.getHours() : fecha.getHours();
    var dia = fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
    var mes =
      fecha.getMonth() + 1 < 10
        ? "0" + (fecha.getMonth() + 1)
        : fecha.getMonth() + 1;
    var year = fecha.getFullYear();
    var fechaFinal =
      year + "-" + mes + "-" + dia + " " + horas + ":" + min + ":" + seg;

    return fechaFinal;
  };

  render() {
    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;

    const borrarRegistrosChip = (
      <a
        onClick={(e) => this.borrarRegistrosDeChip(e, eventoIndex)}
        className="waves-effect waves-light btn"
      >
        <i className="material-icons right">delete</i>Borrar Registros Chip
      </a>
    );

    const subirDatosChip = (
      <a
        onClick={(e) => this.readFilesUploadData(e, true, eventoIndex)}
        className="waves-effect waves-light btn"
      >
        <i className="material-icons right">send</i>Enviar Registros Chip
      </a>
    );

    return (
      <div className="row">
        <div className="col s12 m8 offset-m2 l8 offset-l2">
          <h5>Requisitos de la data a subir</h5>
          <ul>
            <li>Formato del archivo CSV</li>
            <li>
              Al menos una columna de nombre "Número" con el folio de los
              atletas
            </li>
            <li>
              Otra columna de nombre "Tiempo" con el tiempo de meta en formato
              "hh:mm:ss"
            </li>
            <li>
              Otra columna de nombre "Control" con el codigo de punto de control
              en formato INT
            </li>
          </ul>

          <div id="idEventoLabel"></div>
          <div className="file-field input-field">
            <div className="btn">
              <span>File</span>
              <input
                type="file"
                id="fileForUpload"
                onChange={(e) =>
                  this.readFilesUploadData(e, false, eventoIndex)
                }
                multiple
              />
            </div>
            <div className="file-path-wrapper">
              <input
                className="file-path validate"
                type="text"
                placeholder="Upload one or more files"
              />
            </div>
          </div>
          {subirDatosChip}
          {borrarRegistrosChip}
          <div id="ConfiguracionEvento">{this.state.displayData}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const UploadResultsData = connect(mapStateToProps)(UploadResultsDataView);

export default UploadResultsData;
