import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "./SimpleDialog";
import FormRender from "../../Fields/FormRender";
import {
  updateEventAttribute,
  createEventAttribute,
} from "../../../data-store/actions/events-actions";
import EditableField from "../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateMiniThemeCopy(props) {
  const { pageNew, callback } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const classes = useStyles();

  function handleChange(value) {
    console.log("miniTheme", value);
    console.log("miniTheme", pageNew);
    const page = JSON.parse(JSON.stringify(pageNew));
    if (page.miniTheme === undefined) {
      page.miniTheme = {};
    }

    page.miniTheme[Object.keys(value)[0]] = Object.values(value)[0];
    console.log("miniTheme", page);

    callback(page);
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Tema de la web
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {form &&
              Object.values(form).map((field, index) => {
                const defaultValue =
                  pageNew &&
                  pageNew.miniTheme !== undefined &&
                  pageNew.miniTheme[field.fieldName] !== undefined
                    ? pageNew.miniTheme[field.fieldName]
                    : field.defaultValue;

                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Editar Tema"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
const form = {
  "1primary": {
    label: "Color Primario",
    errorMessage: "Campo Requerido",
    fieldName: "primary",
    type: "color",
    defaultValue: "#ff4400",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "2secondary": {
    label: "Color Secundario",
    errorMessage: "Campo Requerido",
    fieldName: "secondary",
    type: "color",
    defaultValue: "#0044ff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "3text": {
    label: "Color de texto",
    errorMessage: "Campo Requerido",
    fieldName: "text",
    type: "color",
    defaultValue: "#0044ff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "3background": {
    label: "Color de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "background",
    type: "color",
    defaultValue: "#ffffff",
    required: true,
    regex: "",
    textSize: "h6",
  },
  "2backgroundImage": {
    label: "Imagen de fondo",
    errorMessage: "Campo Requerido",
    fieldName: "backgroundImage",
    type: "photo",
    defaultValue: "",
    required: false,
    regex: "",
    textSize: "h6",
  },
  "2fontFamily": {
    label: "Fuente",
    errorMessage: "Campo Requerido",
    fieldName: "fontFamily",
    type: "select",
    defaultValue: "p",
    required: false,
    regex: "",
    textSize: "h6",
    optionsArray: [
      {
        value: "MavenProRegular, MavenProBold,Helvetica, Arial",
        label: "Maven pro",
      },
      {
        value: "Forza, MavenProBold,Helvetica, Arial",
        label: "Forza",
      },
      {
        value: "Arial,Helvetica",
        label: "Arial",
      },
      {
        value: "Helvetica,Arial",
        label: "Helvetica",
      },
      {
        value: "Redig,Arial",
        label: "Redig",
      },
      {
        value: "BebasNeue-Regular,Arial",
        label: "BebasNeue-Regular",
      },
    ],
  },
};
