import { combineReducers } from "redux";
import events from "./events-reducer";
import atlete from "./atlete-reducer";
import registry from "./registry-reducer";
import organizer from "./organizer-reducer";
//import timing from "./timing-reducer";
import tournaments from "./tournaments-reducer";

export default combineReducers({
  events,
  atlete,
  registry,
  organizer,
  //  timing,
  tournaments,
});
