import React from "react";
import "../pages/Modals/atleteDetailCss.css";

import AtleteDetailsContainer from "../pages/reports/atleteDetail/AtleteDetailContainer";

class AtleteDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { animation: "", show: true };
    this.eventDetail = {
      ciudadEvento: "Ticul - Santa Elena",
      cronometrajeDisponible: true,
      descripcionEvento: "Por los Caminos del Sur Ticul - Santa Elena",
      eventoDisponible: 4,
      fechaEvento: "2017-07-20",
      horaEvento: "06:30:00",
      iDEvento: 68,
      imagenEvento: "EventosLogo/TiculSantaElena2017.jpg",
      logoEvento:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/EventosLogo%2FTiculSantaElena2017.jpg?alt=media&token=e9f73889-cfdc-442b-8829-c7d95a1c170a",
      lugarEvento: "Ticul Yucatán",
      nombreEvento: "Por los Caminos del Sur",
      nombreEventoCorto: "Por los Caminos del Sur",
      organizador: {
        colorOrganizador: "#000000",
        linkEvento: "https://dashport.run/proximos-eventos/evento_86",
        logoOrganizador:
          "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
        nombreOrganizador: "powered by Dashport",
        telefonoContacto: 9997405097,
        webOrganizador: "https://dashport.run/",
      },
      organizadorEvento: "Koonex Alkab",
      paisEvento: "México",
      tipoDeEvento: "Asfalto",
    };

    this.resultado = {
      ab: 0,
      cat: "Sub Master",
      cg: 5,
      codCat: 2,
      codMod: 1,
      fhm: "2017-08-20 07:23:23.852",
      gen: "V",
      mod: 14.5,
      mp: 0,
      nombre: "MARCO TARDELLY FLORES PARTIDA",
      num: 1,
      pCat: 2,
      pG: 3,
      pR: 3,
      parciales: [
        {
          fechaHoraPunto: "2017-08-20 06:49:05.414",
          kpc: 5,
          latLng: "1,1",
          pCat: 1,
          tkpc: "00:19:18",
        },
        {
          fechaHoraPunto: "2017-08-20 07:06:06.451",
          kpc: 10,
          latLng: "1,1",
          pCat: 2,
          tkpc: "00:36:19",
        },
      ],
      serie: 1,
      tc: "00:53:36",
      to: "00:53:36",
    };
  }
  componentDidMount() {
    this.setState({ animation: "OpenAtlete" });
  }
  render() {
    /*
        if(!this.props.show){
			return null;
		}
*/
    return (
      <div>
        <div onClick={this.props.onClose} className="ModalAtleteBack"></div>
        <div className={"ModalAtlete " + this.state.animation} id="ModalAtlete">
          <span
            onClick={this.props.onClose}
            className="btn-floating cerrarModal"
          >
            <i className="material-icons">close</i>
          </span>
          <AtleteDetailsContainer
            resultado={this.resultado}
            eventDetail={this.eventDetail}
          />
        </div>
      </div>
    );
  }
}
export default AtleteDetailsModal;
