import React from "react";
import { connect } from "react-redux";
import { configuracionEvento } from "../../../data-store/actions/events-actions";

import TitleResultReport from "./TitleResultReport";
import TitleResultReportDefault from "./TitleResultReportDefault";

import AtleteDetailsModal from "../Modals/atleteDetailsModal";
import AtleteDetailsContainer from "../reports/atleteDetail/AtleteDetailContainer";

import { ControlesAtleta } from "./reportsLogic";
import { DescargarCertificadoReg } from "./atleteDetail/certificadoLogic";

import FormDialog from "../Modals/FormDialog";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SimpleDialogDemo from "../../Landing/LayoutContainers/SimpleDialog";
import DNFAndDQView from "./DNFAndDQView";
import { Button } from "@material-ui/core";

class RowResultReportView extends React.Component {
  // this.props.data = {typeRow:1, reportType:tipoReporte , mod:Mod, cat:Cat, gen:Gen, ab:false}
  // or {typeRow:2, result:Resultadosdelatelta en BD}
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  descargarCertificadoReg = (
    e,
    eventDetail,
    resultadoJSON,
    tipo,
    certificateConfig
  ) => {
    e.preventDefault();
    console.log("descargar certificadoaa", certificateConfig);
    DescargarCertificadoReg(
      eventDetail,
      resultadoJSON,
      tipo,
      certificateConfig
    );
  };

  render() {
    const certificateConfig =
      this.props.eventConfiguration.timingConfig &&
      this.props.eventConfiguration.timingConfig.certificateConfig
        ? this.props.eventConfiguration.timingConfig.certificateConfig
        : false;

    var resultado;

    var element;

    var typeRow = this.props.data.typeRow;
    if (typeRow == 1) {
      // titulo

      element = (
        <TitleResultReport
          data={this.props.data}
          controles={this.props.controles}
          controlesArray={this.props.controlesArray}
        />
      );
    } else if (typeRow == 3) {
      element = (
        <TitleResultReportDefault
          data={this.props.data}
          controles={this.props.controles}
          controlesArray={this.props.controlesArray}
        />
      );
    } else {
      // fila de resutlado de un atleta

      var eventDetail = this.props.eventDetail;

      function setStatus(resultadoFunc, eventConfigurationFunc) {
        resultadoFunc.text = "";
        resultadoFunc.button = "";
        if (resultadoFunc.nombre.indexOf("*") > 0) {
          resultadoFunc.pG = 0;
          resultadoFunc.pR = 0;
          resultadoFunc.pCat = 0;
          resultadoFunc.dnfPlus = true;
        }
        if (resultadoFunc.borrado) {
          resultadoFunc.dq = true;
        }
        if (!resultadoFunc.borrado && resultadoFunc.pG === 0) {
          resultadoFunc.dnf = true;
        }
        if (
          !resultadoFunc.registroSalida &&
          !resultadoFunc.parciales &&
          resultadoFunc.pG === 0
        ) {
          resultadoFunc.dns = true;
        }
        if (resultadoFunc.dnfPlus && !resultadoFunc.dns) {
          resultadoFunc.text =
            "El atleta no finalizó el evento en el tiempo previsto para la distancia  ";
          resultadoFunc.button = "DNF";
        } else if (resultadoFunc.dnf && !resultadoFunc.dns) {
          resultadoFunc.text = "El atleta no finalizó el evento  ";
          resultadoFunc.button = "DNF";
        } else if (resultadoFunc.dq) {
          resultadoFunc.text =
            "El atleta fué descalificado por los jueces del evento";
          resultadoFunc.button = "DQ";
        } else if (resultadoFunc.dns && !resultadoFunc.dq) {
          resultadoFunc.text = "El atleta no inició el evento";
          resultadoFunc.button = "DNS";
        }

        return resultadoFunc;
      }

      resultado = {
        ...this.props.data.result,
        ...setStatus(this.props.data.result, this.props.eventConfiguration),
      };

      var controlesAtleta = [];
      var controlesTD;
      if (this.props.controles) {
        controlesAtleta = ControlesAtleta(
          this.props.puntosControl,
          resultado,
          this.props.controlesArray
        );

        console.log(
          "controlesAtleta",
          resultado.num,
          controlesAtleta,
          this.props.puntosControl
        );
        controlesTD = controlesAtleta.map((control, index) => (
          <TableCell key={index} className={control.class}>
            {control.value}
          </TableCell>
        ));
      }
      console.log("controlesTD", controlesTD);

      const posiciones = [];
      if (resultado.pG === 0) {
        posiciones.push(
          <TableCell colSpan={3}>
            <SimpleDialogDemo fullScreen={false} buttonText={resultado.button}>
              <DNFAndDQView text={resultado.text} />
            </SimpleDialogDemo>
          </TableCell>
        );
      } else {
        posiciones.push(<TableCell>{resultado.pG}</TableCell>);
        posiciones.push(<TableCell> {resultado.pR}</TableCell>);
        posiciones.push(<TableCell>{resultado.pCat}</TableCell>);
      }

      element = (
        <TableRow>
          <TableCell>
            {resultado.num}
            {resultado.borrado && "*"}
          </TableCell>
          {posiciones}
          <TableCell style={{ width: "700px" }} scope="row">
            <FormDialog
              buttomLabel={resultado.nombre}
              ShowIcon={false}
              ButtonStyle={null}
              maxWidthProp={"lg"}
              style={{
                minHeight: "80vh",
                maxHeight: "80vh",
              }}
              report={
                <AtleteDetailsContainer
                  eventDetail={this.props.eventDetail}
                  resultado={resultado}
                />
              }
            />
          </TableCell>
          <TableCell>{resultado.gen}</TableCell>
          <TableCell>{resultado.cat}</TableCell>
          <TableCell>{resultado.mod}</TableCell>
          {controlesTD}
          <TableCell>{resultado.to}</TableCell>
          <TableCell>{resultado.tc}</TableCell>
          <TableCell>
            <Button
              onClick={(e) =>
                this.descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  2,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat"
              target="_blank"
            >
              JPG
            </Button>
            {/* <Button
              onClick={(e) =>
                this.descargarCertificadoReg(
                  e,
                  eventDetail,
                  resultado,
                  1,
                  certificateConfig
                )
              }
              className="waves-effect waves-teal btn-flat"
              target="_blank"
            >
              PDF
            </Button> */}
          </TableCell>
        </TableRow>
      );
    }

    return element;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
  };
};

const RowResultReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(RowResultReportView);

export default RowResultReport;
