import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import { createEventAttribute } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateInput(props) {
  const { inputGroup } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const createEventObject = createEventConfig.adicionalInput;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function test(data) {
    console.log(data);
    const newInput = {
      anchoCampo: data.anchoCampo || "s12 m6 l6",
      campo: 0,
      etiqueta: data.etiqueta || "Nombre del campo",
      iDCampo: "textoPlayera",
      longitud: 100,
      observacion: data.observaciones || "",
      regex: data.regex || "",
      regexMessage: data.regexMessage || "",
      tipoCampo: data.tipoCampo || "text",
      validacion: data.validacion || "Requerido",
      valor: data.vaor || "",
    };
  }
  function onSubmit(data) {
    console.log("createInput", data);
    let inputGroupNew = inputGroup;
    let index = 0;
    if (inputGroupNew === null) {
      if (registryConfiguration.gruposFormularios !== undefined) {
        inputGroupNew =
          "campos_" +
          (parseInt(
            Object.keys(registryConfiguration.gruposFormularios).length
          ) +
            1);
      } else {
        inputGroupNew = "campos_1";
      }
    }
    if (
      registryConfiguration.gruposFormularios !== undefined &&
      registryConfiguration.gruposFormularios[inputGroupNew] !== undefined
    ) {
      index = registryConfiguration.gruposFormularios[inputGroupNew].length;
    }
    const route = `inscripcionesConfiguracion/gruposFormularios/${inputGroupNew}/${index}/`;

    if (data.tipoCampo === "Select") {
      const valorSplit = data.valor.split(";");
      data.valor = valorSplit.map((a) => ({ texto: a, valor: a }));
    }
    const newInput = {
      anchoCampo: data.anchoCampo || "s12 m6 l6",
      campo: index,
      etiqueta: data.etiqueta || "Nombre del campo",
      iDCampo:
        data.etiqueta
          .replace(/[^\w\s]|_/g, "")
          .replace(/\s/g, "")
          .toLowerCase() || "idcampo",
      longitud: 100,
      observacion: data.observaciones || "",
      regex: data.regex || "",
      regexMessage: data.regexMessage || "",
      tipoCampo: data.tipoCampo || "text",
      validacion: data.validacion || "Requerido",
      valor: data.valor || "",
    };

    dispatch(
      createEventAttribute("evento_" + eventDetail.iDEvento, route, newInput)
    );
    // registrar campo en modalidades
    if (inputGroupNew !== "general") {
      for (var i in eventSubEventsRegistry) {
        const newRoute = `modalidadesInscripcion/${i}/configModalidad/camposAdicionalesModalidad/${inputGroupNew}/${index}/`;
        dispatch(
          createEventAttribute(
            "evento_" + eventDetail.iDEvento,
            newRoute,
            newInput
          )
        );
      }
    } else {
      const newRoute = `inscripcionesConfiguracion/formulario/${index}/`;
      dispatch(
        createEventAttribute(
          "evento_" + eventDetail.iDEvento,
          newRoute,
          newInput
        )
      );
    }
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea un nuevo input
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={
                inputGroup === null
                  ? "Crear un grupo de campos nuevo"
                  : "Crear un campo"
              }
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo
          buttonText={
            inputGroup === null
              ? "Crear un grupo de campos nuevo"
              : "Crear un campo"
          }
        >
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
