// {
//   "anchoCampo" : "s12 m12 l12",
//   "campo" : 0,
//   "etiqueta" : "Talla de playera",
//   "iDCampo" : "talladeplayera",
//   "longitud" : 100,
//   "observacion" : "",
//   "regex" : "",
//   "regexMessage" : "",
//   "tipoCampo" : "Select",
//   "validacion" : "NoRequerido",
//   "valor" : [ {
//     "texto" : "XS",
//     "valor" : "XS"
//   }, {
//     "texto" : "S",
//     "valor" : "S"
//   }, {
//     "texto" : "M",
//     "valor" : "M"
//   }, {
//     "texto" : "L",
//     "valor" : "L"
//   }, {
//     "texto" : "XL",
//     "valor" : "XL"
//   } ]
// }

//------------------------------------------------------------------------------------
export const translateForm = (formObject) => {
  // function para convertir un objeto del formato dashport a un formato  origina
  let object = {};
  for (var i in formObject) {
    object[i] = translateInput(formObject[i]);
  }
  return object;
};
//------------------------------------------------------------------------------------
export const translateInput = (originalInput) => {
  // function para convertir un objeto del formato dashport a un formato  origina

  const tipoCampo = {
    Select: "select",
    text: "text",
  };

  let defaultValue = "";

  if (
    originalInput.tipoCampo !== "Select" ||
    originalInput.tipoCampo !== "option"
  ) {
    defaultValue = originalInput.valor === " " ? "" : originalInput.valor;
  }
  if (originalInput.tipoCampo === "checkbox") {
    if (originalInput.valor === true || originalInput.valor === "true") {
      defaultValue = true;
    } else {
      defaultValue = false;
    }
  }

  let object = {
    order: originalInput.order || 100,
    label: originalInput.etiqueta || "",
    errorMessage:
      originalInput.regexMessage ||
      originalInput.observacion ||
      "Campo requerido",
    fieldName: originalInput.iDCampo || "",
    type:
      originalInput.tipoCampo === "Select"
        ? "select"
        : originalInput.tipoCampo || "text",
    defaultValue: defaultValue,
    required: originalInput.validacion === "Requerido" ? true : false,
    regex: originalInput.regex || "",
    textSize: "h6",
    optionsArray: [
      {
        value: "s12 m12 l12",
        label: "Ancho completo para todas las pantallas",
      },
      {
        value: "s12 m6 l6",
        label: "Ancho completo para Moviles y 50% para pantallas grandes",
      },
      {
        value: "s12 m4 l4",
        label: "Ancho completo para Moviles y 1/3 para pantallas grandes",
      },
    ],
  };

  if (originalInput.tipoCampo === "Select") {
    console.log("originalInput", originalInput);
    object.optionsArray = originalInput.valor.map((a) => ({
      label: a.texto,
      value: a.valor,
    }));
  }
  if (originalInput.tipoCampo === "option") {
    object.optionsArray = originalInput.valor.map((a) => ({
      label: a.texto,
      value: a.valor,
    }));
  }

  return object;
};
