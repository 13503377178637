import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import {
  updateEventAttribute,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import FormRender from "../../../../Fields/FormRender";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputLabelTitle: {
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));
export default function AsignImportedDataToConvert() {
  const dispatch = useDispatch();
  const [data, setData] = useState(false);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  useEffect(() => {}, [eventSubEvents, eventSubEventsRegistry]);

  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: 20 }}
    >
      <Grid item xs={12}>
        {eventConfiguration &&
          eventConfiguration.timingConfig &&
          eventConfiguration.timingConfig.asingToImport &&
          Object.values(
            eventConfiguration.timingConfig.asingToImport
          ).map((a) => <div>{JSON.stringify(a)}</div>)}
      </Grid>
      <Grid item xs={6}>
        {JSON.stringify(data)}
      </Grid>
    </Grid>
  );
}
