import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import PushNotificationComponent from "./PushNotificationComponent";
import SimpleSnackbar from "./components/Dialog/SimpleSnackbar";

import "./App.css";
import Root from "./Root";
//import Router from './components/navigation/Router'

import { BrowserRouter } from "react-router-dom";

import Footer from "./components/footer/";
//import ParallaxDashport from "./components/navigation-ui/ParallaxDashport";

import AppBarDashport from "./components/navigation-ui/AppBarDashport";

import {
  initEvents,
  eventCreateSubEventsRegistry,
} from "./data-store/actions/events-actions";
import { atleteAccount } from "./data-store/actions/atlete-actions";

const theme = createMuiTheme({
  typography: {
    fontFamily: "MavenProRegular, MavenProBold,Helvetica, Arial, sans-serif",
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 700,
    fontWeightMedium: 500,
    color: "#ffffff",
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ff4400",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#000000",
      main: "#2222221",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#000000",
    },
    // error: will use the default color
  },
  spacing: 4,
});

const App = ({ store }) => {
  store.dispatch(initEvents());
  store.dispatch(atleteAccount());

  const element = (
    <Provider store={store}>
      {/* <PushNotificationComponent /> */}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBarDashport>
            <Container
              maxWidth="md"
              style={{ marginTop: "150px", marginBottom: "50px" }}
            >
              <Root />
            </Container>
            <Footer />
          </AppBarDashport>
          <SimpleSnackbar />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

  return element;
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
