import React from "react";
import { connect } from "react-redux";
import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import ParticipantDetailContainerReports from "./ParticipantDetailContainerReports";

import DeleteParticipantButtomDetail from "./DeleteParticipantButtomDetail";
import ModCatGenDetail from "./ModCatGenDetail";
import EditableInput from "../../../../DynamicInput/EditableInput";
import ParticipanStandarData from "./ParticipanStandarData";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import UpdateRaceNumber from "./UpdateRaceNumber";
import { Button, Typography } from "@material-ui/core";

class ParticipantDetailContainerView extends React.Component {
  constructor(props) {
    super(props);
  }

  copyLink = () => {
    console.log("copiado");
    var ruta = this.props.registryConfiguration.configuracion
      .rutaDetalleInscrito;

    const eventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    var rutaCompleta =
      ruta === "https://dashport.run/#/detalle-inscrito/"
        ? "https://dashport.run/detalle-inscrito" +
          "?event=" +
          eventoIndex +
          "&registry=" +
          this.props.ParticipantDetailKey +
          "&download=" +
          true
        : ruta +
          "?event=" +
          eventoIndex +
          "&registry=" +
          this.props.ParticipantDetailKey +
          "&download=" +
          true;
    navigator.clipboard.writeText(rutaCompleta);
  };
  render() {
    var numero = "No asignado";
    let ParticipantDetail = this.props.eventParticipants[
      this.props.ParticipantDetailKey
    ];
    if (ParticipantDetail.numero != undefined) {
      numero = ParticipantDetail.numero;
    }

    var style = {};
    if (ParticipantDetail.borrado) {
      style = { backgroundColor: "#CFCFCF" };
    } else {
      style = { backgroundColor: "white" };
    }
    return (
      <div className="padding overflowTrue" style={style}>
        <div>
          <div className="row">
            <span className="col s8 m8 l8">
              <h4>Datos del atleta inscrito </h4>
              <p>
                {numero > 0 ? (
                  <SimpleDialogDemo buttonText={"Número " + numero}>
                    <UpdateRaceNumber
                      callback={null}
                      eventIndex={"evento_" + this.props.eventDetail.iDEvento}
                      raceNumber={numero}
                      idRegistro={this.props.ParticipantDetailKey}
                      ParticipantDetail={this.props.ParticipantDetail}
                      eventParticipants={this.props.eventParticipants}
                    />
                  </SimpleDialogDemo>
                ) : (
                  <Typography
                    color="primary"
                    component="h2"
                    variant="subtitle1"
                  >
                    Número no asignado
                  </Typography>
                )}
                <Typography color="primary" component="h2" variant="subtitle1">
                  Interno: <b>{this.props.ParticipantDetailKey}</b>
                </Typography>
              </p>
            </span>
            <span
              id="botonBorrarInscritoCont"
              className="col s4 m4 l4 center-align"
            >
              <DeleteParticipantButtomDetail
                ParticipantDetail={ParticipantDetail}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                eventDetail={this.props.eventDetail}
                atleteAccount={this.props.atleteAccount}
                userID={this.props.userID}
              />
            </span>
            <Button onClick={() => this.copyLink()}>Link de registro</Button>
            {/* <a
              className="btn-flat"
              onClick="modalConfirmacionFuncPrevent(event, 1, '${idRegistro}')"
            >
              Imprimir
            </a> */}
          </div>
          <ParticipanStandarData
            ParticipantDetail={ParticipantDetail}
            eventDetail={this.props.eventDetail}
            userID={this.props.userID}
            atleteAccount={this.props.atleteAccount}
            ParticipantDetailKey={this.props.ParticipantDetailKey}
            registryConfiguration={this.props.registryConfiguration}
          />
          <div className="row" id="ModCatGenDivCont">
            <ModCatGenDetail
              ParticipantDetail={ParticipantDetail}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              eventDetail={this.props.eventDetail}
              eventSubEventsRegistry={this.props.eventSubEventsRegistry}
              registryAutorizedUsers={this.props.registryAutorizedUsers}
              userID={this.props.userID}
              atleteAccount={this.props.atleteAccount}
            />
          </div>
          <ParticipantDetailContainerReports
            ParticipantDetail={ParticipantDetail}
            ParticipantDetailKey={this.props.ParticipantDetailKey}
            eventDetail={this.props.eventDetail}
            eventSubEventsRegistry={this.props.eventSubEventsRegistry}
            registryConfiguration={this.props.registryConfiguration}
            userID={this.props.userID}
            atleteAccount={this.props.atleteAccount}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventParticipants: state.registry.eventParticipants,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    eventSubEventsRegistrySnap: state.events.eventSubEventsRegistrySnap,
    userID: state.atlete.userID,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const ParticipantDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantDetailContainerView);
export default ParticipantDetailContainer;
