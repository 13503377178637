import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";

export let store;
export default function configureStore() {
  var initialState = {
    atlete: {
      activitiesGarminLoading: false,
      userID: undefined,
    },
    events: {
      eventDetail: {},
      eventSubEvents: {},
      eventSubEventsRegistry: {},
      eventConvocatory: [],
      eventWeb: {},
    },
    registry: {
      eventParticipants: {},
      formData: false,
      codesList: false,
      codesGroups: false,
    },
    organizer: {
      snackbar: { show: false, text: "", duration: 0 },
    },
  };

  store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, logger)
  );

  return store;
}
