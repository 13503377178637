import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";

import EditableField from "../../../../EditableField/EditableField";
import Field from "../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import DefaultDistancesAndCategories from "./DefaultDistancesAndCategories";
import TimingConfigSubEvents from "./TimingConfigSubEvents";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function DistancesAndCategories(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Distancias y Categorías
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}>
            <SimpleDialogDemo buttonText={"Distancias por defecto"}>
              <DefaultDistancesAndCategories />
            </SimpleDialogDemo>
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}>
            <TimingConfigSubEvents />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
