import React from "react";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { Button, Paper } from "@material-ui/core";

import { auth } from "../../firebase/firebasejs";
//import M from 'materialize-css'

export default function PassworRestore() {
  const { register, handleSubmit, errors } = useForm();

  function onSubmit(data) {
    auth.sendPasswordResetEmail(data.correo).then(
      () => {
        // Email sent.
        // M.toast("Se ha enviado un correo a tu cuenta", 4000);
      },
      (error) => {
        // An error happened.
        //M.toast("Error en correo", 4000);
      }
    );
    console.log("rec contrasena");
  }
  return (
    <Grid container justify="center" spacing={3}>
      <Paper style={{ padding: 40 }}>
        <Grid item xs={12}>
          <h4>Recuperar Contraseña</h4>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid item xs={12}>
            <label>Correo</label>
            <input
              name="correo"
              type="email"
              ref={register({ required: true })}
            />
            {errors.correo && "Campo requerido"}
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" primary>
              Recuperar Contraseña
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
