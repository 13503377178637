import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../Modals/SimpleDialog";

import EditableField from "../../../EditableField/EditableField";
import { setCustomView } from "../../../../data-store/actions/events-actions";
import CustomViewsConfig from "./CustomViewsConfig";
import DragDropContextBox from "./DragDropContextBox";
import DragableBoxEdit from "./DragableBoxEdit";
import DragDropContextBoxPlain from "./DragDropContextBoxPlain";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "30px",
    margin: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CustomView(props) {
  const { view, callback, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const [showViewEdit, setshowViewEdit] = useState(-1);
  const [showEditSection, setshowEditSection] = useState(false);

  const classes = useStyles();

  function addBox() {
    view.views.push({
      text: "Nueva",
      fontFamily: "MavenProRegular, MavenProBold,Helvetica, Arial",
      position: "center",
    });

    callback(view, index);
  }
  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setshowEditSection(!showEditSection)}
            >
              Editar
            </Button>
          </Grid>{" "}
          <Grid item xs={10}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {(view && view.miniTheme && view.miniTheme.name) || ""}
            </Typography>
          </Grid>
          {showEditSection && (
            <div style={{ width: "900px" }}>
              <Grid item xs={12}>
                <Typography gutterBottom variant={"subtitle1"} component="h2">
                  Configuración de la vista
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={6}>
                    <CustomViewsConfig
                      index={index}
                      view={view}
                      callback={callback}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={() => addBox()}>Agregar Caja</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} style={{ margin: "10px" }}>
                {showViewEdit >= 0 && (
                  <DragableBoxEdit
                    index={index}
                    view={view}
                    callback={callback}
                    itemIndex={showViewEdit}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
                <DragDropContextBox
                  view={view}
                  showEdition={setshowViewEdit}
                  index={index}
                  callback={callback}
                />
              </Grid>
            </div>
          )}
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <DragDropContextBoxPlain
              view={view}
              showEdition={setshowViewEdit}
              index={index}
              callback={callback}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
