import React from "react";
import { connect } from "react-redux";

import { fechahora } from "../../../../../services/utils";
import { db } from "../../../../../firebase/firebasejs";
import FormRender from "../../../../Fields/FormRender";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";

//import M from 'materialize-css'

class AddAutorizedUserView extends React.Component {
  sendForm = (data) => {
    var datosAutorizado = {
      correo: data.correo.toLowerCase(),
      perfil: data.perfil,
      fechaAutoriza: fechahora(),
      userIdAuth: this.props.userID,
      status: "",
    };
    console.log(datosAutorizado);

    // Get a key for a new Post.
    var newPostKey = db
      .ref()
      .child(
        `/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/`
      )
      .push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.

    var Ref = db.ref(
      `/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/${newPostKey}/status/`
    );
    Ref.on("value", function(snapshot) {
      var status = snapshot.val();
      if (status == "ok") {
        //M.toast('Agregado exitosamente', 2000);
      } else if (status == "" || status == null) {
      } else {
        //M.toast( status, 2000);
      }
    });

    var updates = {};
    updates[
      `/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/${newPostKey}/`
    ] = datosAutorizado;
    return db.ref().update(updates);
  };

  render() {
    const filterSelect = {
      correo: {
        label: "Correo",
        errorMessage: "Campo Requerido",
        fieldName: "correo",
        type: "text",
        defaultValue: "",
        required: true,
        regex: "",
        textSize: "h6",
      },
      filter: {
        label: "Perfil",
        errorMessage: "Campo Requerido",
        fieldName: "perfil",
        type: "select",
        defaultValue: 1,
        required: true,
        regex: "",
        textSize: "h6",
        optionsArray: [
          {
            label: "Punto de Venta",
            value: "inscriptor",
          },
          {
            label: "Master Organizador",
            value: "MasterOrganizador",
          },
          {
            label: "Master Cliente",
            value: "MasterCliente",
          },
          {
            label: "Entrega de kits",
            value: "Kits",
          },
          {
            label: "Editor de Página Web",
            value: "Web",
          },
          {
            label: "Sin Acceso",
            value: "sinacceso",
          },
        ],
      },
    };

    return (
      <Paper style={{ margin: 5, padding: 20, minHeight: 320 }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Agregar usuarios autorizados
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormRender
              callback={this.sendForm}
              formObject={filterSelect}
              submitButtonText={"Autorizar"}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    EventoIndex: "evento_" + state.events.eventDetail.iDEvento,
    userID: state.atlete.userID,
  };
};

const AddAutorizedUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAutorizedUserView);

export default AddAutorizedUser;
