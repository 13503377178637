import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { fechahora, timestampToDate } from "../../../../../services/utils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CodeUses(props) {
  const { code } = props;

  const classes = useStyles();
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );

  function getModCat(modalidad) {
    return eventSubEventsRegistry["modalidad_" + modalidad]
      .descripcionModalidad;
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Monto</TableCell>
            <TableCell align="center">Correo atleta</TableCell>
            <TableCell align="center">Modalidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {code &&
            code.uses &&
            Object.values(code.uses).map((row) => (
              <TableRow key={row.date}>
                <TableCell align="center" component="th" scope="row">
                  {timestampToDate(row.date)}
                </TableCell>
                <TableCell align="center">{row.amount || "N/A"}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">
                  {getModCat(row.subEvent.modalidad)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
