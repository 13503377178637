import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import { createEventAttribute } from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CreateConvocatoryItem(props) {
  const { inputGroup } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventConvocatory = useSelector(
    (state) => state.events.eventConvocatory
  );
  let createEventObject = createEventConfig.convocatory;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function onSubmit(data) {
    console.log(data);
    let indexNumber = 0;
    if (eventConvocatory !== undefined && eventConvocatory !== null) {
      indexNumber = eventConvocatory.length;
    }
    const route = `convocatoria/${indexNumber}/`;
    let value = data.value;
    if (data.type === "image") {
      value = data.photo;
    } else if (data.type === "array") {
      value = data.value.split(";");
    }

    const newInput = {
      htmlLabel: data.htmlLabel || "p",
      name: data.name || "vacio",
      type: data.type || "text",
      value: value,
    };

    dispatch(
      createEventAttribute("evento_" + eventDetail.iDEvento, route, newInput)
    );
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              Crea una sección de la convocatoria
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            <FormRender
              callback={onSubmit}
              formObject={createEventObject}
              submitButtonText={"Crear sección"}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Crear una Sección"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
