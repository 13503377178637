import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createEventConfig from "../EventCreate/createEventconfig.json";
import {
  updateEventAttribute,
  createEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateProduct(props) {
  const { item, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventConvocatory = useSelector(
    (state) => state.events.eventConvocatory
  );

  const createEventObject = createEventConfig.convocatory;
  //delete createEventObject.value1;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "convocatoria/" + index + "/",
        value
      )
    );
  }
  function deleteItem(itemIndex) {
    console.log(itemIndex);
    let convocatory = [];
    eventConvocatory.map((a, i) => {
      return i !== itemIndex ? convocatory.push(a) : null;
    });
    console.log(convocatory);
    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "convocatoria/",
        convocatory
      )
    );
  }
  function moveUp(itemIndex) {
    console.log(itemIndex);
    let convocatory = { ...eventConvocatory };
    convocatory[itemIndex] = convocatory[itemIndex - 1];
    convocatory[itemIndex - 1] = item;

    console.log(convocatory);
    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "convocatoria/",
        convocatory
      )
    );
  }
  function moveDown(itemIndex) {
    console.log(itemIndex);
    let convocatory = { ...eventConvocatory };
    convocatory[itemIndex] = convocatory[itemIndex + 1];
    convocatory[itemIndex + 1] = item;

    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "convocatoria/",
        convocatory
      )
    );
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {item.name}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {index})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button primary onClick={() => deleteItem(index)}>
              Borrar Sección
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {Object.values(createEventObject).map((field, index) => {
              const defaultValue = item[field.fieldName];

              return (
                <EditableField
                  key={index}
                  fieldObject={{
                    ...field,
                    type:
                      item.type === "image" && field.fieldName === "value"
                        ? "photo"
                        : field.type,
                    defaultValue: defaultValue,
                  }}
                  onSubmit={handleChange}
                />
              );
            })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6}>
        <SimpleDialogDemo buttonText={"Editar Sección"}>
          {eventView}
        </SimpleDialogDemo>
      </Grid>
      <Grid item xs={6}>
        {index > 0 && (
          <IconButton onClick={() => moveUp(index)}>
            <ArrowUpwardIcon />
          </IconButton>
        )}
        {index + 2 <= eventConvocatory.length && (
          <IconButton onClick={() => moveDown(index)}>
            <ArrowDownwardIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
