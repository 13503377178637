import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomViews,
  setCustomView,
  updateEventObject,
} from "../../../../data-store/actions/events-actions";

import CustomView from "./CustomView";

function CustomViewContainer() {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const customViews = useSelector((state) => state.events.customViews);

  useEffect(() => {
    if (eventDetail) {
      dispatch(getCustomViews("evento_" + eventDetail.iDEvento));
    }
  }, [eventDetail]);

  function createView() {
    console.log("evento_" + eventDetail.iDEvento);
    if (customViews) {
      dispatch(
        updateEventObject(
          "evento_" + eventDetail.iDEvento,
          `/customViews/${customViews.length}/`,
          {
            miniTheme: {
              name: "Por definir",
              height: 500,
              width: 500,
            },
            views: [
              {
                text: "Vacio",
                fontFamily: "MavenProRegular, MavenProBold,Helvetica, Arial",
                position: "center",
              },
            ],
          }
        )
      );
    } else {
      dispatch(
        updateEventObject(
          "evento_" + eventDetail.iDEvento,
          `/customViews/${0}/`,
          {
            miniTheme: {
              name: "Por definir",
              height: 500,
              width: 500,
            },
            views: [
              {
                text: "Vacio",
                fontFamily: "MavenProRegular, MavenProBold,Helvetica, Arial",
                position: "center",
              },
            ],
          }
        )
      );
    }
  }
  function saveViewChanges(view, index) {
    console.log("evento_" + eventDetail.iDEvento);
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        `/customViews/${index}/`,
        view
      )
    );
  }
  return (
    <div>
      <Button onClick={() => createView()}>Crear una vista nueva</Button>
      {customViews &&
        customViews.map((view, index) => (
          <CustomView view={view} callback={saveViewChanges} index={index} />
        ))}
    </div>
  );
}

export default CustomViewContainer;
