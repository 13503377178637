import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, IconButton, Paper } from "@material-ui/core";
import SimpleDialogDemo from "./SimpleDialog";
import FormRender from "../../Fields/FormRender";
import {
  updateEventAttribute,
  createEventAttribute,
  updateEventObject,
} from "../../../data-store/actions/events-actions";
import EditableField from "../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InputsArray from "../../Fields/InputsArray";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    color: "black",
  },
  paper: {
    padding: "30px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UpdateWebSectionCopy(props) {
  const { item, index, form, name, pageNew, callback } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventWeb = { ...pageNew };

  //delete createEventObject.value1;
  const [testInput, setTestInput] = useState(false);
  const classes = useStyles();

  function handleChange(value) {
    console.log(value);
    eventWeb.views[index].componentConfig[
      Object.keys(value)[0]
    ] = Object.values(value)[0];
    callback(eventWeb);
  }
  function handleChangeArray(value, attribute, indexArray) {
    console.log("data", value);
    // dispatch(
    //   updateEventObject(
    //     "evento_" + eventDetail.iDEvento,
    //     "web/views/" +
    //       index +
    //       "/componentConfig/" +
    //       attribute +
    //       "/" +
    //       indexArray +
    //       "/",
    //     { ...value }
    //   )
    // );
    if (
      eventWeb.views[index].componentConfig[attribute] === "" ||
      eventWeb.views[index].componentConfig[attribute] === undefined
    ) {
      eventWeb.views[index].componentConfig[attribute] = [];
    }
    if (eventWeb.views[index].componentConfig[attribute][indexArray] === "") {
      eventWeb.views[index].componentConfig[attribute][indexArray] = {};
    }
    eventWeb.views[index].componentConfig[attribute][indexArray] = { ...value };
    callback(eventWeb);
  }
  function deleteItem(itemIndex) {
    eventWeb.views.splice(itemIndex, 1);
    callback(eventWeb);
  }
  function moveUp(itemIndex) {
    console.log(itemIndex);
    eventWeb.views = array_move(eventWeb.views, itemIndex, itemIndex - 1);
    callback(eventWeb);
  }
  function moveDown(itemIndex) {
    console.log(itemIndex);
    eventWeb.views = array_move(eventWeb.views, itemIndex, itemIndex + 1);
    callback(eventWeb);
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  function onSubmit(data) {
    console.log("data", data);
  }

  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {item.component}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {index})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button primary onClick={() => deleteItem(index)}>
              Borrar Sección
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
            {form &&
              Object.values(form).map((field, index) => {
                const defaultValue = item.componentConfig[field.fieldName];

                if (field.inputsArray !== undefined) {
                  return (
                    <InputsArray
                      fieldObject={field}
                      item={item}
                      index={index}
                      callback={handleChangeArray}
                    />
                  );
                }

                return (
                  <EditableField
                    key={index}
                    fieldObject={{
                      ...field,
                      type:
                        item.type === "image" && field.fieldName === "value"
                          ? "photo"
                          : field.type,
                      defaultValue: defaultValue,
                    }}
                    onSubmit={handleChange}
                  />
                );
              })}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
  return (
    <Paper style={{ width: "400px", backgroundColor: "#e0e0e0" }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={6}>
          <SimpleDialogDemo
            buttonText={
              name +
              " (Editar)" +
              (item.componentConfig.hide ? " - Sección Oculta" : "")
            }
          >
            {eventView}
          </SimpleDialogDemo>
        </Grid>
        <Grid item xs={6}>
          {index > 0 && (
            <IconButton onClick={() => moveUp(index)}>
              <ArrowUpwardIcon />
            </IconButton>
          )}
          {index + 2 <= eventWeb.views.length && (
            <IconButton onClick={() => moveDown(index)}>
              <ArrowDownwardIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
