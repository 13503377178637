import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Login from '../../Login/Login'



const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function MyEventsCotainerView(props){
	const classes = useStyles();
	const { atleteAccount } = props;
 
	if(atleteAccount == null){
		return <Login/>
	}
	  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
					<h1>Area en construcción</h1>
					</Paper>
        </Grid>
      </Grid>
    </div>
	  );
	}
	

	const mapDispatchToProps = (dispatch) => {
		return {
		}
	}
	
	const mapStateToProps = (state) => {
		return {		
			eventDetail: state.events.eventDetail,
			eventParticipants : state.registry.eventParticipants,
			atleteAccount : state.atlete.atleteAccount
		}
		}
		
	const MyEventsCotainer = connect(
		mapStateToProps,
		mapDispatchToProps
	)(MyEventsCotainerView);
	
	export default MyEventsCotainer;
