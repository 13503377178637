import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Input, Icon, Row } from "react-materialize";
import AtleteDetailsModal from "../../Modals/atleteDetailsModal";
import ParticipantDetailContainer from "./ParticipantDetail/ParticipantDetailContainer";
import { getCleanedString } from "../../../../services/utils";

class ModalBuscadorRegistryView extends React.Component {
  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  constructor(props) {
    super(props);
    this.state = {
      nombreNumero: 0,
      isOpen: false,
      ParticipantDetail: null,
      ParticipantDetailKey: null,
    };
  }
  getValue(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }
  toggleModal = (e, ParticipantDetail, ParticipantDetailKey, show) => {
    e.preventDefault();
    console.log("toogle modal");
    console.log(ParticipantDetail);
    console.log(this.state.isOpen);
    this.setState({
      isOpen: !this.state.isOpen,
      ParticipantDetail: ParticipantDetail,
      ParticipantDetailKey: ParticipantDetailKey,
    });
  };

  render() {
    var modalDetail;
    if (this.state.isOpen) {
      modalDetail = (
        <AtleteDetailsModal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          report={
            <ParticipantDetailContainer
              ParticipantDetail={this.state.ParticipantDetail}
              ParticipantDetailKey={this.state.ParticipantDetailKey}
            />
          }
        />
      );
    }

    var ValorBuscado = this.state.nombreNumero;
    let listaAtletas = [];
    if (this.props.eventParticipants != undefined) {
      if (!isNaN(ValorBuscado)) {
        console.log("numero");
        for (var i in this.props.eventParticipants) {
          const value = this.props.eventParticipants[i];
          const index = i;
          if (value.numero == ValorBuscado) {
            listaAtletas.push(
              <li
                key={value.numero}
                onClick={(e) => this.toggleModal(e, value, index)}
                className="collection-item"
              >
                {value.numero} - {value.nombres} {value.apellidos}
              </li>
            );
          }
        }
      } else {
        console.log("texto");
        if (ValorBuscado.length > 2) {
          ValorBuscado = getCleanedString(ValorBuscado);
          var nombreBuscado = ValorBuscado.toUpperCase();
          for (var i in this.props.eventParticipants) {
            const value = this.props.eventParticipants[i];
            const index = i;
            const nombreComparar = getCleanedString(
              value.nombres.toUpperCase() + " " + value.apellidos.toUpperCase()
            );
            if (nombreComparar.match(nombreBuscado) != null) {
              listaAtletas.push(
                <li
                  key={i}
                  onClick={(e) => this.toggleModal(e, value, index)}
                  className="collection-item"
                >
                  {value.numero} - {nombreComparar}
                </li>
              );
            }
          }
        }
      }
    }

    let buttom;
    if (this.props.icon) {
      buttom = (
        <li>
          <i className="material-icons md-40 marginIcons">search</i>
        </li>
      );
    } else {
      buttom = (
        <li>
          <a href="#" className="red-text">
            Buscar por Nombre o Número
          </a>
        </li>
      );
    }
    return (
      <div>
        <Modal header="Ingresa tu número o nombre" fixedFooter trigger={buttom}>
          <Row>
            <Input
              name="nombreNumero"
              onChange={(e) => this.getValue(e)}
              type="text"
              label="Nombre o Número"
              validate
              s={12}
            />
          </Row>
          <ul id="ListaNumeroNombre" className="collection">
            {listaAtletas}
          </ul>
        </Modal>
        {modalDetail}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventParticipants: state.registry.eventParticipants,
  };
};

const ModalBuscadorRegistry = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBuscadorRegistryView);

export default ModalBuscadorRegistry;
