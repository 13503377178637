import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import ConvertRegistryDataToTimingDataTable from "./ConvertRegistryDataToTimingDataTable";
import SimpleDialogDemo from "../../../../Landing/LayoutContainers/SimpleDialog";
import ImportDataToConvert from "./ImportDataToConvert";
import ConvertRegistryDataToTimingData from "./ConvertRegistryDataToTimingData";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ConvertRegistryDataToTimingContainer() {
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
  }, []);

  const classes = useStyles();

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h2"} component="h2">
          Migrar datos de inscripciones a crono
        </Typography>
        <Typography gutterBottom variant={"h6"} component="h2">
          Asigna la Distancia, Rama y Categoria que corresponde a cada caso y
          luego confirma la migración para crear la data de crono
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleDialogDemo
          buttonText="Ver datos de la migración"
          fullScreen={true}
        >
          <ConvertRegistryDataToTimingDataTable />
        </SimpleDialogDemo>
      </Grid>
      <Grid item xs={4}>
        {/* <SimpleDialogDemo buttonText="Importar datos" fullScreen={true}>
          <ImportDataToConvert />
        </SimpleDialogDemo> */}
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <ConvertRegistryDataToTimingData />
        </Grid>
      </Paper>
    </Grid>
  );
}
