import React from 'react';
import {Pie} from 'react-chartjs-2';

let CobradoOxxo = 100;
let CobradoEfectivo = 60;
let CobradoTarjeta = 20;

const data = {
	labels: [
		'Cobrado Oxxo',
		'Cobrado Efectivo',
		'Cobrado Tarjeta'
	],
	datasets: [{
		data: [CobradoOxxo , CobradoEfectivo, CobradoTarjeta],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};

var options = {
	tooltips: {
		callbacks: {
			title: function(tooltipItem, data) {
				return data['labels'][tooltipItem[0]['index']];
			},
			label: function(tooltipItem, data) {
				var dataset = data['datasets'][0];
				var value = data['datasets'][0]['data'][tooltipItem['index']];
				var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
				return value +' (' + percent + '%)';
			}		
		}
	}
}

const circle =()=>(
    <div className ="row ">
      <div className="col s12 z-depth-3">
            <Pie
							data={data}
							options={options}
            />
      </div>
    </div>
  )
  
  
  export default circle;