import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  translateForm,
  translateInput,
} from "../../../../../Fields/FieldUtils";
import FormRender from "../../../../../Fields/FormRender";

const AditionalInputs = ({
  eventDetail,
  eventSubEventsRegistry,
  registryConfigurationData,
  currentOrder,
  callbackInputs,
}) => {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const registryAutorizedUsers = useSelector(
    (state) => state.registry.registryAutorizedUsers
  );

  console.log("registryConfigurationData", registryConfigurationData);
  console.log("currentOrder", currentOrder);

  const profile = registryAutorizedUsers[userID].perfil;

  function onSubmit(value) {
    console.log("value", value);
    callbackInputs(value);
  }
  function getProfileToShow(input, profile) {
    if (
      (input.hideInPublic === undefined || input.hideInPublic === false) &&
      profile === "Public"
    ) {
      return true;
    } else if (
      (input.hideInscriptor === undefined || input.hideInscriptor === false) &&
      profile === "inscriptor"
    ) {
      return true;
    } else if (
      (input.hideMasterCliente === undefined ||
        input.hideMasterCliente === false) &&
      profile === "MasterCliente"
    ) {
      return true;
    } else if (
      (input.hideMasterOrganizador === undefined ||
        input.hideMasterOrganizador === false) &&
      profile === "MasterOrganizador"
    ) {
      return true;
    } else if (
      (input.hideMasterOrganizador === undefined ||
        input.hideMasterOrganizador === false) &&
      profile === "master"
    ) {
      return true;
    }

    return false;
  }
  const camposAdicionalesModalidadFunc = () => {
    var camposAdicionalesModalidad = [];
    console.log("profile", profile);

    var modalidadesBuscar = eventSubEventsRegistry;

    var formulario = !modalidadesBuscar["modalidad_" + currentOrder.modalidad]
      .hideAditionalInputs
      ? registryConfigurationData.formulario
      : {};

    for (var i in formulario) {
      if (getProfileToShow(formulario[i], profile)) {
        camposAdicionalesModalidad.push(formulario[i]);
        console.log("registryConfigurationData", formulario[i]);
      }
    }

    var arrayCampoModalidad = [];

    if (
      currentOrder.modalidad !== undefined &&
      modalidadesBuscar["modalidad_" + currentOrder.modalidad] !== undefined
    ) {
      const modalidad =
        modalidadesBuscar["modalidad_" + currentOrder.modalidad];
      var modalidadCampos =
        modalidadesBuscar["modalidad_" + currentOrder.modalidad].configModalidad
          .camposAdicionalesModalidad;
      if (modalidadCampos !== undefined && modalidad !== undefined) {
        for (var e in modalidadCampos) {
          if (
            modalidad.ramaCat["rama_" + currentOrder.rama] !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ] !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ].camposAdicionalesKey !== undefined &&
            modalidad.ramaCat["rama_" + currentOrder.rama].categorias[
              "categoria_" + currentOrder.categoria
            ].camposAdicionalesKey === e
          ) {
            console.log("registryConfigurationData", e);
            var campos = modalidadCampos[e];
            console.log(e);
            for (var f in campos) {
              console.log("profile", profile);

              //if (campos[f].tipoCampo != "titulo") {
              if (!arrayCampoModalidad.includes(campos[f].iDCampo)) {
                if (getProfileToShow(campos[f], profile)) {
                  camposAdicionalesModalidad.push(campos[f]);
                }
              }
              //}
            }
          }
        }
      }
    }

    return camposAdicionalesModalidad;
  };

  var camposAdicionales = camposAdicionalesModalidadFunc();

  return (
    <FormRender
      callback={onSubmit}
      formObject={translateForm(camposAdicionales)}
      submitButtonText={"Guardar y continuar"}
    />
  );
};

export default AditionalInputs;
