import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CSVButton from "../../Reports/CSVButton";
import {
  generateReport,
  ReportTable,
  getTotalPurchases,
} from "../../Reports/generateReport";
import UpdateCode from "./UpdateCode";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    overflowY: "scroll",
    width: "100%",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  list: {
    margin: 30,
    width: "400px",
    color: "black",
  },
}));

export default function FormReports(props) {
  const { codesGroup, codesIndex } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const [report, setReport] = useState([]);
  const [labels, setLabels] = useState([]);
  const [reportType, setReportType] = useState(undefined);
  const [itemIdentifier, setItemIdentifier] = useState(undefined);

  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);
  function detailComponent(item) {
    console.log("item", item);
    return <UpdateCode code={item} codesGroup={codesGroup} />;
  }
  console.log(eventDetail);
  const classes = useStyles();
  useEffect(() => {
    if (codesList && codesList) {
      // filtro solo los códigs del grupo a revisar
      const data = Object.values(codesList)
        .map((a) => {
          let available = true;

          if (a.uses && Object.values(a.uses).length >= parseInt(a.maxUses)) {
            available = false;
          }
          if (!a.available) {
            available = false;
          }

          return {
            ...a,
            value:
              a.typeCode === "PercentRegistry" || a.typeCode === "PercentAmount"
                ? a.value * 100 + "%"
                : a.value,
            available: available,

            usesCount:
              a.uses && Object.values(a.uses).length
                ? Object.values(a.uses).length
                : 0,
          };
        })
        .filter((a) => a.codeGroup === codesIndex);
      const newReport = generateReport({
        data: data,
        fields: [
          { label: "Código", path: "code" },
          { label: "Disponible", path: "available" },
          { label: "Usos permitidos", path: "maxUses" },
          { label: "Veces usado", path: "usesCount" },
          { label: "Tipo de código", path: "typeCode" },
          { label: "Valor", path: "value" },
        ],
      });
      setLabels(newReport.labels);
      setReport(newReport.report);
      setItemIdentifier(newReport.itemIdentifier);
    }
  }, [codesList]);

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" alignContent="center">
        <Grid item xs={12} alignItems="center">
          {codesList && <CSVButton report={report} labels={labels} />}
          {codesList ? (
            <ReportTable
              report={report}
              labels={labels}
              data={Object.values(codesList)
                .filter((a) => a.codeGroup === codesIndex)
                .map((a) => {
                  let available = true;

                  if (
                    a.uses &&
                    Object.values(a.uses).length >= parseInt(a.maxUses)
                  ) {
                    available = false;
                  }
                  if (!a.available) {
                    available = false;
                  }

                  return {
                    ...a,
                    value:
                      a.typeCode === "PercentRegistry" ||
                      a.typeCode === "PercentAmount"
                        ? a.value * 100 + "%"
                        : a.value,
                    available: available,
                    usesCount:
                      a.uses && Object.values(a.uses).length
                        ? Object.values(a.uses).length
                        : 0,
                  };
                })}
              reportType={"code"}
              itemIdentifier={itemIdentifier}
              showButtonDetail={true}
              detailComponent={detailComponent}
            />
          ) : (
            <Typography gutterBottom variant={"h5"} component="h2">
              No hay códigos creados en este grupo
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
