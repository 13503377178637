import { Paper, Typography } from "@material-ui/core";
import React from "react";
import ChangesROW from "./ChangesROW";

const ChangesList = ({
  eventParticipants,
  userID,
  atleteAccount,
  eventDetail,
}) => {
  var dataTable = [];
  var eventoIndex = "evento_" + eventDetail.iDEvento;

  for (var i in eventParticipants) {
    if (eventParticipants[i].changesRegistry != undefined) {
      dataTable.push(
        <ChangesROW
          key={i}
          eventoIndex={eventoIndex}
          ParticipanDetail={eventParticipants[i]}
          ParticipanDetailKey={i}
          userID={userID}
          atleteAccount={atleteAccount}
        />
      );
    }
  }
  return (
    <Paper style={{ margin: 5, padding: 20 }}>
      <Typography variant={"h3"} align="left">
        Lista de cambios efectuados
      </Typography>

      <table className="striped">
        <thead>
          <tr>
            <th>Folio</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Cantidad de cambios</th>
            <th>Cambios</th>
          </tr>
        </thead>
        <tbody>{dataTable}</tbody>
      </table>
    </Paper>
  );
};
export default ChangesList;
