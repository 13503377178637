import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from "@material-ui/core";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import FormRender from "../../../../../Fields/FormRender";
import EditableField from "../../../../../EditableField/EditableField";
import Field from "../../../../../Fields/Field";
import { Link, useParams } from "react-router-dom";
import WebIcon from "@material-ui/icons/Web";
import SendIcon from "@material-ui/icons/Send";
import {
  createTournamentParticipant,
  updateTournamentAtribute,
} from "../../../../../../data-store/actions/tournaments-actions";
import GroupView from "./GroupView";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function RoundView(props) {
  const { tournamentId, round } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();

  const tournament = useSelector((state) => state.tournaments.tournament);

  function onSubmit(data) {
    let roundNumber = 0;
    if (tournament.rounds) {
      roundNumber = Object.keys(tournament.rounds).length();
    }
    const objectChange = {
      rounds: {
        [roundNumber]: {
          ...round,
          ...data,
          userCreate: userID,
        },
      },
    };
    dispatch(updateTournamentAtribute(tournamentId, objectChange));
  }

  function getGroups() {
    let groups = [];
    let add = round.numberOfGroups;
    if (round.groups) {
      add = add - Object.values(round.groups).length;
    }
    for (var i = 0; i <= add; i++) {
      groups.push(<GroupView round={round} group={null} />);
    }
    return groups;
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Typography gutterBottom variant={"h3"} component="h2">
          {round.name}
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          {round &&
            round.groups &&
            Object.values(round.groups)
              .sort((a, b) => a.groupNumber - b.groupNumber)
              .map((a, i) => (
                <Grid item xs={12} sm={12}>
                  <GroupView
                    tournamentId={tournamentId}
                    round={round}
                    group={a}
                  />{" "}
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
