import { Button, Paper, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const DashboardPaymentMethod = ({
  CobradoOxxo,
  CobradoEfectivo,
  CobradoTarjeta,
  codesAmount,
}) => {
  const reportTemplateRef = useRef(null);
  const data = {
    labels: [
      "Cobrado Oxxo",
      "Cobrado Efectivo",
      "Cobrado Tarjeta",
      "Códigos de descuento",
    ],
    datasets: [
      {
        data: [CobradoOxxo, CobradoEfectivo, CobradoTarjeta, -codesAmount],
        backgroundColor: ["#993366", "#669966", "#FFCC00", "#333333"],
        hoverBackgroundColor: ["#993366", "#669966", "#FFCC00", "#333333"],
      },
    ],
  };

  var options = {
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return data["labels"][tooltipItem[0]["index"]];
        },
        label: function(tooltipItem, data) {
          var dataset = data["datasets"][0];
          var value = data["datasets"][0]["data"][tooltipItem["index"]];
          //var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
          var percent = Math.round(
            (dataset["data"][tooltipItem["index"]] /
              (CobradoEfectivo + CobradoOxxo + CobradoTarjeta - codesAmount)) *
              100
          );
          return value + " (" + percent + "%)";
        },
      },
    },
  };

  function getPdf() {
    window.html2canvas = html2canvas;
    const doc = new jsPDF();
    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
      html2canvas: { scale: 0.4 },
    });
  }

  return (
    <Paper ref={reportTemplateRef} style={{ padding: 20, margin: 5 }}>
      {/* <Button onClick={() => getPdf()}>Descargar</Button> */}
      <Typography variant={"h2"} color="primary">
        Formas de pago
      </Typography>

      <div className="col s12">
        <Pie data={data} options={options} />
      </div>
    </Paper>
  );
};

export default DashboardPaymentMethod;
