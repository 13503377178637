import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { registryParticipantDataFunc } from "../../../../../../data-store/actions/registry-actions";
import CodePayment from "./CodePayment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function SaveAthleteDataView(props) {
  const {
    callback,
    callbackCode,
    order,
    test,
    currency,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();
  const [view, setView] = useState(false);
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  function saveAthleteDataAndPay() {
    callback();

    setTimeout(topFormFunc(), 1000);
  }
  function topFormFunc() {
    const section = document.getElementById("topForm");
    if (section instanceof HTMLElement) {
      section.scrollIntoView(true);
    }
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h6">
          Total a pagar $
        </Typography>
        <Typography color="primary" component="h1" variant="h3">
          {order &&
            order.reduce(function(acc, obj) {
              return (
                acc +
                Math.round(
                  parseFloat(obj.unit_price) *
                    obj.quantity *
                    currency.tipoCambio *
                    100
                ) /
                  100
              );
            }, 0)}{" "}
          {currency.codigo}
        </Typography>
      </Grid>
      {registryConfiguration.configuracion.formasDePago.codigoDescuento && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "20px", textAlign: "center" }}
        >
          <CodePayment
            callbackCode={callbackCode}
            currency={currency}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        </Grid>
      )}

      {!view && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "40px", textAlign: "center" }}
        >
          <Button
            onClick={() => saveAthleteDataAndPay()}
            variant="contained"
            color="primary"
            style={{
              height: "150px",
              width: "300px",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            Continuar con el pago
          </Button>
          {/* <Button
            onClick={() => saveAthleteDataAndAddOther()}
            variant="contained"
            color="primary"
            style={{
              height: "150px",
              width: "150px",
              fontSize: "18px",
              margin: "10px",
            }}
          >
            Agregar otro partipante
          </Button> */}
        </Grid>
      )}
    </Grid>
  );
}
