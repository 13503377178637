import React from "react";
import { TiempoAPasoMinxK } from "../../../../../../../services/utils";
import { DescargarCertificadoReg } from "./certificadoLogic";

const descargarCertificadoReg = (e, eventDetail, resultadoJSON, tipo) => {
  e.preventDefault();
  console.log("descargar certificado");
  DescargarCertificadoReg(eventDetail, resultadoJSON, tipo);
};

const AtleteData = ({ eventDetail, resultado }) => {
  var paso = TiempoAPasoMinxK(resultado.to, resultado.mod);

  return (
    <div>
      <div className="divider"></div>
      <div className="row">
        <div className="col s8 left-align">
          <h5>{resultado.nombre}</h5>
        </div>
        <div className="col s4 left-align">
          <a
            onClick={(e) =>
              descargarCertificadoReg(e, eventDetail, resultado, 2)
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            JPG
          </a>
          <a
            onClick={(e) =>
              descargarCertificadoReg(e, eventDetail, resultado, 1)
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            PDF
          </a>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <span className="col s6 m3 center-align">
          <b>Número </b>
          <br />
          {resultado.num}
        </span>
        <span className="col s6 m3 center-align">
          <b>Género </b>
          <br />
          {resultado.gen}
        </span>
        <span className="col s6 m3 center-align">
          <b>Categoría </b>
          <br />
          {resultado.cat}
        </span>
        <span className="col s6 m3 center-align">
          <b>Modalidad </b>
          <br />
          {resultado.mod}
        </span>
      </div>
      <div className="row z-depth-2">
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>T. Oficial</b>
          <br />
          {resultado.to}
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>T. Real</b>
          <br />
          {resultado.tc}
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>Paso min/K</b>
          <br />
          {paso}
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>P. General</b>
          <br />
          {resultado.pG}
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>P. Rama</b>
          <br />
          {resultado.pR}
        </div>
        <div className="#f44336 red white-text col s6 m4 l2 center-align">
          <b>P. Categoria</b>
          <br />
          {resultado.pCat}
        </div>
      </div>
    </div>
  );
};

export default AtleteData;
