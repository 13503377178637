import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import UpdateWebSectionCopy from "./UpdateWebSectionCopy";
import Components from "../LandingComponents";
import CreateWebSectionCopy from "./CreateWebSectionCopy";
import UpdateMiniThemeCopy from "./UpdateMiniThemeCopy";
import { Paper } from "@material-ui/core";
import { updateEventAttribute } from "../../../data-store/actions/events-actions";
import { setOrganizerWebView } from "../../../data-store/actions/organizers-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function LayoutResolverEditCopy({
  page,
  organizerWeb,
  container,
  businessId,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const [showEditopcions, setShowEditopcions] = useState(true);
  const [pageOriginal, setPageOriginal] = useState({});

  const [pageNew, setPageNew] = useState({});
  useEffect(() => {
    console.log("pageNewChange", page);

    setPageNew({ ...page });
    setPageOriginal(JSON.parse(JSON.stringify(page)));
  }, [page]);

  const defaultWidth = { xs: 12, sm: 6 };
  const widthList = {
    1: { xs: 12, sm: 12 },
    2: { xs: 12, sm: 6 },
    3: { xs: 12, sm: 4 },
    4: { xs: 12, sm: 3 },
    5: { xs: 6, sm: 6 },
    6: { xs: 6, sm: 3 },
  };
  const formPaddingWidht = {
    width: {
      label: "Ancho en relación a la pantalla",
      errorMessage: "Campo Requerido",
      fieldName: "width",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "1",
          label: "Ancho completo para todas las pantallas",
        },
        {
          value: "2",
          label: " 100% para Movil y 50% para Pantallas Grandes",
        },
        {
          value: "3",
          label: " 100% para Movil y 1/3 para Pantallas Grandes",
        },
        {
          value: "4",
          label: " 100% para Movil y 25% para Pantallas Grandes",
        },
        {
          value: "5",
          label: " 50% para Movil y 50% para Pantallas Grandes",
        },
        {
          value: "6",
          label: " 50% para Movil y 25% para Pantallas Grandes",
        },
      ],
    },
    padding: {
      label: "Separación del margen en relación a otro componente",
      errorMessage: "Campo Requerido",
      fieldName: "padding",
      type: "text",
      defaultValue: 0,
      required: false,
      regex: "",
      textSize: "h6",
      min: 0,
    },
    sectionID: {
      label: "ID de la sección para enlazarla con la navegación",
      errorMessage: "Campo Requerido",
      fieldName: "sectionID",
      type: "text",
      defaultValue: 0,
      required: false,
      regex: "",
      textSize: "h6",
    },
    hide: {
      label: "Ocultar esta secciónº",
      errorMessage: "Campo Requerido",
      fieldName: "hide",
      type: "switch",
      defaultValue: false,
      required: false,
      regex: "",
      textSize: "h6",
    },
  };

  function saveWebChanges() {
    console.log(pageNew);

    if (organizerWeb && container) {
      console.log("organizerWeb", organizerWeb);
      console.log("organizerWeb", container);

      dispatch(
        setOrganizerWebView(businessId, organizerWeb, container, pageNew)
      );
    } else if (eventDetail.iDEvento) {
      // dispatch(
      //   updateEventAttribute(
      //     "evento_" + eventDetail.iDEvento,
      //     "web/",
      //     pageNew
      //   )
      // );
    }
  }
  function discartChanges() {
    console.log("pageNewChange", pageOriginal);
    setPageNew(JSON.parse(JSON.stringify(pageOriginal)));
  }
  function handleChanges(pageNewChange) {
    console.log("pageNewChange", pageNewChange);
    setPageNew(pageNewChange);
    console.log("pageNewChange", pageNew);
  }
  if (pageNew) {
    const innerComponents = (
      <div className={classes.root}>
        <Paper style={{ width: "100%", backgroundColor: "#e0e0e0" }}>
          <Grid container direction="row" alignItems="center">
            <Grid xs={3} style={{ padding: "30px" }} item>
              {pageOriginal !== pageNew && (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ padding: "20px" }}
                  disabled={pageOriginal === pageNew ? true : false}
                  onClick={() => saveWebChanges()}
                >
                  {pageOriginal !== pageNew && "Guardar Cambios"}
                  {pageOriginal === pageNew && "Sin Cambios"}
                </Button>
              )}

              {pageOriginal === pageNew && "Sin Cambios"}

              {pageOriginal !== pageNew && (
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  disabled={pageOriginal === pageNew ? true : false}
                  onClick={() => discartChanges()}
                >
                  Descartar Cambios
                </Button>
              )}
            </Grid>

            <Grid xs={3} style={{ padding: "30px" }} item>
              <CreateWebSectionCopy
                pageNew={pageNew}
                callback={handleChanges}
                Components={Components}
              />
            </Grid>
            <Grid xs={3} style={{ padding: "30px" }} item>
              <UpdateMiniThemeCopy pageNew={pageNew} callback={handleChanges} />
            </Grid>
            <Grid xs={3} style={{ padding: "30px" }} item>
              <Button
                color="primary"
                onClick={() => setShowEditopcions(!showEditopcions)}
              >
                {showEditopcions && "Ocultar Botones de edición"}
                {!showEditopcions && "Mostrar Botones de edición"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            textAlign: "center",
            backgroundRepeat: "repeat",
            backgroundPosition: "left top",
            backgroundSize: "100% auto",
            backgroundImage: `url(${
              pageNew.miniTheme ? pageNew.miniTheme.backgroundImage : ""
            })`,
          }}
        >
          {pageNew &&
            pageNew.views &&
            pageNew.views.map((item, i) => {
              const SpecificComponent = Components[item.component].default;
              const ComponentName =
                Components[item.component].default.displayName ||
                item.component;
              const form = Components[item.component].componentDescription
                ? {
                    ...Components[item.component].componentDescription
                      .componentConfig,
                    ...formPaddingWidht,
                  }
                : { ...formPaddingWidht };

              const width = item.componentConfig.width || 1;

              if (item.componentConfig.hide) {
                return (
                  <Grid key={i} item xs={12} sm={12}>
                    {showEditopcions && (
                      <UpdateWebSectionCopy
                        item={item}
                        index={i}
                        form={form}
                        name={ComponentName}
                        pageNew={pageNew}
                        callback={handleChanges}
                      />
                    )}
                  </Grid>
                );
              }
              return (
                <Grid
                  key={i}
                  id={
                    item.componentConfig.sectionID ||
                    Math.floor(Math.random() * 10000)
                  }
                  item
                  xs={
                    widthList[width] && widthList[width].xs
                      ? widthList[width].xs
                      : defaultWidth.xs
                  }
                  sm={
                    widthList[width] && widthList[width].sm
                      ? widthList[width].sm
                      : defaultWidth.sm
                  }
                  style={{
                    width: "100%",
                    padding: item.componentConfig.padding
                      ? item.componentConfig.padding
                      : 0,
                  }}
                >
                  {SpecificComponent ? (
                    <SpecificComponent componentConfig={item.componentConfig} />
                  ) : null}

                  {showEditopcions && (
                    <UpdateWebSectionCopy
                      item={item}
                      index={i}
                      form={form}
                      name={ComponentName}
                      pageNew={pageNew}
                      callback={handleChanges}
                    />
                  )}
                </Grid>
              );
            })}
        </Grid>
      </div>
    );

    if (pageNew.miniTheme) {
      const { miniTheme } = pageNew;
      return (
        <ThemeProvider
          theme={(theme) => {
            const primaryColor =
              miniTheme.primary || theme.palette.primary.main;
            const secondaryColor =
              miniTheme.secondary || theme.palette.secondary.main;
            const textPrimaryColor =
              miniTheme.text || theme.palette.text.primary || "#000";
            const backgroundColor =
              miniTheme.background ||
              theme.palette.background.default ||
              "#FFFFF";
            const fontFamily =
              miniTheme.fontFamily ||
              theme.typography.fontFamily ||
              "MavenProRegular, MavenProBold,Helvetica, Arial";

            return createMuiTheme({
              ...theme,
              typography: {
                fontFamily: fontFamily,
              },
              palette: {
                background: {
                  default: backgroundColor,
                },
                primary: {
                  main: primaryColor,
                },
                secondary: {
                  main: secondaryColor,
                },
                text: {
                  primary: textPrimaryColor,
                },
              },
            });
          }}
        >
          <Box bgcolor="background.default">{innerComponents}</Box>
        </ThemeProvider>
      );
    }

    return innerComponents;
  }

  return null;
}
