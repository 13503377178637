import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import FormDialog from "./FormDialog";
import DashportLogo from "./DASHPORT_white_horizontal.png";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    background: "black",
    height: "64px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  ButtonStyleDefault: {
    color: "red",
  },
  logo: {
    height: "40px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const { buttomLabel, report, title, BarButtons, ButtonStyle } = props;
  // BarButtons =  Array de Componentes botones que muestran un Dialog adicional o hacen algo.
  // buttomLabel =  Texto del boton del rDialog full.

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button
        className={classes.ButtonStyleDefault}
        style={
          ButtonStyle == undefined || ButtonStyle == null ? null : ButtonStyle
        }
        color="primary"
        onClick={handleClickOpen}
      >
        {buttomLabel}
      </Button>
      <Dialog
        id="dialogReport"
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h6" className={classes.logo}>
              <img className={classes.logo} src={DashportLogo} />
            </Typography>
            {BarButtons}
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" style={{ marginTop: "100px" }}>
          {report}
        </Container>
      </Dialog>
    </div>
  );
}
