import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import {
  updateEventAttribute,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import FormRender from "../../../../Fields/FormRender";
import AsignImportedDataToConvert from "./AsignImportedDataToConvert";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputLabelTitle: {
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));
export default function ImportDataToConvert() {
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  const [configJson, setConfigJson] = useState(false);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  useEffect(() => {}, [eventSubEvents, eventSubEventsRegistry]);

  const classes = useStyles();
  function importData(data) {
    let displayData = [];
    let resultsJson = [];
    let resultsConfigJson = {};

    console.log("importData", data);
    const resultLines = data.participants.split("\n");

    const titles = resultLines[0].split("\t");
    const validateTitles = validateTitlesFunc(titles);

    if (validateTitles.valid) {
      for (var f in resultLines) {
        console.log(resultLines[f]);
        if (f > 0 && resultLines[f] !== "") {
          var datos = resultLines[f].split("\t");
          const validateLine = validateLineFunc(datos, titles);
          if (validateLine.valid) {
            // guardar Dato en resultados
            const dataLine = validateLine.lineObject;
            resultsJson.push(dataLine);
            // si la modaliddad, genero y categoria de la linea no existe ya en el array la agrego
            if (
              !resultsConfigJson[
                "modalidad:" +
                  dataLine.Modalidad +
                  ",genero:" +
                  dataLine.Genero +
                  ",categoria:" +
                  dataLine.Categoria
              ]
            ) {
              resultsConfigJson[
                "modalidad:" +
                  dataLine.Modalidad +
                  ",genero:" +
                  dataLine.Genero +
                  ",categoria:" +
                  dataLine.Categoria
              ] = {
                modalidad: dataLine.Modalidad,
                genero: dataLine.Genero,
                categoria: dataLine.Categoria,
              };
            }

            // if (subir) {
            //   {
            //     ab: 0,
            //     cat: modGenCatCrono.category.nombreCategoria,
            //     cg: modGenCatCrono.category.cantidadGanadores,
            //     codCat: modGenCatCrono.category.codigo,
            //     codMod: modGenCatCrono.codMod,
            //     fhm: "0000-00-00 00:00:00.000",
            //     gen: modGenCatCrono.gen,
            //     mod: modGenCatCrono.mod,
            //     mp: 0,
            //     nombre: (participant.nombres + " " + participant.apellidos).toUpperCase(),
            //     num: participant.numero / 1,
            //     pCat: 0,
            //     pG: 0,
            //     pR: 0,
            //     registroSalida: false,
            //     serie: modGenCatCrono.category.serie,
            //     tc: "00:00:00",
            //     to: "00:00:00",
            //   }
            // //   addRegistryTime(eventID, resultID, dataLine);
            // }
            //displayData.push(JSON.stringify(validateLine.lineObject));
          } else {
            //mandar error
            displayData.push(
              `<div>
                <h5>Error en linea ${f}</h5>
                validateTitles.notFoundTitles
              </div>`
            );
          }
        }
      }
      console.log(resultsJson);
      //callback(resultsJson);
    } else {
      displayData.push(
        `
          Columnas no encontradas
          ${validateTitles.notFoundTitles}
        `
      );
    }
    setData(resultsJson);
    setConfigJson(resultsConfigJson);
    return { data: resultsJson, text: displayData };
  }

  const validateTitlesFunc = (titulos) => {
    var indexTitles = {};
    for (var e in titulos) {
      indexTitles[titulos[e]] = e;
    }
    console.log(indexTitles);
    const arrayRequired = [
      "Número",
      "Nombres",
      "Categoria",
      "Genero",
      "Modalidad",
      "Serie",
    ];
    let valid = true;
    let notFoundTitles = "";
    for (var i in arrayRequired) {
      if (indexTitles[arrayRequired[i]] === undefined) {
        valid = false;
        notFoundTitles = notFoundTitles + arrayRequired[i];
      }
    }
    return {
      indexTitles: indexTitles,
      valid: valid,
      notFoundTitles: notFoundTitles,
    };
  };
  const validateLineFunc = (line, titulos) => {
    var indexValues = {};
    for (var e in titulos) {
      indexValues[e] = titulos[e];
    }
    const arrayRequired = [
      "Número",
      "Nombres",
      "Categoria",
      "Genero",
      "Modalidad",
      "Serie",
    ];
    let valid = true;
    let notFoundTitles = "";
    let lineObject = {};
    for (var i in line) {
      lineObject[indexValues[i]] = line[i];
    }
    return {
      lineObject: lineObject,
      valid: valid,
      notFoundTitles: notFoundTitles,
    };
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: 20 }}
    >
      <Grid item xs={12}>
        <AsignImportedDataToConvert configJson={configJson} />
      </Grid>
      <Grid item xs={6}>
        <FormRender
          callback={importData}
          formObject={{
            ticket: {
              label: "Datos de participantes",
              errorMessage: "Campo Requerido",
              fieldName: "participants",
              type: "multiline",
              defaultValue: "",
              required: true,
              regex: "",
              textSize: "h6",
            },
          }}
          submitButtonText={"Importar Datos"}
        />
      </Grid>
      <Grid item xs={6}>
        {JSON.stringify(data)}
      </Grid>
    </Grid>
  );
}
