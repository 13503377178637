import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import timingConfig from "../timingConfig.json";
import {
  updateEventAttribute,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ControlPointLap(props) {
  const { kpc, controlPointIndex, lap, distanceIndex } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);

  let controlPoint = timingConfig.controlPointLap;
  console.log("controlPoint1", controlPoint);

  // useEffect(() => {
  //   if (lap.tipoControl === 2) {
  //     controlPoint["kilometro"] = timingConfig.kilometroLap.kilometro;
  //     console.log("controlPoint1", controlPoint);
  //   } else {
  //     delete controlPoint["kilometro"];
  //     console.log("controlPoint1", controlPoint);
  //   }
  // }, [puntosControl]);

  function onSubmit(data) {
    const key = Object.keys(data)[0];

    let lapNew = { ...lap, [key]: data[key] };
    console.log("lap1", lapNew);
    if (key === "tipoControl") {
      if (data[key] === 3) {
        lapNew.kilometro =
          eventSubEvents["modalidad_" + distanceIndex].modalidad;
      } else if (data[key] === 1) {
        lapNew.kilometro = 0;
      } else if (data[key] === 2) {
        lapNew.kilometro =
          eventSubEvents["modalidad_" + distanceIndex].modalidad / 2;
      }
    }

    console.log("lap1", lapNew);
    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "puntosDeControl/kPC_" +
          controlPointIndex +
          "/modalidadesPuntoDeControl/modalidad_" +
          distanceIndex +
          "/laps/lap_" +
          lap.lap,
        lapNew
      )
    );
  }
  function deleteLap() {
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "puntosDeControl/kPC_" +
          controlPointIndex +
          "/modalidadesPuntoDeControl/modalidad_" +
          distanceIndex +
          "/laps/",
        { ["lap_" + lap.lap]: null }
      )
    );
  }

  return (
    <Paper className={classes.paper} style={{ margin: 10, padding: 10 }}>
      <Grid item xs={12}>
        <Typography gutterBottom variant={"subtitle1"} component="subtitle1">
          Lap Nro: {lap.lap}
        </Typography>
        <Typography gutterBottom variant={"h5"} component="h5">
          Descripción: {lap.descripcionPunto}
        </Typography>

        {Object.values(controlPoint).map((field, index) => {
          const fieldNew = {
            ...field,
            defaultValue: lap[field.fieldName],
          };
          return (
            <EditableField
              key={index}
              fieldObject={fieldNew}
              onSubmit={onSubmit}
            />
          );
        })}
        {lap.tipoControl === 2 && (
          <EditableField
            key={"f"}
            fieldObject={{
              ...timingConfig.kilometroLap.kilometro,
              defaultValue: lap.kilometro,
            }}
            onSubmit={onSubmit}
          />
        )}
        {lap.tipoControl !== 2 && (
          <div>
            <Typography
              gutterBottom
              variant={"subtitle1"}
              component="subtitle1"
            >
              Kilómetro :
            </Typography>
            <Typography gutterBottom variant={"h3"} component="h3">
              {lap.kilometro}
            </Typography>
          </div>
        )}
      </Grid>
      {lap.lap > 1 && <Button onClick={() => deleteLap()}>Borrar lap</Button>}
    </Paper>
  );
}
