import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import SimpleDialogDemo from "../../../LayoutContainers/SimpleDialog";
//import LoginForm from "../Login/LoginForm";
import {
  configuracionEvento,
  detalleEvento,
  resultadosEventoNew,
  eventSubEventsRegistry,
  resultReportType,
  getPuntosControl,
} from "../../../../../data-store/actions/events-actions";

import ResultsReportNew from "./reports/ResultsReportNew";

import { Link } from "react-router-dom";

import BuscadorResults from "./reports/BuscadorResults";
import FullScreenDialog from "./reports/FullScreenDialog";
import FormDialog from "./reports/FormDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  paper: {
    padding: "0px",
    backgroundColor: "#283747",
  },
  color: {
    padding: "0px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function EventRegistryPublic(props) {
  const {
    eventIdDashport,
    title,
    textBottom,
    name,
    eventLogo,
    image,
    renderType,
  } = props;
  const dispatch = useDispatch();

  //const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventResults = useSelector((state) => state.events.eventResults);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const puntosControl = useSelector((state) => state.events.puntosControl);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    console.log("getRegistryDATA", eventIdDashport);
    if (eventIdDashport) {
      dispatch(resultadosEventoNew(eventIdDashport)),
        dispatch(configuracionEvento(eventIdDashport)),
        dispatch(getPuntosControl(eventIdDashport));
    }
  }, [eventIdDashport]);

  if (eventResults === undefined || puntosControl === undefined) {
    return <div></div>;
  }

  let BarButtons = [];
  BarButtons.push(
    <FormDialog
      key={1}
      buttomLabel={"search"}
      ShowIcon={true}
      ButtonStyle={null}
      maxWidthProp={"sm"}
      report={<BuscadorResults icon={false} />}
    />
  );
  let numberAndNameFinder = (
    <FormDialog
      key={1}
      buttomLabel={"Buscar por nombre ó Número"}
      ShowIcon={false}
      ButtonStyle={null}
      maxWidthProp={"sm"}
      style={{
        minHeight: "40vh",
      }}
      report={<BuscadorResults icon={false} />}
    />
  );

  let WinnersButton = (
    <FullScreenDialog
      buttomLabel={"Resultados Ganadores"}
      title={"Resultados Ganadores"}
      report={
        <ResultsReportNew
          reportResultType={4}
          controles={false}
          puntosControl={puntosControl}
        />
      }
      BarButtons={BarButtons}
    />
  );
  let CategoryResults = (
    <FullScreenDialog
      buttomLabel={"Resultados Categoría"}
      title={"Resultados por Categoría"}
      report={
        <ResultsReportNew
          reportResultType={3}
          controles={false}
          eventDetail={eventDetail}
          puntosControl={puntosControl}
        />
      }
      BarButtons={BarButtons}
    />
  );
  let GenderResults = (
    <FullScreenDialog
      buttomLabel={"Resultados Genero"}
      title={"Resultados por Genero"}
      report={
        <ResultsReportNew
          reportResultType={2}
          controles={false}
          eventDetail={eventDetail}
          puntosControl={puntosControl}
        />
      }
      BarButtons={BarButtons}
    />
  );
  let GeneralResults = (
    <FullScreenDialog
      buttomLabel={"Resultados Generales"}
      title={"Resultados Generales"}
      report={
        <ResultsReportNew
          reportResultType={1}
          controles={false}
          eventDetail={eventDetail}
          puntosControl={puntosControl}
        />
      }
      BarButtons={BarButtons}
    />
  );

  const view = (
    <Grid
      container
      direction="row"
      wrap="wrap"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography color="primary" gutterBottom variant={"h3"} component="h2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: "10px" }}>
        {/* {numberAndNameFinder} */}
        {WinnersButton}
        {CategoryResults}
        {GenderResults}
        {GeneralResults}
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" variant={"h5"} component="h2">
          {textBottom}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <div>
      {" "}
      {view}
      {/* <SimpleDialogDemo fullScreen={false} view={view}>
        <EventRegistryPublicForm eventIdDashport={eventIdDashport} />
      </SimpleDialogDemo> */}
    </div>
  );
}
