import { helperMultisort } from "../../../../../../services/utils";
export const ResultadosGanadores = (JsonOrdenado) => {
  // seccion de Absolutos;
  var JsonOrdenadoAbsolutos = [];
  JsonOrdenado.forEach(function(value, index) {
    if (value.ab == 1) {
      JsonOrdenadoAbsolutos.push(JsonOrdenado[index]);
    }
  });
  JsonOrdenadoAbsolutos = helperMultisort(
    JsonOrdenadoAbsolutos,
    ["codMod", "gen", "pR"],
    ["ASC", "ASC", "ASC"]
  );

  // seccion de por Categorias;

  var JsonOrdenadoCategorias = [];
  JsonOrdenado.forEach(function(value, index) {
    if (value.ab != 1) {
      if (JsonOrdenado[index].pCat <= JsonOrdenado[index].cg) {
        JsonOrdenadoCategorias.push(JsonOrdenado[index]);
      }
    }
  });
  JsonOrdenadoCategorias = helperMultisort(
    JsonOrdenadoCategorias,
    ["codMod", "cat", "gen", "pCat"],
    ["ASC", "ASC", "ASC", "ASC"]
  );

  // unir los dos arrays
  var JsonOrdenadoUnido = JsonOrdenadoAbsolutos.concat(JsonOrdenadoCategorias);
  return JsonOrdenadoUnido;
};

//------------------------------------------------------------------------------------
export const ResultadosGenerales = (JsonOrdenado) => {
  JsonOrdenado = helperMultisort(
    JsonOrdenado,
    ["codMod", "pG"],
    ["ASC", "ASC"]
  );
  return JsonOrdenado;
};

//------------------------------------------------------------------------------------
export const ResultadosRama = (JsonOrdenado) => {
  JsonOrdenado = helperMultisort(
    JsonOrdenado,
    ["codMod", "gen", "pR"],
    ["ASC", "ASC", "ASC"]
  );
  return JsonOrdenado;
};

//------------------------------------------------------------------------------------
export const ResultadosCategoria = (JsonOrdenado) => {
  // seccion de Absolutos;
  var JsonOrdenadoAbsolutos = [];
  JsonOrdenado.forEach(function(value, index) {
    if (value.ab == 1) {
      JsonOrdenadoAbsolutos.push(JsonOrdenado[index]);
    }
  });
  JsonOrdenadoAbsolutos = helperMultisort(
    JsonOrdenadoAbsolutos,
    ["codMod", "gen", "pR"],
    ["ASC", "ASC", "ASC"]
  );

  // seccion de por Categorias;

  var JsonOrdenadoCategorias = [];
  JsonOrdenado.forEach(function(value, index) {
    if (value.ab != 1) {
      JsonOrdenadoCategorias.push(JsonOrdenado[index]);
    }
  });
  JsonOrdenadoCategorias = helperMultisort(
    JsonOrdenadoCategorias,
    ["codMod", "cat", "gen", "pCat"],
    ["ASC", "ASC", "ASC", "ASC"]
  );

  // unir los dos arrays
  var JsonOrdenadoUnido = JsonOrdenadoAbsolutos.concat(JsonOrdenadoCategorias);
  return JsonOrdenadoUnido;
};

// ###########################################################################

export const controlesArrayFunc = (puntosControl) => {
  var controlesArray = {};
  if (puntosControl != undefined) {
    var controles = puntosControl.val();
    for (var m in controles) {
      var modPC = controles[m].modalidadesPuntoDeControl;
      for (var n in modPC) {
        var modalidadIndex = n;
        var laps = modPC[n].laps;
        for (var d in laps) {
          if (controlesArray[modalidadIndex] == undefined) {
            controlesArray[modalidadIndex] = {};
            controlesArray[modalidadIndex].tamano = 0;
            controlesArray[modalidadIndex].titles = [];
            controlesArray[modalidadIndex].kilometro = [];
          }
          if (laps[d].tipoControl != 3) {
            controlesArray[modalidadIndex].tamano++;
            controlesArray[modalidadIndex].titles.push({
              descripcionPunto: laps[d].descripcionPunto,
              kilometro: laps[d].kilometro,
            });
            controlesArray[modalidadIndex].kilometro[
              "km-" + laps[d].kilometro
            ] = laps[d].kilometro;
          }
        }
      }
    }
  }

  return controlesArray;
};

export const ImprimirDatos = (
  JsonOrdenado,
  tipoReporte,
  correr,
  controles,
  puntosControl,
  IDEvento
) => {
  // tipo reporte tipo 1 = generales, tipo 2 = Rama, tipo 3 = Ganadores Categoria, Tipo 4 = Competidor especifico.

  var ModSig;
  var CatSig;
  var GenSig;
  var showControlesTitle = "";

  var indexAnterior = -1;
  var ArrayFilas = [];

  for (var i in JsonOrdenado) {
    // datos objeto actual
    var Mod = JsonOrdenado[i].mod;
    var codMod = JsonOrdenado[i].codMod;
    var Cat = JsonOrdenado[i].cat;
    var Gen = JsonOrdenado[i].gen;
    var PG = JsonOrdenado[i].pG;
    var AB = JsonOrdenado[i].ab;

    if (PG > 0) {
      // datos objeto siguiente
      if (indexAnterior != -1) {
        ModAnt = JsonOrdenado[indexAnterior].codMod;
        CatAnt = JsonOrdenado[indexAnterior].cat;
        GenAnt = JsonOrdenado[indexAnterior].gen;
        ABAnt = JsonOrdenado[indexAnterior].ab;
      } else {
        var ModAnt = 0;
        var CatAnt = "";
        var GenAnt = "";
        var ABAnt = 0;
      }
      var Insertar = { typeRow: 2, result: JsonOrdenado[i] };
      if (tipoReporte == 1) {
        // generales
        if (i == 0) {
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        } else if (codMod != ModAnt) {
          console.log(codMod + " " + ModAnt);
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        } else {
          ArrayFilas.push(Insertar);
        }
      } else if (tipoReporte == 2) {
        // Rama
        if (i == 0) {
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        } else if (codMod != ModAnt || Gen != GenAnt) {
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        } else {
          ArrayFilas.push(Insertar);
        }
      } else if (tipoReporte == 3 || tipoReporte == 4) {
        // Categoria

        if (AB == 1 && (codMod != ModAnt || Gen.localeCompare(GenAnt) != 0)) {
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: true,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        }
        if (AB == 1 && codMod == ModAnt && Gen.localeCompare(GenAnt) == 0) {
          ArrayFilas.push(Insertar);
        }

        if (
          AB == 0 &&
          (codMod != ModAnt ||
            Gen.localeCompare(GenAnt) != 0 ||
            Cat.localeCompare(CatAnt) != 0)
        ) {
          ArrayFilas.push({
            typeRow: 1,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push({
            typeRow: 3,
            reportType: tipoReporte,
            codMod: codMod,
            mod: Mod,
            cat: Cat,
            gen: Gen,
            ab: false,
          });
          ArrayFilas.push(Insertar);
        }
        if (
          AB == 0 &&
          codMod == ModAnt &&
            Gen.localeCompare(GenAnt) == 0 &&
            Cat.localeCompare(CatAnt) == 0
        ) {
          ArrayFilas.push(Insertar);
        }
      } else if (tipoReporte == 4) {
        // individual
      } else {
      }

      indexAnterior = i;
    }
  }
  return ArrayFilas;
};

export const ControlesAtleta = (puntosControl, resultado, controlesArray) => {
  var codMod = resultado.codMod;
  var controlesTiempos = [];
  var controlesTitle = "";
  var controlestamano = 0;
  if (puntosControl != "") {
    if (controlesArray["modalidad_" + codMod] != undefined) {
      controlestamano = controlesArray["modalidad_" + codMod].tamano;
    }
    var parciales = resultado.parciales;
    if (parciales != undefined) {
      for (var i in controlesArray["modalidad_" + codMod].kilometro) {
        var registrado = false;
        for (var e in parciales) {
          if (
            parciales[e].kpc ==
            controlesArray["modalidad_" + codMod].kilometro[i]
          ) {
            registrado = true;
            controlesTiempos.push({
              registred: true,
              value: parciales[e].tkpc,
              class: "",
            });
          }
        }
        if (!registrado) {
          controlesTiempos.push({
            registred: false,
            value: "N/R",
            class: "teal lighten-2",
          });
        }
      }
    } else {
      for (var i = 1; i <= controlestamano; i++) {
        controlesTiempos.push({
          registred: false,
          value: "N/R",
          class: "teal lighten-2",
        });
      }
    }
  }
  return controlesTiempos;
};

//#########################################################################################
export const ordenarPuntosDeControlDatos2 = (
  soloMeta,
  ResultadosEvento,
  control
) => {
  var JsonOrdenado = ResultadosEvento;

  var arrayPuntosControl = [];
  // seccion de Absolutos;
  var JsonKPC = [];

  for (var i in JsonOrdenado) {
    var value = JsonOrdenado[i];

    arrayPuntosControl.push();

    var num = value.num;
    var nombre = value.nombre;
    var kpc = value.mod;
    var mod = value.mod;
    var pCat = 0;
    var tiempo = value.to;
    var parciales = value.parciales;
    var fechaHora = value.fhm;

    // primera parte es la meta

    if (arrayPuntosControl.indexOf(pCat) == -1) {
      arrayPuntosControl.push(pCat);
    }

    var JsonObjeto = {};
    JsonObjeto.num = num;
    JsonObjeto.nombre = nombre;
    JsonObjeto.kpc = kpc;
    JsonObjeto.mod = mod;
    JsonObjeto.pCat = pCat;
    JsonObjeto.tiempo = tiempo;
    JsonObjeto.fechaHora = fechaHora;

    if (tiempo != "00:00:00" && (control == 0 || control == null)) {
      JsonKPC.push(JsonObjeto);
    }

    if (parciales != undefined && parciales != 0 && !soloMeta) {
      console.log("Parciales");
      parciales.forEach(function(value2) {
        var kpc = value2.kpc;
        var pCat = value2.pCat;
        var tiempo = value2.tkpc;
        var fechaHora = value2.fechaHoraPunto;

        if (arrayPuntosControl.indexOf(pCat) == -1) {
          arrayPuntosControl.push(pCat);
        }

        var JsonParcial = {};
        JsonParcial.num = num;
        JsonParcial.nombre = nombre;
        JsonParcial.kpc = kpc;
        JsonParcial.mod = mod;
        JsonParcial.pCat = pCat;
        JsonParcial.tiempo = tiempo;
        JsonParcial.fechaHora = fechaHora;
        if (tiempo != "00:00:00" && (control == pCat || control == null)) {
          JsonKPC.push(JsonParcial);
        }
      });
    }
  }

  JsonKPC = helperMultisort(JsonKPC, ["fechaHora"], ["DESC"]);

  return { JsonKPC: JsonKPC, arrayPuntosControl: arrayPuntosControl };
};
//#########################################################################################
export const ordenarPuntosDeControlDatos = (
  soloMeta,
  ResultadosEvento,
  control,
  anouncer
) => {
  var JsonOrdenado = ResultadosEvento;

  // seccion de Absolutos;
  var arrayPuntosControl = [];
  var JsonKPC = [];
  var JsonKPCPorTerminar = [];

  for (var i in JsonOrdenado) {
    var value = JsonOrdenado[i];

    arrayPuntosControl.push();

    var num = value.num;
    var nombre = value.nombre;
    var kpc = value.mod;
    var mod = value.mod;
    var pCat = 0;
    var tiempo = value.to;
    var parciales = value.parciales;
    var fechaHora = value.fhm;
    var registroSalida = value.registroSalida;

    // primera parte es la meta

    if (arrayPuntosControl.indexOf(pCat) == -1) {
      arrayPuntosControl.push(pCat);
    }

    var JsonObjeto = {};
    JsonObjeto.num = num;
    JsonObjeto.nombre = nombre;
    JsonObjeto.kpc = kpc;
    JsonObjeto.mod = mod;
    JsonObjeto.pCat = pCat;
    JsonObjeto.tiempo = tiempo;
    JsonObjeto.fechaHora = fechaHora;

    if (tiempo != "00:00:00" && (control == 0 || control == null)) {
      JsonKPC.push(JsonObjeto);
    }
    if (tiempo == "00:00:00" && registroSalida) {
      JsonKPCPorTerminar.push(JsonObjeto);
    }

    if (parciales != undefined && parciales != 0 && !soloMeta) {
      parciales.forEach(function(value2) {
        var kpc = value2.kpc;
        var pCat = value2.pCat;
        var tiempo = value2.tkpc;
        var fechaHora = value2.fechaHoraPunto;

        if (arrayPuntosControl.indexOf(pCat) == -1) {
          arrayPuntosControl.push(pCat);
        }

        var JsonParcial = {};
        JsonParcial.num = num;
        JsonParcial.nombre = nombre;
        JsonParcial.kpc = kpc;
        JsonParcial.mod = mod;
        JsonParcial.pCat = pCat;
        JsonParcial.tiempo = tiempo;
        JsonParcial.fechaHora = fechaHora;
        if (tiempo != "00:00:00" && (control == pCat || control == null)) {
          JsonKPC.push(JsonParcial);
        }
      });
    }
  }

  JsonKPC = helperMultisort(JsonKPC, ["fechaHora"], ["DESC"]);
  if (anouncer) {
    JsonKPC = JsonKPC.slice(0, 20);
  }

  return { JsonKPCPorTerminar: JsonKPCPorTerminar, JsonKPC: JsonKPC };
};

// ###########################################################################
