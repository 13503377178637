// 

import React from 'react' 

const DetalleCompetidor =()=> {

    
    var resultado = {
        "ab" : 0, // si es gandor absuloto del evento el valor es 1
        "cat" : "Sub Master", //nombre de la categoria 
        "cg" : 5, // cantidad de ganadores de la categoria
        "codCat" : 2, // codigo categoris
        "codMod" : 1, // codigo de la modalidad
        "fhm" : "2017-08-20 07:23:23.852", // la fehc ay hora en la que llego a la meta
        "gen" : "V", // la rama o genero del atleta, varonil o femenil
        "mod" : 14.5,  // la distancia que corrio.
        "mp" : 0, // mp es el minino de participantes que debe haber en la categoria para premiar.
        "nombre" : "MARCO TARDELLY FLORES PARTIDA", // nombre
        "num" : 1, // nunero del dorsal del atleta
        "pCat" : 2, // posicion de categoria
        "pG" : 3, //Posicion general
        "pR" : 3, // posicion segun rama
        "parciales" : [ {   /// array de parciales del atleta, son los puntos de contraol del evento que el atleta atravezo
          "fechaHoraPunto" : "2017-08-20 06:49:05.414", // fecha hora cuando paso por ese punto
          "kpc" : 5, // el kilometro que represente ese punto de control
          "latLng" : "1,1", // latitud y longitud del punto de control, (no se usa casi nunca)
          "pCat" : 1, // numero del punto de control
          "tkpc" : "00:19:18" // el tiempo que hizo el atelta en ese control.
        }, {
          "fechaHoraPunto" : "2017-08-20 07:06:06.451",
          "kpc" : 10,
          "latLng" : "1,1",
          "pCat" : 2,
          "tkpc" : "00:36:19"
        } ],
        "serie" : 1, // la serie de salida del atleta, puede ser 1, 2,3 y mas, va a depender de las salidas que tenga el evento.
        "tc" : "00:53:36", // tiempo chip del atleta.
        "to" : "00:53:36" // tiempo oficial del atleta. 
          }

    return (
        <div>
            <div className="row">
                <h1>{resultado.nombre}</h1>
            </div>

            <div className="row z-depth-2 hoverable">
                <div className="col s12 m4 13 center-align">Posición general<br/>{resultado.pG}</div>
                <div className="col s12 m4 13 center-align">Posición rama<br/>{resultado.pR}</div>
                <div className="col s12 m4 13 center-align">Posición categoria<br/>{resultado.pCat}</div>
        
            </div>
        </div>

        
    
          
    )
}

export default DetalleCompetidor;
