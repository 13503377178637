import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper, IconButton } from "@material-ui/core";
import SimpleDialogDemo from "../../../Modals/SimpleDialog";
import FormRender from "../../../../Fields/FormRender";
import createCodeConfig from "./createCodeConfig.json";
import {
  updateEventAttribute,
  createEventAttribute,
  updateEventObject,
} from "../../../../../data-store/actions/events-actions";
import EditableField from "../../../../EditableField/EditableField";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { eventCodes } from "../../../../../data-store/actions/registry-actions";
import CodeReports from "./CodeReports";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "100%",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CodesGroup(props) {
  const { codeGroup, index } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const registryAutorizedUsers = useSelector(
    (state) => state.registry.registryAutorizedUsers
  );
  const codesList = useSelector((state) => state.registry.codesList);
  const codesGroups = useSelector((state) => state.registry.codesGroups);

  const createEventObject = createCodeConfig.createCode;
  const [testInput, setTestInput] = useState(false);
  const [advance, setAdvance] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    console.log("codeList");
    if (!codesList) dispatch(eventCodes("evento_" + eventDetail.iDEvento));
  }, []);

  function disableCodes() {
    console.log("disable Codes");

    const codesListFilter = Object.values(codesList).filter(
      (a) => a.codeGroup === codesGroups[index].codeGroup
    );

    codesListFilter.forEach((item, index) =>
      setTimeout(() => {
        console.log("deshabilitar", item.code);

        dispatch(
          updateEventAttribute(
            "evento_" + eventDetail.iDEvento,
            "codigosDescuento/codigos/" + item.code + "/",
            { available: false }
          )
        );
        setAdvance(
          "Avance: " +
            Math.round((index / (codesListFilter.length - 1)) * 100) +
            "% código:" +
            item.code
        );
        if (index === codesListFilter.length - 1) {
          setDisabled(false);
          setAdvance("Terminado");
        }
      }, index * 10)
    );
  }
  const eventView = (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h4"} component="h2">
              {codesGroups[index].typeCode}
            </Typography>
            <Typography gutterBottom variant={"h5"} component="h2">
              (ID: {codesGroups[index].codeGroup})
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant={"subtitle1"} component="h2">
              Cantidad de códigos:
            </Typography>
            <Typography variant={"subtitle1"} component="h2">
              {codesGroups[index].codesQuantity}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant={"subtitle1"} component="h2">
              Cantidad de Usos Permitidos:
            </Typography>
            <Typography variant={"subtitle1"} component="h2">
              {codesGroups[index].maxUses}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant={"subtitle1"} component="h2">
              Usuario creador:
            </Typography>
            <Typography variant={"subtitle1"} component="h2">
              {registryAutorizedUsers[codesGroups[index].userID] !== undefined
                ? registryAutorizedUsers[codesGroups[index].userID].nombre
                : codesGroups[index].userID}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} sm={3}>
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={() => disableCodes()}
            >
              Bloquear la lista de códigos completa
            </Button>
            <Typography gutterBottom variant={"h5"} component="h2">
              {advance}
            </Typography>{" "}
          </Grid> */}

          <Grid item xs={12} sm={12}>
            <CodeReports codesGroup={codesGroups[index]} codesIndex={index} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12} sm={12}>
        {eventView}
      </Grid>
    </Grid>
  );
}
