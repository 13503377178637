import React from "react";
import { Modal, Button, Input, Icon, Row } from "react-materialize";
import AtleteDetailsModal from "../Modals/atleteDetailsModal";
import AtleteDetailsContainer from "../reports/atleteDetail/AtleteDetailContainer";
import { getCleanedString } from "../../../services/utils";

class ModalBuscadorResults extends React.Component {
  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  constructor(props) {
    super(props);
    this.state = {
      nombreNumero: 0,
      isOpen: false,
      resultado: null,
    };
  }
  getValue(e) {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }
  toggleModal = (e, resultado) => {
    e.preventDefault();
    console.log("toogle modal");
    this.setState({
      isOpen: !this.state.isOpen,
      resultado: resultado,
    });
  };

  render() {
    var modalDetail;
    if (this.state.isOpen) {
      modalDetail = (
        <AtleteDetailsModal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          report={
            <AtleteDetailsContainer
              eventDetail={this.props.eventDetail}
              resultado={this.state.resultado}
            />
          }
        />
      );
    }

    var ValorBuscado = this.state.nombreNumero;
    let listaAtletas = [];
    if (this.props.eventResults != undefined) {
      if (!isNaN(ValorBuscado)) {
        console.log("numero");
        this.props.eventResults.forEach((value) => {
          //      console.log(ListaAtletas[value]);
          if (value.num == ValorBuscado) {
            if (this.props.certificates) {
              listaAtletas.push(
                <li
                  key={value.num}
                  onclick="certificadoImpresionHTML(${value.num}, ${IDEvento})"
                  className="collection-item"
                >
                  {value.num} - {value.nombre}
                </li>
              );
            } else {
              listaAtletas.push(
                <li
                  key={value.num}
                  onClick={(e) => this.toggleModal(e, value)}
                  className="collection-item"
                >
                  {value.num} - {value.nombre}
                </li>
              );
            }
          }
        });
      } else {
        console.log("texto");
        if (ValorBuscado.length > 2) {
          ValorBuscado = getCleanedString(ValorBuscado);

          var nombreBuscado = ValorBuscado.toUpperCase();
          this.props.eventResults.forEach((value) => {
            var nombreComparar = getCleanedString(value.nombre.toUpperCase());
            if (nombreComparar.match(nombreBuscado) != null) {
              if (this.props.certificates) {
                listaAtletas.push(
                  <li
                    key={value.num}
                    onclick="certificadoImpresionHTML(${value.num})"
                    className="collection-item"
                  >
                    {value.num} - {nombreComparar}
                  </li>
                );
              } else {
                listaAtletas.push(
                  <li
                    key={value.num}
                    onClick={(e) => this.toggleModal(e, value)}
                    className="collection-item"
                  >
                    {value.num} - {nombreComparar}
                  </li>
                );
              }
            }
          });
        }
      }
    }

    let buttom;
    if (this.props.icon) {
      buttom = (
        <li>
          <i className="material-icons md-40 marginIcons">search</i>
        </li>
      );
    } else {
      buttom = (
        <li>
          <a href="#" className="red-text">
            Buscar por Nombre o Número
          </a>
        </li>
      );
    }

    return (
      <div>
        {modalDetail}
        <Modal header="Ingresa tu número o nombre" fixedFooter trigger={buttom}>
          <Row>
            <Input
              name="nombreNumero"
              onChange={(e) => this.getValue(e)}
              type="text"
              label="Nombre o Número"
              validate
              s={12}
            />
          </Row>
          <ul id="ListaNumeroNombre" className="collection">
            {listaAtletas}
          </ul>
        </Modal>
      </div>
    );
  }
}

export default ModalBuscadorResults;
