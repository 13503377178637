import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Modal, Button, Input, Icon, Row } from "react-materialize";
import AtleteDetailsModal from "../../Modals/atleteDetailsModal";
import ParticipantDetailContainer from "./ParticipantDetail/ParticipantDetailContainer";
import { getCleanedString } from "../../../../services/utils";
import FormDialog from "../../Modals/FormDialog";

export default function BuscadorRegistry(props) {
  const { icon } = props;
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  const [athletesList, setAthletesList] = useState();

  console.log(watch("nameOrNumber")); // watch input value by passing the name of it
  console.log(eventParticipants); // watch input value by passing the name of it

  // icon true el modla se activa con el icono de la lupa
  // icon false con el texto buscar por numero o nombre
  const findRegistry = (value) => {
    var ValorBuscado = value;
    let listaAtletas = [];
    if (eventParticipants !== undefined) {
      if (!isNaN(ValorBuscado)) {
        console.log("numero");
        for (var i in eventParticipants) {
          const value = eventParticipants[i];
          const index = i;
          if (value.numero == ValorBuscado) {
            listaAtletas.push(
              <li key={i} className="collection-item">
                <FormDialog
                  buttomLabel={
                    (value.numero == undefined
                      ? "Folio Pendiente"
                      : value.numero) +
                    " - " +
                    value.nombres +
                    " - " +
                    value.apellidos
                  }
                  ShowIcon={false}
                  ButtonStyle={null}
                  maxWidthProp={"lg"}
                  style={{
                    minHeight: "80vh",
                    maxHeight: "80vh",
                  }}
                  report={
                    <ParticipantDetailContainer
                      ParticipantDetail={value}
                      ParticipantDetailKey={index}
                    />
                  }
                />
              </li>
            );
          }
        }
        console.log(listaAtletas);
        return listaAtletas;
      } else {
        console.log("texto");
        if (ValorBuscado.length > 2) {
          ValorBuscado = getCleanedString(ValorBuscado);
          var nombreBuscado = ValorBuscado.toUpperCase();
          for (var i in eventParticipants) {
            const value = eventParticipants[i];
            const index = i;
            console.log(value.nombres);
            if (value.nombres != undefined) {
              const nombreComparar = getCleanedString(
                value.nombres.toUpperCase() +
                  " " +
                  value.apellidos.toUpperCase() +
                  " " +
                  value.correo.toUpperCase() +
                  i
              );
              if (nombreComparar.match(nombreBuscado) != null) {
                listaAtletas.push(
                  <li key={i} className="collection-item">
                    <FormDialog
                      buttomLabel={
                        (value.numero == undefined
                          ? "Folio Pendiente"
                          : value.numero) +
                        " - " +
                        getCleanedString(
                          value.nombres.toUpperCase() +
                            " " +
                            value.apellidos.toUpperCase()
                        )
                      }
                      ShowIcon={false}
                      ButtonStyle={null}
                      maxWidthProp={"lg"}
                      style={{
                        minHeight: "80vh",
                        maxHeight: "80vh",
                      }}
                      report={
                        <ParticipantDetailContainer
                          ParticipantDetail={value}
                          ParticipantDetailKey={index}
                        />
                      }
                    />
                  </li>
                );
              }
            }
          }
        }
        console.log(listaAtletas);
        return listaAtletas;
      }
    }
  };

  const handleChange = (e) => {
    console.log("AntdInput", e.target.value);
    setAthletesList(findRegistry(e.target.value));
    console.log(athletesList);
  };

  let buttom;
  if (icon) {
    buttom = (
      <li>
        <i className="material-icons md-40 marginIcons">search</i>
      </li>
    );
  } else {
    buttom = (
      <li>
        <a href="#" className="red-text">
          Buscar por Nombre o Número
        </a>
      </li>
    );
  }
  return (
    <div>
      <h5>Buscador por Nombre o Número</h5>
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          onChange={handleChange}
          name="nameOrNumber"
          defaultValue=""
          ref={register}
        />
      </form>
      <ul id="ListaNumeroNombre" className="collection">
        {athletesList}
      </ul>
    </div>
  );
}
