import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { CSVLink } from "react-csv";
import { fechahora } from "../../../../../services/utils";

import FormRender from "../../../../Fields/FormRender";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function SpecialReports(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  const eventResults = useSelector((state) => state.events.eventResults);

  const [csvLabels, setCSVLabels] = useState([
    { label: "Número", key: "num" },
    { label: "Posición General", key: "pG" },
    { label: "Posición Rama", key: "pR" },
    { label: "Posición Categoría", key: "pCat" },
    { label: "Nombre", key: "nombre" },
    { label: "Genero", key: "gen" },
    { label: "Categoría", key: "cat" },
    { label: "Modalidad", key: "mod" },
    { label: "Tiempo Oficial", key: "to" },
    { label: "Tiempo Real", key: "tc" },
    { label: "Fecha hora Meta", key: "fhm" },
    { label: "Registro Salida", key: "registroSalida" },
    { label: "Serie", key: "serie" },
  ]);
  const [csvReport, setCSVReport] = useState([]);

  useEffect(() => {
    if (eventResults) {
      setCSVReport(eventResults);
    }
  }, [eventResults]);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Reportes
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}>
            {eventResults && (
              <CSVLink
                filename={
                  "Resultados - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReport}
                headers={csvLabels}
              >
                <Button variant="contained" color="primary">
                  Descargar Resultados
                </Button>
              </CSVLink>
            )}
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
