import React from "react";
import { useSelector } from "react-redux";
import ModalidadesRender from "./ModalidadesRender";
import UpdateConvocatoryItem from "./UpdateConvocatoryItem";

export default function Convocatory() {
  const eventConvocatory = useSelector(
    (state) => state.events.eventConvocatory
  );

  const renderConvocatoria = (item, i) => {
    // hmltLabel = etiqueta que envuleve el texto
    // name = nombre del campo, solo informativo, no se muestra.
    // type = text  - solo texto encapsulado en htmlLabel, puede incluir un divider <hr/>
    //        array  -  lista de cosas por ejm kit.
    //        link  - un link con titulo previo para mostrar algo como convocatoria oficial
    //        modalidades - lista de modalidades y ramas que viene de la configuracion de evento.
    //        contenedor  - es un campo que contendra a otro grupo de campos. con los mismo detalles de un campo tradicional
    //            en contenedor puede tener caracteristicas de estilos.
    //        image  -  imagen que colocar por algun motivo.
    //        ruta  -  Listado de coordenadas guaradadas desde google maps
    // value = valor del campo a mostrar

    var dCTexto;
    var mapas = [];
    //dC[i].htmlLabel;
    //dC[i].value;
    //dC[i].type;
    //dC[i].name;

    const Tag = item.htmlLabel;
    if (item.type == "text") {
      dCTexto = <Tag> {item.value} </Tag>;
    } else if (item.type == "array") {
      item.value.map(function(itemArray) {
        dCTexto = <Tag> {itemArray} </Tag>;
      });
    } else if (item.type == "link") {
      dCTexto = (
        <a className="red-text" href={item.value} target="_blank">
          {item.value}
        </a>
      );
    } else if (item.type == "modalidades") {
      dCTexto = <ModalidadesRender />;
    } else if (item.type == "contenedor") {
    } else if (item.type == "image") {
      dCTexto = (
        <div className="row">
          <div className="col s12 m6 offset-m3 l6 offset-l3">
            <img className="materialboxed" width="100%" src={item.value} />
          </div>
        </div>
      );
    } else if (item.type == "ruta") {
      //				dCTexto = <div class="col s12" style="height:400px;" id="mapa-${i}"></div>;
      //				mapas.push({"ruta":item.value.Ruta, "idDiv":"mapa-"+i});
    } else if (item.type == "place") {
      dCTexto = (
        <a
          className="red-text"
          href="https://maps.google.com/?ll=${item.value.lat},${item.value.lng}&z=18"
          target="_blank"
        >
          Presiona para ver en el mapa
        </a>
      );
    }

    return dCTexto;
  };
  return (
    <div className="row">
      <div className="col s12 m10 offset-m1 l10 offset-l1">
        {eventConvocatory &&
          eventConvocatory.map((ConvcItem, index) => (
            <div>
              <div key={index}>{renderConvocatoria(ConvcItem, index)}</div>
              <UpdateConvocatoryItem item={ConvcItem} index={index} />
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
}
