import React from "react";
import BackForwardButton from "../Forms/BackForwardButton";
import Conekta from "../../../../../conekta/ConektaHelper";
import DynamicInputs from "../../../../DynamicInput/DynamicInputs";
//import M from 'materialize-css';
import axios from "axios";

class ConektaCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getValue = (e, inputObject) => {
    var valor = e.target.value;
    this.setState({ [inputObject.iDCampo]: valor });
  };

  sendPayment = (e) => {
    e.preventDefault();
    this.setState({ sending: true });

    var validarConekta = true;

    let production = true;

    if (this.props.test) {
      production = false;
    }
    Conekta.initConekta(production);

    if (!Conekta.validateCardNumber(this.state.numeroDeTarjeta)) {
      validarConekta = false;

      //M.toast('Revisa el numero de tarjeta!', 2000);
    }
    if (!Conekta.validateCvc(this.state.cvc)) {
      validarConekta = false;
      //M.toast('Revisa el código de seguidad!', 2000);
    }
    if (!Conekta.validateExpirationDate(this.state.MM, this.state.AAAA)) {
      validarConekta = false;
      //M.toast('Revisa la fecha de expiración!', 2000);
    }

    console.log("validar conekta " + validarConekta);

    if (validarConekta) {
      Conekta.tokenize(
        this.state.numeroDeTarjeta,
        this.state.nombreDelTarjetahabiente,
        this.state.MM,
        this.state.AAAA,
        this.state.cvc,
        this.successCallback,
        this.errorCallback
      );
    } else {
      this.setState({ sending: false });
    }
  };
  successCallback = (token) => {
    console.log("succese");
    var amount = 0;
    var order = [];

    for (var i in this.props.athleteData.order) {
      var price = this.props.athleteData.order[i].unit_price;
      if (this.props.athleteData.monedaAUsar.codigo != "MXN") {
        price = parseFloat(
          price * this.props.athleteData.monedaAUsar.cambioAPeso
        );
      }

      var item = {
        name: this.props.athleteData.order[i].name,
        quantity: this.props.athleteData.order[i].quantity,
        unit_price: Math.round(price * 100),
      };

      amount =
        Math.round(
          (parseFloat(amount) +
            parseFloat(price) *
              parseFloat(this.props.athleteData.order[i].quantity)) *
            100
        ) / 100;
      console.log("amount " + amount);
      order.push(item);
    }

    var last4 = this.state.numeroDeTarjeta.substring(
      this.state.numeroDeTarjeta.length - 4,
      this.state.numeroDeTarjeta.length
    );

    var eventoIndex = "evento_" + this.props.eventDetail.iDEvento;
    var jsonDatos = {
      nombres: this.state.nombreDelTarjetahabiente,
      correo: this.props.athleteData.correo,
      telefono: this.props.athleteData.telefono,
      token: token.id,
      tipoPago: "card",
      jsonItems: order,
      inscritoKey: this.props.registryParticipantKey,
      eventoIndex: eventoIndex,
      last4: last4,
      brand: "",
      amount: amount,
    };

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log(res);
        const paymentMethod = 1;
        this.props.setPaymentMethod(paymentMethod, "");

        this.setState({
          sending: false,
        });
        this.props.backForward(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          sending: false,
        });
        var errorTexto = "";
        if (error.responseJSON != undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
        //M.toast("Error procesando el pago: " + errorTexto, 8000);
      });
  };

  errorCallback = (err) => {
    // err keys: object, type, message, message_to_purchaser, param, code
    console.log("error tokenizando " + JSON.stringify(err));
    console.log(err);
    //M.toast('Error:' + err.message_to_purchaser, 6000);
    //TODO: mostrar aqui error al tokenizar, detalle de numero o datos
    this.setState({ sending: false });
  };

  render() {
    let atletaDAta = JSON.stringify(this.props.registryParticipantData);
    let atletaKey = JSON.stringify(this.props.registryParticipantKey);

    const loadingSend = (
      <div className="preloader-wrapper active">
        <div className="spinner-layer spinner-red-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    );

    const buttomSend = (
      <button
        onClick={(e) => this.sendPayment(e)}
        id="sendPaymentButon"
        className="btn waves-effect waves-light"
        type="submit"
        name="action"
      >
        <span id="sendPaymentText">Enviar pago</span>
        <i className="material-icons right">send</i>
      </button>
    );

    let buttomSendFinal = buttomSend;
    if (this.state.sending) {
      buttomSendFinal = loadingSend;
    } else {
      buttomSendFinal = buttomSend;
    }

    const inputObject = {
      Nombre_del_tarjetahabiente: {
        campo: 1,
        etiqueta: "Nombre del tarjetahabiente",
        iDCampo: "nombreDelTarjetahabiente",
        longitud: 100,
        observacion: "",
        tipoCampo: "text",
        validacion: "Requerido",
        valor: "",
        anchoCampo: "s12 m12 l12",
      },
      Número_de_tarjeta: {
        campo: 1,
        etiqueta: "Número de tarjeta",
        iDCampo: "numeroDeTarjeta",
        longitud: 100,
        observacion: "",
        tipoCampo: "text",
        validacion: "Requerido",
        valor: "",
        anchoCampo: "s12 m12 l12",
      },
      CVC: {
        campo: 1,
        etiqueta: "CVC",
        iDCampo: "cvc",
        longitud: 100,
        observacion: "",
        tipoCampo: "number",
        validacion: "Requerido",
        valor: "",
        anchoCampo: "s12 m12 l12",
      },
      MM: {
        campo: 1,
        etiqueta: "MM",
        iDCampo: "MM",
        longitud: 100,
        observacion: "",
        tipoCampo: "number",
        validacion: "Requerido",
        valor: "",
        anchoCampo: "s12 m6 l6",
      },
      AAAA: {
        campo: 1,
        etiqueta: "AAAA",
        iDCampo: "AAAA",
        longitud: 100,
        observacion: "",
        tipoCampo: "number",
        validacion: "Requerido",
        valor: "",
        anchoCampo: "s12 m6 l6",
      },
    };

    var formulario = (
      <form action="" method="POST" id="card-form">
        <span className="card-errors"></span>
        <div className="row">
          <DynamicInputs
            inputObject={inputObject.Nombre_del_tarjetahabiente}
            actualValue={""}
            callbackchange={(e) =>
              this.getValue(e, inputObject.Nombre_del_tarjetahabiente)
            }
          />
          <DynamicInputs
            inputObject={inputObject.Número_de_tarjeta}
            actualValue={""}
            callbackchange={(e) =>
              this.getValue(e, inputObject.Número_de_tarjeta)
            }
          />
          <DynamicInputs
            inputObject={inputObject.CVC}
            actualValue={""}
            callbackchange={(e) => this.getValue(e, inputObject.CVC)}
          />
          <h5>fecha de expiración</h5>
          <DynamicInputs
            inputObject={inputObject.MM}
            actualValue={""}
            callbackchange={(e) => this.getValue(e, inputObject.MM)}
          />
          <DynamicInputs
            inputObject={inputObject.AAAA}
            actualValue={""}
            callbackchange={(e) => this.getValue(e, inputObject.AAAA)}
          />

          {buttomSendFinal}
        </div>
      </form>
    );

    var data = {
      card: {
        number: "4242424242424242",
        name: "Javier Pedreiro",
        exp_year: "2018",
        exp_month: "12",
        cvc: "123",
      },
    };

    return (
      <div id="conektaCardDiv" className="col s12 m6 l6 hoverable">
        <br />
        <br />
        <div className="center">
          <i className="large material-icons md-primary">code</i>
          <h5>Pago con tarjeta de crédito o débito</h5>
          {formulario}
        </div>
      </div>
    );
  }
}

export default ConektaCard;
