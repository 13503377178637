import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Button, Grid, Paper } from "@material-ui/core";
import EditableField from "../../../../EditableField/EditableField";
import { updateEventAttribute } from "../../../../../data-store/actions/events-actions";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { eventParticipants } from "../../../../../data-store/actions/registry-actions";
import ConvertRegistryDataToTimingDataTable from "./ConvertRegistryDataToTimingDataTable";
import SimpleDialogDemo from "../../../../Landing/LayoutContainers/SimpleDialog";
import ImportDataToConvert from "./ImportDataToConvert";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ConvertRegistryDataToTimingData() {
  const dispatch = useDispatch();
  const [arraySubEvents, setArraySubEvent] = useState(false);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  useEffect(() => {
    if (
      !eventParticipantsRequested ||
      eventParticipantsEventIndex !== "evento_" + eventDetail.iDEvento
    ) {
      dispatch(eventParticipants("evento_" + eventDetail.iDEvento));
    }
    setArraySubEvent(setArraySubEventFunc());
  }, [eventSubEvents, eventSubEventsRegistry]);

  const classes = useStyles();
  console.log("modGenCat", arraySubEvents);

  function handleChange(data) {
    console.log("modGenCat", data);
    const modGenCat = arraySubEvents[Object.values(data)[0]];
    const modGenCatKey = Object.keys(data)[0].split("/")[1];

    console.log("modGenCat1", modGenCat);
    console.log("modGenCat1", modGenCatKey);

    if (modGenCat !== "" && modGenCat) {
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          "modalidadesInscripcion/modalidad_" +
            modGenCatKey.split("-")[0] +
            "/ramaCat/rama_" +
            modGenCatKey.split("-")[1] +
            "/categorias/categoria_" +
            modGenCatKey.split("-")[2] +
            "/",
          {
            modGenCatCrono: {
              modalidad: modGenCat.codMod,
              genero: modGenCat.gen,
              categoria: modGenCat.codCat,
            },
          }
        )
      );
      //dispatch(
      // updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
      //   codMod: modGenCat.codMod,
      //   codCat: modGenCat.codCat,
      //   mod: modGenCat.mod,
      //   cat: modGenCat.cat,
      //   gen: modGenCat.gen,
      //   recalcular: true,
      // })
    } else {
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          "modalidadesInscripcion/modalidad_" +
            modGenCatKey.split("-")[0] +
            "/ramaCat/rama_" +
            modGenCatKey.split("-")[1] +
            "/categorias/categoria_" +
            modGenCatKey.split("-")[2] +
            "/",
          {
            modGenCatCrono: null,
          }
        )
      );
    }
  }
  function handleChange2(data) {
    console.log("modGenCat", data);
    const modGenCat = arraySubEvents[data.modGenCat];

    if (modGenCat !== "" && modGenCat) {
      dispatch(
        updateEventAttribute(
          "evento_" + eventDetail.iDEvento,
          "modalidadesInscripcion/modalidad_" +
            modGenCat.modGenCatRegistry.modalidad +
            "/ramaCat/rama_" +
            modGenCat.modGenCatRegistry.genero +
            "/categorias/categoria_" +
            modGenCat.modGenCatRegistry.categoria +
            "/",
          {
            modGenCatCrono: {
              modalidad: modGenCat.codMod,
              genero: modGenCat.gen,
              categoria: modGenCat.codCat,
            },
          }
        )
      );
      //dispatch(
      // updateAthleteResult("evento_" + eventDetail.iDEvento, resultado.num, {
      //   codMod: modGenCat.codMod,
      //   codCat: modGenCat.codCat,
      //   mod: modGenCat.mod,
      //   cat: modGenCat.cat,
      //   gen: modGenCat.gen,
      //   recalcular: true,
      // })
    }
  }
  function setArraySubEventFunc() {
    let array = {};
    for (var mod in eventSubEvents) {
      const modalidad = eventSubEvents[mod];
      const ramas = modalidad.ramaCat;
      for (var gen in ramas) {
        const genero = ramas[gen];
        const categorias = genero.categorias;
        for (var cat in categorias) {
          const categoria = categorias[cat];
          array[
            modalidad.codigoModalidad +
              "-" +
              genero.rama +
              "-" +
              categoria.codigo
          ] = {
            codMod: modalidad.codigoModalidad,
            mod: modalidad.modalidad,
            descripcionModalidad: modalidad.descripcionModalidad,
            gen: genero.rama,
            codCat: categoria.codigo,
            cat: categoria.nombreCategoria,
            modGenCatRegistry: {
              modalidad: modalidad.codigoModalidad,
              genero: genero.rama,
              categoria: categoria.codigo,
            },
          };
        }
      }
    }

    return array;
  }
  function modGenCat(eventSubEvents, gender, modGenCatRegistry) {
    let array = [];
    for (var mod in eventSubEvents) {
      const modalidad = eventSubEvents[mod];
      const ramas = modalidad.ramaCat;
      for (var gen in ramas) {
        const genero = ramas[gen];
        //if (genero.rama === gender) { // ya no filtro por genero por las cetegorias mixtas
        const categorias = genero.categorias;
        for (var cat in categorias) {
          const categoria = categorias[cat];
          array.push({
            label:
              modalidad.descripcionModalidad +
              " - " +
              genero.rama +
              " - " +
              categoria.nombreCategoria,
            value:
              modalidad.codigoModalidad +
              "-" +
              genero.rama +
              "-" +
              categoria.codigo,
          });
        }
        //}
      }
    }
    array.push({
      label: "No definida",
      value: "",
    });

    return array;
  }

  function sugestedCategory(eventSubEvents, modalidad, genero, categoria) {
    if (
      eventSubEvents["modalidad_" + modalidad] &&
      eventSubEvents["modalidad_" + modalidad].ramaCat["rama_" + genero] &&
      eventSubEvents["modalidad_" + modalidad].ramaCat["rama_" + genero]
        .categorias["categoria_" + categoria] &&
      eventSubEvents["modalidad_" + modalidad].ramaCat["rama_" + genero]
        .categorias["categoria_" + categoria].nombreCategoria
    ) {
      return {
        text:
          eventSubEvents["modalidad_" + modalidad].descripcionModalidad +
          " - " +
          genero +
          " - " +
          eventSubEvents["modalidad_" + modalidad].ramaCat["rama_" + genero]
            .categorias["categoria_" + categoria].nombreCategoria,
        data:
          eventSubEvents["modalidad_" + modalidad].codigoModalidad +
          "-" +
          genero +
          "-" +
          eventSubEvents["modalidad_" + modalidad].ramaCat["rama_" + genero]
            .categorias["categoria_" + categoria].codigo,
      };
    } else {
      return {
        text: false,
        data: false,
      };
    }
  }

  function renderDistanceConfig(data) {
    data = Object.values(data).sort((a, b) => a.order - b.order);

    let view = [];
    for (var i in data) {
      const modalidad = data[i];

      view.push(
        <Grid item xs={12}>
          <Typography
            color="primary"
            gutterBottom
            variant={"h2"}
            component="h2"
          >
            {data[i].descripcionModalidad}
          </Typography>
        </Grid>
      );
      const ramas = data[i].ramaCat;
      for (var e in ramas) {
        const rama = ramas[e];
        const categorias = ramas[e].categorias;
        let cateogriesView = [];
        let x = 0;
        for (var f in categorias) {
          const categoria = categorias[f];
          x++;
          cateogriesView.push(
            <Paper
              key={e + "-" + f + "card"}
              style={{ padding: 10, margin: 5, width: "100%" }}
            >
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={5}>
                  <Paper
                    style={{
                      backgroundColor: "#ff4400",
                      color: "#ffffff",
                      padding: 10,
                    }}
                  >
                    <Typography
                      color="secondary"
                      variant={"subtitle1"}
                      align="left"
                    >
                      Categoría de inscripción
                    </Typography>{" "}
                    <Typography color="secondary" variant={"h6"} align="center">
                      {modalidad.descripcionModalidad} - {rama.rama} -{" "}
                      {categoria.nombreCategoria}
                      {/* -{categoria.edadDesde}
                        {categoria.edadHasta === 200
                          ? " y mayores"
                          : " a " + categoria.edadHasta + " años"} */}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} style={{ textAlign: "center" }}>
                  <ArrowRightAltIcon style={{ fontSize: 50 }} />
                </Grid>
                <Grid item xs={5}>
                  <Paper
                    style={{
                      backgroundColor: "#ff4400",
                      color: "#ffffff",
                      padding: 10,
                    }}
                  >
                    <EditableField
                      fieldObject={{
                        label: "Categoría para el crono",
                        errorMessage: "Campo Requerido",
                        fieldName:
                          "modGenCat/" +
                          modalidad.codigoModalidad +
                          "-" +
                          rama.rama +
                          "-" +
                          categoria.codigo,
                        type: "select",
                        defaultValue: categoria.modGenCatCrono
                          ? categoria.modGenCatCrono.modalidad +
                            "-" +
                            categoria.modGenCatCrono.genero +
                            "-" +
                            categoria.modGenCatCrono.categoria
                          : "",
                        // {
                        //   codMod: resultado.codMod,
                        //   mod: resultado.mod,
                        //   descripcionModalidad:
                        //     eventSubEvents["modalidad_" + resultado.codMod]
                        //       .descripcionModalidad,
                        //   gen: resultado.gen,
                        //   codCat: resultado.codCat,
                        //   cat: resultado.categoria,
                        // },
                        required: true,
                        regex: "",
                        textSize: "h6",
                        optionsArray: modGenCat(eventSubEvents, rama.rama, {
                          modalidad: modalidad.codigoModalidad,
                          genero: rama.rama,
                          categoria: categoria.codigo,
                        }),
                      }}
                      onSubmit={handleChange}
                    />
                    {!categoria.modGenCatCrono && eventSubEvents && (
                      <div>
                        <Typography
                          key={e + "-" + f + "title"}
                          color="secondary"
                          variant={"subtitle1"}
                          align="center"
                        >
                          {sugestedCategory(
                            eventSubEvents,
                            modalidad.codigoModalidad,
                            rama.rama,
                            categoria.codigo
                          ).text ? (
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleChange2({
                                  modGenCat: sugestedCategory(
                                    eventSubEvents,
                                    modalidad.codigoModalidad,
                                    rama.rama,
                                    categoria.codigo
                                  ).data,
                                })
                              }
                            >
                              Sugerido:{" "}
                              {
                                sugestedCategory(
                                  eventSubEvents,
                                  modalidad.codigoModalidad,
                                  rama.rama,
                                  categoria.codigo
                                ).text
                              }
                            </Button>
                          ) : (
                            " (Sin sugerencia)"
                          )}
                        </Typography>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          );
        }
        view.push(
          <Grid item xs={12} sm={12} alignItems="center">
            <div style={{ marginLeft: 20 }}>
              <Typography
                key={e + "modalidad"}
                color="secondary"
                gutterBottom
                variant={"h4"}
                component="h2"
              >
                {ramas[e].rama === "V" ? "Varonil" : "Femenil"}
                {cateogriesView}
              </Typography>
            </div>
          </Grid>
        );
      }
    }
    return view;
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      {eventSubEventsRegistry && renderDistanceConfig(eventSubEventsRegistry)}
    </Grid>
  );
}
