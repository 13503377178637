import React from "react";
import logoHeader from "../../../assets/dashport-logos/DASHPORT_white_horizontal.png";
import { Link } from "react-router-dom";
import $ from "jquery";

class HeaderWhiteContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let buttomModal;
    if (this.props.buttomModal != null) {
      buttomModal = this.props.buttomModal;
    }
    return (
      <div className="navbar">
        <nav className="black">
          <div className="nav-wrapper">
            <div className="brand-logo center valign-wrapper">
              <img
                height="45px"
                className="LogoHeader"
                src={logoHeader}
                alt="Logo"
              />
            </div>
            <ul className="right marginClose">
              {buttomModal}
              <li onClick={this.props.onClose}>
                <i className="material-icons md-40">close</i>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default HeaderWhiteContainer;
