import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import TableBody from "@material-ui/core/TableBody";

import IncomeRow from "./IncomeRow";

export default function IncomeTableCont(props) {
  const { camposAdicionales, productosAdicionales, filter, quantity } = props;

  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const registryAutorizedUsers = useSelector(
    (state) => state.registry.registryAutorizedUsers
  );

  var cantidadRegistros = 0;
  for (var i in eventParticipants) {
    cantidadRegistros++;
  }

  var conteoActual = 0;

  let { EventoIndex } = useParams();
  console.log("eventDetail", eventDetail);
  if ("evento_" + eventDetail.iDEvento !== EventoIndex) {
    return (
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography gutterBottom variant={"h2"} component="h2">
            Error Recarga la web
          </Typography>
        </Grid>
      </Grid>
    );
  }

  var dataTable = [];
  var perfilUsuarioEvento = registryAutorizedUsers[userID].perfil;
  for (var i in eventParticipants) {
    conteoActual++;
    if (
      perfilUsuarioEvento == "master" ||
      perfilUsuarioEvento == "MasterCliente" ||
      perfilUsuarioEvento == "MasterOrganizador" ||
      userID == eventParticipants[i].usuarioRegistra
    ) {
      if (cantidadRegistros - quantity < conteoActual) {
        dataTable.push(
          <IncomeRow
            key={i}
            inscritoData={eventParticipants[i]}
            inscritoKey={i}
            camposAdicionales={camposAdicionales}
            productosAdicionales={productosAdicionales}
            filter={filter}
          />
        );
      }
    }
  }
  return <TableBody>{dataTable}</TableBody>;
}
