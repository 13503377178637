import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateEventObject } from "../../../../../../data-store/actions/events-actions";
import { fechahora } from "../../../../../../services/utils";
import FormRender from "../../../../../Fields/FormRender";

export default function AddItem() {
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const userID = useSelector((state) => state.atlete.userID);

  const eventDepositsList = useSelector(
    (state) => state.events.eventDepositsList
  );
  const dispatch = useDispatch();

  function saveDepostit(data) {
    const date = fechahora();
    const push = new Date().getTime();
    const depositData = {
      [push]: {
        dateRegistry: date,
        amount: data.amount,
        depositDate: data.depositDate,
        description: data.description,
        document: data.document,
        userIdAuth: userID,
        type: data.type,
      },
    };

    dispatch(
      updateEventObject(
        "evento_" + eventDetail.iDEvento,
        "deposits/depositsList/",
        { ...eventDepositsList, ...depositData }
      )
    );
  }

  const createFieldObject = {
    type: {
      label: "Tipo de Registro",
      errorMessage: "Campo Requerido",
      fieldName: "type",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "oxxoRegistryPercent",
          label: "Porcentaje de las inscripciones en Oxxo",
        },
        {
          value: "cardRegistryPercent",
          label: "Porcentaje de las inscripciones en TC y TD",
        },
        {
          value: "cashRegistryPercent",
          label: "Porcentaje de las inscripciones en Efectivo",
        },
        {
          value: "fixedAmount",
          label: "Monto Fijo",
        },
        {
          value: "deposit",
          label: "Deposito o transferencia realizada",
        },
      ],
    },
    depositDate: {
      label: "Fecha",
      errorMessage: "Campo Requerido",
      fieldName: "depositDate",
      type: "date",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    amount: {
      label:
        "Monto para valor fijo o Porcentaje para tipos de porcentaje (ejm 100 = 100%) ",
      errorMessage: "Campo Requerido",
      fieldName: "amount",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      min: -1000000000,
      step: "0.01",
    },
    description: {
      label: "Descripción",
      errorMessage: "Campo Requerido",
      fieldName: "description",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    reference: {
      label: "Referencia si aplica",
      errorMessage: "Campo Requerido",
      fieldName: "reference",
      type: "text",
      defaultValue: "",
      required: false,
      regex: "",
      textSize: "h6",
    },
    document: {
      label: "Imagen de soporte (opcional)",
      errorMessage: "Campo Requerido",
      fieldName: "document",
      type: "file",
      defaultValue: false,
      required: false,
      regex: "",
      textSize: "h6",
    },
  };

  if (!eventDepositsList && atleteAccount.perfil !== 1) {
    return <br></br>;
  }

  let showFormToAdd;
  if (atleteAccount.perfil === 1) {
    showFormToAdd = (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: "30px" }}
      >
        <Grid item xs={12}>
          <Typography
            style={{ paddingBottom: "30px" }}
            gutterBottom
            variant={"h5"}
            component="h2"
          >
            Agregar un Depósito
          </Typography>
          <FormRender
            callback={saveDepostit}
            formObject={createFieldObject}
            submitButtonText={"Guardar Depósito"}
          />
        </Grid>
      </Grid>
    );
  }

  return showFormToAdd;
}
