import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import DashboardResumeCard from "./DashboardResumeCard";
import MoneyIcon from "@material-ui/icons/Money";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import StoreIcon from "@material-ui/icons/Store";
const DashboardResume = ({ jsonDashboard }) => {
  var tabla = (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={6} sm={3}>
        <DashboardResumeCard
          title={"Total Cobrado e Inscritos"}
          amount={jsonDashboard.totalCobrado}
          quantity={jsonDashboard.totalInscritos}
          backgroundColor={"#e0e0e0"}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <DashboardResumeCard
          title={"Pagos en Efectivo"}
          amount={jsonDashboard.totalPagosEfectivo}
          quantity={jsonDashboard.cantidadPagosEfectivo}
          icon={<MoneyIcon />}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <DashboardResumeCard
          title={"Pagos completos en oxxo"}
          amount={jsonDashboard.totalCompletoOxxo}
          quantity={jsonDashboard.cantidadCompletoOxxo}
          icon={<StoreIcon />}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <DashboardResumeCard
          title={"Pagos Tarjetas TD y TC"}
          amount={jsonDashboard.totalTarjeta}
          quantity={jsonDashboard.cantidadTarjeta}
          icon={<CreditCardIcon />}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper style={{ padding: 10, margin: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={6} sm={6}>
              <Typography variant={"h6"} align="center">
                Registros incompletos:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"h4"} color="primary" align="center">
                {jsonDashboard.totalIncompletos}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper style={{ padding: 10, margin: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item xs={6} sm={6}>
              <Typography variant={"h6"} align="center">
                Registros Borrados
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant={"h4"} color="primary" align="center">
                {jsonDashboard.totalBorrados}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  return tabla;
};

export default DashboardResume;
