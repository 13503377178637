import React from 'react';

import AtleteModalDev from './AtleteModalDev';
import SesionViewDev from '../Login/SesionView';
import DetalleCompetidor from './DetalleCompetidor';
import ParticipantDetailContainer from '../pages/nextEvents/Registry/ParticipantDetail/ParticipantDetailContainer';
import DashboardContainer from '../pages/nextEvents/Registry/Dashboard/DashboardContainer';
import FormsContainerUI from '../pages/nextEvents/Registry/Forms/FormsContainerUI';
import RegistryContainerPublic from '../pages/nextEvents/Registry/RegistryContainerPublic';
import RegistryContainerPublicSteps from '../pages/nextEvents/Registry/RegistryContainerPublicSteps';
import RegistryConfirmation from '../pages/nextEvents/Registry/RegistryConfirmation/RegistryConfirmation';

import {
    eventDetail,
    registryConfiguration,
    eventSubEvents,
    registryAutorizedUsers,
    eventParticipants,
    ParticipantDetailRelevo,
    eventConfiguration,
    ParticipantDetail,
    userID,
    atleteAccount,
    customStrings,
    registryParticipantDataConfirm,
    registryParticipantKeyConfirm
} from './DevRouterData';
import EventConfigContainer from '../pages/EventsConfig/EventConfigContainer';


export default class DevRouter extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props.match.params.tipo);

    }


    render() {
        var vista = this.props.match.params.tipo;

        let element = "";
        if (vista == "atleteDetail") {
            element = <AtleteModalDev />

        } else if (vista == "Sesion") {
            element = <SesionViewDev />
        } else if (vista == "Dashboard") {

            element = <DashboardContainer
                registryConfiguration={registryConfiguration}
                eventSubEvents={eventSubEvents}
                eventParticipants={eventParticipants}
                registryAutorizedUsers={registryAutorizedUsers}
                eventDetail={eventDetail}
            />
        } else if (vista == "DetalleCompetidor") {
            element = <DetalleCompetidor />

        } else if (vista == "ParticipantDetail") {

            element = <ParticipantDetailContainer ParticipantDetail={ParticipantDetail}
                ParticipantDetailKey="-LK8jmi1vzaUgJjmV0py"
                eventDetail={eventDetail}
                registryConfiguration={registryConfiguration}
                eventSubEvents={eventSubEvents} />

        } else if (vista == "FormUI") {
            element = <FormsContainerUI
                eventDetail={eventDetail}
                eventSubEvents={eventSubEvents}
                eventParticipants={eventParticipants}
                registryConfiguration={registryConfiguration}
                registryAutorizedUsers={registryAutorizedUsers}
                userID={userID}
                atleteAccount={atleteAccount}
                customStrings={customStrings} />
        } else if (vista == "FormPublic") {
                element = <RegistryContainerPublic
                    eventDetail={eventDetail}
                    eventConfiguration={eventConfiguration}
                    registryConfiguration={registryConfiguration}
                    eventSubEvents={eventSubEvents}
                    registryAutorizedUsers={registryAutorizedUsers}
                    userID={userID}
                    atleteAccount={atleteAccount}
                    eventDetail={eventDetail}
                    customStrings={customStrings}
                     />
        } else if (vista == "Confirmation") {
            element = <RegistryConfirmation
                eventDetail={eventDetail}
                eventSubEvents={eventSubEvents}
                registryConfiguration={registryConfiguration}
                registryParticipantDataConfirm={registryParticipantDataConfirm}
                registryParticipantKeyConfirm={registryParticipantKeyConfirm}
                tipo={1}
                reference={92387497234}
                codigoSuscripcion={39847294387}
            />
    } else if (vista == "Configuracion") {
        element = <EventConfigContainer />
    }

        return element;
    }
}
