import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createEventAttribute,
  updateEventAttribute,
} from "../../../../../data-store/actions/events-actions";
import { changesRegistryFunc } from "../../../../../firebase/firebasejs";
import Grid from "@material-ui/core/Grid";

import EditableInput from "../../../../DynamicInput/EditableInput";
import EditableField from "../../../../EditableField/EditableField";
import { translateInput } from "../../../../Fields/FieldUtils";
import { StandarInputsData } from "../StandarInputsData";

export default function ParticipanStandarData(props) {
  const {
    ParticipantDetail,
    ParticipantDetailKey,
    eventDetail,
    userID,
    atleteAccount,
    registryConfiguration,
  } = props;
  const dispatch = useDispatch();

  function handleChange(value) {
    console.log(value);

    const key = Object.keys(value)[0];
    let valueObject = Object.values(value)[0];

    var tipoChangesJson = {
      nombres: { description: "Cambio el nombre de", value: 4 },
      apellidos: { description: "Cambio el apellido de", value: 5 },
      telefono: { description: "Cambio el teléfono de", value: 6 },
      correo: { description: "Cambio el correo de", value: 3 },
      fechaNacimiento: {
        description: "Cambio fecha de nacimiento de",
        value: 7,
      },
      modalidad: { description: "Cambio fecha de modalidad de", value: 8 },
      rama: { description: "Cambio fecha de rama de", value: 9 },
      categoria: { description: "Cambio fecha de categoria de", value: 10 },
    };

    if (key === "fechaNacimiento") {
      valueObject = translateDate(valueObject, "/", "-");
    }

    var changesRegistry = changesRegistryFunc(
      ParticipantDetailKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      tipoChangesJson[key].value,
      `${tipoChangesJson[key].description} ${ParticipantDetail[key] ||
        ""} a ${valueObject}`
    );

    console.log(changesRegistry);
    dispatch(
      updateEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscritos/listadoInscritos/" + ParticipantDetailKey + "/",
        { [key]: valueObject }
      )
    );
    dispatch(
      createEventAttribute(
        "evento_" + eventDetail.iDEvento,
        "inscritos/listadoInscritos/" +
          ParticipantDetailKey +
          "/changesRegistry/",
        changesRegistry
      )
    );
  }

  var jsonCampos = StandarInputsData;

  let nombresApellidosCont;

  var apellidosSeparadosSet =
    registryConfiguration.configuracion.separarApellidos;
  if (apellidosSeparadosSet) {
    nombresApellidosCont = (
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.nombres),
              textSize: "h4",
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.nombres).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.apellidoPaterno),
              textSize: "h4",
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.apellidoPaterno).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.apellidoMaterno),
              textSize: "h4",
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.apellidoMaterno).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
      </Grid>
    );
  } else {
    nombresApellidosCont = (
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={6}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.nombres),
              textSize: "h4",
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.nombres).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={6}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.apellidos),
              textSize: "h4",
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.apellidos).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
      </Grid>
    );
  }

  function translateDate(date, translateTo, translateFrom) {
    const newDate = date.split(translateFrom);
    if (newDate.length > 0) {
      return newDate[0] + translateTo + newDate[1] + translateTo + newDate[2];
    } else {
      return date;
    }
  }
  const birthDefault = translateDate(
    ParticipantDetail[translateInput(jsonCampos.fechaNacimiento).fieldName],
    "-",
    "/"
  );

  return (
    <div>
      {nombresApellidosCont}
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.correo),
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.correo).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.fechaNacimiento),
              defaultValue: birthDefault || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: "10px", paddingRight: "30px" }}
          xs={12}
          sm={4}
        >
          <EditableField
            fieldObject={{
              ...translateInput(jsonCampos.telefono),
              defaultValue:
                ParticipantDetail[
                  translateInput(jsonCampos.telefono).fieldName
                ] || "",
            }}
            onSubmit={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
