import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { fecha } from "../../../services/utils";

export default function DownloadEventsList({ jsonDashboard }) {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const events = useSelector((state) => state.events.events);

  const [csvLabels, setCSVLabels] = useState([
    { label: "Fecha", key: "fechaEvento" },
    { label: "Hora", key: "horaEvento" },
    { label: "Tipo", key: "type" },
    { label: "ID Evento", key: "iDEvento" },
    { label: "Nombre Evento", key: "nombreEvento" },
    { label: "Ciudad", key: "ciudadEvento" },
    { label: "Lugar", key: "lugarEvento" },
    { label: "Logo", key: "logoEvento" },
  ]);
  const [csvReport, setCSVReport] = useState([]);

  useEffect(() => {
    if (events) {
      generateCSV(events);
    }
  }, [events]);

  function generateCSV(data) {
    let report =
      data &&
      Object.values(data)
        .sort((a, b) =>
          a.fechaEvento > b.fechaEvento
            ? 1
            : b.fechaEvento > a.depositDate
            ? -1
            : 0
        )
        .map((item, i) => {
          let amount = 0;
          let date = fecha(0);

          return {
            ...item,
          };
        });

    setCSVReport(report);
    //return { labels: labels, report: report };
  }

  if (!events) {
    return <div></div>;
  }
  return (
    <div>
      {events && atleteAccount.perfil === 1 && (
        <CSVLink
          filename={"Lista de eventos Dashport.csv"}
          data={csvReport}
          headers={csvLabels}
        >
          <Button variant="contained" color="primary">
            Descargar CSV de eventos{" "}
          </Button>
        </CSVLink>
      )}
    </div>
  );
}
