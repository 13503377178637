import React from "react";
import { connect } from "react-redux";
import {
  eventParticipants,
  registryConfiguration,
} from "../../../../../data-store/actions/registry-actions";
import AutorizedUsers from "./AutorizedUsers";
import AddAutorizedUser from "./AddAutorizedUser";
import DeletedParticipantNumber from "./DeleteParticipantsTable/DeletedParticipantList";
import ChangesList from "./ChangesTable/ChangesList";
import DownloadButtomAllData from "../DownloadData/DownloadButtomAllDataNew";
import EventRegistryConfig from "../../../AuthorizedUsers/EventsConfig/EventRegistryConfig";
import { Grid } from "@material-ui/core";

class RegistryAuthorizationContainerView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props.registryAutorizedUsers);
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12}>
          <DownloadButtomAllData
            eventParticipants={this.props.eventParticipants}
            reportType={1}
            eventDetail={this.props.eventDetail}
            registryAutorizedUsers={this.props.registryAutorizedUsers}
            userID={this.props.userID}
            registryConfiguration={this.props.registryConfiguration}
            eventSubEventsRegistry={this.props.eventSubEventsRegistry}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AddAutorizedUser />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutorizedUsers
            registryAutorizedUsers={this.props.registryAutorizedUsers}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DeletedParticipantNumber
            eventParticipants={this.props.eventParticipants}
            userID={this.props.userID}
            atleteAccount={this.props.atleteAccount}
            eventDetail={this.props.eventDetail}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ChangesList
            eventParticipants={this.props.eventParticipants}
            userID={this.props.userID}
            atleteAccount={this.props.atleteAccount}
            eventDetail={this.props.eventDetail}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventParticipants: state.registry.eventParticipants,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
    registryConfiguration: state.registry.registryConfiguration,
    registryAutorizedUsers: state.registry.registryAutorizedUsers,
    userID: state.atlete.userID,
    eventDetail: state.events.eventDetail,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const RegistryAuthorizationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistryAuthorizationContainerView);
export default RegistryAuthorizationContainer;
