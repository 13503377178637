import { Paper, Typography } from "@material-ui/core";
import React from "react";

const AutorizedUsers = ({ registryAutorizedUsers }) => {
  var dataTable = [];

  for (var i in registryAutorizedUsers) {
    dataTable.push(
      <tr key={i}>
        <td>{registryAutorizedUsers[i].nombre}</td>
        <td>{registryAutorizedUsers[i].perfil}</td>
        <td>{registryAutorizedUsers[i].correo}</td>
      </tr>
    );
  }

  return (
    <Paper style={{ margin: 5, padding: 20, minHeight: 320 }}>
      <Typography variant={"h4"} align="left">
        Lista de usuarios
      </Typography>
      <table>
        <thead>
          <tr>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Nombre
              </Typography>
            </th>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Perfil
              </Typography>
            </th>
            <th>
              <Typography variant={"subtitle1"} align="left">
                Correo
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>{dataTable}</tbody>
      </table>
    </Paper>
  );
};
export default AutorizedUsers;
