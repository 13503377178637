import { Button, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { fechahora, getPhotoFileToUpload } from "../../../../services/utils";

function CameraDashport(props) {
  const { eventId } = props;
  const [takePhoto, setTakePhoto] = useState(false);
  const [upload, setUpload] = useState(false);
  const [localDownload, setLocalDownload] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState("");
  const [uploadingPhotos, setuploadingPhotos] = useState(null);
  const [uploadingPhotosProgress, setuploadingPhotosProgress] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (takePhoto) {
        document.getElementById("inner-circle").click();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [takePhoto]);

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...

    console.log("takePhoto");

    console.log(dataUri);
    //callback(dataUri);

    var link = document.createElement("a");
    link.href = dataUri;
    const date = new Date();
    link.download = "evento_" + eventId + " - " + fechahora() + ".jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto2");
  }

  function handleCameraError(error) {
    console.log("handleCameraError", error);
  }

  function handleCameraStart(stream) {
    console.log("handleCameraStart");
  }

  function handleCameraStop() {
    console.log("handleCameraStop");
  }
  const route =
    "eventosCompletos/evento_572" + "/fotosEvento/fotos/puntosFotografico_1/";

  const callback = (file) => {
    const rutaFoto = route ? route : "fotosVarias/" + Date.now();
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 800, "foto").then((image) => {
      if (image) {
        var fotoSinJPG = file.name.substring(0, file.name.length - 4);
        var nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        var storageRef = fb.storage.ref();
        var uploadTask = storageRef
          .child(rutaFoto + "/" + nombreRandom)
          .put(image);
        uploadTask.on(
          "state_changed",
          function(snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setuploadingPhotos(file.name);
            setuploadingPhotosProgress(progress);

            console.log("Upload is " + progress + "% done");
          },
          function(error) {
            // Handle unsuccessful uploads
            setuploadingPhotos(null);
            setuploadingPhotosProgress(null);
          },
          function() {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                setuploadingPhotos(null);
                setuploadingPhotosProgress(null);

                setUrlPhoto(downloadURL);
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };

  return (
    <div>
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
        onTakePhotoAnimationDone={(dataUri) => {
          handleTakePhotoAnimationDone(dataUri);
        }}
        onCameraError={(error) => {
          handleCameraError(error);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{ width: 1280, height: 960 }}
        imageType={IMAGE_TYPES.JPG}
        imageCompression={1}
        isMaxResolution={true}
        isImageMirror={false}
        isSilentMode={false}
        isDisplayStartCameraError={true}
        isFullscreen={false}
        sizeFactor={1}
        onCameraStart={(stream) => {
          handleCameraStart(stream);
        }}
        onCameraStop={() => {
          handleCameraStop();
        }}
      />
      Subiendo : {uploadingPhotos}
      <CircularProgress variant="static" value={uploadingPhotosProgress} />
      <Button onClick={() => setTakePhoto(false)}>Detener</Button>
      <Button onClick={() => setTakePhoto(true)}>Tomar Fotos</Button>
    </div>
  );
}

export default CameraDashport;
